import { useState, useEffect } from "react";

import useWindowSize from "../../../features/use-window-size";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
function useTabContainer(activeResortId: number) {
  const [tabIndex, setTabIndex] = useState(0);
  const [containerRef, setContainerRef] = useState<null | HTMLDivElement>(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    setTabIndex(activeResortId);
  }, [activeResortId]);

  return {
    tabIndex,
    setTabIndex,
    containerRef,
    windowSize,
    setContainerRef,
  };
}

export default useTabContainer;
