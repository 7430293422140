import React, { RefObject } from "react";
import {
  ContentDto,
  IContentHorizontalListBlockDto,
  IContentVideoCarouselBlockDto,
} from "../../app/OwnerService-api";
import EpiWelcomeBlock from "../epi-welcome-block";
import EpiTestimonialBlock from "../epi-testimonial-block";
import EpiGuideBlock from "../epi-guide-block";
import EpiHorizontalListBlock from "../epi-horizontal-list-block";
import EpiVideoCarouselBlock from "../epi-video-carousel-block";
import EpiTwoColumnBlock from "../epi-two-column-block";
import { EpiVideoCarouselBlockType } from "../epi-video-carousel-block";

import "./epi-content-container.scss";
import { useLocation } from "react-router";

type EpiContentContainerProps = {
  content: ContentDto[];
  benefitsRef?: RefObject<HTMLAnchorElement>;
};

const EpiContentContainer = ({ content, benefitsRef }: EpiContentContainerProps): JSX.Element => {
  let reverseOrder = false;
  const location = useLocation();
  const isHome = location.pathname === "/koti" ? true : false;

  return (
    <>
      {content.map((item, i) => {
        if ("_discriminator" in item) {
          if (item["_discriminator"] === "ContentWelcomeBlockDto") {
            return <EpiWelcomeBlock key={item["_discriminator"] + i} data={item} />;
          }
          if (item["_discriminator"] === "ContentGuideBlockDto") {
            reverseOrder = !reverseOrder;

            return (
              <EpiGuideBlock
                key={item["_discriminator"] + i}
                data={item}
                className={reverseOrder ? "odd" : "even"}
              />
            );
          }
          if (item["_discriminator"] === "ContentTestimonialBlockDto") {
            return <EpiTestimonialBlock key={item["_discriminator"] + i} data={item} />;
          }
          if (item["_discriminator"] === "ContentHorizontalListBlockDto") {
            const dataItem = item as IContentHorizontalListBlockDto;
            return (
              <EpiHorizontalListBlock
                key={item["_discriminator"] + i}
                title={dataItem.title || ""}
                items={dataItem.content || []}
                showMoreLink={isHome}
                benefitsRef={benefitsRef}
              />
            );
          }
          if (item["_discriminator"] === "ContentTwoColumnBlockDto") {
            reverseOrder = !reverseOrder;

            return (
              <EpiTwoColumnBlock
                data={item}
                key={item["_discriminator"] + i}
                className={reverseOrder ? "odd" : "even"}
              />
            );
          }
          if (item["_discriminator"] === "ContentVideoCarouselBlockDto") {
            reverseOrder = !reverseOrder;

            const data = item as IContentVideoCarouselBlockDto;
            const dataItem = data as EpiVideoCarouselBlockType;
            return (
              <EpiVideoCarouselBlock
                data={dataItem}
                key={item["_discriminator"] + i}
                className={reverseOrder ? "odd" : "even"}
              />
            );
          }
        }
        return null;
      })}
    </>
  );
};

export default EpiContentContainer;
