import React from "react";
import { IMembershipCardDto } from "../../../app/OwnerService-api";
import Row from "../../flex-row";
import Col from "../../flex-col";
import MembershipCard from "../../membership-card";
import FormGroupContainer from "../../form-group-container";
import cx from "classnames";
import HcInput from "../../hc-input";
import { lang } from "../../../features/i18n";
import HcText from "../../hc-text";
import InfoIcon from "../../../images/info-icon-16.svg";
import OrderCardActionBlock from "../../profile-membership-cards/order-card-action-block";
import useMembershipCards from "../../profile-membership-cards/hooks";
import DefaultActionBlock from "../../profile-membership-cards/default-action-block";
import OrderInfo from "../../profile-membership-cards/order-info";
import MobileCard from "../../profile-membership-cards/mobile-card";
import {useSelector} from "react-redux";
import {selectProfileState} from "../../../store/customer-profile";

type MembershipCardGroupProps = {
  cards: IMembershipCardDto[];
};



const MembershipCardGroup = ({ cards }: MembershipCardGroupProps): JSX.Element => {
  const tRoot = lang.epi.welcomeBlock;
  const {
    visibleCards,
    isManyCards,
    editMode,
    orderDisabled,
    setEditMode,
    t,
    onMembershipCardSelect,
    activeOrder,
    changeActiveOrder,
    selectedCards,
    cancelOrder,
  } = useMembershipCards(cards);

  const profileState = useSelector(selectProfileState);
  const profileData = profileState.profileData[profileState.profileData.length - 1];
  
  const formGroupClass = cx({
    "group-has-many-cards": isManyCards,
    "group-has-single-card": !isManyCards,
  });

  return (
    <Col m={visibleCards.length > 1 ? 12 : 6} className="welcome-block-card-group">
      <FormGroupContainer className={formGroupClass}>
        <Row>
          {visibleCards.map((card, index) => (
            <Col m={visibleCards.length > 1 ? 6 : 12} key={index}>
              <MembershipCard
                isStackSpace={isManyCards}
                memberNumber={card.membershipNumber}
                membershipLevel={card.membershipLevel}
                editMode={editMode}
                editModeDisabled={activeOrder === "mobile-card-order-wip"}
                onMembershipCardSelect={editMode ? onMembershipCardSelect : undefined}
              />
            </Col>
          ))}
        </Row>
          {!editMode && <DefaultActionBlock translate={t} setEditMode={setEditMode} showLinkToMembershipBenefits={false} />}
          {editMode && <OrderInfo translate={t} cancelOrder={() => cancelOrder()} />}
          {editMode && activeOrder === "no-order" && (
            <OrderCardActionBlock
              translate={t}
              setEditMode={setEditMode}
              isOrderingDisabled={orderDisabled}
              changeActiveOrder={changeActiveOrder}
            />
          )}
          {editMode && activeOrder === "mobile-card-order-wip" && (
            <MobileCard
              phone={profileData.phone || ""}
              changeActiveOrder={changeActiveOrder}
              setEditMode={setEditMode}
              selectedCards={selectedCards}
            />
          )}
        <Row className={`${isManyCards ? "mt-tablet-l" : "mt-l"}`}>
          <Col>{t(tRoot.phoneTitle)}</Col>
          <Col>
            <HcInput
              value={t(lang.general.customerServicePhone)}
              readOnly={true}
              className="mb-xs"
              label=""
              aria-label={t(tRoot.phoneTitle)}
            ></HcInput>
          </Col>
          <Col>
            <HcText size="xs" bottomSpace="l" className="info-group">
              <img src={InfoIcon} alt="" />
              {t(lang.general.callPrice)}
            </HcText>
          </Col>
        </Row>
      </FormGroupContainer>
    </Col>
  );
};

export default MembershipCardGroup;
