import { LoadingState, RootState } from "..";
import {
  HoldingReservationDto,
  IHoldingReservationDto,
  IReserveHoldingDto,
  ReserveHoldingDto,
} from "../../app/OwnerService-api";
import reservationsSlice, {
  getReservations,
  reserveHolding,
  cancelHoldingReservation,
} from "./reservations-slice";

export type ReduxReservationsState = {
  reservationsData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reservations: any[]; // serialized HoldingReservationDto[]
    loading: LoadingState;
  };
  sentReservations: { [key: string]: ReduxSentReservationState };
  sentCancellations: { [reservationId: string]: SentCancellationState };
  reservationProcessOpen: boolean;
};

export type ReservationsState = {
  reservationsData: {
    reservations: HoldingReservationDto[];
    loading: LoadingState;
  };
  sentReservations: { [key: string]: SentReservationState };
  sentCancellations: { [reservationId: string]: SentCancellationState };
};

export type ReduxSentReservationState = {
  loading: LoadingState;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reservation: any; // serialized ReserveHoldingDto
};

export type SentReservationState = {
  loading: LoadingState;
  error?: string;
  reservation: ReserveHoldingDto;
};

export type SentCancellationState = {
  loading: LoadingState;
  error?: string;
};

const selectReservationsState = (state: RootState): ReservationsState => ({
  reservationsData: {
    reservations: state.reservations.reservationsData.reservations.map((obj) => {
      const reservation = new HoldingReservationDto(obj);
      const reservationToReturn: IHoldingReservationDto = {
        ...reservation,
        endDate: reservation.endDate,
        reservationDate: reservation.reservationDate,
        startDate: reservation.startDate,
      };

      return new HoldingReservationDto(reservationToReturn);
    }),
    loading: state.reservations.reservationsData.loading,
  },
  sentReservations: Object.entries(state.reservations.sentReservations).reduce<{
    [key: string]: SentReservationState;
  }>((result, [key, serialReservation]) => {
    const initialSentReservation = new ReserveHoldingDto(serialReservation.reservation);
    const sentReservationToReturn: IReserveHoldingDto = {
      ...initialSentReservation,
      endDate: initialSentReservation.endDate,
      startDate: initialSentReservation.startDate,
    };

    return {
      ...result,
      [key]: {
        ...serialReservation,
        reservation: new ReserveHoldingDto(sentReservationToReturn),
      },
    };
  }, {}),
  sentCancellations: state.reservations.sentCancellations,
});

const selectIsReservationProcessOpen = (state: RootState): boolean =>
  state.reservations.reservationProcessOpen;

export const { openReservationProcess, closeReservationProcess } = reservationsSlice.actions;

export {
  selectReservationsState,
  getReservations,
  reserveHolding,
  cancelHoldingReservation,
  selectIsReservationProcessOpen,
};

export default reservationsSlice.reducer;
