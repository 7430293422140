import { useEffect, useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import { HoldingDto, WeekType } from "../../../app/OwnerService-api";
import useReservations from "./use-reservations";
import useCalendarData from "./use-calendar-data";
import useHoldings from "./use-holdings";
import useFirstHolding from "./use-first-holding";
import { HoldingReserved, Wizards } from "..";
import useOverlayContent from "./use-overlay-content";
import useClickedHolding from "./use-clicked-holding";
import { useAppDispatch } from "../../../store";
import {
  closeCalendarOverlay as closeCalendarOverlayAction,
  openCalendarOverlay as openCalendarOverlayAction,
  setActiveHolding as setActiveHoldingAction,
  clearActiveHolding as clearActiveHoldingAction,
  selectActiveHolding,
  selectIsCalendarOverlayOpen,
  selectIsCalendarOverlayClosing,
  cancelOnRequestCloseOverlay as cancelOnRequestCloseOverlayAction,
  onRequestCloseOverlay as onRequestCloseOverlayAction,
} from "../../../store/calendar-overlay";
import {
  setClickedDate as setClickedDateAction,
  clearClickedDate as clearClickedDateAction,
  clearInitDate as clearInitDateAction,
  selectClickedDate,
  selectInitDate,
} from "../../../store/calendar";
import {
  closeReservationProcess as closeReservationProcessAction,
  selectIsReservationProcessOpen,
} from "../../../store/reservations";
import { useSelector } from "react-redux";
import useGTM from "../../../features/use-gtm";
import useSelectedHolding from "./use-selected-holding";
import { selectIsFirstLogin } from "../../../store/home";

export type VisibleDateRangeType = {
  firstDate: Date;
  lastDate: Date;
};

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useHoldingsCalendar = (
  data: HoldingDto[],
  getWizards: (holding: HoldingReserved | undefined) => Wizards
) => {
  const dispatch = useAppDispatch();
  const isCalendarOverlayVisible = useSelector(selectIsCalendarOverlayOpen);
  const isCalendarOverlayClosing = useSelector(selectIsCalendarOverlayClosing);
  const activeHolding = useSelector(selectActiveHolding);
  const clickedDate = useSelector(selectClickedDate);
  const calendarInitDate = useSelector(selectInitDate);

  const { t } = useTypedTranslation();
  const [visibleDateRange, setVisibleDateRange] = useState<VisibleDateRangeType | undefined>(
    undefined
  );
  const [initDate, setInitDate] = useState<Date | undefined>(undefined);
  const [filteredHoldings, setFilteredHoldings] = useState<HoldingReserved[]>([]);
  const { sendDataToGTM } = useGTM();

  /**
   * Is reservation pipeline active
   */
  const isReservationProcessOpen = useSelector(selectIsReservationProcessOpen);

  // Is first login
  const isFirstLogin = useSelector(selectIsFirstLogin);

  /**
   * If reservation process is open confirm closing
   * else close calendar overlay
   */
  const handleOverlayClose = () => {
    if (isReservationProcessOpen) {
      dispatch(onRequestCloseOverlayAction());
    } else {
      closeCalendarOverlay();
    }
  };

  /**
   * Get selected calendar view
   */
  const { changeSelectedHolding, selectedHolding, selectableHoldingsData } = useSelectedHolding(
    data
  );

  /**
   * Get reservations data
   */
  const { reservations, reservationsLoading } = useReservations();

  /**
   * Add reservations data to holdings.
   */
  const holdings = useHoldings(data, reservations);

  /**
   * Filter holdings based on user selection in holdings calendar.
   * SHOW_ALL: no filterin - returns all holdings and reservations
   * Single holding selected: returns selected holdings with reservations
   *
   * If the selected holding is Fixed or PointsFixed --> let's compare both unitId and weekNumber
   */
  useEffect(() => {
    let filtered;
    if (selectedHolding !== "SHOW_ALL") {
      if (
        selectedHolding.weekType === WeekType.PointsFixed ||
        selectedHolding.weekType === WeekType.Fixed
      ) {
        filtered = holdings.filter(
          (h) => h.unitId === selectedHolding.unitId && h.weekNumber === selectedHolding.weekNumber
        );
      } else {
        filtered = holdings.filter((h) => h.unitId === selectedHolding.unitId);
      }
    } else {
      filtered = holdings;
    }
    setFilteredHoldings(filtered);
  }, [selectedHolding, holdings]);

  /**
   * Get clicked holding
   */
  const clickedHolding = useClickedHolding(filteredHoldings, clickedDate);

  /**
   * Get themed calendar data
   */
  const calendarData = useCalendarData(filteredHoldings);

  /**
   * Get the reservation that is closest to the first visible calendar day
   */
  const firstHoldingOrUndefined = useFirstHolding(filteredHoldings, reservations, visibleDateRange);

  /**
   * Get overlay content
   */
  const OverlayContent = useOverlayContent(getWizards, activeHolding, clickedDate, isFirstLogin);

  const isVillasFullOwner = filteredHoldings.some(
    (holding) => holding.weekType === WeekType.VillasFullOwnership
  );

  /**
   * Handle opening calendar overlay in redux store
   */
  const openCalendarOverlay = () => {
    dispatch(openCalendarOverlayAction());
  };

  /**
   * Handle closing calendar overlay in redux store
   */
  const closeCalendarOverlay = () => {
    dispatch(closeCalendarOverlayAction());
    dispatch(closeReservationProcessAction());
    dispatch(clearClickedDateAction());
  };

  /**
   * Update isClosing state to false in redux store
   */
  const cancelOnRequestCloseOverlay = () => {
    dispatch(cancelOnRequestCloseOverlayAction());
  };

  /**
   * Handle calendar click:
   * open overlay if reservation is clicked or user is Villas Full owner
   * @param {Date} day - date clicked
   * @param {boolean} isActive - true if date has highlight color
   */
  const handleCalendarClick = (day: Date, isActive: boolean) => {
    if (isActive || isVillasFullOwner) {
      dispatch(setClickedDateAction(day));
      setInitDate(day);
      openCalendarOverlay();
    }

    if (!isActive && isVillasFullOwner) {
      sendDataToGTM({
        event: "booking_start",
        event_description: "Pyydä varausta",
      });
    }
  };

  /**
   * Handle calendar overlay toggle
   */
  const handleDetailsToggle = () => {
    if (isCalendarOverlayVisible) {
      handleOverlayClose();
    } else {
      if (clickedDate) {
        setInitDate(clickedDate);
      }
      openCalendarOverlay();
    }
  };

  /**
   * Update active reservation in redux store
   */
  useEffect(() => {
    if (clickedHolding && isCalendarOverlayVisible) {
      dispatch(setActiveHoldingAction(clickedHolding));
    } else if (firstHoldingOrUndefined) {
      dispatch(setActiveHoldingAction(firstHoldingOrUndefined));
    } else {
      dispatch(clearActiveHoldingAction);
    }
  }, [firstHoldingOrUndefined, clickedHolding, isCalendarOverlayVisible, dispatch]);

  useEffect(() => {
    if (calendarInitDate) {
      setInitDate(calendarInitDate);
      dispatch(closeCalendarOverlayAction());
      dispatch(clearInitDateAction());
    }
  }, [calendarInitDate, dispatch]);

  return {
    t,
    handleCalendarClick,
    handleDetailsToggle,
    calendarData,
    holdings: filteredHoldings,
    OverlayContent,
    initDate,
    isCalendarOverlayVisible,
    activeHolding,
    reservationsLoading,
    isCalendarOverlayClosing,
    closeCalendarOverlay,
    cancelOnRequestCloseOverlay,
    handleOverlayCloseIconClick: handleOverlayClose,
    sendDataToGTM,
    changeSelectedHolding,
    selectedHolding,
    selectableHoldingsData,
    setVisibleDateRange,
  };
};

export default useHoldingsCalendar;
