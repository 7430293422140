import React from "react";
import { WeekType, HoldingDto } from "../../../app/OwnerService-api";
import HcText from "../../hc-text";
import HcHeading from "../../hc-heading";
import HCLogo from "../../../images/hc-logo-burgundy.svg";
import VillasLogo from "../../../images/villas-128x24.svg";
import Badge from "../../badge";
import cx from "classnames";
import { lang, TypedTranslate } from "../../../features/i18n";
import { format } from "date-fns";
import parse from "html-react-parser";
import { convertOptions } from "../../../features/utils";
import { NextWeekDataType } from "./holdings-tab";

/**
 * Holding info to heading: villas share, rci points or week number
 * @returns React component
 */
const HoldingsTabHeadingContent = ({
  holding,
  translate: t,
}: {
  holding: HoldingDto;
  translate: TypedTranslate;
}): JSX.Element => {
  const tRoot = lang.home.holdingsDetails;

  switch (holding.weekType) {
    case WeekType.PointsFixed: {
      const points = new Intl.NumberFormat("fi-FI").format(holding.points ?? 0);
      return <Badge>{points} pt.</Badge>;
    }
    case WeekType.VillasFractional:
    case WeekType.VillasFullOwnership: {
      return (
        <HcText colorVariant="weak" size="m" bottomSpace="0">
          {t(tRoot.villasShare, { share: holding.villasShare ?? "" })}
        </HcText>
      );
    }
    default:
      return (
        <HcText colorVariant="weak" size="m" bottomSpace="0">
          {t(tRoot.weekNumber, { week: `${holding.weekNumber}` })}
        </HcText>
      );
  }
};

const HoldingsTabHeading = ({
  holding,
  nextWeekData,
  translate: t,
}: {
  holding: HoldingDto;
  nextWeekData: NextWeekDataType | undefined;
  translate: TypedTranslate;
}): JSX.Element => {
  /**
   * @param {string} weekStart
   * @param {string} weekEnd
   * @returns {string} formatted string: `d.M.yyyy - d.M.yyyy`
   */
  const nextRange = (weekStart: string, weekEnd: string) => {
    const start = format(new Date(weekStart), "d.M.yyyy");
    const end = format(new Date(weekEnd), "d.M.yyyy");
    return `${start} - ${end}`;
  };

  const parsedDescription =
    holding.description !== undefined ? parse(holding.description, convertOptions) : <></>;

  const contentCssClasses = cx("holdings-description-container");
  const logoContent =
    holding.weekType === WeekType.VillasFractional ||
    holding.weekType === WeekType.VillasFullOwnership
      ? VillasLogo
      : HCLogo;

  return (
    <>
      <div className="week-logo-container">
        <HoldingsTabHeadingContent holding={holding} translate={t} />
        <img src={logoContent} alt="" className="week-logo-element" />
      </div>

      <HcHeading semanticElement="h3" variant="h2" bottomSpace="s" className="holding-title">
        {holding.resortName}
      </HcHeading>

      <HcText colorVariant="weak" size="s" bottomSpace="0">
        {nextWeekData && nextRange(nextWeekData.weekStart, nextWeekData.weekEnd)}
      </HcText>

      <div className={contentCssClasses}>{parsedDescription}</div>
    </>
  );
};

export default HoldingsTabHeading;
