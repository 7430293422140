import React from "react";
import cx from "classnames";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import HcCheckbox from "../../hc-checkbox/hc-checkbox";
import { WizardStep } from "../../wizard-container";
import Col from "../../flex-col";
import Row from "../../flex-row";
import { lang, TypedTranslate } from "../../../features/i18n";
import { ReservationSlotFrac } from "./types-villas-frac";
import useConfirmVillasFrac from "./use-confirm-villas-frac";
import { formatDateRangeStr } from "../utils";

type Props = {
  selectedReservations: ReservationSlotFrac[];
  translate: TypedTranslate;
  parentClassName: string;
  resortName: string;
  room: string;
  isLastMinute: boolean;
} & WizardStep;

const ConfirmVillasFrac = ({
  nextStep,
  previousStep,
  selectedReservations,
  translate: t,
  parentClassName,
  resortName,
  room,
  isLastMinute,
}: Props): JSX.Element => {
  const { isConfirmChecked, toggleConfirmChecked, handleNextStep } = useConfirmVillasFrac(nextStep);
  const tRoot = lang.home.holdings;
  const className = cx(`${parentClassName}__step--confirm`, "no-p-lr");

  const baseClassName = cx(`${parentClassName}__step--confirm`);
  const selectionsClassName = `${baseClassName}__selections`;
  const selectionClassName = `${selectionsClassName}__selection`;

  /**
   * Check if some of the selected reservations starts in less than 31 days
   * to show the right confirm button text
   */
  const confirmButtonText = isLastMinute
    ? t(tRoot.lastMinuteFrac.requestBooking)
    : t(tRoot.villas.sendReservation);

  return (
    <Col className={className}>
      <Row className={selectionsClassName}>
        <HcText weight="strong">
          {t(tRoot.villas.confirmStep.reservationMsg, { resortName: resortName, room: room })}
        </HcText>

        {selectedReservations.map((reservation, index) => (
          <div key={`reservation-confirmation-${index}`} className={selectionClassName}>
            <HcText size="s">
              {formatDateRangeStr(
                reservation.startDate,
                reservation.endDate,
                t(tRoot.week),
                reservation.weekNumber
              )}
            </HcText>
          </div>
        ))}
      </Row>
      <Row>
        <HcCheckbox
          ariaLabel={t(tRoot.villas.confirmCheckboxLabel)}
          checked={isConfirmChecked}
          onChange={toggleConfirmChecked}
        >
          <HcText size="s" bottomSpace="xs" topSpace="m">
            {t(tRoot.reserved.confirmAcceptTerms)}
          </HcText>
        </HcCheckbox>
        <HcButton onClick={handleNextStep} disabled={!isConfirmChecked} theme="dark">
          {t(confirmButtonText)}
        </HcButton>
        <HcButton onClick={previousStep} isSecondary>
          {t(tRoot.villas.back)}
        </HcButton>
      </Row>
    </Col>
  );
};

export default ConfirmVillasFrac;
