import React from "react";
import Col from "../../flex-col";
import Row from "../../flex-row";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { lang } from "../../../features/i18n";
import { WizardStep } from "../../wizard-container";
import useRentHobby from "../rent-hobby/use-rent-hobby";
import "./rent-hobby.scss";

type RentHobbyProps = {
  resortName: string;
} & WizardStep;

const RentHobby = ({ resortName, nextStep, previousStep }: RentHobbyProps): JSX.Element => {
  const { additionalInformation, handleInformationChange, t } = useRentHobby();
  const tRoot = lang.rentFormModal;

  const classNameRoot = "rent-hobby";
  const classNameContainer = `${classNameRoot}__container no-side-padding`;

  return (
    <Row className={classNameRoot}>
      <Col className={classNameContainer}>
        <Row className="confirm-step-body">
          <Col className="no-side-padding">
            <HcText weight="normal" bottomSpace="s">
              Mikäli haluat jättää myös kyseiseen viikkoon kuuluvia Katinkullan harrasteosakkeita
              vuokralle, voit tehdä sen kirjaamalla harrasteosakkeeet lisätiedot kenttään.
            </HcText>
          </Col>
          <Col className="no-side-padding">
            <textarea
              rows={5}
              className="hc-textarea"
              value={additionalInformation}
              onChange={(e) => handleInformationChange(e.target.value)}
            ></textarea>
          </Col>
        </Row>

        <Row className="step-action-row">
          <Col className="no-side-padding">
            <HcButton onClick={nextStep}>{t(tRoot.continue)}</HcButton>
            <HcButton onClick={previousStep} isSecondary>
              {t(tRoot.back)}
            </HcButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RentHobby;
