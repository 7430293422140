import { useTranslation } from "react-i18next";
import { Interpolation, TypedTranslate } from ".";

import { GET_VALUE } from "./lang-definitions";

/**
 * Should be used instead of the default useTranslation offered by react-i18next
 * For best result, `lang`-object should be used as paramter which is created in the `lang-definitions.ts`-file.
 * `lang`-object is stongly typed, and up to date with current `en.ts` translation-file
 *
 * @returns Translation hook, which accepts strongly typed `lang`-parameter
 */
export default function useTypedTranslation(): { t: TypedTranslate } {
  const { t: untypedT } = useTranslation();

  return {
    t(id: string, interpolation?: Interpolation) {
      const idString: string | undefined = id[GET_VALUE];

      if (!idString) {
        return untypedT(id, interpolation);
      } else {
        return untypedT(idString, interpolation);
      }
    },
  };
}
