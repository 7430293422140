import React from "react";
import cx from "classnames";

import WizardContainer from "../../wizard-container";
import ConfirmVillasFrac from "./confirm-villas-frac";
import InfoVillasFrac from "./info-villas-frac";
import SelectVillasFrac from "./select-villas-frac";
import useReservationVillasFrac from "./use-reservation-villas-frac";
import "./reservation-villas-frac.scss";
import { HoldingReserved, VillasReservationWizardProps } from "..";
import { IReserveHoldingDto, ReservationType, WeekType } from "../../../app/OwnerService-api";
import { ReservationSlotFrac } from "./types-villas-frac";
import { isSameDay } from "date-fns";
import { isPastDate } from "../../date-range/utils";

type Props = {
  holding: HoldingReserved;
} & VillasReservationWizardProps;

const ReservationVillasFrac = ({
  cancelWizard,
  confirmWizard,
  holding,
  reserveHoldings,
  closeOverlay,
}: Props): JSX.Element => {
  const {
    t,
    stepIndex,
    setStepIndex,
    selectedWeekIds,
    setSelectedWeekIds,
    isLastMinute,
    setIsLastMinute,
    daysBefore,
    closeReservationProcess,
  } = useReservationVillasFrac(holding, closeOverlay);

  const className = cx("reservation-villas-frac");

  /**
   * @param {HoldingReserved} holding - holding with weeks
   * @returns {ReservationSlotFrac[]} array of reservations slots
   */
  const holdingReservedToHoldingReservationSlots = (
    holding: HoldingReserved
  ): ReservationSlotFrac[] => {
    return holding.weeks
      ? holding.weeks.reduce<ReservationSlotFrac[]>((slots, week) => {
          if (
            holding.resortName &&
            holding.weekNumber &&
            holding.roomNumber &&
            holding.weekType === WeekType.VillasFractional &&
            !isPastDate(new Date(week.weekStart))
          ) {
            const startDate = new Date(week.weekStart);
            const endDate = new Date(week.weekEnd);
            const reservationStatus = holding.reservations.find(
              (r) => isSameDay(r.startDate, startDate) && isSameDay(r.endDate, endDate)
            )?.reservationStatus;
            return [
              ...slots,
              {
                startDate,
                endDate,
                resortName: holding.resortName,
                room: holding.roomNumber,
                weekNumber: week.weekNumber,
                weekId: week.calendarId,
                reservationStatus,
              },
            ];
          } else {
            return slots;
          }
        }, [])
      : [];
  };

  const reservationSlots = holdingReservedToHoldingReservationSlots(holding);

  /**
   * Reserve selected weeks
   */
  const handleConfirm = () => {
    if (holding.weeks) {
      const reservations: IReserveHoldingDto[] = holding.weeks.reduce<IReserveHoldingDto[]>(
        (selections, week) => {
          if (selectedWeekIds.includes(week.calendarId)) {
            return [
              ...selections,
              {
                holdingId: holding.holdingId,
                contractId: holding.contractId,
                holdingUnitId: holding.unitId,
                weekNumber: week.weekNumber,
                weekType: holding.weekType,
                type: ReservationType.OwnUse,
                startDate: week.weekStart,
                endDate: week.weekEnd,
              },
            ];
          } else {
            return selections;
          }
        },
        []
      );

      reserveHoldings(reservations);
    }

    /**
     * Check if overlay should be closed
     * if reservation is done at last minute, don't close overlay, cancellation info is shown to user
     */
    if (!isLastMinute) {
      confirmWizard();
    }
  };

  return (
    <WizardContainer stepIndex={stepIndex} className={className}>
      <InfoVillasFrac
        nextStep={() => setStepIndex(1)}
        previousStep={cancelWizard}
        resortName={holding.resortName || ""}
        days={daysBefore ? daysBefore.toString() : ""}
        translate={t}
        isLastMinute={isLastMinute}
      />
      <SelectVillasFrac
        nextStep={() => setStepIndex(2)}
        previousStep={closeReservationProcess}
        reservationSlots={reservationSlots}
        parentClassName={className}
        translate={t}
        setSelections={setSelectedWeekIds}
        initialSelections={selectedWeekIds}
        isLastMinute={isLastMinute}
        setIsLastMinute={setIsLastMinute}
      />
      <ConfirmVillasFrac
        nextStep={handleConfirm}
        previousStep={() => setStepIndex(1)}
        selectedReservations={reservationSlots.filter((h) => selectedWeekIds.includes(h.weekId))}
        translate={t}
        parentClassName={className}
        resortName={holding.resortName || ""}
        room={reservationSlots.length > 0 ? reservationSlots[0].room : ""}
        isLastMinute={isLastMinute}
      />
    </WizardContainer>
  );
};

export default ReservationVillasFrac;
