import {
  Configuration,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { IUser } from "./../authorization";
import { IInviteModule } from ".";

const b2cClientId: string =
  process.env.REACT_APP_B2C_CLIENT_ID ?? "59aedd9f-e540-4e22-97e3-0eec09110ff1";
const b2cBaseUrl: string =
  process.env.REACT_APP_B2C_BASE_URL ??
  "https://login-test.holidayclubresorts.com/holidayclubcustomerb2c.onmicrosoft.com";
const b2cSignInPolicyId: string =
  process.env.REACT_APP_B2C_SIGNIN_POLICY_ID ?? "B2C_1A_signin_only";
const b2cResetPolicyId: string = process.env.REACT_APP_B2C_RESET_POLICY_ID ?? "B2C_1_reset";
const b2cInvitePolicyId: string =
  process.env.REACT_APP_B2C_INVITE_POLICY_ID ?? "B2C_1A_signup_invitation";
const b2cDataReadScope: string =
  process.env.REACT_APP_B2C_DATA_READ_SCOPE ??
  "https://holidayclubcustomerb2c.onmicrosoft.com/cc6ad300-c8fb-4520-bed5-07487e6dddd0/Data.Read";

const b2cPolicies = {
  names: {
    signIn: b2cSignInPolicyId,
    forgotPassword: b2cResetPolicyId,
  },
  authorities: {
    signIn: {
      authority: `${b2cBaseUrl}/${b2cSignInPolicyId}`,
    },
    forgotPassword: {
      authority: `${b2cBaseUrl}/${b2cResetPolicyId}`,
    },
    invite: {
      authority: `${b2cBaseUrl}/${b2cInvitePolicyId}`,
    },
  },
  validateAuthority: false,
};

const appRoot: string = window.location.origin.toString();

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: b2cClientId,
    authority: b2cPolicies.authorities.invite.authority,
    knownAuthorities: [
      b2cPolicies.authorities.invite.authority,
      b2cPolicies.authorities.signIn.authority,
      b2cPolicies.authorities.forgotPassword.authority,
    ],
    redirectUri: "/",
    postLogoutRedirectUri: appRoot,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export default class inviteModuleMsal implements IInviteModule {
  private msalInstance: PublicClientApplication;
  private requestBase: RedirectRequest;

  constructor(config?: Configuration) {
    this.msalInstance = new PublicClientApplication(config ?? MSAL_CONFIG);
    this.requestBase = {
      scopes: ["openid", "profile", "offline_access", b2cDataReadScope],
      extraQueryParameters: { ui_locales: "fi" },
    };
  }

  async handleRedirect(): Promise<IUser | void> {
    return this.msalInstance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse && tokenResponse.account) {
        const account = tokenResponse.account;
        const claims = tokenResponse.idTokenClaims as {
          given_name: string;
          family_name: string;
        };
        const accessToken = tokenResponse.accessToken;

        return {
          ...account,
          accessToken,
          givenName: claims.given_name,
          familyName: claims.family_name,
        };
      }
    });
  }

  acquireToken(user?: IUser): Promise<string> {
    const request = {
      ...this.requestBase,
      scopes: [b2cDataReadScope], // "User.Read"
      forceRefresh: false,
    };

    if (user) {
      const silentRequest = {
        ...request,
        account: user,
      };

      return this.msalInstance.acquireTokenSilent(silentRequest).then(
        (tokenResponse) => {
          return tokenResponse.accessToken;
        },
        (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            this.msalInstance.acquireTokenRedirect(request);
            return "NOTOKEN";
          } else {
            throw error;
          }
        }
      );
    }

    throw new InteractionRequiredAuthError("No account present");
  }

  invite(token: string): void {
    const inviteRequest = {
      scopes: ["openid", "profile", "offline_access", b2cDataReadScope],
      extraQueryParameters: {
        ui_locales: "fi",
        id_token_hint: token,
      },
    };
    this.msalInstance.loginRedirect(inviteRequest).catch((e) => {
      console.error(e);
      console.error(JSON.stringify(e, null, 4));
    });
  }
}
