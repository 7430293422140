import { useTypedTranslation, lang } from "../../../features/i18n";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useInfoRented = () => {
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();
  const tRoot = lang.rentFormModal;

  /**
   * Send data to GTM and open link in new tab
   */
  const handleCustomerServiceClick = () => {
    // Google tag manager
    sendDataToGTM({
      event: "telephone_link_click",
      event_description: t(lang.general.customerServicePhone),
    });
    window.open(t(tRoot.rentedCustomerServiceLink), "_blank");
  };

  return { handleCustomerServiceClick, t };
};

export default useInfoRented;
