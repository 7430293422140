import React from "react";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcText from "../../hc-text";
import HcInput from "../../hc-input";
import InfoIcon from "../../../images/info-icon-16.svg";
import useInfoLastMinuteCancellation from "./use-info-last-minute-cancellation";

type Props = {
  resortName: string;
  translate: TypedTranslate;
};

const InfoLastMinuteCancellation = ({ resortName, translate: t }: Props): JSX.Element => {
  const tRoot = lang.home.holdings.reserved;
  useInfoLastMinuteCancellation();

  return (
    <>
      <HcText bottomSpace="s" className="overlay-description-content" size="s" topSpace="l">
        {t(tRoot.lastMinuteInfo, { resortName })}
      </HcText>

      <HcInput
        value={t(lang.general.customerServicePhone)}
        readOnly={true}
        className="mb-xs"
        label=""
        aria-label={t(lang.general.customerServicePhone)}
      />

      <HcText size="xs" bottomSpace="xl" className="info-group">
        <img src={InfoIcon} alt="exclamation mark icon" />
        {t(lang.general.callPrice)}
      </HcText>
    </>
  );
};

export default InfoLastMinuteCancellation;
