import React from "react";
import Carousel from "nuka-carousel";
import ArrowLeft from "./../../images/arrow-left.svg";
import ArrowRight from "./../../images/arrow-right.svg";
import { LazyImage } from "react-lazy-images";
import PlaceholderImg from "../placeholder-img";
import AspectRatioWrapper, { AspectRatioType } from "../aspect-ratio-wrapper";
import { ImageAsset } from "../../app/OwnerService-api";

import "./hc-image-carousel.scss";

export type ImageCarouselProps = {
  data: ImageAsset[] | undefined;
  imgAspectRatio?: AspectRatioType;

  /**
   * Crop carousel image
   */
  cropToAspectRatio?: boolean;
};

const getPlaceholderFallback = (aspectRatio: AspectRatioType) => {
  return (
    <AspectRatioWrapper aspectRatio={aspectRatio}>
      <PlaceholderImg roundedCorners={false} />
    </AspectRatioWrapper>
  );
};

const parseCarouselImages = (
  data: ImageAsset[] | undefined,
  aspectRatio: AspectRatioType,
  crop: boolean
) => {
  if (data !== undefined && data.length > 0) {
    return data.map((item) => {
      const imgAlt = item.description ? item.description : "";

      if (item.sources !== undefined && item.sources.medium.uri !== undefined) {
        const imgSrc = item.sources.medium.uri;
        const isLandscape =
          item.sources.medium.width &&
          item.sources.medium.height &&
          item.sources.medium.width > item.sources.medium.height;
        let imgSrcSet = "";

        for (const key of Object.keys(item.sources).reverse()) {
          imgSrcSet += `${item.sources[key].uri} ${item.sources[key].width}w, `;
        }
        imgSrcSet = imgSrcSet.slice(0, -2);

        return (
          <LazyImage
            src={imgSrc}
            alt={imgAlt}
            key={imgSrc}
            srcSet={imgSrcSet}
            placeholder={({ ref }) => (
              <AspectRatioWrapper aspectRatio={aspectRatio} ref={ref}>
                <PlaceholderImg roundedCorners={false} className="generic-loading-state" />
              </AspectRatioWrapper>
            )}
            actual={({ imageProps }) => (
              <div className="image-container">
                <AspectRatioWrapper aspectRatio={aspectRatio}>
                  {isLandscape ? (
                    <img
                      alt={imageProps.alt}
                      src={imageProps.src}
                      srcSet={imgSrcSet}
                      sizes="(min-width: 1250px) 800px, (min-width: 570px) 1200px, 100vw"
                      className={`carousel-image ${crop ? "carousel-image__crop" : ""}`}
                    />
                  ) : (
                    <div
                      className="portrait-image-placeholder"
                      style={{ backgroundImage: `url(${imageProps.src})` }}
                      role="img"
                      aria-label={imageProps.alt}
                    >
                      {" "}
                    </div>
                  )}
                </AspectRatioWrapper>
              </div>
            )}
          />
        );
      } else {
        return getPlaceholderFallback(aspectRatio);
      }
    });
  } else {
    return getPlaceholderFallback(aspectRatio);
  }
};

/**
 * We are using nuka-carousel with some custom styles.
 *
 * Nuka: https://github.com/FormidableLabs/nuka-carousel
 *
 * @param data Array of image objects
 */
const HcImageCarousel = ({
  data,
  imgAspectRatio = "4:3",
  cropToAspectRatio = false,
}: ImageCarouselProps): JSX.Element => {
  return (
    <>
      {data !== undefined && data.length > 0 ? (
        <Carousel
          wrapAround={true}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide} type="button" className="nav-button prev-arrow">
              <img src={ArrowLeft} className="arrow" alt="Navigate to previous slide" />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} type="button" className="nav-button next-arrow">
              <img src={ArrowRight} className="arrow" alt="Navigate to next slide" />
            </button>
          )}
        >
          {parseCarouselImages(data, imgAspectRatio, cropToAspectRatio)}
        </Carousel>
      ) : (
        getPlaceholderFallback(imgAspectRatio)
      )}
    </>
  );
};

export default HcImageCarousel;
