import { Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import configureAppStore from "./configure-app-store";
import history from "../features/history";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const storeHistory = history;
const store = configureAppStore(storeHistory);

export type AppDispatch = ThunkDispatch<RootState, undefined, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type LoadingState = "idle" | "pending" | "error" | "success";

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
