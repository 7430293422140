import * as React from "react";
import "./../../styles/_flexboxgrid.scss";

export type FlexRowProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

/**
 * Creates flex row element.
 *
 * @param {object} style style object for adding custom css
 */
const FlexRow = ({ children, className, ...props }: FlexRowProps): JSX.Element => {
  const cssClasses = `row${className ? ` ${className}` : ""}`;

  return (
    <div className={cssClasses} {...props}>
      {children}
    </div>
  );
};

export default FlexRow;
