import React from "react";
import { Trans } from "react-i18next";
import { ReservationStatus } from "../../../app/OwnerService-api";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import { CancellationReservationSlot } from ".";
import { daysBeforeRangeStart } from "../../date-range/utils";
import InfoLastMinuteCancellation from "./info-last-minute-cancellation";
import useInfoCancellation from "./use-info-cancellation";

type Props = {
  translate: TypedTranslate;
  reservation: CancellationReservationSlot;
} & WizardStep;

const Info = ({ nextStep, previousStep, translate: t, reservation }: Props): JSX.Element => {
  const tRoot = lang.home;

  const { handleCancelReservationClick } = useInfoCancellation(nextStep);

  const daysBefore = daysBeforeRangeStart(new Date(), reservation.startDate);
  const daysString = t(tRoot.general.inNDays, { days: daysBefore.toString() || "" });

  const resortName = reservation.resortName || "";

  const lastMinuteReservation =
    daysBefore < 31 && reservation.reservationStatus === ReservationStatus.Pending;

  /**
   * If reservation is made last minute and not confirmed, show separate info
   */
  if (lastMinuteReservation) {
    return <InfoLastMinuteCancellation resortName={resortName} translate={t} />;
  }

  return (
    <>
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        <Trans i18nKey="home.holdings.reserved.infoText">
          Seuraava lomasi kohteessa {{ resortName }} alkaa
          <HcText weight="strong" tag="span" size="s">
            {{ days: daysString }}
          </HcText>
          . Suunnittele lomaviikkosi etukäteen tarkastelemalla kohdepalveluja ja -etuja. Jos
          lomallesi tulee este, muistathan peruuttaa varauksesi.
        </Trans>
      </HcText>

      <HcButton onClick={previousStep} theme="dark">
        {t(tRoot.general.makeAnotherBooking)}
      </HcButton>
      <HcButton onClick={handleCancelReservationClick} isSecondary>
        {t(tRoot.general.cancelTarget, { target: t(tRoot.holdings.reserved.reservation) })}
      </HcButton>
    </>
  );
};

export default Info;
