import React from "react";
import { TypedTranslate } from "../../../features/i18n";
import { lang } from "../../../features/i18n";
import HcText from "../../hc-text";
import HcInput from "../../hc-input";
import Col from "../../flex-col";
import HcButton from "../../hc-button";
import { WizardStep } from "../../wizard-container";

type ConfirmMobileCardOrderProps = {
  translate: TypedTranslate;
  phone: string;
} & WizardStep;

const ConfirmMobileCardOrder = ({
  translate: t,
  phone,
  nextStep,
  previousStep,
}: ConfirmMobileCardOrderProps): JSX.Element => {
  const tRoot = lang.membershipCard.orderMobileCard;

  return (
    <Col>
      <HcText weight="strong" bottomSpace="l">
        {t(tRoot.sendToNumberTitle)}
      </HcText>
      <HcInput value={phone} readOnly={true} className="mb-l"></HcInput>

      <HcButton onClick={nextStep} disabled={phone === "" ? true : false}>
        {t(tRoot.confirmPhone)}
      </HcButton>
      <HcButton onClick={previousStep} isSecondary>
        {t(tRoot.previousStep)}
      </HcButton>
    </Col>
  );
};

export default ConfirmMobileCardOrder;
