import React from "react";
import { Field, Form } from "react-final-form";
import FormGroupContainer from "../../form-group-container";
import Row from "../../flex-row";
import Col from "../../flex-col";
import {
  formTitleStyle,
  formGroupStyle,
  MyProfileData,
  MyProfileDataFields,
  ProfileSectionsType,
} from "..";
import EditButton from "../../form-edit-button";
import { Decorator, FormApi, ValidationErrors } from "final-form";
import { arrayEquals } from "../../../features/utils";
import useFormGroup from "./use-form-group";
import HcText from "../../hc-text";
import FormGroupInput from "./form-group-input";
import FormGroupSubmitButton from "./form-group-submit-button";
import createDecorator from "final-form-focus";
import FocusTrap from "focus-trap-react";
import { LoginDataType } from "../login-details/login-details";

type FormGroupProps = {
  isEditable: boolean;
  isInEditMode: boolean;
  toggleIsInEditMode: () => void;
  onSubmit: (data: MyProfileData) => void;
  dataRows: MyProfileData[];
  dataFieldRows: MyProfileDataFields[];
  groupTitle: string;
  groupDescription?: string | JSX.Element;
  fullWidthField?: boolean;
  isSubmitting: boolean;
  validateForm?: (
    values: LoginDataType,
    validationErrors: ValidationErrors
  ) => ValidationErrors | undefined;
  resetAfterSubmit?: boolean;
  formKey?: keyof ProfileSectionsType;
};

const focusOnError = createDecorator() as Decorator;

const FormGroup = ({
  isEditable,
  onSubmit,
  dataRows,
  groupTitle,
  dataFieldRows,
  isInEditMode,
  toggleIsInEditMode,
  groupDescription,
  fullWidthField = false,
  isSubmitting,
  validateForm,
  resetAfterSubmit,
  formKey,
}: FormGroupProps): JSX.Element => {
  const getData = () => {
    return dataRows.reduce(
      (result, data) => ({
        ...result,
        ...data,
      }),
      {} as MyProfileData
    );
  };

  const isValidData = () => {
    if (dataRows.length !== dataFieldRows.length) {
      throw new Error(
        `Length of rows are invalid:\ndataRows length: ${dataRows.length}\ndataFieldRows length: ${dataFieldRows.length}`
      );
    }

    dataRows.forEach((data, index) => {
      const dataKeys = Object.keys(data);
      const dataFieldKeys = Object.keys(dataFieldRows[index]);

      if (!arrayEquals(dataKeys, dataFieldKeys)) {
        throw new Error(
          `Keys of data and dataFields do not match\nKeys of data row ${index}: ${dataKeys}\nKeys of dataField row ${index}: ${dataFieldKeys}`
        );
      }
    });
  };
  isValidData();

  const { validationErrors, setValidationErrors, t } = useFormGroup();

  const onValidate = (
    value: string,
    fieldName: string,
    validation?: (value: string) => string | undefined
  ): string | undefined => {
    if (validation) {
      const message = validation(value);
      const newErrors = validationErrors;

      newErrors[fieldName] = message;
      setValidationErrors(newErrors);

      return message;
    }
  };

  const handleEditButtonPress = (
    e: React.MouseEvent,
    formData: FormApi<MyProfileData, Partial<MyProfileData>>
  ) => {
    e.preventDefault();

    if (isInEditMode) {
      formData.reset(getData());
    }

    toggleIsInEditMode();
  };

  return (
    <FocusTrap active={isInEditMode}>
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={getData()}
          decorators={[focusOnError]}
          validate={validateForm ? (values) => validateForm(values, validationErrors) : undefined}
          render={({ handleSubmit, form, submitting, valid }) => (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (valid) {
                  handleSubmit(event);
                  if (resetAfterSubmit) {
                    form.reset();
                  }
                }
              }}
            >
              <FormGroupContainer isEditable={isInEditMode} isSubmitting={isSubmitting}>
                <Row style={formTitleStyle}>
                  <Col s={6} m={6} l={6}>
                    <span className="form-group-title">{groupTitle}</span>
                  </Col>
                  <Col s={6} m={6} l={6} align="right">
                    <EditButton
                      type="button"
                      inEditMode={isInEditMode}
                      disabled={!isEditable || submitting}
                      onClick={(e) => handleEditButtonPress(e, form)}
                    />
                  </Col>
                </Row>

                {groupDescription && isInEditMode && (
                  <Row>
                    <Col>
                      <HcText>{groupDescription}</HcText>
                    </Col>
                  </Row>
                )}

                {dataFieldRows.map((dataFields, rowIndex) => (
                  <Row style={formGroupStyle} key={`form-row-${rowIndex}`}>
                    {Object.entries(dataFields)
                      .map(([name, fields]) =>
                        fields ? (
                          <Col
                            m={fullWidthField ? 12 : 6}
                            l={fullWidthField ? 12 : 6}
                            key={`form-input-${name}`}
                          >
                            <Field
                              name={name}
                              label={fields.label}
                              readOnly={!isInEditMode}
                              disabled={fields.isDisabled && isInEditMode}
                              type={fields.type}
                              validate={(value) => onValidate(value, name, fields.validate)}
                              format={fields.format}
                            >
                              {(fieldRendererProps) => (
                                <FormGroupInput
                                  fieldRendererProps={fieldRendererProps}
                                  fields={fields}
                                  infoText={fields.infoText}
                                  successText={fields.successText}
                                  inputId={fields.inputId || name}
                                  isSubmitting={isSubmitting}
                                  showErrorWhenActive={fields.showErrorsWhenActive}
                                />
                              )}
                            </Field>
                          </Col>
                        ) : undefined
                      )
                      .filter((val) => val !== undefined)}
                  </Row>
                ))}

                {isInEditMode && (
                  <FormGroupSubmitButton
                    isLoginFormGroup={formKey === "login"}
                    fullWidthField={fullWidthField}
                    translate={t}
                  />
                )}
              </FormGroupContainer>
            </form>
          )}
        />
      </div>
    </FocusTrap>
  );
};

export default FormGroup;
