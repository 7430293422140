import React from "react";
import CalendarDay from "./calendar-day";
import DisabledCalendarDay from "./disabled-calendar-day";
import HcText from "./../hc-text";
import useCalendar from "./hooks/use-calendar";
import { CalendarType, ThemedDateRange } from ".";
import { isPast } from "date-fns";
import { VisibleDateRangeType } from "../holdings-calendar/hooks/use-holdings-calendar";
import "./calendar.scss";

type CalendarProps = {
  /**
   * ISO calendar starts on monday, US calendar starts on Sunday
   */
  calendarType?: CalendarType;

  /**
   * Contains all date ranges in the calendar.
   */
  rangeData?: ThemedDateRange[] | undefined;

  /**
   * Shows different style for hovered day if enabled
   */
  hoverModeEnabled?: boolean;

  /**
   * Called when day is clicked
   */
  onDaySelect?: (day: Date, isActive: boolean) => void;

  /**
   * Called when day is hovered
   */
  onDayHover?: (day: Date, isActive: boolean) => void;

  className?: string;

  /**
   * Date to init calendar month and year by
   */
  initUsingDate?: Date | undefined;

  clickedDate?: Date | undefined;

  /**
   * Is calendar hidden under overlay
   */
  isHidden?: boolean;

  /**
   * Called when visible month changes
   */
  setVisibleDateRange?: (value: VisibleDateRangeType) => void;
};

const Calendar = ({
  calendarType = "ISO",
  rangeData = undefined,
  hoverModeEnabled = false,
  onDaySelect,
  onDayHover,
  className,
  initUsingDate = undefined,
  clickedDate,
  isHidden = false,
  setVisibleDateRange,
}: CalendarProps): JSX.Element => {
  const {
    activeYear,
    activeMonth,
    activeMonthTitle,
    prevMonthTitle,
    nextMonthTitle,
    localizeDayNames,
    calendarData,
    handlePrevMonth,
    handleNextMonth,
  } = useCalendar(calendarType, rangeData, initUsingDate, clickedDate, setVisibleDateRange);

  /**
   * Transform calendarData into an array of ReactNodes
   * @returns {ReactNode[]}
   */
  const getCalendarContent = () => {
    const content: React.ReactNode[] = [];

    calendarData &&
      calendarData.forEach((item, i) => {
        const weekNumber = (
          <div className="week" key={item.dayItem.toISOString() + item.week}>
            <HcText size="xxs" colorVariant="weak" tag="span">
              {item.week}
            </HcText>
          </div>
        );

        // Check if previous and next days have reservations. Used for overlapping day styles.
        const prevIsNone = i > 0 && calendarData[i - 1].highlightType === "none";
        const nextIsNone =
          i + 1 < calendarData.length && calendarData[i + 1].highlightType === "none";

        /**
         * The only past days that are not disabled are those that are part of ongoing reservation.
         * Previous reservations are filtered out of the data
         */
        const day =
          isHidden ||
          (isPast(item.dayItem.setHours(23, 59, 59, 999)) && item.highlightType === "none") ? (
            <DisabledCalendarDay day={item.dayItem} key={item.dayItem.toISOString()} />
          ) : (
            <CalendarDay
              day={item.dayItem}
              key={item.dayItem.toISOString()}
              dayTextStyle={item.dayColorStyle}
              highlightColor={item.highlightColor}
              highlightType={item.highlightType}
              showHover={hoverModeEnabled}
              onDaySelect={onDaySelect}
              onDayHover={onDayHover}
              prevIsNone={prevIsNone}
              nextIsNone={nextIsNone}
            />
          );

        if (i % 7 === 0) {
          content.push(<React.Fragment key={item.week}>{[weekNumber, day]}</React.Fragment>);
        } else {
          content.push(day);
        }
      });

    return <>{content}</>;
  };

  const cssClasses = `calendar-container${className ? ` ${className}` : ""}`;

  return (
    <div className={[cssClasses].join(" ")}>
      <div className="calendar">
        <div className="month-indicator">
          <div className="wrap" key="nav-prev">
            <button onClick={handlePrevMonth} className="nav" disabled={isHidden}>
              {prevMonthTitle}
            </button>
          </div>
          <div className="nav-spacer">|</div>
          <div className="wrap current-month" data-date-time={`${activeYear}-${activeMonth}`}>
            {activeMonthTitle} {activeYear}
          </div>
          <div className="nav-spacer">|</div>
          <div className="wrap" key="nav-next">
            <button onClick={handleNextMonth} className="nav" disabled={isHidden}>
              {nextMonthTitle}
            </button>
          </div>
        </div>
        <div className="day-of-week">
          <div></div>
          {localizeDayNames.map((day, i) => (
            <div key={i}>
              <HcText size="xxs" colorVariant="weak" tag="span">
                {day}
              </HcText>
            </div>
          ))}
        </div>
        <div className={`date-grid `}>{getCalendarContent()}</div>
      </div>
    </div>
  );
};

export default Calendar;
