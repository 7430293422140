import React from "react";
import { EpiWelcomeBlockProps } from ".";
import Row from "../flex-row";
import Col from "../flex-col";
import MembershipCardGroup from "./membership-card-group";
import HcHeading from "../hc-heading";
import cx from "classnames";
import parse from "html-react-parser";
import { convertOptions } from "../../features/utils";
import "./epi-welcome-block.scss";
import FlexContainer from "../flex-container";

const EpiWelcomeBlock = ({ data }: EpiWelcomeBlockProps): JSX.Element => {
  const { title, content, membershipCards } = data;
  const contentCssClasses = cx("content-base");
  const blockContainerCss = cx("epi-welcome-block-container");

  let parsedContent: JSX.Element[] = [];
  if (content !== undefined) {
    parsedContent = parse(content, convertOptions) as JSX.Element[];
  }
  return (
    <FlexContainer className={blockContainerCss}>
      <Row>
        <Col l={9} className="block-text-content">
          <HcHeading semanticElement="h1">{title}</HcHeading>
          <div className={contentCssClasses}>{parsedContent}</div>
        </Col>
        <Col l={3}>
          {membershipCards !== undefined && membershipCards.length > 0 ? (
            <MembershipCardGroup cards={membershipCards} />
          ) : (
            <div>Jäsenyyskortteja ei löytynyt</div>
          )}
        </Col>
      </Row>
    </FlexContainer>
  );
};

export default EpiWelcomeBlock;
