import React from "react";

type Props = {
  isHidden: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const OverflowButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ isHidden, ...props }, ref): JSX.Element => {
    const buttonIconPath = `${process.env.PUBLIC_URL}/mini-menu.svg`;

    return (
      <button ref={ref} {...props} className={`overflow-button${isHidden ? "--hidden" : ""}`}>
        <img src={buttonIconPath} alt="mini-menu" />
      </button>
    );
  }
);

OverflowButton.displayName = "OverflowButton";
export default OverflowButton;
