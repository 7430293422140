import { useSelector } from "react-redux";
import { ReservationType } from "../../../app/OwnerService-api";
import { useAppDispatch } from "../../../store";
import {
  closeRentForm,
  selectHoldings,
  selectIsRentFormOpen,
  selectRentFormHoldingUnitId,
  selectRentFormReservations,
  selectRentFormWeek,
  selectRentFormInformation,
} from "../../../store/home";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function useRentFormModalRedux() {
  const dispatch = useAppDispatch();
  const holdingUnitId = useSelector(selectRentFormHoldingUnitId);
  const holdings = useSelector(selectHoldings);
  const isOpen = useSelector(selectIsRentFormOpen);
  const reservations = useSelector(selectRentFormReservations);
  const rentFormWeek = useSelector(selectRentFormWeek);
  const holding = holdings.find(
    (holding) =>
      holding.unitId === holdingUnitId &&
      holding.weeks?.some((week) => week.calendarId === rentFormWeek?.calendarId)
  );

  function closeRentFormModal() {
    dispatch(closeRentForm());
  }

  const activeWeek =
    rentFormWeek &&
    reservations.find(
      (week) => week.startDate === rentFormWeek.weekStart && week.endDate === rentFormWeek.weekEnd
    );

  const rented: boolean = activeWeek !== undefined && activeWeek.type === ReservationType.ForRental;

  const additionalInformation = useSelector(selectRentFormInformation);

  return {
    isOpen,
    closeRentFormModal,
    holding,
    rented,
    rentFormWeek,
    additionalInformation,
  };
}

export default useRentFormModalRedux;
