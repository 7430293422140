import useReduxModal from "../../redux-modal/use-redux-modal";
import { useHistory } from "react-router-dom";
import { lang } from "../../../features/i18n";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBannerModalContent = (id: string, resetFilters: (() => void) | undefined) => {
  const { closeModal } = useReduxModal(id);
  const { t } = useTypedTranslation();
  const history = useHistory();
  const tRoot = lang.benefitsPage.general;

  const handleShowAllBtnClick = () => {
    if (resetFilters) {
      resetFilters();
    }
    closeModal();

    const pushData = {
      pathname: "osakasedut",
      state: {
        scrollPosition: "tabs",
        activeTab: t(tRoot.searchTabHash),
      },
    };
    history.push(pushData);
  };

  return {
    closeModal,
    handleShowAllBtnClick,
  };
};

export default useBannerModalContent;
