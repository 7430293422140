import { useTypedTranslation } from "../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useEpiHorizontalList = () => {
  const { t } = useTypedTranslation();

  return {
    t,
  };
};

export default useEpiHorizontalList;
