import React from "react";
import HcCheckbox from "../../hc-checkbox";
import HcText from "../../hc-text";
import { BenefitType } from "../../../app/OwnerService-api";
import { lang, TypedTranslate } from "../../../features/i18n";

import "./benefit-filter-badge.scss";

type BenefitFilterBadgeProps = {
  filterType: BenefitType;
  isActive: boolean;
  toggle: () => void;
  translate: TypedTranslate;
  describedBy: string;
};

const BenefitFilterBadge = ({
  filterType,
  isActive,
  toggle,
  translate: t,
  describedBy,
}: BenefitFilterBadgeProps): JSX.Element => {
  const tRoot = lang.benefitsPage;

  const getFilterContent = (item: BenefitType) => {
    let result = "";

    switch (item) {
      case BenefitType.NewShareOwner:
      case BenefitType.ShareOwner:
        result = t(tRoot.search.generalBenefits);
        break;
      case BenefitType.Destination:
        result = t(tRoot.search.destinationBenefits);
        break;
      case BenefitType.Gold:
        result = t(tRoot.search.goldBenefits);
        break;
      case BenefitType.Villas:
        result = t(tRoot.search.villasBenefits);
        break;
    }

    return result;
  };

  return (
    <HcCheckbox
      ariaLabel={getFilterContent(filterType)}
      onChange={toggle}
      checked={isActive}
      className="badge-cb-container"
      aria-describedby={describedBy}
    >
      <HcText bottomSpace="0">{getFilterContent(filterType)}</HcText>
    </HcCheckbox>
  );
};

export default BenefitFilterBadge;
