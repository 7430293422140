import useGTM from "../../../features/use-gtm";
import useReduxModal from "../../redux-modal/use-redux-modal";
import {
  HoldingDto,
  HoldingReservationDto,
  ICalendarDto,
  IHoldingDto,
  WeekType,
} from "../../../app/OwnerService-api";
import { useAppDispatch } from "../../../store";
import { openRentForm } from "../../../store/home";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useHoldingsTabLinks = (holding: HoldingDto, reservationsData: HoldingReservationDto[]) => {
  const { sendDataToGTM } = useGTM();
  const { openModal } = useReduxModal("");

  /**
   * Open modal with id
   * @param {string } modalId
   */
  const handleVillasReservationLinkClick = (modalId: string) => {
    openModal(modalId);
  };

  const dispatch = useAppDispatch();

  /**
   * Open rent form modal
   * @param {number} holdingUnitId
   * @param {HoldingReservationDto[]} reservations
   * @param {ICalendarDto} week
   */
  const openRentFormModal = (
    holdingUnitId: IHoldingDto["unitId"],
    reservations: HoldingReservationDto[],
    week: ICalendarDto
  ) => {
    if (
      holding &&
      (holding.weekType === WeekType.Fixed || holding.weekType === WeekType.PointsFixed)
    ) {
      dispatch(
        openRentForm({
          holdingUnit: holdingUnitId,
          reservations: reservations,
          week: week,
          additionalInformation: "",
        })
      );
    }
  };

  /**
   * Send data to GTM and open rent form modal
   * @param {number} holdingUnitId
   * @param {ICalendarDto} weeksData
   */
  const handleRentFormOpen = (holdingUnitId: IHoldingDto["unitId"], weeksData: ICalendarDto) => {
    const clickedHoldingReservations = reservationsData.filter(
      (reservation) =>
        reservation.holdingUnitId === holdingUnitId &&
        reservation.startDate === weeksData.weekStart &&
        reservation.endDate === weeksData.weekEnd
    );

    // Google Tag Manager
    sendDataToGTM({
      event: "rent_week_start",
      event_description: "Vuokraa viikko eteenpäin",
    });

    openRentFormModal(holdingUnitId, clickedHoldingReservations, weeksData);
  };

  return { handleVillasReservationLinkClick, handleRentFormOpen };
};

export default useHoldingsTabLinks;
