import React from "react";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcButton from "../../hc-button";
import HcInput from "../../hc-input";
import HcText from "../../hc-text";
import ArrowLeft from "./../../../images/arrow-left-small.svg";
import useNewAccount from "./use-new-account";
import "./new-account.scss";
import { Feedback } from "../../input-feedback";

type NewAccountProps = {
  translate: TypedTranslate;
  onFormClose: () => void;
  onNewAccountSave: (e: React.MouseEvent<HTMLButtonElement>, value: string) => void;
  newAccountFeedback: Feedback | undefined;
  resetNewAccountFeedback: () => void;
  validateBankAccount: (bankAccount: string) => void;
};

const NewAccount = ({
  translate: t,
  onFormClose,
  onNewAccountSave,
  newAccountFeedback,
  resetNewAccountFeedback,
  validateBankAccount,
}: NewAccountProps): JSX.Element => {
  const { isSaveDisabled, handleIBANInputChange, accountNumber, handleClick } = useNewAccount(
    resetNewAccountFeedback,
    onNewAccountSave
  );
  const tRoot = lang.accountNumber;

  return (
    <div className="new-account-form-container">
      <HcText bottomSpace="l">
        <button className="new-account-back-button" onClick={onFormClose}>
          <img src={ArrowLeft} alt="" />
        </button>
        <HcText tag="span" colorVariant="black" className="new-account-form-title">
          {t(tRoot.createNewAccount)}
        </HcText>
      </HcText>
      <HcText colorVariant="weak" size="s" bottomSpace="xs">
        {t(tRoot.IBANFieldLabel)}
      </HcText>
      <HcInput
        feedback={newAccountFeedback}
        className="new-account-field"
        onChange={(e) => handleIBANInputChange(e)}
        onBlur={accountNumber.length > 0 ? () => validateBankAccount(accountNumber) : undefined}
      />
      <HcButton disabled={isSaveDisabled} onClick={handleClick}>
        {t(tRoot.saveNewAccountNumber)}
      </HcButton>
    </div>
  );
};

export default NewAccount;
