import React from "react";
import ReduxModal from "../../redux-modal";
import HcHeading from "../../hc-heading/hc-heading";
import HcText from "../../hc-text/hc-text";
import HcButton from "../../hc-button";
import { lang, TypedTranslate } from "../../../features/i18n";
import "./alert-modal.scss";

type AlertModalProps = {
  translate: TypedTranslate;
  id: string;
  closeModal: () => void;
};

const AlertModal = ({ translate: t, id, closeModal }: AlertModalProps): JSX.Element => {
  const tRoot = lang.profile.alertModal;
  const header = (
    <HcHeading semanticElement="h5" topSpace="l">
      {t(tRoot.title)}
    </HcHeading>
  );
  return (
    <ReduxModal
      id={id}
      ariaLabel="my-storyboook-modal"
      shouldConfirmBeforeClose={false}
      defaultPadding={false}
      header={header}
    >
      <div className="alert-modal-container">
        <div className="alert-modal-content">
          <HcText size="s" bottomSpace="xl">
            {t(tRoot.description)}
          </HcText>
        </div>
        <div className="alert-modal-footer">
          <div className="alert-modal-footer-button-wrapper">
            <HcButton onClick={closeModal}>{t(tRoot.closeButtonText)}</HcButton>
          </div>
        </div>
      </div>
    </ReduxModal>
  );
};

export default AlertModal;
