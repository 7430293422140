import { useMemo } from "react";
import { HoldingReserved } from "..";
import { WeekType } from "../../../app/OwnerService-api";
import { isReservation } from "../utils";
import { differenceInCalendarDays } from "date-fns";

type ReservationToCompare = {
  holding: HoldingReserved;
  reservationLength: number;
};

/**
 * Get clicked holding: if multiple reservations match clicked date,
 * the shortest one is displayed.
 * @param {HoldingReserved[]} holdings - array of holdings owned by user
 * @param {Date} clickedDate - date that user clicked
 * @returns {(HoldingReserved|undefined)} the shortest reservation on clicked date or undefined
 */
const getShortestMatchingReservation = (
  holdings: HoldingReserved[],
  clickedDate: Date
): HoldingReserved | undefined => {
  let matchingReservations: ReservationToCompare[] = [];

  holdings.forEach((h) => {
    const addMatchingReservation = (start: Date, end: Date): void => {
      if (isReservation(clickedDate, start, end)) {
        matchingReservations = [
          ...matchingReservations,
          {
            holding: h,
            reservationLength: differenceInCalendarDays(end, start),
          },
        ];
      }
    };

    for (const r of h.reservations) {
      addMatchingReservation(r.startDate, r.endDate);
    }

    if (h.weeks) {
      for (const w of h.weeks) {
        addMatchingReservation(new Date(w.weekStart), new Date(w.weekEnd));
      }
    }
  });

  return matchingReservations.length > 0
    ? matchingReservations.reduce((shortest, r) =>
        shortest === undefined || r.reservationLength < shortest.reservationLength ? r : shortest
      ).holding
    : undefined;
};

/**
 * @param {HoldingReserved[]} holdings
 * @param {Date} [clickedDate]
 * @returns {(HoldingReserved|undefined)} if clickedDate, holding with clicked reservation.
 * If user is Villas Full owner and no reservation is clicked, returns Villas Full holding
 */
const useClickedHolding = (
  holdings: HoldingReserved[],
  clickedDate?: Date
): HoldingReserved | undefined => {
  const clickedHolding = useMemo<HoldingReserved | undefined>(() => {
    if (holdings.length === 0 || clickedDate === undefined) {
      return undefined;
    }

    const clicked = getShortestMatchingReservation(holdings, clickedDate);

    return clicked || holdings.find((holding) => holding.weekType === WeekType.VillasFullOwnership);
  }, [clickedDate, holdings]);

  return clickedHolding;
};

export default useClickedHolding;
