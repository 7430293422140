import React from "react";
import { FieldRenderProps } from "react-final-form";
import { MyProfileField } from "..";
import InputAdapter from "../input-adapter";

type Props = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  fieldRendererProps: FieldRenderProps<any, HTMLElement>;
  fields: MyProfileField;
  infoText?: string;
  successText?: string;
  inputId: string;
  showErrorWhenActive?: boolean;
  isSubmitting?: boolean;
};

/**
 * Input for FormGroup. This should be only be called inside Field-tag of react-final-form-library.
 */
const FormGroupInput = ({
  fieldRendererProps,
  fields,
  infoText,
  successText,
  inputId,
  showErrorWhenActive = false,
  isSubmitting = false,
}: Props): JSX.Element => {
  const { readOnly, meta } = fieldRendererProps;

  const metaWithError = {
    ...meta,
    error:
      !readOnly && !isSubmitting && (meta.touched || (showErrorWhenActive && meta.active))
        ? meta.error
        : "",
  };

  if (fields.AdapterComponent) {
    const InheritedInputAdapter = fields.AdapterComponent;

    return <InheritedInputAdapter {...fieldRendererProps} meta={metaWithError} id={inputId} />;
  } else {
    return (
      <InputAdapter
        {...fieldRendererProps}
        meta={metaWithError}
        infoText={infoText}
        successText={successText}
        inputId={inputId}
      />
    );
  }
};

export default FormGroupInput;
