import TabContainer from "./tab-container";

export type TabContent = {
  header: string;
  Content: () => JSX.Element;
};

export type TabContainerProps = {
  contentList: TabContent[];
  activeResortId?: number;
  onTabSelect?: (tab: string) => void;
};

export default TabContainer;
