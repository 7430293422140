import { useMemo } from "react";
import { HoldingReserved, ReservationInfo } from "..";
import { IHoldingDto, IHoldingReservationDto } from "../../../app/OwnerService-api";
import { isSameDay } from "date-fns";

/**
 * @param {LoadingState} reservationsLoading - loading state of reservations
 * @param {IHoldingDto[]} holdingDtos - array of holdings
 * @param {IHoldingReservationDto[]} reservationDtos - array of reservations
 * @returns {HoldingReserved[]} holdings combined with reservations
 */
const useHoldings = (
  holdingDtos: IHoldingDto[],
  reservationDtos: IHoldingReservationDto[]
): HoldingReserved[] => {
  const holdings: HoldingReserved[] = useMemo(() => {
    /**
     * Combine reservations with holdings data
     */
    return holdingDtos.map((holding) => {
      const foundReservations = reservationDtos.filter((r) => {
        if (holding.weeks && holding.weeks.length) {
          return (
            r.holdingUnitId === holding.unitId &&
            holding.weeks.some((w) => isSameDay(new Date(w.weekStart), new Date(r.startDate)))
          );
        }
        return r.holdingUnitId === holding.unitId;
      });

      const reservationInfo: ReservationInfo[] = foundReservations.map((r) => ({
        reservationDate: new Date(r.reservationDate),
        startDate: new Date(r.startDate),
        endDate: new Date(r.endDate),
        reservationStatus: r.status,
        reservationId: r.reservationId,
        type: r.type,
      }));

      const reservedHolding = {
        ...holding,
        reservations: reservationInfo,
      };

      return reservedHolding;
    });
  }, [reservationDtos, holdingDtos]);

  return holdings;
};

export default useHoldings;
