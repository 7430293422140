import * as React from "react";

import { Container } from "reactstrap";

import HcAvatar from "./../hc-avatar";
import Row from "./../flex-row";
import Col from "./../flex-col";

import LoginDetails from "./login-details";
import PersonalDataDetails from "./personal-data-details";
import ContactDetails from "./contact-details";
import AddressDetails from "./address-details";

import useProfile from "./hooks/use-profile";
import { ProfileHooks } from "../../routes/profile";
import MembershipCards from "../profile-membership-cards";

import CloseIcon from "../../images/x-icon-32.svg";

import { Link } from "react-router-dom";
import { lang } from "../../features/i18n";
import AlertModal from "./alert-modal";
import Error500 from "../error-500";
import "./profile.scss";

const Profile = (): JSX.Element => {
  const alertModalId = "profile-alert-modal";
  const {
    profileData,
    profileLoading,
    getInitials,
    t,
    commonOnSubmit,
    editMode,
    changePassword,
    isEditable,
    toggleIsInEditMode,
    submitMode,
    closeAlertModal,
  }: ProfileHooks = useProfile(alertModalId);

  const tRoot = lang;

  let content;

  if (profileData) {
    content = (
      <>
        <Row>
          <Col l={8} lOffset={2} m={10} s={11}>
            <div className="avatar-title-group">
              <HcAvatar>{getInitials()}</HcAvatar>
              <h1 className="h1-title">Omat tiedot ja asetukset</h1>
            </div>
          </Col>
          <Col l={2} m={2} s={1} className="profile-close-button-container">
            <Link to="/">
              <img src={CloseIcon} alt={t(tRoot.profile.closeIconAltText)} />
            </Link>
          </Col>
        </Row>
        <div className="profile-form-content">
          <Row>
            <Col l={6} lOffset={2}>
              <LoginDetails
                isEditable={isEditable("login")}
                onEditModeChange={() => toggleIsInEditMode("login")}
                editMode={editMode.login}
                profileData={profileData}
                changePassword={changePassword}
                isSubmitting={submitMode.login}
                formKey={"login"}
              />

              <PersonalDataDetails
                isEditable={isEditable("personal")}
                onEditModeChange={() => toggleIsInEditMode("personal")}
                editMode={editMode.personal}
                profileData={profileData}
                commonOnSubmit={commonOnSubmit}
                isSubmitting={submitMode.personal}
                formKey={"personal"}
              />

              <ContactDetails
                isEditable={isEditable("contact")}
                onEditModeChange={() => toggleIsInEditMode("contact")}
                editMode={editMode.contact}
                profileData={profileData}
                commonOnSubmit={commonOnSubmit}
                isSubmitting={submitMode.contact}
                formKey={"contact"}
              />

              <AddressDetails
                isEditable={isEditable("address")}
                onEditModeChange={() => toggleIsInEditMode("address")}
                editMode={editMode.address}
                profileData={profileData}
                commonOnSubmit={commonOnSubmit}
                isSubmitting={submitMode.address}
                formKey={"address"}
              />
            </Col>
            <MembershipCards profileData={profileData} />
          </Row>
        </div>
      </>
    );
  } else if (profileLoading === "error") {
    content = <Error500 />;
  } else {
    content = (
      <Row>
        <Col>{t(tRoot.loadingStatus.contentLoading)}</Col>
      </Row>
    );
  }

  return (
    <Container>
      <div className="profile-page-container">{content}</div>
      <AlertModal translate={t} id={alertModalId} closeModal={closeAlertModal} />
    </Container>
  );
};

export default Profile;
