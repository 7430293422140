import React from "react";
import { CancellationReservationSlot } from ".";
import { lang, TypedTranslate } from "../../../features/i18n";
import FlexCol from "../../flex-col";
import FlexRow from "../../flex-row";
import HcButton from "../../hc-button";
import HcCheckbox from "../../hc-checkbox";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import { formatDateRangeStr } from "../utils";
import useSelect from "./use-select-cancellation";
import { isPastDate } from "../../date-range/utils";

type Props = WizardStep & {
  reservations: CancellationReservationSlot[];
  translate: TypedTranslate;
  setWizardReservationSlots: (selected: string[]) => void;
  initialReservationSlots: string[];
  parentClassName: string;
};

const Select = ({
  reservations,
  translate: t,
  nextStep,
  previousStep,
  initialReservationSlots,
  setWizardReservationSlots,
  parentClassName,
}: Props): JSX.Element => {
  const { selectedReservationIds, isSelected, toggleSelection, handleNextStep } = useSelect(
    initialReservationSlots,
    setWizardReservationSlots,
    nextStep
  );

  const tRoot = lang.home.holdings;

  const className = `no-p-lr ${parentClassName}__step--select`;
  const classNameSelections = `${className}__selections`;
  const classNameSelection = `${classNameSelections}__selection`;

  const isNoneSelected = selectedReservationIds.size === 0;

  // Filter out ongoing reservations since they can not be cancelled
  const filteredReservations = reservations.filter((r) => !isPastDate(r.startDate));

  return (
    <FlexCol className={className}>
      <FlexRow className={classNameSelections}>
        <HcText weight="strong">{t(tRoot.reserved.selectTitle)}:</HcText>

        {filteredReservations.map((slot, index) => (
          <HcCheckbox
            ariaLabel={slot.resortName}
            labelPosition="left"
            hasContainer
            key={`selection-${index}`}
            checked={isSelected(slot.reservationId)}
            onChange={() => toggleSelection(slot.reservationId)}
            className={classNameSelection}
          >
            <HcText size="s" bottomSpace="0">
              {slot.resortName}
            </HcText>
            <HcText size="s" colorVariant="weak" bottomSpace="0">
              {t(tRoot.room, { room: slot.room })}
            </HcText>

            <HcText size="s" colorVariant="weak" bottomSpace="0">
              {formatDateRangeStr(slot.startDate, slot.endDate, t(tRoot.week), slot.weekNumber)}
            </HcText>
          </HcCheckbox>
        ))}
      </FlexRow>

      <FlexRow>
        <HcButton onClick={handleNextStep} theme="dark" disabled={isNoneSelected}>
          {t(tRoot.confirmTarget, { target: t(tRoot.selection) })}
        </HcButton>
        <HcButton isSecondary onClick={previousStep}>
          {t(tRoot.back)}
        </HcButton>
      </FlexRow>
    </FlexCol>
  );
};

export default Select;
