import * as React from "react";
import "./../../styles/_flexboxgrid.scss";

export type FlexContainerProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
};

const FlexContainer = ({ children, className, id = "" }: FlexContainerProps): JSX.Element => {
  const cssClasses = `container${className ? ` ${className}` : ""}`;

  return (
    <div className={cssClasses} id={id}>
      {children}
    </div>
  );
};

export default FlexContainer;
