import React from "react";
import { Link } from "react-router-dom";
import { BenefitInitState } from "../../benefits";
import {
  BenefitType,
  WeekType,
  HoldingReservationDto,
  HoldingDto,
  ICalendarDto,
} from "../../../app/OwnerService-api";
import HcText from "../../hc-text";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcButton from "../../hc-button";
import { isPastDate } from "../../date-range/utils";
import useHoldingsTabLinks from "./use-holdings-tab-links";
import { NextWeekDataType } from "./holdings-tab";

const HoldingsTabLinks = ({
  holding,
  translate: t,
  reservationsData,
  nextWeekData,
}: {
  holding: HoldingDto;
  translate: TypedTranslate;
  reservationsData: HoldingReservationDto[];
  nextWeekData: NextWeekDataType | undefined;
}): JSX.Element => {
  const tRoot = lang.home.holdingsDetails;

  const { handleRentFormOpen, handleVillasReservationLinkClick } = useHoldingsTabLinks(
    holding,
    reservationsData
  );

  const isVillasType =
    holding.weekType === WeekType.VillasFullOwnership ||
    holding.weekType === WeekType.VillasFractional;

  /**
   * Get reservation link for Villas holdings or
   * rent week link for Week holdings
   * @returns React component
   */
  const getHoldingManagementLink = () => {
    if (isVillasType) {
      const villasModalId = "villas-reservation-modal-" + holding.unitId;
      return (
        <>
          <HcButton
            isLinkButton
            className="link-btn"
            onClick={() => handleVillasReservationLinkClick(villasModalId)}
          >
            {t(tRoot.villasManageReservationLink)}
          </HcButton>
        </>
      );
    } else if (
      nextWeekData &&
      nextWeekData.calendarId &&
      nextWeekData.weekNumber &&
      !isPastDate(new Date(nextWeekData.weekStart))
    ) {
      return (
        <HcButton
          isLinkButton
          className="link-btn"
          onClick={() => handleRentFormOpen(holding.unitId, nextWeekData as ICalendarDto)}
        >
          {t(tRoot.lendForward)}
        </HcButton>
      );
    }
    return <></>;
  };

  const activeBenefitsTab = t(lang.benefitsPage.general.searchTabHash);

  return (
    <>
      <div className="custom-tab-col left">
        <HcText weight="strong" bottomSpace="m">
          {t(tRoot.management)}
        </HcText>
        {getHoldingManagementLink()}
      </div>
      <div className="custom-tab-col right">
        <HcText weight="strong" bottomSpace="m">
          {t(tRoot.destinationTips)}
        </HcText>
        <HcText bottomSpace="m">
          <Link
            to={{
              pathname: "osakasedut",
              state: {
                scrollPosition: "tabs",
                activeTab: activeBenefitsTab,
                activeSearchCategories: [BenefitType.Destination],
              } as BenefitInitState,
            }}
            className="action-link"
          >
            {t(tRoot.destinationBenefits)}
          </Link>
        </HcText>
        {(holding.links ?? []).map((link, i) => {
          return (
            <HcText bottomSpace="m" key={"link-" + i}>
              <a href={link.href} target="_blank" className="action-link" rel="noopener noreferrer">
                {link.title}
              </a>
            </HcText>
          );
        })}
      </div>
    </>
  );
};

export default HoldingsTabLinks;
