import React from "react";
import cx from "classnames";
import { SpacingType } from "./../../features/types";

import "./hc-heading.scss";

export type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type HeadingProps = {
  children: React.ReactNode;

  /**
   * Semantic element
   * h1|h2|h3|h4|h5|h6
   */
  semanticElement: HeadingTag;

  /**
   * UI variant for the semantic element. Useful if you need for example visually h1 but
   * semantically h3.
   * h1|h2|h3|h4|h5|h6
   */
  variant?: HeadingTag;

  id?: string;

  /**
   * Optional top & bottom spacing for heading. Value follows size token scale.
   * xxxs|xxs|xs|s|m|l|xl|xxl|xxxl|xxxxl
   */
  topSpace?: SpacingType | undefined;
  bottomSpace?: SpacingType | undefined;

  className?: string;
};

/**
 * A heading component which renders h1-h6 HTML heading tag.
 * The heading component uses the variant property to associate a UI variant with a semantic element.
 */
const HcHeading = ({
  semanticElement,
  variant,
  children,
  id,
  topSpace,
  bottomSpace,
  className,
}: HeadingProps): JSX.Element => {
  const Tag = `${semanticElement}` as HeadingTag;

  const currentStyle = variant !== undefined ? variant : semanticElement;

  const headingClasses = cx({
    [`heading__${currentStyle}`]: true,
    [`mt-${topSpace}`]: topSpace !== undefined,
    [`mb-${bottomSpace}`]: bottomSpace !== undefined,
  });

  return (
    <Tag
      className={className !== undefined ? headingClasses.concat(" " + className) : headingClasses}
      id={id}
    >
      {children}
    </Tag>
  );
};

export default HcHeading;
