import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarState } from ".";

const initialState: CalendarState = {
  clickedDate: undefined,
  initDate: undefined,
};

const calendarSlice = createSlice({
  initialState,
  name: "calendar",
  reducers: {
    setClickedDate: (state, action: PayloadAction<Date>) => {
      state.clickedDate = action.payload;
    },
    clearClickedDate: (state) => {
      state.clickedDate = undefined;
    },
    setInitDate: (state, action: PayloadAction<Date>) => {
      state.initDate = action.payload;
    },
    clearInitDate: (state) => {
      state.initDate = undefined;
    },
  },
});

export default calendarSlice;
