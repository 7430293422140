import { FetchManager } from "./fetch-manager";
import { isPasswordResetError, isAuthDisabled } from "./auth-utils";
import { AuthModuleDummy } from "./auth-module-dummy";
import { AccountInfo } from "@azure/msal-browser";
import authModuleMsal from "./auth-module-msal";

export interface IAuthModule {
  handleRedirect: () => Promise<IUser | void>;
  login: () => void;
  logout: (user?: IUser) => void;
  forgotPassword: () => void;
  acquireToken: (user?: IUser) => Promise<string>;
}

const authModule: IAuthModule = isAuthDisabled() ? new AuthModuleDummy() : new authModuleMsal();

export { FetchManager, isPasswordResetError, isAuthDisabled };

export interface IUser extends AccountInfo {
  givenName: string;
  familyName: string;
  accessToken: string;
}

export default authModule;
