import { useState, useEffect } from "react";
import { BannerItemType } from "..";
import {
  BenefitType,
  IContentHolidayChangeDto,
  IContentOwnerBenefitDto,
} from "../../../app/OwnerService-api";
import { lang } from "../../../features/i18n";
import { useTypedTranslation } from "../../../features/i18n";
import { BadgeThemeType } from "../../badge";
import { ButtonTheme } from "../../hc-button";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBannerTheme = (
  data: IContentOwnerBenefitDto | IContentHolidayChangeDto,
  itemType: BannerItemType
) => {
  const [badge, setBadge] = useState<BadgeThemeType>("grey");
  const [badgeText, setBadgeText] = useState<string>("");
  const [buttonTheme, setButtonTheme] = useState<ButtonTheme>("default");

  const { t } = useTypedTranslation();
  const tRoot = lang.epi.horizontalList;

  const initBannerThemeConf = () => {
    let badgeThemeConf: BadgeThemeType = "grey";
    let badgeTextConf = "";
    let buttonThemeConf: ButtonTheme = "default";

    if (itemType === "OwnerBenefit") {
      const item = (data as IContentOwnerBenefitDto).benefitType;

      switch (item) {
        case BenefitType.NewShareOwner:
          badgeThemeConf = "green";
          badgeTextConf = t(tRoot.newShareOwnerBadge);
          buttonThemeConf = "default";
          break;
        case BenefitType.Villas:
          badgeThemeConf = "villas";
          badgeTextConf = t(tRoot.villasBadge);
          buttonThemeConf = "dark";
          break;
        case BenefitType.Gold:
          badgeThemeConf = "black";
          badgeTextConf = t(tRoot.goldBadge);
          buttonThemeConf = "gold";
          break;
        case BenefitType.ShareOwner:
          badgeThemeConf = "beige";
          badgeTextConf = t(tRoot.shareOwnerBadge);
          buttonThemeConf = "default";
          break;
        case BenefitType.RCIPoints:
          badgeThemeConf = "green";
          badgeTextConf = "";
          buttonThemeConf = "default";
          break;
      }
    } else {
      const item = data as IContentHolidayChangeDto;
      badgeTextConf = item.points || "";
      badgeThemeConf = item.points !== "" ? "green" : "grey";
    }

    setBadge(badgeThemeConf);
    setBadgeText(badgeTextConf);
    setButtonTheme(buttonThemeConf);
  };

  useEffect(() => {
    initBannerThemeConf();
  }, []);

  return {
    badge,
    badgeText,
    buttonTheme,
  };
};

export default useBannerTheme;
