import React from "react";
import { HoldingReserved } from "..";
import WizardContainer from "../../wizard-container";
import Confirm from "./confirm-cancellation";
import Info from "./info-cancellation";
import Select from "./select-cancellation";
import useCancellationVillas from "./use-cancellation-villas";
import "./cancellation-villas.scss";

type Props = {
  holding: HoldingReserved;
  cancelWizard: () => void;
  confirmWizard: () => void;
};

const CancellationVillas = ({ cancelWizard, holding, confirmWizard }: Props): JSX.Element => {
  const {
    t,
    stepIndex,
    setStepIndex,
    reservationsToCancel,
    setReservationsToCancel,
    cancelReservations,
    closeReservationProcess,
    reservations,
    selectedReservation,
  } = useCancellationVillas(holding);

  const className = "cancellation-villas";

  /**
   * Array of the ids of selected reservations
   */
  const initialSelected = reservationsToCancel.includes(selectedReservation.reservationId)
    ? reservationsToCancel
    : [...reservationsToCancel, selectedReservation.reservationId];

  const handleCancelWizard = () => {
    cancelWizard();
  };

  const handleConfirmWizard = () => {
    cancelReservations();
    confirmWizard();
  };

  return (
    <WizardContainer stepIndex={stepIndex} className={className}>
      <Info
        nextStep={() => setStepIndex(1)}
        previousStep={handleCancelWizard}
        translate={t}
        reservation={selectedReservation}
      />
      <Select
        nextStep={() => setStepIndex(2)}
        previousStep={() => {
          setStepIndex(0);
          setReservationsToCancel([]);
          closeReservationProcess();
        }}
        reservations={reservations}
        translate={t}
        setWizardReservationSlots={setReservationsToCancel}
        initialReservationSlots={initialSelected}
        parentClassName={className}
      />
      <Confirm
        nextStep={handleConfirmWizard}
        previousStep={() => setStepIndex(1)}
        translate={t}
        selectedReservationIds={reservationsToCancel}
        reservations={reservations}
        parentClassName={className}
      />
    </WizardContainer>
  );
};

export default CancellationVillas;
