import React from "react";
import { EpiVideoCarouselBlockType } from ".";
import VideoCarousel from "../video-carousel";
import Row from "../flex-row";
import Col from "../flex-col";
import HcHeading from "../hc-heading";
import parse from "html-react-parser";
import { convertOptions } from "../../features/utils";
import FlexContainer from "../flex-container";
import HcButton from "../hc-button";
import useEpiVideoCarouselBlock from "./use-epi-video-carousel-block";

import "./epi-video-carousel-block.scss";

type EpiVideoCarouselBlockProps = {
  data: EpiVideoCarouselBlockType;
  className?: string;
};

const EpiVideoCarouselBlock = ({ data, className }: EpiVideoCarouselBlockProps): JSX.Element => {
  const { title, content, videos } = data;
  const { activeVideo, setActiveVideo, isPlaying, setIsPlaying } = useEpiVideoCarouselBlock();

  let parsedContent: string | JSX.Element | JSX.Element[] = <></>;
  if (content !== undefined) {
    parsedContent = parse(content, convertOptions);
  }

  return (
    <div className="video-carousel-block-wrapper epi-block">
      <FlexContainer
        className={`epi-video-carousel-block-container orderable-block ${
          className !== undefined ? className : ""
        }`}
      >
        <Row>
          <Col s={12} m={12} l={6} className="orderable-block-text-content">
            <div>
              <HcHeading semanticElement="h4">{title}</HcHeading>
            </div>
            <div>{parsedContent}</div>
            {videos !== undefined &&
              videos.length > 0 &&
              videos.map((item, i) => {
                return (
                  <div key={`link_button${i}`} className="video-title-link-container">
                    <HcButton
                      isLinkButton={true}
                      onClick={() => {
                        setActiveVideo(i);
                        setIsPlaying(false);
                      }}
                    >
                      {item.title}
                    </HcButton>
                  </div>
                );
              })}
          </Col>
          <Col s={12} m={12} l={6} className="orderable-block-video-content">
            <VideoCarousel
              data={videos}
              slideIndex={activeVideo}
              setActiveVideo={setActiveVideo}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
            />
          </Col>
        </Row>
      </FlexContainer>
    </div>
  );
};

export default EpiVideoCarouselBlock;
