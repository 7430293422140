import React from "react";
import InputFeedback, { Feedback } from "../../input-feedback";

type Props = {
  children: JSX.Element;
  feedback?: Feedback;
  label?: string;
  inputId?: string;
  inheritedClassName?: string;
};

const InputWrapper = React.forwardRef<HTMLDivElement, Props>(
  ({ children, feedback, inputId, label, inheritedClassName }: Props, ref) => {
    const Label = ({ text }: { text: string }) => (
      <label htmlFor={inputId} className="hc-input__label">
        {text}
      </label>
    );

    return (
      <div className={`hc-input ${inheritedClassName ? inheritedClassName : ""}`} ref={ref}>
        {label && <Label text={label} />}
        {children}
        {feedback && <InputFeedback {...feedback} />}
      </div>
    );
  }
);

InputWrapper.displayName = "InputWrapper";
export default InputWrapper;
