import { isBefore, isSameDay, format, differenceInCalendarDays } from "date-fns";

export const formatDate = (incomingDate: Date): string => {
  return format(incomingDate, "d.M.yyyy");
};

/**
 * Format date for reservations list
 * @param reservationSlot
 * @param week
 */
export const formatDateRangeStr = (
  startDate: Date,
  endDate: Date,
  week?: string,
  weekNumber?: number
): string => {
  const weekStr = week && weekNumber ? `${week} ${weekNumber} | ` : "";

  return `${weekStr}${formatDate(startDate)} - ${formatDate(endDate)}`;
};

/**
 * Use date-fns functions to compare dates.
 * Returns true if date given as the first parameter is before the date to compare to
 * @param date
 * @param compareTo
 */
export const isSameDayOrBefore = (date: Date, compareTo: Date): boolean => {
  return isBefore(date, compareTo) || isSameDay(date, compareTo);
};

/**
 * Check if clickedDate is part of the reservation
 * @param clickedDate
 * @param start reservation start date
 * @param end reservation end date
 */
export const isReservation = (clickedDate: Date, start: Date, end: Date): boolean =>
  isSameDayOrBefore(start, clickedDate) && isSameDayOrBefore(clickedDate, end);

export enum ContractLengthType {
  "OwnerNew",
  "Owner2MonthsToYear",
  "OwnerOld",
}

export const getContractLength = (contractDate: string | undefined): ContractLengthType => {
  const length = contractDate ? differenceInCalendarDays(new Date(), new Date(contractDate)) : 0;

  if (length <= 62) {
    return ContractLengthType.OwnerNew;
  }
  return length <= 365 ? ContractLengthType.Owner2MonthsToYear : ContractLengthType.OwnerOld;
};
