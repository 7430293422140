import { LoadingState, RootState } from "..";
import { MyProfileDto } from "../../app/OwnerService-api";
import CustomerProfileSlice, {
  requestProfile,
  updateProfile,
  validateBankAccount,
} from "./customer-profile-slice";

export type CustomerProfileState = {
  profileData: MyProfileDto[];
  loading: LoadingState;
};

export const selectProfileState = (state: RootState): CustomerProfileState => state.customerProfile;
export const selectProfileData = (state: RootState): MyProfileDto[] =>
  state.customerProfile.profileData;

export { requestProfile };
export { updateProfile };
export { validateBankAccount };

export default CustomerProfileSlice.reducer;
