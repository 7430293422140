import React from "react";
import { DocumentType, HoldingDto } from "../../../app/OwnerService-api";
import { TypedTranslate, lang } from "../../../features/i18n";
import HcText from "../../hc-text";

const HoldingsTabDetails = ({
  holding,
  translate: t,
}: {
  holding: HoldingDto;
  translate: TypedTranslate;
}): JSX.Element => {
  const tRoot = lang.home.holdingsDetails;

  return (
    <>
      <div className="custom-tab-col left">
        <HcText weight="strong" bottomSpace="0">
          {holding.realEstateCompany}
        </HcText>
        <HcText bottomSpace="0" tag="span">
          Huoneisto {holding.roomNumber} {holding.roomSize}m
          <HcText tag="span" size="xs">
            <span style={{ verticalAlign: "super" }}>2</span>
          </HcText>
        </HcText>
        <div>
          {holding.documents !== undefined &&
            holding.documents.map((document, i) => {
              return (
                <span key={"document-" + i}>
                  <a
                    href={document.uri}
                    target="_blank"
                    className="action-link"
                    rel="noopener noreferrer"
                  >
                    {document.documentType === DocumentType.FloorPlan
                      ? t(tRoot.floorPlan)
                      : t(tRoot.articlesOfAssociation)}
                  </a>{" "}
                </span>
              );
            })}
        </div>
      </div>
      <div className="custom-tab-col right">
        <HcText weight="strong" bottomSpace="0">
          {t(tRoot.customerService)}
        </HcText>
        <HcText bottomSpace="0">{t(lang.general.customerServicePhone)}</HcText>
        <a
          href={t(tRoot.customerServiceUrl)}
          className="action-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(tRoot.customerServiceContactInfo)}
        </a>
      </div>
    </>
  );
};

export default HoldingsTabDetails;
