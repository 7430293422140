import React from "react";
import { createPortal } from "react-dom";

import HcHeading from "../hc-heading";
import HcText from "../hc-text";
import cx from "classnames";
import HcButton from "../hc-button";

import { useModal, useModalA11y } from "./hooks";
import { lang } from "../../features/i18n";
import FocusTrap from "focus-trap-react";
import CloseIcon from "../../images/close.svg";

import "./hc-modal.scss";

export type HeaderSizeType = "s" | "m";

export type HcModalProps = {
  children: React.ReactNode;

  isOpen: boolean;
  onClose: () => void;

  ariaLabel: string;

  /* Boolean indicating if the overlay should close the modal */
  shouldCloseOnOverlayClick?: boolean;

  /* Boolean indicating if pressing the esc key should close the modal
     Note: By disabling the esc key from closing the modal
     you may introduce an accessibility issue. */
  shouldCloseOnEsc?: boolean;

  /**
   * Should confirm before close the modal
   */
  shouldConfirmBeforeClose?: boolean;

  /* String id to be applied to the content div. */
  id?: string;

  /* Use width & max-width for modal content */
  hasWidth?: boolean;

  /* Use default padding */
  defaultPadding?: boolean;

  /* Element used as header or undefined if none */
  header?: JSX.Element;

  /* Select header element height */
  headerSize?: HeaderSizeType;
};

/**
 * Root element for modal
 */
let modalRoot = document.getElementById("modal-root");
if (!modalRoot) {
  modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modal-root");
  document.body.appendChild(modalRoot);
}

const HcModal = ({
  isOpen,
  children,
  onClose,
  ariaLabel,
  id,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  shouldConfirmBeforeClose = false,
  hasWidth = true,
  defaultPadding = true,
  header,
  headerSize = "m",
}: HcModalProps): JSX.Element => {
  const {
    handleOverlayClick,
    confirmModalClose,
    isClosing,
    t,
    cancelOnRequestClose,
    handleCloseButtonClick,
  } = useModal(isOpen, shouldConfirmBeforeClose, shouldCloseOnOverlayClick, onClose);
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  useModalA11y(modalRoot!, shouldCloseOnEsc, isOpen);
  const tRoot = lang.modal;

  const modalOverlayClass = cx("modal-backdrop", {
    show: isOpen,
  });

  const modalContainerClass = cx("modal-container", {
    show: isOpen,
    hide: !isOpen,
    "modal-container--has-width": hasWidth,
    "modal-container--has-padding": defaultPadding,
  });

  const modalHeaderClass = cx("modal-header", {
    "modal-header--small": headerSize === "s",
    "modal-header--margins": defaultPadding === false,
  });

  const modalBodyClass = cx("modal-body", {
    "modal-body--small-header": headerSize === "s",
    "modal-body--no-header": header === undefined,
  });

  // If the user is closing the modal and we should confirm closing
  const showConfirmContent = isClosing && shouldConfirmBeforeClose ? true : false;

  const confirmBeforeCloseContent = (
    <div className={`modal-close-confirmation ${showConfirmContent ? "show" : "hide"}`}>
      <HcHeading semanticElement="h5">{t(tRoot.confirmCloseTitle)}</HcHeading>
      <HcText size="xs" colorVariant="weak">
        {t(tRoot.confirmCloseContent)}
      </HcText>
      <div className="modal-close-confirmation--actions">
        <HcButton onClick={cancelOnRequestClose}>{t(tRoot.confirmCloseContinue)}</HcButton>
        <HcButton onClick={() => confirmModalClose()} isSecondary>
          {t(tRoot.confirmCloseExit)}
        </HcButton>
      </div>
    </div>
  );

  const modalContent = (
    <>
      {header && <div className={modalHeaderClass}>{header}</div>}
      <div className={modalBodyClass}>{children}</div>
    </>
  );

  // Combine modal content with confirmation
  const modalContentWithConfirmationOnClose = (
    <>
      <div className={`${showConfirmContent && "hide-modal-container"}`}>{modalContent}</div>
      {confirmBeforeCloseContent}
    </>
  );

  return createPortal(
    isOpen ? (
      <>
        <div
          className={modalOverlayClass}
          onClick={(e) => handleOverlayClick(e, e.currentTarget)}
          role="presentation"
          data-testid="modal-dialog"
        >
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              fallbackFocus: ".modal-container",
            }}
          >
            <div
              className={modalContainerClass}
              role="dialog"
              id={id !== undefined ? id : ""}
              aria-label={ariaLabel}
              aria-modal="true"
              tabIndex={-1}
            >
              {!isClosing && (
                <button className="modal-close-button" onClick={handleCloseButtonClick}>
                  <img src={CloseIcon} alt={t(tRoot.confirmCloseExit)} />
                </button>
              )}
              {shouldConfirmBeforeClose ? modalContentWithConfirmationOnClose : modalContent}
            </div>
          </FocusTrap>
        </div>
      </>
    ) : null,
    modalRoot!
  );
};

export default HcModal;
