import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import {
  selectSelectedHolding,
  setSelectedHolding as setSelectedHoldingAction,
} from "../../../store/calendar-overlay";
import { SelectedHolding } from "../../../store/calendar-overlay";
import { HoldingDto, IHoldingDto, WeekType } from "../../../app/OwnerService-api";

/**
 * Returns selected holding when user changes holdings from calendar
 */

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useSelectedHolding = (holdings: HoldingDto[]) => {
  const [selectableHoldingsData, setSelectableHoldingsData] = useState<SelectedHolding[]>([]);
  const [activeSelectionIndex, setActiveSelectionIndex] = useState<number>(0);
  const dispatch = useAppDispatch();
  const selectedHolding = useSelector(selectSelectedHolding);

  /**
   * Parse holdings. If there are more than one holding --> add SHOW_ALL option
   */
  useEffect(() => {
    const parsedData: SelectedHolding[] = holdings.map((h) => h as IHoldingDto);
    if (parsedData.length > 1) {
      parsedData.unshift("SHOW_ALL");
    }
    setSelectableHoldingsData(parsedData);

    /* Selected holding is initially SHOW_ALL at redux store, so we need to update it if theres only one holding */
    if (parsedData.length === 1) {
      dispatch(setSelectedHoldingAction(parsedData[0]));
    }
  }, [holdings, dispatch]);

  // returns index of selected holding. In selection data there is "SHOW_ALL" in the beginning of
  // array so let's add 1 for found index.
  const getSelectedHoldingIndex = (): number | undefined => {
    if (selectedHolding !== "SHOW_ALL") {
      const isVillasFullOrFrac =
        selectedHolding.weekType === WeekType.VillasFullOwnership ||
        selectedHolding.weekType === WeekType.VillasFractional;
      return isVillasFullOrFrac
        ? holdings.findIndex((h) => h.unitId === selectedHolding.unitId) + 1
        : holdings.findIndex(
            (h) =>
              h.unitId === selectedHolding.unitId && h.weekNumber === selectedHolding.weekNumber
          ) + 1;
    }
  };

  /**
   * @param {boolean} isNext true if right (next) nav button is clicked, false if left (prev) one
   * @returns {number} index of the next selection
   */
  const getNextSelectionIndex = (isNext: boolean): number => {
    const holdingIndexData = getSelectedHoldingIndex();
    const currentIndex = holdingIndexData !== undefined ? holdingIndexData : activeSelectionIndex;

    if (isNext) {
      return currentIndex === selectableHoldingsData.length - 1 ? 0 : currentIndex + 1;
    }

    return currentIndex === 0 ? selectableHoldingsData.length - 1 : currentIndex - 1;
  };

  /**
   * Set selected holding index.
   * @param {boolean} isNext true if right (next) nav button is clicked, false if left (prev) one
   */
  const changeSelectedHolding = (isNext: boolean) => {
    const nextIndex = getNextSelectionIndex(isNext);

    setActiveSelectionIndex(nextIndex);
    dispatch(setSelectedHoldingAction(selectableHoldingsData[nextIndex]));
  };

  return {
    changeSelectedHolding,
    selectedHolding,
    selectableHoldingsData,
  };
};
export default useSelectedHolding;
