import useReduxModal from "../redux-modal/use-redux-modal";
import { useTypedTranslation } from "../../features/i18n";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useEpiTestimonialBlock = (videoModalId: string) => {
  const { t } = useTypedTranslation();
  const { openModal } = useReduxModal(videoModalId);

  return {
    t,
    openModal,
  };
};

export default useEpiTestimonialBlock;
