import React from "react";
import { ContentDto, IContentTwoColumnBlockDto } from "../../app/OwnerService-api";
import EditorialBlock from "./editorial-block";
import ImageBlock from "./image-block";
import Row from "../flex-row";
import FlexContainer from "../flex-container";
import "./epi-two-column-block.scss";

type EpiTwoColumnBlockProps = {
  data: IContentTwoColumnBlockDto;
  className?: string;
};

const getItemContent = (item: ContentDto | undefined): JSX.Element => {
  let result = <></>;
  if (item !== undefined && "_discriminator" in item) {
    if (item["_discriminator"] === "ContentEditorialBlockDto") {
      result = <EditorialBlock data={item} className="orderable-block-text-content" />;
    }
    if (item["_discriminator"] === "ContentImageBlockDto") {
      result = <ImageBlock data={item} className="orderable-block-image-content" />;
    }
  }

  return result;
};

const EpiTwoColumnBlock = ({ data, className }: EpiTwoColumnBlockProps): JSX.Element => {
  const leftContentData = data.leftContent !== undefined ? data.leftContent[0] : undefined;
  const rightContentData = data.rightContent !== undefined ? data.rightContent[0] : undefined;

  return (
    <div className="two-column-block-wrapper epi-block">
      <FlexContainer
        className={`epi-two-column-block-container orderable-block ${
          className !== undefined ? className : ""
        }`}
      >
        <Row>
          {getItemContent(leftContentData)}
          {getItemContent(rightContentData)}
        </Row>
      </FlexContainer>
    </div>
  );
};

export default EpiTwoColumnBlock;
