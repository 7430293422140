import React from "react";
import FormGroupContainer from "../form-group-container";
import MembershipCard from "../membership-card";
import Row from "../flex-row";
import Col from "../flex-col";
import useMembershipCards from "./hooks";
import DefaultActionBlock from "./default-action-block";
import OrderCardActionBlock from "./order-card-action-block";
import cx from "classnames";
import MobileCard from "./mobile-card";
import OrderInfo from "./order-info";

import "./profile-membership-cards.scss";
import { MyProfileDto } from "../../app/OwnerService-api";

const ProfileMembershipCards = ({ profileData }: { profileData: MyProfileDto }): JSX.Element => {
  const cards = profileData?.membershipCards || [];

  const {
    visibleCards,
    isManyCards,
    editMode,
    orderDisabled,
    setEditMode,
    t,
    onMembershipCardSelect,
    activeOrder,
    changeActiveOrder,
    selectedCards,
    cancelOrder,
  } = useMembershipCards(cards);

  const formGroupClass = cx({
    "group-has-many-cards": isManyCards,
    "group-has-single-card": !isManyCards,
  });

  return (
    <Col m={visibleCards.length > 1 ? 12 : 6} l={3}>
      <FormGroupContainer className={formGroupClass}>
        <Row>
          {visibleCards.map((card, index) => (
            <Col m={visibleCards.length > 1 ? 6 : 12} key={index}>
              <MembershipCard
                isStackSpace={isManyCards}
                memberNumber={card.membershipNumber}
                membershipLevel={card.membershipLevel}
                editMode={editMode}
                editModeDisabled={activeOrder === "mobile-card-order-wip"}
                onMembershipCardSelect={editMode ? onMembershipCardSelect : undefined}
              />
            </Col>
          ))}
        </Row>
        {!editMode && <DefaultActionBlock translate={t} setEditMode={setEditMode} />}
        {editMode && <OrderInfo translate={t} cancelOrder={() => cancelOrder()} />}
        {editMode && activeOrder === "no-order" && (
          <OrderCardActionBlock
            translate={t}
            setEditMode={setEditMode}
            isOrderingDisabled={orderDisabled}
            changeActiveOrder={changeActiveOrder}
          />
        )}
        {editMode && activeOrder === "mobile-card-order-wip" && (
          <MobileCard
            phone={profileData.phone || ""}
            changeActiveOrder={changeActiveOrder}
            setEditMode={setEditMode}
            selectedCards={selectedCards}
          />
        )}
      </FormGroupContainer>
    </Col>
  );
};

export default ProfileMembershipCards;
