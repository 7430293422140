import React from "react";
import HcText from "../hc-text";
import HcHeading from "../hc-heading";
import PlaceholderImg from "../placeholder-img";
import Badge from "../badge";
import AspectRatioWrapper from "../aspect-ratio-wrapper";
import { LazyImage } from "react-lazy-images";
import { BannerDataType } from ".";
import useBanner from "./hooks/use-banner";
import BannerModal from "./banner-modal";
import { lang } from "../../features/i18n";
import "./banner.scss";

type BannerProps = BannerDataType;

const Banner = ({ data, itemType, modalId }: BannerProps): JSX.Element => {
  const {
    handleCardClick,
    primaryHeadline,
    secondaryHeadline,
    subHeadline,
    badgeAdditionalUnit,
    imgSrc,
    imgAlt,
    badgeTheme,
    badgeText,
    t,
  } = useBanner(data, itemType, modalId);

  const tRoot = lang.epi.horizontalList;
  const ariaLabelString =
    itemType === "OwnerBenefit"
      ? t(tRoot.benefitBannerAriaLabel).concat(primaryHeadline.trim())
      : t(tRoot.holidayChangeBannerAriaLabel).concat(primaryHeadline.trim());

  return (
    <div className="banner-wrapper">
      <button className="banner-button" onClick={handleCardClick}>
        {imgSrc !== "" ? (
          <LazyImage
            src={imgSrc}
            alt={imgAlt}
            placeholder={({ ref }) => (
              <AspectRatioWrapper ref={ref}>
                <PlaceholderImg roundedCorners={true} className="generic-loading-state" />
              </AspectRatioWrapper>
            )}
            actual={({ imageProps }) => (
              <AspectRatioWrapper>
                <div
                  className="banner-portrait-image-placeholder"
                  style={{ backgroundImage: `url(${imgSrc})` }}
                  role="img"
                  aria-label={imageProps.alt || ariaLabelString}
                >
                  {" "}
                </div>
              </AspectRatioWrapper>
            )}
          />
        ) : (
          <AspectRatioWrapper>
            <PlaceholderImg roundedCorners={true} />
          </AspectRatioWrapper>
        )}

        <div className="banner-content-wrapper">
          <div className="second-headline-wrapper">
            <HcText colorVariant="weakgold" size="s" bottomSpace="0">
              {secondaryHeadline}
            </HcText>
            {badgeText !== "" && (
              <Badge additionalUnit={badgeAdditionalUnit} theme={badgeTheme}>
                {badgeText}
              </Badge>
            )}
          </div>
          <HcHeading semanticElement="h3" variant="h6" bottomSpace="s" topSpace="s">
            {primaryHeadline}
          </HcHeading>
          <HcText size="s">{subHeadline}</HcText>
        </div>
      </button>
      <BannerModal data={data} itemType={itemType} id={modalId} />
    </div>
  );
};

export default Banner;
