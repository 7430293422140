import React from "react";
import { Trans } from "react-i18next";
import InfoIcon from "../../../images/info-icon-16.svg";
import HcInput from "../../hc-input";
import HcText from "../../hc-text";
import HcButton from "../../hc-button";
import { lang, TypedTranslate } from "../../../features/i18n";
import useRentedWeekOverlay from "./use-rented-week-overlay";

type RentedWeekOverlayProps = {
  translate: TypedTranslate;
  resortName: string;
};

const RentedWeekOverlay = ({ resortName }: RentedWeekOverlayProps): JSX.Element => {
  const tRoot = lang.rentFormModal;

  const { t, handleCustomerServiceClick } = useRentedWeekOverlay();

  return (
    <>
      <HcText size="s" topSpace="xl" bottomSpace="m">
        <Trans i18nKey="home.rentFormModal.rentedMsg">
          Olet jättänyt kohteen{" "}
          <HcText size="s" weight="strong" tag="span">
            {resortName}
          </HcText>{" "}
          vuokralle. Voit tiedustella vuokrauksesi tilaa asiakaspalvelun numerosta:
        </Trans>
      </HcText>
      <HcInput
        value={t(lang.general.customerServicePhone)}
        readOnly={true}
        className="mb-xs"
        label=""
        aria-label={t(lang.general.customerServicePhone)}
      ></HcInput>
      <HcText size="xs" bottomSpace="xl" className="info-group">
        <img src={InfoIcon} alt="" />
        {t(lang.general.callPrice)}
      </HcText>
      <HcButton onClick={handleCustomerServiceClick}>{t(tRoot.rentedCustomerServiceBtn)}</HcButton>
    </>
  );
};

export default RentedWeekOverlay;
