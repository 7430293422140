import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useMobileCard = () => {
  const { t } = useTypedTranslation();
  const [stepIndex, setStepIndex] = useState(0);
  const { sendDataToGTM } = useGTM();

  return {
    t,
    stepIndex,
    setStepIndex,
    sendDataToGTM,
  };
};

export default useMobileCard;
