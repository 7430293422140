import React from "react";
import ReactPlayer from "react-player/youtube";
import AspectRatioWrapper, { AspectRatioType } from "../aspect-ratio-wrapper";
import useVideoPlayer from "./use-video-player";

type VideoProps = {
  aspectRatio?: AspectRatioType;
  className?: string;
  videoUrl?: string;
  onPlay?: () => void;
  playing?: boolean;
  title?: string;
};

const VideoPlayer = ({
  aspectRatio,
  className,
  videoUrl,
  onPlay,
  playing,
  title,
}: VideoProps): JSX.Element => {
  const { onVideoStart, onVideoEnd } = useVideoPlayer(title);

  // Used to prevent controls being set to unaccessible top corners on iOS
  const youtubePlayerConfig = {
    playerVars: {
      showinfo: 1,
    },
  };

  return videoUrl ? (
    <AspectRatioWrapper aspectRatio={aspectRatio}>
      <ReactPlayer
        config={youtubePlayerConfig}
        url={videoUrl}
        className={className || "video-player"}
        width="100%"
        height="100%"
        controls={true}
        onStart={onVideoStart}
        onEnded={onVideoEnd}
        onPlay={onPlay}
        playing={playing}
      />
    </AspectRatioWrapper>
  ) : (
    <></>
  );
};

export default VideoPlayer;
