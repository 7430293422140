import i18next from "i18next";

export function getToastContent(success: boolean): string {
  const langCode = i18next.language;

  let successMsg = "Toiminto onnistui";
  let errorMsg = "Toiminto epäonnistui";

  if (langCode === "en") {
    successMsg = "Toiminto onnistui";
    errorMsg = "Toiminto epäonnistui";
  }

  return success ? successMsg : errorMsg;
}
