import { useState } from "react";
import { ReservationInfo } from "..";
import { DateRangeType } from "../../calendar";
import { differenceInDays } from "date-fns";
import { ReservationStatus, ReservationType } from "../../../app/OwnerService-api";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useVillasReservations = () => {
  const [reservations, setReservations] = useState<ReservationInfo[]>([]);
  const [latestDateRange, setLatestDateRange] = useState<DateRangeType[]>([]);
  const [initUsingLatest, setInitUsingLatest] = useState<boolean>(false);

  /**
   * Add selection to reservations and sort them, update latestDateRange[]
   * @param {DateRangeType} selectionData
   */
  const addCurrentSelectionToReservations = (selectionData: DateRangeType) => {
    if (selectionData !== undefined) {
      const status: ReservationStatus =
        differenceInDays(selectionData.start, new Date()) >= 30
          ? ReservationStatus.Accepted
          : ReservationStatus.Pending;

      const newData: ReservationInfo = {
        startDate: selectionData.start,
        endDate: selectionData.end,
        reservationDate: new Date(),
        reservationStatus: status,
        type: ReservationType.OwnUse,
      };

      setLatestDateRange([...latestDateRange, selectionData]);

      const combined = sortReservations([...reservations, ...[newData]]);
      setReservations(combined);
    }
  };

  /**
   * Remove the latest reservation, update latestDateRange[]
   */
  const removeLatestFromReservations = () => {
    if (latestDateRange.length > 0) {
      const latest = latestDateRange[latestDateRange.length - 1];
      const filtered = reservations.filter((item) => {
        return (
          latest.start.getTime() !== item.startDate.getTime() &&
          latest.end.getTime() !== item.endDate.getTime()
        );
      });

      setReservations(filtered);
      setLatestDateRange(latestDateRange.slice(0, -1));
    }
  };

  /**
   * @param {Date} date - date to convert
   * @returns {number} time value in milliseconds
   */
  const getTime = (date: Date) => {
    return date !== null ? date.getTime() : 0;
  };

  /**
   * @param {ReservationInfo[]} reservations
   * @returns {ReservationInfo[]} reservations by start date, ascending
   */
  const sortReservations = (reservations: ReservationInfo[]): ReservationInfo[] => {
    return reservations.slice().sort((a, b) => getTime(a.startDate) - getTime(b.startDate));
  };

  return {
    reservations,
    addCurrentSelectionToReservations,
    removeLatestFromReservations,
    latestDateRange,
    setInitUsingLatest,
    initUsingLatest,
  };
};

export default useVillasReservations;
