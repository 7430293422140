import * as React from "react";
import "./hc-avatar.scss";

type SizeTypes = "normal" | "small";

export type AvatarProps = {
  size?: SizeTypes;
  children: React.ReactNode;
};

const HcAvatar = ({ children }: AvatarProps): JSX.Element => (
  <div className="avatar">{children}</div>
);

export default HcAvatar;
