import useMembershipCards from "./use-membership-cards";
import useCardOrder from "./use-card-order";
import { IMembershipCardDto } from "../../../app/OwnerService-api";

export type ActiveOrder = "no-order" | "mobile-card-order-wip" | "mobile-card-order-done";

export type SelectedCards = {
  card: IMembershipCardDto;
  selected: boolean;
};

export default useMembershipCards;
export { useCardOrder };
