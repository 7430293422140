import Home from "./../../components/home";
import React from "react";
import ProtectedRoute from "../../components/protected-route";
import { RouteWrapperProps } from "..";

/**
 * Protected route for owner's personal home page
 */
const HomeRoute = (props: RouteWrapperProps): JSX.Element => (
  <ProtectedRoute {...props} render={() => <Home />}></ProtectedRoute>
);

export default HomeRoute;
