import React from "react";
import HcText from "./../../hc-text";

import { format } from "date-fns";

type DisabledCalendarDayProps = {
  day: Date;
};

export const DisabledCalendarDay = ({ day }: DisabledCalendarDayProps): JSX.Element => {
  const dataDateTime = format(day, "yyyy-MM-dd");
  const dataDay = format(day, "d");

  return (
    <button className="day-item" type="button" disabled>
      <div data-date-time={dataDateTime}>
        <div className="day-wrapper">
          <HcText tag="span" colorVariant="weak" size="s">
            {dataDay}
          </HcText>
        </div>
      </div>
    </button>
  );
};

export default DisabledCalendarDay;
