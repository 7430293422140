import React from "react";
import { Trans } from "react-i18next";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import { differenceInCalendarDays } from "date-fns";
import { CalendarDto } from "../../../app/OwnerService-api";
import RentedWeekOverlay from "../reservation-weeks/rented-week-overlay";

type Props = {
  points: number;
  resortName: string;
  translate: TypedTranslate;
  selectedWeek: CalendarDto | undefined;
  isRented: boolean;
} & WizardStep;

const Info = ({
  nextStep,
  previousStep,
  points,
  resortName,
  translate: t,
  selectedWeek,
  isRented,
}: Props): JSX.Element => {
  const tRoot = lang.home;
  const pointsString = new Intl.NumberFormat("fi-FI").format(points);
  const diffInDays = selectedWeek?.weekStart
    ? differenceInCalendarDays(new Date(selectedWeek.weekStart), new Date())
    : null;

  let overlayContenti18nKey = "home.holdings.rciReservation.infoTextDefault";
  let overlayContent: React.ReactElement = (
    <Trans i18nKey={overlayContenti18nKey}>
      Varaa omistuksistasi kertyneillä
      <HcText size="s" weight="strong" tag="span">
        {{ points: pointsString }} pisteellä
      </HcText>
      seuraava lomasi. Käytössäsi on RCI lomanvaihto-organisaation yli 4000 lomakeskusta yli 100
      maassa. Holiday Club Points -järjestelmän toiminnasta vastaa maailman johtava
      lomanvaihto-organisaatio RCI.
    </Trans>
  );

  /**
   * Difference in days between current date and holding week
   * 304-334 --> 10-11 months
   * 335-365 --> 11-12 months
   * 366-396 --> 12-13 months
   */
  if (diffInDays !== null) {
    if (diffInDays > 303 && diffInDays <= 334) {
      overlayContenti18nKey = "home.holdings.rciReservation.infoText10to11";

      overlayContent = (
        <Trans i18nKey={overlayContenti18nKey}>
          Sinulla on etuoikeus tehdä omistuksistasi kertyneillä
          <HcText size="s" weight="strong" tag="span">
            {{ points: pointsString }} pisteellä
          </HcText>
          varauksia muista Holiday Club -kohteista ennen muita vaihtojärjestelmän jäseniä, kun
          varaat viimeistään 10 kk ennen haluamaasi loma-ajankohtaa. Holiday Club Points
          -järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.
        </Trans>
      );
    } else if (diffInDays > 334 && diffInDays <= 365) {
      overlayContenti18nKey = "home.holdings.rciReservation.infoText11to12";

      overlayContent = (
        <Trans i18nKey={overlayContenti18nKey}>
          Hyödynnä etuoikeutesi varata omistuksistasi kertyneillä
          <HcText size="s" weight="strong" tag="span">
            {{ points: pointsString }} pisteellä
          </HcText>
          loma{" "}
          <HcText size="s" weight="strong" tag="span">
            {{ resortName: resortName }}
          </HcText>{" "}
          viimeistään 11 kk ennen haluamaasi ajankohtaa. Holiday Club Points -järjestelmän
          toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.
        </Trans>
      );
    } else if (diffInDays > 365 && diffInDays <= 396) {
      overlayContenti18nKey = "home.holdings.rciReservation.infoText12to13";

      overlayContent = (
        <Trans i18nKey={overlayContenti18nKey}>
          Varaa omistuksistasi kertyneillä
          <HcText size="s" weight="strong" tag="span">
            {{ points: pointsString }} pisteellä
          </HcText>
          seuraava lomasi kohteessasi{" "}
          <HcText size="s" weight="strong" tag="span">
            {{ resortName: resortName }}
          </HcText>
          , muistathan tehdä varauksen viimeistään 12kk ennen omistamasi lomaviikon alkua. Holiday
          Club Points -järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio
          RCI.
        </Trans>
      );
    }
  }

  return isRented ? (
    <RentedWeekOverlay translate={t} resortName={resortName} />
  ) : (
    <>
      <HcText bottomSpace="xl" size="s" className="overlay-description-content" topSpace="l">
        {overlayContent}
      </HcText>

      <HcButton onClick={nextStep}>{t(tRoot.holdings.rciReservation.seeAtRci)}</HcButton>
      <HcButton onClick={previousStep} isSecondary>
        {t(tRoot.general.inspectBenefits)}
      </HcButton>
    </>
  );
};

export default Info;
