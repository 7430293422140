import React from "react";
import HcText, { ColorStyle } from "../hc-text";
import cx from "classnames";
import { BadgeThemeType } from ".";
import "./badge.scss";

type BadgeProps = {
  children: React.ReactNode;

  /**
   * To add extra information using different text style, like <strong text>400€</strong text> <normal text>/ vrk</normal text>
   */
  additionalUnit?: string;

  /**
   * Badge bg & text color theme
   */
  theme?: BadgeThemeType;
};

const Badge = ({ children, theme = "green", additionalUnit }: BadgeProps): JSX.Element => {
  const badgeCss = cx("badge-base", {
    "badge-black": theme === "black",
    "badge-grey": theme === "grey",
    "badge-green": theme === "green",
    "badge-villas": theme === "villas",
    "badge-beige": theme === "beige",
  });

  const textColor = theme === "black" ? "gold" : "black";

  return (
    <div className={badgeCss}>
      <div className="badge-text-container">
        <HcText colorVariant={textColor} size="xs" weight="strong" tag="span" bottomSpace="0">
          {children}
        </HcText>{" "}
        {additionalUnit ? (
          <HcText colorVariant={textColor} size="xxs" tag="span" bottomSpace="0">
            {additionalUnit}
          </HcText>
        ) : null}
      </div>
    </div>
  );
};

export default Badge;
