import Col from "../../flex-col";
import Row from "../../flex-row";
import HcButton from "../../hc-button";
import { WizardStep } from "../../wizard-container";
import useStartRent from "./use-start-rent";
import React from "react";
import { lang } from "../../../features/i18n";
import HcText from "../../hc-text";
import "./start-rent.scss";

type Props = WizardStep;

const StartRent = ({ nextStep, previousStep }: Props): JSX.Element => {
  const { t, handleNextStep } = useStartRent(nextStep);
  const tRoot = lang.rentFormModal;
  const classNameRoot = "info-rent";
  const classNameContainer = `${classNameRoot}__container no-side-padding`;

  return (
    <Row className={classNameRoot}>
      <Col className={classNameContainer}>
        <Row>
          <Col className="no-side-padding">
            <HcText size="s" bottomSpace="l">
              {t(tRoot.startRent.text)}
            </HcText>
          </Col>
        </Row>

        <Row>
          <Col className="no-side-padding">
            <HcButton onClick={handleNextStep}>{t(tRoot.continue)}</HcButton>
            <HcButton onClick={previousStep} isSecondary>
              {t(tRoot.back)}
            </HcButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StartRent;
