import { IAuthModule, IUser } from ".";
import authModuleMsal from ".";

/**
 * Class that handles Bearer requests for data using Fetch.
 */
export class FetchManager {
  requestor?: IUser;
  auth: IAuthModule;

  constructor(requestor?: IUser) {
    this.requestor = requestor;
    this.auth = authModuleMsal;
  }

  async fetch(url: RequestInfo, options: RequestInit): Promise<Response> {
    const token = await this.auth.acquireToken(this.requestor);

    const bearer = `Bearer ${token}`;

    const optionsWithAuth = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: bearer,
      },
    };

    return await fetch(url, optionsWithAuth);
  }
}
