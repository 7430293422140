import React from "react";
import FlexContainer from "../flex-container";
import Row from "../flex-row";
import Col from "../flex-col";
import HcText from "../hc-text";
import FooterLink from "./footer-link";
import useFooter from "./use-footer";
import { lang } from "../../features/i18n";
import SocialMediaSection from "./social-media-section";

import BwLogo from "../../images/hc-logo-white.svg";

import "./footer.scss";

const Footer = (): JSX.Element => {
  const { t } = useFooter();
  const tRoot = lang.footer;

  return (
    <footer className="footer-container">
      <FlexContainer>
        <Row className="footer-logo-container">
          <Col>
            <img src={BwLogo} alt="Holiday Club logo" />
          </Col>
        </Row>
        <Row className="footer-content">
          <Col s={12} m={4} l={3}>
            <HcText weight="strong" size="l" colorVariant="white" bottomSpace="l" className="footer-category-text">
              {t(tRoot.aboutLinks.categoryName)}
            </HcText>
            <FooterLink href={t(tRoot.aboutLinks.faq.link)}>
              {t(tRoot.aboutLinks.faq.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.aboutLinks.owner.link)}>
              {t(tRoot.aboutLinks.owner.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.aboutLinks.resale.link)}>
              {t(tRoot.aboutLinks.resale.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.aboutLinks.gdpr.link)} className="list-last-link">
              {t(tRoot.aboutLinks.gdpr.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.aboutLinks.termsOfUse.link)}>
              {t(tRoot.aboutLinks.termsOfUse.text)}
            </FooterLink>
          </Col>
          <Col s={12} m={4} l={3}>
            <HcText weight="strong" size="l" colorVariant="white" bottomSpace="l" className="footer-category-text">
              {t(tRoot.contactLinks.categoryName)}
            </HcText>
            <FooterLink href={t(tRoot.contactLinks.customerService.link)}>
              {t(tRoot.contactLinks.customerService.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.contactLinks.propertyManager.link)}>
              {t(tRoot.contactLinks.propertyManager.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.contactLinks.newsletters.link)} className="list-last-link">
              {t(tRoot.contactLinks.newsletters.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.contactLinks.feedback.link)}>
              {t(tRoot.contactLinks.feedback.text)}
            </FooterLink>
          </Col>
          <Col s={12} m={4} l={3}>
            <HcText weight="strong" size="l" colorVariant="white" bottomSpace="l" className="footer-category-text">
              {t(tRoot.shopLinks.categoryName)}
            </HcText>
            <FooterLink href={t(tRoot.shopLinks.reserve.link)}>
              {t(tRoot.shopLinks.reserve.text)}
            </FooterLink>
            <FooterLink href={t(tRoot.shopLinks.gift.link)} className="list-last-link">
              {t(tRoot.shopLinks.gift.text)}
            </FooterLink>
          </Col>
          <Col s={12} m={4} l={3} className="hide-md hide-sm social-media-wrapper-desktop">
            <SocialMediaSection translate={t} />
          </Col>
        </Row>
        <Row className="hide-lg divider-wrapper">
          <Col>
            <div className="social-media-divider" />
          </Col>
        </Row>
        <Row className="hide-lg social-media-wrapper">
          <SocialMediaSection translate={t} />
        </Row>
      </FlexContainer>
    </footer>
  );
};

export default Footer;
