import React from "react";
import useFirstLoginBadge from "./use-first-login-badge";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { lang } from "../../../features/i18n";

import "./first-login-badge.scss";
import HcText from "../../hc-text";
import HcButton from "../../hc-button";
import ArrowRight from "../../../images/arrow-right-red.svg";
import { isMobile } from "react-device-detect";

const FirstLoginBadge = (): JSX.Element => {
  const {
    setRefElement,
    hideFirstLoginTooltipAction,
    t,
    handleProfileLinkClick,
  } = useFirstLoginBadge();
  const tRoot = lang.home.general;

  const firstLoginCss = cx("first-login-badge");
  const tooltipId = "first-login-tooltip";

  return (
    <>
      <span className={firstLoginCss} ref={setRefElement} data-for={tooltipId} data-tip />

      <ReactTooltip
        place="bottom"
        effect="solid"
        globalEventOff={isMobile ? "touchstart" : "click"}
        offset={{ bottom: 25 }}
        clickable={true}
        event="click"
        afterHide={hideFirstLoginTooltipAction}
        id={tooltipId}
        type="light"
        className="first-login-tooltip"
        scrollHide={true}
        overridePosition={({ left, top }) => {
          const tipOffsetW = window.innerWidth <= 1250 ? 280 : 340;
          const tipOffsetH = window.innerWidth <= 1250 ? 40 : 60;
          left = window.innerWidth - tipOffsetW;
          top = tipOffsetH;
          return { top, left };
        }}
      >
        <>
          <HcText>{t(tRoot.firstLoginNotificationMessage)}</HcText>
          <HcButton onClick={handleProfileLinkClick} isLinkButton>
            {t(tRoot.firstLoginNotificationLink)} <img src={ArrowRight} alt="" />
          </HcButton>
        </>
      </ReactTooltip>
    </>
  );
};

export default FirstLoginBadge;
