import CollapsingTabList from "./collapsing-tab-list";
import { TabContent } from "..";
import { WindowSize } from "../../../features/use-window-size";
import { MenuItem } from "../../dropdown-menu";

export type CollapsingTabListProps = {
  tabs: TabContent[];
  tabIndex: number;
  parentClass: string;
  parent: HTMLElement | null;
  windowSize: WindowSize;
  setTabIndex: (newIndex: number) => void;
  overflowTabs: MenuItem[];
  setOverflowTabs: (newState: MenuItem[]) => void;
  overflowIndex: number;
  setOverflowIndex: (newState: number) => void;
  overflowButtonRef: null | HTMLButtonElement;
  setOverflowButtonRef: (newState: null | HTMLButtonElement) => void;
  onMenuItemSelect?: (tab: string) => void;
};

export default CollapsingTabList;
