import {
  eachDayOfInterval,
  getISOWeek,
  getISODay,
  getWeek,
  getDay,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";

import {
  CalendarType,
  CalendarDayType,
  HighlightType,
  ThemedDateRange,
  HighlightColorType,
  DateRangeTheme,
} from ".";

import { DateRangeType } from ".";

/**
 * @param {number} year
 * @param {number} month 0-indexed (January is 0, February is 1, etc)
 * @returns {number} amount of days
 */
export const daysInMonth = (year: number, month: number): number => {
  /**
   * 0 as the day returns the last day of the previous month,
   * so we have to add 1 to the month number
   * */
  const fixedMonth = month + 1;
  return new Date(year, fixedMonth, 0).getDate();
};

/**
 * Get array of week numbers in given month
 * @param {number} year
 * @param {number} month
 * @param {CalendarType} type
 */
export const getWeekNumbersInMonth = (
  year: number,
  month: number,
  type: CalendarType
): number[] => {
  const daysInCurrentMonth = daysInMonth(year, month);
  const firstOfMonth = new Date(year, month, 1);
  const lastOfMonth = new Date(year, month, daysInCurrentMonth);
  const firstWeek = getLocalizedWeek(firstOfMonth, type);
  const lastWeek = getLocalizedWeek(lastOfMonth, type);

  const weeks: number[] = [];
  for (let i = firstWeek; i <= lastWeek; i++) {
    if (!weeks.includes(i)) {
      weeks.push(i);
    }
  }

  return weeks;
};

/**
 * Get array of Date objects in given month
 * @param {number} year
 * @param {number} month
 */
export const getMonthData = (year: number, month: number): Date[] => {
  const result = eachDayOfInterval({
    start: new Date(year, month, 1),
    end: new Date(year, month, daysInMonth(year, month)),
  });

  return result;
};

/**
 * Get array of Date objects in previous month. We use this data to fill
 * possible empty days in the beginning of current month. For example if the first
 * day of current month is Wednesday and we use ISO calendar, we get Mon-Tue from the previous month.
 * @param {number} year - current year
 * @param {number} month - current month
 */
export const getPrevMonthData = (year: number, month: number): Date[] => {
  let data: Date[] = [];

  if (month > 0) {
    data = getMonthData(year, month - 1);
  }
  if (month === 0) {
    data = getMonthData(year - 1, 11);
  }

  return data;
};

/**
 * Get array of Date objects in next month. We use this data to fill
 * possible empty days in the end of current month. For example if the last
 * day of current month is Wednesday and we use ISO calendar, we get we get Thu-Sun from the next month.
 * @param {number} year - current year
 * @param {number} month - current month
 */
export const getNextMonthData = (year: number, month: number): Date[] => {
  let data: Date[] = [];

  if (month < 11) {
    data = getMonthData(year, month + 1);
  }
  if (month === 11) {
    data = getMonthData(year + 1, 0);
  }

  return data;
};

/**
 * @param {Date} day
 * @param {DateRangeType} range - the range the day belongs to
 * @param {boolean} overlapping - is range overlapping some other reservation
 * @param {DateRangeTheme} rangeType
 * @returns {HighlightType}
 */
const getDayHighlightType = (
  day: Date,
  range: DateRangeType,
  overlapping: boolean,
  rangeType?: DateRangeTheme
): HighlightType => {
  /**
   * Get the HighlightType in case of overlapping reservations
   * @param {DateRangeTheme} rangeType
   * @param {string }prefix - HighlightType without the color
   * @returns {HighlightType}
   */
  const getOverlappingHighlightType = (
    rangeType: DateRangeTheme,
    prefix: string
  ): HighlightType => {
    switch (rangeType) {
      case "confirmed-primary":
        return `${prefix}-red` as HighlightType;
      case "confirmed-black":
        return `${prefix}-black` as HighlightType;
      case "unconfirmed":
        return `${prefix}-unconfirmed` as HighlightType;
      default:
        return "none";
    }
  };

  if (isSameDay(day, range.start) && isSameDay(day, range.end)) {
    return overlapping && rangeType
      ? getOverlappingHighlightType(rangeType, "overlapping-range-one-day")
      : "range-start-end";
  }

  if (isSameDay(day, range.start)) {
    return overlapping && rangeType
      ? getOverlappingHighlightType(rangeType, "overlapping-range-start")
      : "range-start";
  }

  if (isSameDay(day, range.end)) {
    return overlapping && rangeType
      ? getOverlappingHighlightType(rangeType, "overlapping-range-end")
      : "range-end";
  }

  if (isWithinInterval(day, { start: range.start, end: range.end })) {
    return "range-item";
  }

  return "none";
};

/**
 * @param {DateRangeTheme} rangeType
 * @returns {HighlightColorType}
 */
const getHighlightColor = (rangeType: DateRangeTheme): HighlightColorType => {
  switch (rangeType) {
    case "selection": {
      return "white-selection";
    }
    case "unconfirmed": {
      return "white";
    }
    case "confirmed-primary": {
      return "primary";
    }
    case "confirmed-black": {
      return "black";
    }
    default: {
      return "none";
    }
  }
};

/**
 * How many "empty" days in the beginning of current month. For example if the first
 * day of current month is Wednesday and we use ISO calendar, returns 2
 * @param {number} year
 * @param {number} month 0-indexed
 * @param {CalendarType} calendarType
 * @returns {number}
 */
const getAmountOfEmptyDaysStart = (
  year: number,
  month: number,
  calendarType: CalendarType
): number => {
  const d = new Date(year, month, 1);
  /**
   * date-fns getISODay() returns 1-7, where 1 is Monday
   * getDay() returns 0-6, where 0 is Monday
   */
  return calendarType === "ISO" ? getISODay(d) - 1 : getDay(d);
};

/**
 * Get localized week number by given date
 * @param {Date} date
 * @param {CalendarType} calendarType
 * @returns {number}
 */
const getLocalizedWeek = (date: Date, calendarType: CalendarType): number => {
  return calendarType === "ISO" ? getISOWeek(date) : getWeek(date);
};

/**
 * Create calendar data array from array of dates
 * @param {Date[]} data - array of dates
 * @param {CalendarType} calendarType
 * @param {boolean} isCurrentMonth - true if date is in current month
 * @returns {CalendarDayType[]}
 */
const initCalendarData = (
  data: Date[],
  calendarType: CalendarType,
  isCurrentMonth: boolean
): CalendarDayType[] => {
  const dateData: CalendarDayType[] = [];

  // current month style is black, prev & next dimmed
  data.forEach((item) => {
    dateData.push({
      dayItem: item,
      dayColorStyle: isCurrentMonth ? "black" : "weak",
      highlightColor: "none",
      highlightType: "none",
      week: getLocalizedWeek(item, calendarType),
    });
  });

  return dateData;
};

/**
 * @param {Date} day - date
 * @param {DateRangeType} interval - date range
 * @returns {boolean} true if date is within date range
 */
const isWithinInterval = (day: Date, interval: DateRangeType) => {
  const iStart = new Date(
    interval.start.getFullYear(),
    interval.start.getMonth(),
    interval.start.getDate()
  );
  const iEnd = new Date(
    interval.end.getFullYear(),
    interval.end.getMonth(),
    interval.end.getDate()
  );

  const withinInterval = day >= iStart && day <= iEnd ? true : false;

  return withinInterval;
};

/**
 * Add ranges to calendar date data
 * @param {CalendarDayType[]} dateData
 * @param {ThemedDateRange[]} rangeData
 * @returns {CalendarDayType[]} - array of calendar days
 */
const addRangeData = (
  dateData: CalendarDayType[],
  rangeData: ThemedDateRange[]
): CalendarDayType[] => {
  return dateData.map((item) => {
    /**
     * @param {ThemedDateRangep[]} reservations - array of reservations
     * @returns {ThemedDateRange} shortest of the given reservations
     */
    const getShortestReservation = (reservations: ThemedDateRange[]): ThemedDateRange => {
      return reservations.reduce((shortest, r) =>
        shortest === undefined ||
        differenceInCalendarDays(r.data.end, r.data.start) <
          differenceInCalendarDays(shortest.data.end, shortest.data.start)
          ? r
          : shortest
      );
    };

    const day = item.dayItem;

    // Find all reservations of the day
    const reservations = rangeData.filter((r) => {
      return isWithinInterval(day, { start: r.data.start, end: r.data.end });
    });

    // If there are no reservations return day without highlight
    if (reservations.length === 0) {
      return { ...item, highlightColor: "none", highlightType: "none" };
    }

    // If there are more than one reservations, find the shortest and second shortest reservation
    const isOverlapping = reservations.length > 1;
    const shortestReservation = isOverlapping
      ? getShortestReservation(reservations)
      : reservations[0];
    const secondShortestReservation = isOverlapping
      ? getShortestReservation(reservations.filter((r) => r !== shortestReservation))
      : undefined;

    const highlightType = getDayHighlightType(
      day,
      shortestReservation.data,
      isOverlapping,
      shortestReservation.type
    );

    /**
     * If there are more than one reservations, and the day is overlapping range start or end,
     * the highlightColor is the color of the second shortest reservation, used as calendar day bg
     */
    const highlightColor = getHighlightColor(
      secondShortestReservation && highlightType.startsWith("overlapping-range")
        ? secondShortestReservation.type
        : shortestReservation.type
    );
    return { ...item, highlightColor, highlightType };
  });
};

/**
 * Get array of visible days in calendar. We need to combine visible days
 * from previous and next months with current month.
 * @param {number} year - current calendar year
 * @param {number} month - current calendar month, 0-indexed
 * @param {CalendarType} calendarType
 * @param {ThemedDateRange[]|undefined} dateRangeData array of themed ranges
 * @returns {CalendarDayType[]} array of days with style data
 */
export const getCalendarData = (
  year: number,
  month: number,
  calendarType: CalendarType,
  dateRangeData: ThemedDateRange[] | undefined
): CalendarDayType[] => {
  let prevMonth: Date[] = getPrevMonthData(year, month);
  let nextMonth: Date[] = getNextMonthData(year, month);
  const currentMonth: Date[] = getMonthData(year, month);
  const emptyStart = getAmountOfEmptyDaysStart(year, month, calendarType);
  let dateData: CalendarDayType[] = [];

  // If current month doesn't start from su/mo --> add days from prev month
  if (emptyStart > 0) {
    prevMonth = prevMonth.slice(-emptyStart, prevMonth.length);
    dateData = initCalendarData(prevMonth, calendarType, false);
  }

  // Concat data from current month to dateData
  dateData = dateData.concat(initCalendarData(currentMonth, calendarType, true));

  // We have 6 week calendar (42 days) so let's get missing days from
  // next month
  const endOfMonth = 42 - dateData.length;
  if (endOfMonth > 0) {
    nextMonth = nextMonth.slice(0, endOfMonth);
    dateData = dateData.concat(initCalendarData(nextMonth, calendarType, false));
  }

  // Add range data to dateData array
  if (dateRangeData !== undefined) {
    dateData = addRangeData(dateData, dateRangeData);
  }

  return dateData;
};
