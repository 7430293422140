import { State } from "@popperjs/core";
import { WindowSize } from "../../features/use-window-size";
import DropdownMenu from "./dropdown-menu";

export type MenuItem = {
  name: string;
  action: () => void;
  hasBottomDivider?: boolean;
  hasBadge?: boolean;
};

export type DropdownMenuProps = {
  referenceElement: null | HTMLElement;
  menuItems: MenuItem[];
  altAxis: boolean;
};

export type DropdownMenuHooks = {
  popperElement: React.MutableRefObject<HTMLDivElement | null>;
  arrowElement: React.MutableRefObject<HTMLDivElement | null>;
  popperStyles: { [key: string]: React.CSSProperties };
  popperAttributes: {
    [key: string]:
      | {
          [key: string]: string;
        }
      | undefined;
  };
  popperUpdate: (() => Promise<Partial<State>>) | null;
  windowSize: WindowSize;
  show: () => void;
  hide: () => void;
};

export default DropdownMenu;
