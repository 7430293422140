import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import {
  selectAuthState,
  selectUser,
  checkLogin as checkLoginAction,
} from "../../../store/authorization";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const useProtectedRoute = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  intendedRender: (props: RouteComponentProps<any>) => React.ReactNode,
  fallbackRender: (props: RouteComponentProps<any>) => React.ReactNode
  /* eslint-enable @typescript-eslint/no-explicit-any */
) => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthState);
  const user = useSelector(selectUser);
  const checkLogin = () => dispatch(checkLoginAction());
  const isAuthLoading = authState.loading;
  const location = useLocation();

  const protectedRender = useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (props: RouteComponentProps<any>) => {
      /* eslint-enable @typescript-eslint/no-explicit-any */
      return user ? intendedRender(props) : fallbackRender(props);
    },
    [user, location.pathname]
  );

  useEffect(() => {
    if (!user && isAuthLoading === "idle") {
      checkLogin();
    }
  }, [user, checkLogin, isAuthLoading]);

  return {
    protectedRender,
  };
};

export default useProtectedRoute;
