import Col from "../../flex-col";
import Row from "../../flex-row";
import HcButton from "../../hc-button";
import { WizardStep } from "../../wizard-container";
import React from "react";
import { lang } from "../../../features/i18n";
import HcText from "../../hc-text";
import useFinishRentProcess from "./use-finish-rent-process";
import "./finish-rent-process.scss";
import { WeekType } from "../../../app/OwnerService-api";

type Props = {
  holdingId: string;
  contractId: number;
  holdingUnitId: number;
  weekStart: string | undefined;
  weekEnd: string | undefined;
  resortName: string;
  realEstateCompany: string;
  weekNumber: number;
  weekType: WeekType;
  reservationRange: string;
} & WizardStep;

const FinishRentProcess = ({
  holdingId,
  contractId,
  holdingUnitId,
  weekStart,
  weekEnd,
  resortName,
  realEstateCompany,
  weekNumber,
  weekType,
  reservationRange,
  nextStep,
  previousStep,
}: Props): JSX.Element => {
  const { t, handleFinishProcess } = useFinishRentProcess(
    holdingId,
    contractId,
    holdingUnitId,
    weekNumber,
    weekType,
    weekStart,
    weekEnd,
    nextStep
  );
  const tRoot = lang.rentFormModal;
  const classNameRoot = "finish-rent-process";
  const classNameContainer = `${classNameRoot}__container no-side-padding`;

  return (
    <Row className={classNameRoot}>
      <Col className={classNameContainer}>
        <Row>
          <Col className="no-side-padding">
            <HcText weight="strong" bottomSpace="m">
              {t(tRoot.finishProcess.title)}
            </HcText>
            <div className="holding-info-box">
              <HcText size="s" bottomSpace="0">
                {resortName}
              </HcText>
              <HcText size="s" colorVariant="weak" bottomSpace="0">
                {realEstateCompany}
              </HcText>
              <HcText size="s" colorVariant="weak" bottomSpace="0">
                {t(tRoot.week)} {weekNumber}
                <span className="week-date-separator">|</span>
                {reservationRange}
              </HcText>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="no-side-padding">
            <HcButton onClick={handleFinishProcess}>{t(tRoot.finishProcess.continue)}</HcButton>
            <HcButton onClick={previousStep} isSecondary>
              {t(tRoot.back)}
            </HcButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FinishRentProcess;
