import React from "react";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import useInfo from "./use-info";
import { lang } from "../../../features/i18n";
import { CalendarDto } from "../../../app/OwnerService-api";
import { daysBeforeRangeStart } from "../../date-range/utils";
import RentedWeekOverlay from "./rented-week-overlay";

type Props = {
  resortName: string;
  selectedWeek: CalendarDto | undefined;
  isRented: boolean;
} & WizardStep;

const Info = ({
  nextStep,
  previousStep,
  resortName,
  selectedWeek,
  isRented,
}: Props): JSX.Element => {
  const { t, handlePreviousStep } = useInfo(previousStep);
  const tRoot = lang.home;

  const daysBefore = selectedWeek
    ? daysBeforeRangeStart(new Date(), new Date(selectedWeek.weekStart)).toString()
    : " ";

  return isRented ? (
    <RentedWeekOverlay translate={t} resortName={resortName} />
  ) : (
    <>
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        {t(tRoot.holdings.weeks.infoStep.part1)}{" "}
        <HcText tag="span" weight="strong" size="s">
          {t(tRoot.general.inNDays, { days: daysBefore })}
        </HcText>{" "}
        {t(tRoot.holdings.weeks.infoStep.part2, { resortName: resortName })}{" "}
        <a
          href={t(tRoot.holdings.weeks.infoStep.linkUrl)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(tRoot.holdings.weeks.infoStep.link)}
        </a>
        {"."}
      </HcText>
      <HcButton onClick={nextStep}>{t(tRoot.general.inspectBenefits)}</HcButton>
      <HcButton onClick={handlePreviousStep} isSecondary={true}>
        {t(tRoot.holdings.weeks.rent)}
      </HcButton>
    </>
  );
};

export default Info;
