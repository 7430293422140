import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { onRequestClose as onRequestCloseAction } from "../../../store/modal";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useModalA11y = (modalRoot: HTMLElement, shouldCloseOnEsc: boolean, isOpen: boolean) => {
  const dispatch = useAppDispatch();

  /**
   * Handles escape key
   *
   * @param e
   */
  const handleEscapeKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && shouldCloseOnEsc && isOpen) {
        dispatch(onRequestCloseAction());
      }
    },
    [dispatch, isOpen, shouldCloseOnEsc]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleEscapeKey]);

  return {
    handleEscapeKey,
  };
};

export default useModalA11y;
