import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { HomepageState } from ".";
import { HoldingReservationDto, ICalendarDto, IHoldingDto } from "../../app/OwnerService-api";
import { FrontPageClient } from "../../app/OwnerService-api";
import { FetchManager, IUser } from "../../features/authorization";

const initialState: HomepageState = {
  homepageData: {
    heroImage: undefined,
    holdings: [],
    content: [],
    firstLogin: false,
  },
  showFirstLoginBadge: false,
  showFirstLoginTooltip: false,
  loading: "idle",
  restrictedUserInterfaceAccess: false,
  rentForm: {
    holdingUnitId: -1,
    isOpen: false,
    reservations: [],
    week: undefined,
    additionalInformation: "",
  },
};

export const requestHomepageData = createAsyncThunk(
  "homepageData/getHomepageData",
  async (requestor: IUser | undefined) => {
    const client = new FrontPageClient(undefined, new FetchManager(requestor));

    const homepageData = await client.get();
    return homepageData;
  }
);

const extraRequestHomepageReducers = (builder: ActionReducerMapBuilder<HomepageState>) => {
  builder.addCase(requestHomepageData.pending, (state) => {
    state.loading = "pending";
  });

  builder.addCase(requestHomepageData.fulfilled, (state, action) => {
    if (action.payload) {
      state.homepageData.heroImage = action.payload.heroImage;
      state.homepageData.content = action.payload.content;
      state.homepageData.firstLogin = action.payload.firstLogin;

      // Filter out all externally managed holdings...
      state.homepageData.holdings = action.payload.holdings.filter((h) => !h.isExternallyManaged);

      // ... and restrict UI access for users with only external holdings
      state.restrictedUserInterfaceAccess = action.payload.holdings.every(
        (h) => h.isExternallyManaged
      );

      // Get persist state if found
      const persistShowFirstLoginBadge: boolean =
        localStorage.getItem("firstLoginBadge") !== null
          ? JSON.parse(localStorage.getItem("firstLoginBadge") || "true")
          : true;
      const persistShowFirstLoginTooltip: boolean =
        localStorage.getItem("firstLoginTooltip") !== null
          ? JSON.parse(localStorage.getItem("firstLoginTooltip") || "true")
          : true;

      // Show first login badge if it's first login and badge visibility is true
      // firstLoginBadge is not visible after the user has visited on profile page
      state.showFirstLoginBadge = persistShowFirstLoginBadge && action.payload.firstLogin;

      // Show first login tooltip if it's first login and badge visibility is true and the
      // user has not clicked the tooltip to hide it
      state.showFirstLoginTooltip =
        persistShowFirstLoginTooltip && persistShowFirstLoginBadge && action.payload.firstLogin;

      // set localstorage items if needed
      if (localStorage.getItem("firstLoginBadge") === null && action.payload.firstLogin) {
        localStorage.setItem("firstLoginBadge", JSON.stringify(true));
      }
      if (localStorage.getItem("firstLoginTooltip") === null && action.payload.firstLogin) {
        localStorage.setItem("firstLoginTooltip", JSON.stringify(true));
      }
    } else {
      state.homepageData.heroImage = undefined;
      state.homepageData.holdings = [];
      state.homepageData.content = [];
      state.homepageData.firstLogin = false;
      state.showFirstLoginBadge = false;
      state.showFirstLoginTooltip = false;
    }
    state.loading = "success";
  });

  builder.addCase(requestHomepageData.rejected, (state, action) => {
    console.error(JSON.stringify(action.error, null, 4));
    state.loading = "error";
  });
};

type RentFormType = {
  holdingUnit: IHoldingDto["unitId"];
  reservations: HoldingReservationDto[];
  week: ICalendarDto;
  additionalInformation: "";
};

const homepageSlice = createSlice({
  initialState,
  name: "homepageData",
  reducers: {
    openRentForm: (state, action: PayloadAction<RentFormType>) => {
      state.rentForm.isOpen = true;
      state.rentForm.holdingUnitId = action.payload.holdingUnit;
      state.rentForm.reservations = action.payload.reservations;
      state.rentForm.week = action.payload.week;
    },
    closeRentForm: (state) => {
      state.rentForm.isOpen = false;
      state.rentForm.holdingUnitId = -1;
      state.rentForm.reservations = [];
      state.rentForm.week = undefined;
      state.rentForm.additionalInformation = "";
    },
    hideFirstLoginBadge: (state) => {
      state.showFirstLoginBadge = false;
      localStorage.setItem("firstLoginBadge", JSON.stringify(false));
    },
    hideFirstLoginTooltip: (state) => {
      state.showFirstLoginTooltip = false;
      localStorage.setItem("firstLoginTooltip", JSON.stringify(false));
    },
    updateAdditionalInformation: (state, action: PayloadAction<string>) => {
      state.rentForm.additionalInformation = action.payload;
      console.log(action.payload);
    },
  },
  extraReducers: (builder) => {
    extraRequestHomepageReducers(builder);
  },
});

export default homepageSlice;
