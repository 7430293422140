import React from "react";
import { TypedTranslate } from "../../../features/i18n";
import { lang } from "../../../features/i18n";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import useInfoVillas from "./use-info-villas";

type Props = {
  resortName: string;
  translate: TypedTranslate;
} & WizardStep;

const Info = ({ nextStep, previousStep, resortName, translate: t }: Props): JSX.Element => {
  const tRoot = lang.home;

  const { handleNextStep } = useInfoVillas(nextStep);

  return (
    <>
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        {t(tRoot.holdings.villas.infoStep.part1, { resortName: resortName })}
        <HcText weight="strong" tag="span" size="s">
          {t(tRoot.holdings.villas.infoStep.part2)}
        </HcText>
        {t(tRoot.holdings.villas.infoStep.part3)}
      </HcText>
      <HcButton theme="dark" onClick={() => handleNextStep()}>
        {t(tRoot.holdings.villas.makeReservation)}
      </HcButton>
      <HcButton onClick={previousStep} isSecondary>
        {t(tRoot.general.inspectBenefits)}
      </HcButton>
    </>
  );
};

export default Info;
