import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import authorization from "./authorization";
import customerProfile from "./customer-profile";
import reservations from "./reservations";
import homepageData from "./home";
import cardOrder from "./card-order";
import benefitsPageData from "./benefits";
import modal from "./modal";
import calendarOverlay from "./calendar-overlay";
import calendar from "./calendar";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (history: History) =>
  combineReducers({
    authorization: authorization,
    customerProfile: customerProfile,
    homepageData: homepageData,
    benefitsPageData: benefitsPageData,
    router: connectRouter(history),
    reservations,
    cardOrder,
    modal,
    calendarOverlay,
    calendar,
  });

export default rootReducer;
