import React, { useCallback } from "react";

import { lang } from "../../features/i18n";
import useHoldingsCalendar from "./hooks";

import CardContainer from "../card-container";
import Calendar from "../calendar";
import HcText from "../hc-text";
import HcHeading from "../hc-heading";
import Overlay from "../overlay";

import { HoldingDto, IReserveHoldingDto, WeekType } from "../../app/OwnerService-api";

import ReservationVillasFrac from "./reservation-villas-frac";
import ReservationWeeks from "./reservation-weeks";
import ReservationRci from "./reservation-rci";
import ReservationVillas from "./reservation-villas";
import CancellationVillas from "./cancellation-villas";
import OngoingReservation from "./ongoing-reservation";
import NewCustomerWelcomeOverlay from "./new-customer-welcome-overlay";
import { HoldingReserved, Wizards } from "./";

import "./holdings-calendar.scss";
import Badge from "../badge";
import cx from "classnames";
import "../../styles/_utils.scss";
import HcButton from "../hc-button";
import CloseIcon from "../../images/close.svg";
import ChevronLeft from "../../images/chevron-left.svg";
import ChevronRight from "../../images/chevron-right.svg";

type HoldingsCalendarProps = {
  holdingsData: HoldingDto[];
  reserveHoldings: (reservations: IReserveHoldingDto[]) => Promise<void>;
  scrollToBenefits: () => void;
  scrollToHoldings: () => void;
};

const HoldingsCalendar = ({
  holdingsData,
  reserveHoldings,
  scrollToBenefits,
  scrollToHoldings,
}: HoldingsCalendarProps): JSX.Element => {
  /**
   * OverlayWizards for different reservation types are created here
   * and used via getWizards at useOverlayContent
   */
  type OverlayWizard = (props: { holding: HoldingReserved }) => JSX.Element;

  const CancellationWizard: OverlayWizard = ({ holding }) => (
    <CancellationVillas
      cancelWizard={closeOverlay}
      holding={holding}
      confirmWizard={closeOverlay}
    />
  );

  const FractionalReservationWizard: OverlayWizard = ({ holding }) => (
    <ReservationVillasFrac
      cancelWizard={goToBenefits}
      confirmWizard={closeOverlay}
      holding={holding}
      reserveHoldings={reserveHoldings}
      closeOverlay={closeOverlay}
    />
  );

  const VillasReservationWizard: OverlayWizard = ({ holding }) => (
    <ReservationVillas
      cancelWizard={goToBenefits}
      confirmWizard={closeOverlay}
      holding={holding}
      reserveHoldings={reserveHoldings}
      closeOverlay={closeOverlay}
    />
  );

  const RciWizard: OverlayWizard = ({ holding }) => {
    const points = holdingsData.reduce((sum, holding) => {
      switch (holding.weekType) {
        case WeekType.PointsFixed:
          return sum + (holding.points ?? 0);
        default:
          return sum;
      }
    }, 0);

    return (
      <ReservationRci
        cancelWizard={goToBenefits}
        confirmWizard={() => {
          redirectToRci();
          closeOverlay();
        }}
        points={points}
        holding={holding}
        translate={t}
      />
    );
  };

  const WeeksWizard: OverlayWizard = ({ holding }) => (
    <ReservationWeeks
      cancelWizard={() => {
        closeOverlay();
      }}
      confirmWizard={goToBenefits}
      holding={holding}
    />
  );

  const OngoingReservationWizard: OverlayWizard = ({ holding }) => {
    return (
      <OngoingReservation
        cancelWizard={closeOverlay}
        confirmWizard={closeOverlay}
        holding={holding}
        translate={t}
      />
    );
  };

  const NewCustomerWizard: OverlayWizard = ({ holding }) => {
    return (
      <NewCustomerWelcomeOverlay
        cancelWizard={goToHoldings}
        confirmWizard={goToBenefits}
        holding={holding}
        translate={t}
      />
    );
  };

  /**
   * Get available overlay wizards by holding
   * @param {(HoldingReserved|undefined)} holding
   * @returns {Wizards} overlay wizards object
   */
  /* eslint-disable react/display-name */
  const getWizards = useCallback((holding: HoldingReserved | undefined): Wizards => {
    const FallbackWizard = () => <div />;

    if (holding) {
      const DefaultWizard = () => <WeeksWizard holding={holding} />;

      return {
        default: DefaultWizard,
        fallback: FallbackWizard,
        cancellation: () => <CancellationWizard holding={holding} />,
        ongoing: () => <OngoingReservationWizard holding={holding} />,
        newCustomer: () => <NewCustomerWizard holding={holding} />,
        byWeekType: {
          [WeekType.VillasFractional]: () => <FractionalReservationWizard holding={holding} />,
          [WeekType.VillasFullOwnership]: () => <VillasReservationWizard holding={holding} />,
          [WeekType.PointsFixed]: () => <RciWizard holding={holding} />,
          [WeekType.Fixed]: DefaultWizard,
        },
      };
    } else {
      return {
        byWeekType: {},
        cancellation: FallbackWizard,
        ongoing: FallbackWizard,
        newCustomer: FallbackWizard,
        default: FallbackWizard,
        fallback: FallbackWizard,
      };
    }
  }, []);

  const {
    t,
    handleCalendarClick,
    handleDetailsToggle,
    calendarData,
    holdings,
    OverlayContent,
    initDate,
    isCalendarOverlayVisible,
    activeHolding,
    reservationsLoading,
    isCalendarOverlayClosing,
    closeCalendarOverlay,
    cancelOnRequestCloseOverlay,
    handleOverlayCloseIconClick,
    sendDataToGTM,
    changeSelectedHolding,
    selectedHolding,
    selectableHoldingsData,
    setVisibleDateRange,
  } = useHoldingsCalendar(holdingsData, getWizards);
  const tRoot = lang.home.holdings;
  const overlayRoot = lang.calendarOverlay;

  /**
   * Close calendar overlay
   */
  const closeOverlay = () => {
    handleDetailsToggle();
  };

  /**
   * Scroll to benefts banner
   */
  const goToBenefits = () => {
    scrollToBenefits();
    closeOverlay();
  };

  /**
   * Scroll to holding details
   */
  const goToHoldings = () => {
    scrollToHoldings();
  };

  /**
   * Open rci.com in a new tab
   */
  const redirectToRci = () => {
    const rciRedirectUrl = "rci.com";

    // Google tag manager
    sendDataToGTM({
      event: "outbound_link_click",
      event_description: `https://${rciRedirectUrl}`,
    });

    window.open(`https://${rciRedirectUrl}`, "_blank");
  };

  /**
   * Get holding info to calendar header: villas share, rci points or week number
   * @returns React component
   */
  const HoldingSizeText = () => {
    if (holdings.length > 0) {
      const holding = isCalendarOverlayVisible ? activeHolding : selectedHolding;
      if (holding !== "SHOW_ALL") {
        switch (holding?.weekType) {
          case WeekType.VillasFractional:
          case WeekType.VillasFullOwnership: {
            const share = holding.villasShare ?? "";
            return <HcText colorVariant="weak">{t(tRoot.villasShare, { share })}</HcText>;
          }
          case WeekType.PointsFixed: {
            const points = new Intl.NumberFormat("fi-FI").format(holding.points ?? 0);
            return <Badge>{points} pt.</Badge>;
          }
          default: {
            const holdingWeek = holding && holding.weekNumber ? holding.weekNumber.toString() : "X";
            return <HcText colorVariant="weak">{t(tRoot.weekLong, { week: holdingWeek })}</HcText>;
          }
        }
      }
    }
    return <HcText>{}</HcText>;
  };

  /**
   * Handle calendar click
   * @param {Date} day - date clicked
   * @param {boolean} isActive - true if clicked date is reserved or reservable
   */
  const onDaySelect = (day: Date, isActive: boolean) => {
    handleCalendarClick(day, isActive);
  };

  const cardContainerCss = cx("home-calendar-card-container");

  const overlayContentCss = cx({
    "is-overlay-hidden": isCalendarOverlayClosing,
  });

  const navButtonClasses = cx("chevron-button", {
    "chevron-button--hidden": isCalendarOverlayVisible,
  });

  /**
   * Get calendar heading:
   * - if overlay is visible show active holding name
   * - else show selected holding: resort name or All holdings -header
   * @returns {string} holding name or All holdings -heading
   */
  const getHeading = (): string => {
    if (isCalendarOverlayVisible) {
      return activeHolding?.resortName || "";
    }
    if (selectedHolding === "SHOW_ALL") {
      return t(tRoot.allHoldingsSelected);
    }
    return selectedHolding?.resortName || "";
  };

  let content = (
    <CardContainer className={cardContainerCss}>
      {reservationsLoading !== "pending" && (
        <>
          {isCalendarOverlayVisible && (
            <button className="overlay-close-button" onClick={handleOverlayCloseIconClick}>
              <img src={CloseIcon} alt={t(tRoot.closeOverlayAlt)} />
            </button>
          )}
          <div className="reservation-basic-info">
            {selectedHolding !== "SHOW_ALL" || isCalendarOverlayVisible ? (
              <HoldingSizeText />
            ) : (
              <div style={{ marginTop: "1rem" }} />
            )}

            {selectableHoldingsData.length > 2 ? (
              <div className="holdings-calendar-holding-group-wrapper">
                <HcButton
                  noStyles={true}
                  className={`${navButtonClasses} mr-m chevron-button--left`}
                  onClick={() => changeSelectedHolding(false)}
                  disabled={isCalendarOverlayVisible}
                >
                  <img src={ChevronLeft} alt="prev holding" />
                </HcButton>
                <HcHeading semanticElement="h5" variant="h3" topSpace="s" bottomSpace="0">
                  {getHeading()}
                </HcHeading>
                <HcButton
                  noStyles={true}
                  className={`${navButtonClasses} ml-m chevron-button--right`}
                  onClick={() => changeSelectedHolding(true)}
                  disabled={isCalendarOverlayVisible}
                >
                  <img src={ChevronRight} alt="next holding" />
                </HcButton>
              </div>
            ) : (
              <HcHeading semanticElement="h5" bottomSpace="0">
                {activeHolding && activeHolding.resortName}
              </HcHeading>
            )}

            {selectedHolding !== "SHOW_ALL" && (
              <button className="action-button-reservation-card" onClick={goToHoldings}>
                {t(tRoot.showHolding)}
              </button>
            )}
          </div>
        </>
      )}

      {reservationsLoading !== "pending" && isCalendarOverlayVisible && (
        <Overlay fadeIn={true}>
          <div className={overlayContentCss}>
            <OverlayContent />
          </div>

          {isCalendarOverlayClosing && (
            <>
              <HcHeading semanticElement="h5">{t(overlayRoot.confirmCloseTitle)}</HcHeading>
              <HcText bottomSpace="xl" className="overlay-description-content">
                {t(overlayRoot.confirmCloseContent)}
              </HcText>
              <HcButton theme="dark" onClick={() => cancelOnRequestCloseOverlay()}>
                {t(overlayRoot.cancelConfirmCloseButton)}
              </HcButton>
              <HcButton onClick={() => closeCalendarOverlay()} isSecondary>
                {t(overlayRoot.confirmCloseButton)}
              </HcButton>
            </>
          )}
        </Overlay>
      )}

      <Calendar
        className={isCalendarOverlayVisible ? "blur-calendar" : ""}
        onDaySelect={onDaySelect}
        rangeData={calendarData}
        initUsingDate={initDate}
        isHidden={isCalendarOverlayVisible}
        setVisibleDateRange={setVisibleDateRange}
      />
    </CardContainer>
  );

  if (reservationsLoading === "pending") {
    content = (
      <CardContainer className={"generic-loading-state-absolute loading-state-card-container"}>
        <Calendar calendarType="ISO" key="iso-cal" />
      </CardContainer>
    );
  }

  return <>{content}</>;
};

export default HoldingsCalendar;
