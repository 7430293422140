import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BenefitsPageState } from ".";
import { BenefitsPageClient } from "../../app/OwnerService-api";
import { FetchManager, IUser } from "../../features/authorization";

const initialState: BenefitsPageState = {
  benefitsPageData: {
    intro: undefined,
    content: [],
    benefits: [],
  },
  loading: "idle",
};

export const requestBenefitsPageData = createAsyncThunk(
  "benefitsPageData/getBenefitsPageData",
  async (requestor: IUser | undefined) => {
    const client = new BenefitsPageClient(undefined, new FetchManager(requestor));

    const pageData = await client.get();

    return pageData;
  }
);

const extraRequestBenefitsPageReducers = (builder: ActionReducerMapBuilder<BenefitsPageState>) => {
  builder.addCase(requestBenefitsPageData.pending, (state) => {
    state.loading = "pending";
  });

  builder.addCase(requestBenefitsPageData.fulfilled, (state, action) => {
    state.benefitsPageData.benefits = action.payload.benefits;
    state.benefitsPageData.content = action.payload.content;
    state.benefitsPageData.intro = action.payload.intro;
    state.loading = "success";
  });

  builder.addCase(requestBenefitsPageData.rejected, (state, action) => {
    console.error(JSON.stringify(action.error, null, 4));
    state.loading = "error";
  });
};

const benefitsPageSlice = createSlice({
  initialState,
  name: "benefitsPageData",
  reducers: {},
  extraReducers: (builder) => {
    extraRequestBenefitsPageReducers(builder);
  },
});

export default benefitsPageSlice;
