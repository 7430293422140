import React from "react";
import BannerModalHeader from "../banner-modal-header";
import BannerModalContent from "../banner-modal-content";
import BannerModalFooter from "../banner-modal-footer";
import {
  BenefitType,
  IContentHolidayChangeDto,
  IContentOwnerBenefitDto,
} from "../../../app/OwnerService-api";
import { BannerItemType } from "..";
import useBannerModal from "./use-banner-modal";
import parse from "html-react-parser";
import HcButton from "../../hc-button";
import { lang } from "../../../features/i18n";
import ReduxModal from "../../redux-modal";
import "./banner-modal.scss";
import { getConvertOptions } from "../../../features/utils";
import HcText from "../../hc-text";
import cx from "classnames";

type BannerModalProps = {
  data: IContentOwnerBenefitDto | IContentHolidayChangeDto;
  itemType: BannerItemType;
  id: string;
  resetFilters?: () => void;
};

const BannerModal = ({ data, itemType, id, resetFilters }: BannerModalProps): JSX.Element => {
  const {
    primaryHeadline,
    secondaryHeadline,
    modalButtonTheme,
    t,
    popupLink,
    popupDescription,
    popupDetails,
    subHeadline,
    showDetails,
    setShowDetails,
    hasCategoryBadge,
    totalPoints,
    resortName,
    popupLinks,
  } = useBannerModal(data, itemType);

  const tRoot = lang.epi.horizontalList.modal;

  const options = getConvertOptions("m", "l");

  const isNewCustomerBenefitModal =
    (data as IContentOwnerBenefitDto).benefitType === BenefitType.NewShareOwner;

  const hasDetails = popupDetails !== undefined && popupDetails.length > 0;
  let parsedDetails: string | JSX.Element | JSX.Element[] = <></>;
  if (hasDetails) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    if (isNewCustomerBenefitModal) {
      parsedDetails = (
        <>
          <HcText size="s" bottomSpace="m">
            {t(tRoot.readMoreNewCustomerInfo)}
          </HcText>
          {parse(popupDetails!)}
        </>
      );
    } else {
      parsedDetails = parse(popupDetails!, options);
    }
  }

  const handleClose = () => {
    if (showDetails) {
      setShowDetails(false);
    }
  };

  const readMoreClasses = cx("banner-modal-read-more", {
    "banner-modal-read-more--new-customer": isNewCustomerBenefitModal,
  });

  const bannerModalHeader = (
    <BannerModalHeader
      primaryHeadline={primaryHeadline}
      secondaryHeadline={secondaryHeadline}
      subtitle={subHeadline}
      hasCategoryBadge={hasCategoryBadge}
    />
  );

  const bannerModalContent = showDetails ? (
    <div className="spacing-inset-default">
      <div className={readMoreClasses}>{parsedDetails}</div>
      <HcButton isSecondary onClick={() => setShowDetails(false)}>
        {t(tRoot.backFromDetails)}
      </HcButton>
    </div>
  ) : (
    <>
      <BannerModalContent
        translate={t}
        popupDescription={popupDescription}
        hasDetails={hasDetails}
        onShowDetails={() => setShowDetails(true)}
        isHolidayChange={itemType === "HolidayChange"}
        resortName={resortName || ""}
        totalPoints={totalPoints}
        resetFilters={resetFilters}
        id={id}
      />
      <BannerModalFooter
        translate={t}
        popupLink={popupLink}
        popupLinks={popupLinks}
        buttonTheme={modalButtonTheme}
        isHolidayChange={itemType === "HolidayChange"}
      />
    </>
  );

  return (
    <ReduxModal
      id={id}
      ariaLabel={t(tRoot.labelText)}
      shouldConfirmBeforeClose={false}
      defaultPadding={false}
      header={bannerModalHeader}
      headerSize={hasCategoryBadge || primaryHeadline.length > 28 ? "m" : "s"}
      onClose={handleClose}
    >
      <div className="banner-modal-container">{bannerModalContent}</div>
    </ReduxModal>
  );
};

export default BannerModal;
