import { useTypedTranslation } from "../../features/i18n";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useError404 = () => {
  const { t } = useTypedTranslation();

  const rootPage = () => {
    window.location.pathname = "/";
  };

  return { rootPage, t };
};

export default useError404;
