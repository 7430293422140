import React from "react";
import useBenefits from "./hooks/use-benefits";
import EpiContentContainer from "../epi-content-container";
import EpiWelcomeBlock from "../epi-welcome-block";
import FlexContainer from "../flex-container";
import Row from "../flex-row";
import Col from "../flex-col";
import cx from "classnames";
import CloseIcon from "../../images/x-icon-32.svg";
import { lang } from "../../features/i18n";
import { Link } from "react-router-dom";
import BenefitSearch from "./benefit-search";
import Error500 from "../error-500";
import "./benefits.scss";

const Benefits = (): JSX.Element => {
  const {
    benefitsPageData,
    benefitsPageLoading,
    showSearch,
    t,
    tabsAnchorRef,
    initState,
    handleTabChange,
  } = useBenefits();
  const tRoot = lang.benefitsPage;

  const listCssClasses = cx("benefits-tab", {
    "is-tab-selected": !showSearch,
  });
  const searchCssClasses = cx("benefits-tab", {
    "is-tab-selected": showSearch,
  });

  let content;

  if (benefitsPageData !== undefined && benefitsPageLoading === "success") {
    content = (
      <>
        <FlexContainer className="benefits-page-nav-row-container" id="benefits-top-anchor">
          <Row>
            <Col>
              <Link to="/">
                <img src={CloseIcon} alt={t(tRoot.general.closeIconAltText)} />
              </Link>
            </Col>
          </Row>
        </FlexContainer>
        {benefitsPageData.intro !== undefined && <EpiWelcomeBlock data={benefitsPageData.intro} />}

        <div id="benefits-tabs-anchor" ref={tabsAnchorRef} className="benefits-tabs-container">
          <FlexContainer>
            <Row>
              <Col>
                <div className="benefits-tabs">
                  <button className={listCssClasses} onClick={() => handleTabChange(false)}>
                    {t(tRoot.general.listTab)}
                  </button>
                  <button className={searchCssClasses} onClick={() => handleTabChange(true)}>
                    {t(tRoot.general.searchTab)}
                  </button>
                </div>
              </Col>
            </Row>
          </FlexContainer>
        </div>

        {showSearch ? (
          benefitsPageData.benefits !== undefined && benefitsPageData.benefits.length > 0 ? (
            <BenefitSearch
              benefits={benefitsPageData.benefits}
              translate={t}
              initSearchCategories={initState?.activeSearchCategories}
            />
          ) : (
            <div>{t(tRoot.general.noBenefits)}</div>
          )
        ) : benefitsPageData.content !== undefined ? (
          <div className="benefits-tab--panel">
            <EpiContentContainer content={benefitsPageData.content} benefitsRef={undefined} />
          </div>
        ) : (
          <div>{t(tRoot.general.noContent)}</div>
        )}
      </>
    );
  } else if (benefitsPageLoading === "error") {
    content = <Error500 />;
  } else {
    content = (
      <Row>
        <Col>{t(lang.loadingStatus.contentLoading)}</Col>
      </Row>
    );
  }

  return content;
};

export default Benefits;
