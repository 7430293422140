import React from "react";
import Row from "../flex-row";
import Col from "../flex-col";
import HcButton from "../hc-button";
import { TypedTranslate } from "../../features/i18n";
import { lang } from "../../features/i18n";
import { Link } from "react-router-dom";
import { BenefitInitState } from "../benefits";

type DefaultActionBlockProps = {
  translate: TypedTranslate;
  setEditMode: (value: boolean) => void;
  showLinkToMembershipBenefits? : boolean;
};

const DefaultActionBlock = ({
  translate: t,
  setEditMode,
  showLinkToMembershipBenefits = true,
}: DefaultActionBlockProps): JSX.Element => {
  const tRoot = lang.membershipCard;
  const benefitStateData: BenefitInitState = {
    scrollPosition: "top",
  };
  return (
    <Row>
      {showLinkToMembershipBenefits && (
        <React.Fragment>
          <Col s={12} m={12} l={12}>
            <Link
              to={{ pathname: "osakasedut", state: benefitStateData }}
              className="show-member-benefits-btn"
            >
              {t(tRoot.showMembershipBenefitsButton)}
            </Link>
          </Col>
        <Col className="membership-card-order-divider-block">
          <div className="membership-card-order-divider" />
        </Col>
        </React.Fragment>
      )}
      <Col s={12} m={12} l={12}>
        <HcButton
          isSecondary
          className="order-new-membership-card-button"
          onClick={() => setEditMode(true)}
        >
          {t(tRoot.orderNewMembershipCard)}
        </HcButton>
      </Col>
    </Row>
  );
};

export default DefaultActionBlock;
