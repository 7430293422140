import React from "react";
import cx from "classnames";
import "./placeholder-img.scss";

export type PlaceholderImgType = {
  /**
   * Fixed width for placeholder (100 = 100px). Uses all availabled space if value
   * is not given.
   */
  width?: number;

  /**
   * Fixed height for placeholder. Uses all availabled space if value
   * is not given.
   */
  height?: string;

  className?: string;

  roundedCorners?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const PlaceholderImg = React.forwardRef<HTMLDivElement, PlaceholderImgType>(
  ({ width, height, className, roundedCorners = false }: PlaceholderImgType, ref): JSX.Element => {
    const placeholderClasses = cx("placeholder-bg", {
      "placeholder-rounded-corners": roundedCorners,
    });

    const placeholderDimensions = {
      width: width !== undefined ? width : "100%",
      height: height !== undefined ? height : "100%",
    } as React.CSSProperties;

    return (
      <div
        className={[placeholderClasses, className].join(" ")}
        style={placeholderDimensions}
        ref={ref}
      >
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iNzUiIHZpZXdCb3g9IjAgMCA4MCA3NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcwLjk1NzQgMEg5LjA0OTI0QzQuMDU5MDMgMCAwLjAwMTAzNzYgNC4wNTcwNSAwLjAwMTAzNzYgOS4wNTM2NFY2NS45NTY1QzAuMDAxMDM3NiA3MC45NDcyIDQuMDU5MDMgNzUgOS4wNDkyNCA3NUg3MC45NTcxQzc1Ljk0NzMgNzUgODAuMDAxIDcwLjk0NzIgODAuMDAxIDY1Ljk1NjVWOS4wNTM2NEM4MC4wMDEzIDQuMDU3MDUgNzUuOTQ3NiAwIDcwLjk1NzQgMFpNNTEuNzMzMyAxMy40NjQ1QzU2LjU1OTEgMTMuNDY0NSA2MC40NzI3IDE3LjM3ODggNjAuNDcyNyAyMi4yMDQ4QzYwLjQ3MjcgMjcuMDMwOCA1Ni41NTg4IDMwLjk0NTEgNTEuNzMzMyAzMC45NDUxQzQ2LjkwNjIgMzAuOTQ1MSA0Mi45OTM5IDI3LjAzMDggNDIuOTkzOSAyMi4yMDQ4QzQyLjk5MzkgMTcuMzc4OCA0Ni45MDYyIDEzLjQ2NDUgNTEuNzMzMyAxMy40NjQ1Wk02OC4xMTk4IDY2LjYzNTJINDAuMDAwNEgxMy4xMzM3QzEwLjcyMDEgNjYuNjM1MiA5LjY0NTEgNjQuODg4NyAxMC43MzMyIDYyLjczNEwyNS43MzE3IDMzLjAyMzlDMjYuODE4NCAzMC44Njk1IDI4Ljg4OTYgMzAuNjc3MiAzMC4zNTYzIDMyLjU5NDFMNDUuNDM3OCA1Mi4zMDUxQzQ2LjkwNDYgNTQuMjIyMiA0OS40NjgyIDU0LjM4NTMgNTEuMTY1IDUyLjY2NzdMNTQuODU0NSA0OC45MzEzQzU2LjU0OTkgNDcuMjEzNyA1OS4wNDggNDcuNDI2NCA2MC40MzE3IDQ5LjQwMzJMNjkuOTg1NSA2My4wNTE0QzcxLjM2NjYgNjUuMDMxMiA3MC41MzM1IDY2LjYzNTIgNjguMTE5OCA2Ni42MzUyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=="
          alt="Placeholder icon"
        />
      </div>
    );
  }
);

PlaceholderImg.displayName = "PlaceholderImg";
export default PlaceholderImg;
