import React from "react";
import useReduxModal from "./use-redux-modal";
import HcModal from "../hc-modal";
import { HeaderSizeType } from "../hc-modal/hc-modal";

type ReduxModalProps = {
  children: React.ReactNode;

  ariaLabel: string;

  /* Boolean indicating if the overlay should close the modal */
  shouldCloseOnOverlayClick?: boolean;

  /* Boolean indicating if pressing the esc key should close the modal
     Note: By disabling the esc key from closing the modal
     you may introduce an accessibility issue. */
  shouldCloseOnEsc?: boolean;

  /**
   * Should confirm before close the modal
   */
  shouldConfirmBeforeClose?: boolean;

  /* String id to be applied to the content div. */
  id?: string;

  /* Use width & max-width for modal content */
  hasWidth?: boolean;

  /* Use default padding */
  defaultPadding?: boolean;

  /* Modal header element or undefined if none */
  header?: JSX.Element;

  /* Modal header height */
  headerSize?: HeaderSizeType;

  /* Function runs when modal is closing */
  onClose?: () => void;
};

const ReduxModal = ({
  children,
  ariaLabel,
  id,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  shouldConfirmBeforeClose = false,
  hasWidth = true,
  defaultPadding = true,
  header,
  headerSize,
  onClose,
}: ReduxModalProps): JSX.Element => {
  const { isOpen, activeModalId, closeModal } = useReduxModal(id || "", onClose);
  return (
    <HcModal
      isOpen={isOpen && id === activeModalId}
      onClose={closeModal}
      ariaLabel={ariaLabel}
      id={id}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldConfirmBeforeClose={shouldConfirmBeforeClose}
      hasWidth={hasWidth}
      defaultPadding={defaultPadding}
      header={header}
      headerSize={headerSize}
    >
      {children}
    </HcModal>
  );
};

export default ReduxModal;
