import Profile from "./../../components/profile";
import React from "react";
import ProtectedRoute from "../../components/protected-route";
import { RouteWrapperProps } from "..";

/**
 * Protected route for owner's personal profile page
 */
const ProfileRoute = (props: RouteWrapperProps): JSX.Element => (
  <ProtectedRoute {...props} render={() => <Profile />}></ProtectedRoute>
);

export default ProfileRoute;
