import { useEffect, useState, useCallback } from "react";
import { HoldingReserved } from "..";
import { selectClickedDate } from "../../../store/calendar";
import { useSelector } from "react-redux";
import { CalendarDto, ReservationType } from "../../../app/OwnerService-api";
import { isSameDayOrBefore } from "../utils";
import { isSameDay } from "date-fns";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useReservationRci = (holding: HoldingReserved) => {
  const [selectedWeek, setSelectedWeek] = useState<CalendarDto | undefined>(undefined);
  const [isRented, setIsRented] = useState<boolean>(false);
  const clickedDate = useSelector(selectClickedDate);

  /**
   * Get selectedWeek by clickedDate
   */
  const getClickedWeek = useCallback(() => {
    return (
      clickedDate &&
      holding &&
      holding.weeks &&
      holding.weeks.find(
        (week) =>
          isSameDayOrBefore(new Date(week.weekStart), clickedDate) &&
          isSameDayOrBefore(clickedDate, new Date(week.weekEnd))
      )
    );
  }, [holding, clickedDate]);

  // When clickedDate or holding changes
  useEffect(() => {
    // Update selectedWeek
    const selectedWeek = clickedDate ? getClickedWeek() : holding.weeks && holding.weeks[0];
    setSelectedWeek(selectedWeek);
  }, [clickedDate, holding, getClickedWeek]);

  // When selectedWeek changes
  useEffect(() => {
    // Check if week is rented
    if (selectedWeek) {
      const rented = holding.reservations.some((r) => {
        return (
          selectedWeek &&
          isSameDay(r.startDate, new Date(selectedWeek.weekStart)) &&
          isSameDay(r.endDate, new Date(selectedWeek.weekEnd)) &&
          r.type === ReservationType.ForRental
        );
      });
      setIsRented(rented);
    }
  }, [selectedWeek, holding.reservations]);

  return {
    selectedWeek,
    isRented,
  };
};

export default useReservationRci;
