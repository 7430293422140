import { useState, useEffect, useRef } from "react";
/**
 * Use key and click listeners to close date picker with esc or outside click
 * @param {boolean} initialIsVisible - true if calendar initial state is to be visible
 */
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export default function useCalendarVisible(initialIsVisible: boolean) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Hide date picker on esc key
   * @param event - keyboard event
   */
  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsCalendarVisible(false);
    }
  };

  /**
   * Hide date picker on click outside
   * @param event - click event
   */
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsCalendarVisible(false);
    }
  };

  // Add key and click listeners
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isCalendarVisible, setIsCalendarVisible };
}
