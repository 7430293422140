import React from "react";
import "./footer-link.scss";
import ExternalLink from "../../external-link";

type TagType = "div" | "span";

type FooterLinkProps = {
  wrapper?: TagType;
  external?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const FooterLink = ({
  children,
  target = "_blank",
  className,
  wrapper = "div",
  external = false,
  ...props
}: FooterLinkProps): JSX.Element => {
  const Tag = `${wrapper}` as TagType;

  return external ? (
    <ExternalLink
      wrapperClassName={`footer-link-wrapper ${className !== undefined ? className : ""}`}
      className="footer-link"
      wrapper={wrapper}
      {...props}
    >
      {children}
    </ExternalLink>
  ) : (
    <Tag className={`footer-link-wrapper ${className !== undefined ? className : ""}`}>
      <a className={`footer-link`} target={target} {...props}>
        {children}
      </a>
    </Tag>
  );
};

export default FooterLink;
