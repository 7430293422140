import { useState, useEffect, useCallback } from "react";
import { ReservationInfo } from "..";
import useGTM from "../../../features/use-gtm";
import { daysBeforeRangeStart } from "../../date-range/utils";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useSelectVillas = (
  reservations: ReservationInfo[],
  nextStep: () => void,
  setSelections: (newState: Set<number>) => void,
  setIsLastMinute: (val: boolean) => void
) => {
  const [selected, setSelected] = useState(new Set<number>());
  const { sendDataToGTM } = useGTM();

  const isSelected = (index: number) => selected.has(index);
  const toggleSelected = (index: number) => {
    const newSelected = new Set(selected);

    if (selected.has(index)) {
      newSelected.delete(index);
    } else {
      newSelected.add(index);
    }

    setSelected(newSelected);
  };

  const handleNextStep = () => {
    // Set reservation flow selections at use-selections
    setSelections(selected);
    sendDataToGTM({
      event: "booking_choose_week",
      event_description: "Valitse viikko ja vahvista varaus",
    });
    nextStep();
  };

  useEffect(() => {
    // Add all reservation indexes to selected
    const newSelected = new Set<number>();
    reservations.forEach((item, i) => {
      newSelected.add(i);
    });
    setSelected(newSelected);
  }, [reservations]);

  /**
   * Check if some of the selected reservations starts in less than 31 days
   * to show the right confirm button text, update isLastMinute
   */
  const updateIsLastMinute = useCallback(
    (selected: Set<number>) => {
      let lastMin = false;

      selected.forEach((s) => {
        if (daysBeforeRangeStart(new Date(), new Date(reservations[s].startDate)) < 31) {
          lastMin = true;
        }
      });

      setIsLastMinute(lastMin);
    },
    [reservations, setIsLastMinute]
  );

  /**
   * Check if some of the selected reservations starts in less than 31 days
   * to show the right confirm button text
   */
  useEffect(() => {
    updateIsLastMinute(selected);
  }, [updateIsLastMinute, selected]);

  return {
    selected,
    isSelected,
    toggleSelected,
    handleNextStep,
  };
};

export default useSelectVillas;
