import HcInput from "./hc-input";
import InputWrapper from "./input-wrapper";
import Input from "./input";
import { Feedback } from "../input-feedback";

export type HcInputProps = {
  label?: string;
  inputClassName?: string;
  feedback?: Feedback;
} & React.InputHTMLAttributes<HTMLInputElement>;

export type InputProps = {
  inheritedClassName?: string;
  feedback?: Feedback;
  id?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default HcInput;
export { InputWrapper, Input };
