import { useEffect, useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import {
  onRequestClose as onRequestCloseAction,
  cancelOnRequestClose as cancelOnRequestCloseAction,
  selectIsModalClosing,
} from "../../../store/modal";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useModal = (
  isOpen: boolean,
  shouldConfirmBeforeClose: boolean,
  shouldCloseOnOverlayClick: boolean,
  onClose: () => void
) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const isClosing = useSelector(selectIsModalClosing);

  /**
   * Keep track of the window height. Used to lock the background scroll for mobile devices
   */
  const syncHeight = () => {
    document.documentElement.style.setProperty("--window-inner-height", `${window.innerHeight}px`);
  };

  window.addEventListener("resize", syncHeight);

  /**
   * Prevent Page Scrolling When a Modal is Open
   */
  useEffect(() => {
    if (isOpen) {
      /* Scroll position should be stored in one of these places for all browsers we are supporting */
      const scrollPosition = document.documentElement.scrollTop || window.scrollY;

      /* Store scroll position to return to after closing the modal */
      setScrollPosition(scrollPosition);

      document.documentElement.classList.add("is-locked");

      /* Only scroll to top when background is still locked to make sure villas-reservation-modal button works */
    } else if (document.documentElement.classList.contains("is-locked")) {
      document.documentElement.classList.remove("is-locked");

      window.scrollTo(0, scrollPosition);
    }
  }, [isOpen]);

  /**
   * If isClosing state changes --> check if we should close the modal
   */
  useEffect(() => {
    if (isClosing) {
      handleModalClose();
    }
  }, [isClosing]);

  const handleModalClose = () => {
    if (isOpen && !shouldConfirmBeforeClose) {
      confirmModalClose();
    }
  };

  /**
   * Closes the modal if shouldCloseOVerlayClick is true
   * @param e
   * @param element
   */
  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    element: HTMLDivElement
  ) => {
    // If user clicks the modal instead of background --> we don't want to close the modal
    if (e.target !== element) {
      e.stopPropagation();
      return;
    } else if (e.target === element && shouldCloseOnOverlayClick) {
      dispatch(onRequestCloseAction());
    }
  };

  const handleCloseButtonClick = () => {
    if (isOpen && !shouldConfirmBeforeClose) {
      confirmModalClose();
    } else {
      dispatch(onRequestCloseAction());
    }
  };

  /**
   * Closes the modal if confirmed
   */
  const confirmModalClose = () => {
    cancelOnRequestClose();
    onClose();
  };

  const cancelOnRequestClose = () => {
    dispatch(cancelOnRequestCloseAction());
  };

  return {
    handleModalClose,
    handleOverlayClick,
    confirmModalClose,
    isClosing,
    t,
    cancelOnRequestClose,
    handleCloseButtonClick,
    scrollPosition,
  };
};

export default useModal;
