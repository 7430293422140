import { HoldingReservationSlot } from "..";
import { ReservationStatus } from "../../../app/OwnerService-api";
import CancellationVillas from "./cancellation-villas";

export type CancellationReservationSlot = Omit<HoldingReservationSlot, "weekNumber"> & {
  weekNumber?: number;
  reservationId: string;
  reservationStatus: ReservationStatus;
};

export default CancellationVillas;
