import { RootState } from "..";
import { IHoldingDto } from "../../app/OwnerService-api";
import { HoldingReserved } from "../../components/holdings-calendar";
import calendarOverlaySlice from "./calendar-overlay-slice";

export type SelectedHolding = IHoldingDto | "SHOW_ALL";

export type CalendarOverlayState = {
  isOpen: boolean;
  isClosing: boolean;
  activeHolding: HoldingReserved | undefined;
  selectedHolding: SelectedHolding;
};

const selectIsCalendarOverlayOpen = (state: RootState): boolean => state.calendarOverlay.isOpen;
const selectActiveHolding = (state: RootState): HoldingReserved | undefined =>
  state.calendarOverlay.activeHolding;

const selectIsCalendarOverlayClosing = (state: RootState): boolean =>
  state.calendarOverlay.isClosing;

const selectSelectedHolding = (state: RootState): SelectedHolding =>
  state.calendarOverlay.selectedHolding;

export {
  selectIsCalendarOverlayOpen,
  selectActiveHolding,
  selectIsCalendarOverlayClosing,
  selectSelectedHolding,
};

export const {
  openCalendarOverlay,
  closeCalendarOverlay,
  setActiveHolding,
  clearActiveHolding,
  onRequestCloseOverlay,
  cancelOnRequestCloseOverlay,
  setSelectedHolding,
} = calendarOverlaySlice.actions;

export default calendarOverlaySlice.reducer;
