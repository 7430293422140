import React from "react";
import cx from "classnames";

import HomeHeader from "./home-header";
import Row from "./../flex-row";
import Col from "./../flex-col";
import FlexContainer from "./../flex-container";
import { lang } from "../../features/i18n";

import useHome from "./hooks/use-home";

import HoldingsDetails from "./holdings-details";
import HoldingsCalendar from "../holdings-calendar";

import EpiContentContainer from "../epi-content-container";

import "./home.scss";
import { WeekType } from "../../app/OwnerService-api";
import RentFormModal from "../rent-form-modal";
import CardContainer from "../card-container";
import Calendar from "../calendar";
import HcText from "../hc-text";
import Error500 from "../error-500";

const Home = (): JSX.Element => {
  const {
    holdingsData,
    homepageData,
    epiContentData,
    reservationsData,
    homepageLoading,
    reserveHoldings,
    benefitsRef,
    scrollToBenefits,
    holdingsRef,
    scrollToHoldings,
    t,
    restrictedUserInterfaceAccess,
  } = useHome();
  const tRoot = lang.loadingStatus;

  let content;

  if (homepageLoading === "success") {
    const homeCss = cx("home-container overflow-fix", "home-grass");
    content = (
      <>
        {!restrictedUserInterfaceAccess && (
          <HomeHeader bgImageData={homepageData.heroImage}>
            <HoldingsCalendar
              holdingsData={holdingsData}
              reserveHoldings={reserveHoldings}
              scrollToBenefits={scrollToBenefits}
              scrollToHoldings={scrollToHoldings}
            />
          </HomeHeader>
        )}

        {/* TODO: Add restricted UI EPI data here */}
        {epiContentData.length > 0 && (
          <div className="epi-content-container">
            <EpiContentContainer content={epiContentData} benefitsRef={benefitsRef} />
          </div>
        )}
        {!restrictedUserInterfaceAccess && (
          <>
            <div className={homeCss}>
              <FlexContainer className="ownership-tabs">
                <Row>
                  <Col>
                    <HoldingsDetails
                      holdingsData={holdingsData}
                      reservationsData={reservationsData}
                      holdingsRef={holdingsRef}
                    />
                  </Col>
                </Row>
              </FlexContainer>
            </div>
            <RentFormModal />
          </>
        )}
      </>
    );
  } else if (homepageLoading === "error") {
    content = <Error500 />;
  } else {
    content = (
      <>
        <HomeHeader bgImageData={undefined}>
          <CardContainer className={"generic-loading-state-absolute loading-state-card-container"}>
            <Calendar calendarType="ISO" key="iso-cal" />
          </CardContainer>
        </HomeHeader>
        <Row className="loading-state-content">
          <Col>
            <HcText>{t(tRoot.contentLoading)}</HcText>
          </Col>
        </Row>
      </>
    );
  }

  return content;
};

export default Home;
