import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useInfoVillas = (nextStep: () => void) => {
  const { sendDataToGTM } = useGTM();

  const handleNextStep = () => {
    // Google Tag Manager
    sendDataToGTM({
      event: "booking_start",
      event_description: "Pyydä varausta",
    });
    nextStep();
  };

  return { handleNextStep };
};

export default useInfoVillas;
