import { useState, useEffect } from "react";
import { OptionTypeBase } from "react-select";
import { AccountType } from ".";
import { IBankAccountDto } from "../../app/OwnerService-api";
import { maskIBAN } from "../account-number/account-utils";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useAccountNumberSelect = (account: IBankAccountDto | undefined) => {
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<OptionTypeBase[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<OptionTypeBase | undefined>(undefined);

  useEffect(() => {
    // parse account data
    if (account) {
      const parsedData: AccountType[] = [
        {
          value: account.id || "tmp-id",
          label:
            account.accountNumberMasked && account.accountNumberMasked.length > 0
              ? maskIBAN(account.accountNumberMasked)
              : "",
        },
      ];

      // add custom option to show "Syötä uusi tilinumero" option
      parsedData.push({ custom: true });

      setAccounts(parsedData);

      // set selected account
      setSelectedAccount(parsedData[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedAccount || accounts.length === 0) {
      setIsLoading(false);
    }
  }, [selectedAccount]);

  return {
    accounts,
    selectedAccount,
    isLoading,
  };
};

export default useAccountNumberSelect;
