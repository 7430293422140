import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomerProfileState } from "./";
import {
  MyProfileDto,
  CustomerProfileClient,
  UpdateMyProfileDto,
  FileResponse,
  IUpdateMyProfileDto,
  BankAccountClient,
  UpdateBankAccountDto,
} from "../../app/OwnerService-api";
import { FetchManager, IUser } from "../../features/authorization";
import { RootState } from "../";
import { toast } from "react-toastify";

import { getToastContent } from "../toast-translations";

const initialState: CustomerProfileState = {
  profileData: [],
  loading: "idle",
};

export const requestProfile = createAsyncThunk(
  "customerProfile/getProfile",
  async (requestor: IUser | undefined) => {
    const client = new CustomerProfileClient(undefined, new FetchManager(requestor));
    return (await (await client.get()).toJSON()) as MyProfileDto; // toJSON is requred to make incoming data serializable for Redux
  }
);

const extraRequestProfileReducers = (
  builder: ActionReducerMapBuilder<CustomerProfileState>
): void => {
  builder.addCase(requestProfile.pending, (state) => {
    state.loading = "pending";
  });

  builder.addCase(requestProfile.fulfilled, (state, action) => {
    state.profileData.push(action.payload);
    state.loading = "success";
  });

  builder.addCase(requestProfile.rejected, (state, action) => {
    console.error(JSON.stringify(action.error, null, 4));
    state.loading = "error";
  });
};

type UpdateProfileRequest = {
  requestor?: IUser;
  updatedProfile: MyProfileDto;
  contractId?: number;
};

const selectUpdatedFields = (updated: MyProfileDto, old: MyProfileDto): IUpdateMyProfileDto => {
  let changedFields = Object.keys(old).filter((field) => {
    const key = field as keyof MyProfileDto;
    return old[key] !== updated[key];
  });

  const hasNewKeys = Object.keys(updated).filter((field) => {
    const key = field as keyof MyProfileDto;
    return !(key in old);
  });

  if (hasNewKeys !== undefined && hasNewKeys.length > 0) {
    changedFields = [...changedFields, ...hasNewKeys];
  }

  const updateDto: IUpdateMyProfileDto = changedFields.reduce((dto, field) => {
    const key = field as keyof MyProfileDto;

    return {
      ...dto,
      [key]: updated[key],
    };
  }, {});

  return updateDto;
};

export const updateProfile = createAsyncThunk<
  FileResponse,
  UpdateProfileRequest,
  {
    state: RootState;
  }
>(
  "customerProfile/updateProfile",
  async ({ requestor, updatedProfile, contractId }: UpdateProfileRequest, { getState }) => {
    const { profileData } = getState().customerProfile;
    const client = new CustomerProfileClient(undefined, new FetchManager(requestor));

    // The updated profile is already added to the history at this point. See `extraUpdateProfileReducers`.
    // Therefore the old profile is at the second to last index of the history.
    const oldProfileIndex = profileData.length - 2;
    const oldProfile = profileData[oldProfileIndex];

    let updateDto = selectUpdatedFields(updatedProfile, oldProfile);

    let bankAccount: UpdateBankAccountDto | undefined = undefined;
    if (updateDto.bankAccount && updatedProfile.bankAccount) {
      bankAccount = new UpdateBankAccountDto({
        id: updatedProfile.bankAccount.id,
        accountNumber: updatedProfile.bankAccount.accountNumberMasked?.includes("*")
          ? undefined
          : updatedProfile.bankAccount.accountNumberMasked,
        active: updatedProfile.bankAccount.active,
      });

      updateDto = {
        bankAccount: bankAccount,
        contractId: contractId ? contractId.toString() : "0",
      };
    }

    try {
      const response = await client.update(new UpdateMyProfileDto(updateDto));

      // Show toast if profile is updated, not when bank account is updated
      if (bankAccount === undefined && Object.keys(updateDto).length !== 0) {
        toast.success(getToastContent(true));
      }

      return response;
    } catch (err) {
      // Show toast if profile is updated, not when bank account is updated
      if (bankAccount === undefined) {
        toast.error(getToastContent(false));
      }

      return {} as FileResponse;
    }
  }
);

export const extraUpdateProfileReducers = (
  builder: ActionReducerMapBuilder<CustomerProfileState>
): void => {
  builder.addCase(updateProfile.pending, (state, action) => {
    state.loading = "pending";
    const updatedProfile: MyProfileDto = action.meta.arg.updatedProfile;
    state.profileData.push(updatedProfile);
  });

  builder.addCase(updateProfile.fulfilled, (state) => {
    state.loading = "success";
  });

  builder.addCase(updateProfile.rejected, (state) => {
    state.loading = "error";
    state.profileData.pop();
  });
};

type ValidateBankAccountRequest = {
  bankAccount: string;
};

export const validateBankAccount = createAsyncThunk(
  "customerProfile/validateBankAccount",
  async ({ bankAccount }: ValidateBankAccountRequest) => {
    const client = new BankAccountClient();

    const response = await client.post(bankAccount);

    return response;
  }
);

const customerProfileSlice = createSlice({
  initialState,
  name: "customer-profile",
  reducers: {},
  extraReducers: (builder) => {
    extraRequestProfileReducers(builder);
    extraUpdateProfileReducers(builder);
  },
});

export default customerProfileSlice;
