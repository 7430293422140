import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useInfoCancellation = (nextStep: () => void) => {
  const { sendDataToGTM } = useGTM();

  const handleCancelReservationClick = () => {
    sendDataToGTM({
      event: "cancel_booking_start",
      event_description: "Peruuta varaus",
    });
    nextStep();
  };

  return { handleCancelReservationClick };
};

export default useInfoCancellation;
