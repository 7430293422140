import useGTM from "../../features/use-gtm";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useVideoPlayer = (title?: string) => {
  const { sendDataToGTM } = useGTM();

  /**
   * Send data to GTM when video is started
   */
  const onVideoStart = () => {
    sendDataToGTM({
      event: "video_start",
      event_description: "Videon aloitus",
      event_label: title,
    });
  };

  /**
   * Send data to GTM when video ends
   */
  const onVideoEnd = () => {
    sendDataToGTM({
      event: "video_end",
      event_description: "Videon lopetus",
      event_label: title,
    });
  };

  return { onVideoStart, onVideoEnd };
};

export default useVideoPlayer;
