import { Route } from "react-router-dom";
import React from "react";
import { RouteWrapperProps } from "..";
import Signup from "../../components/signup";

/*
 * Signup route is not protected
 */
const SignupRoute = (props: RouteWrapperProps): JSX.Element => (
  <Route {...props} component={Signup} />
);

export default SignupRoute;
