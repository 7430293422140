import { useState, useEffect } from "react";
import { SelectedCards } from ".";
import { IMembershipCardDto } from "../../../app/OwnerService-api";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useCardSelect = (cards: IMembershipCardDto[]) => {
  const [selectedCards, setSelectedCards] = useState<SelectedCards[]>([]);
  const [orderDisabled, setOrderDisabled] = useState(false);

  useEffect(() => {
    const selected: SelectedCards[] = cards.map((item) => {
      return {
        card: item,
        selected: true,
      };
    });
    setSelectedCards(selected);
  }, [cards]);

  const onMembershipCardSelect = (e: React.ChangeEvent<HTMLInputElement>, cardId: string) => {
    const selection = selectedCards.find((v) => v.card.membershipNumber === cardId);
    if (selection) {
      const selectionData = selectedCards.map((card) => {
        return card.card.membershipNumber === cardId
          ? { ...card, selected: e.currentTarget.checked }
          : card;
      });

      setSelectedCards(selectionData);
    }
  };

  useEffect(() => {
    const noneSelected = selectedCards.every((item) => item.selected === false);
    setOrderDisabled(noneSelected);
  }, [selectedCards]);

  return {
    selectedCards,
    onMembershipCardSelect,
    orderDisabled,
    setOrderDisabled,
  };
};

export default useCardSelect;
