import Benefits from "./../../components/benefits";
import React from "react";
import ProtectedRoute from "../../components/protected-route";
import { RouteWrapperProps } from "..";

/**
 * Protected route for owner's personal home page
 */
const BenefitsRoute = (props: RouteWrapperProps): JSX.Element => (
  <ProtectedRoute {...props} render={() => <Benefits />}></ProtectedRoute>
);

export default BenefitsRoute;
