import { useState } from "react";
import { MenuItem } from "../../dropdown-menu";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
function useCollapsingTabList() {
  const [overflowTabs, setOverflowTabs] = useState<MenuItem[]>([]);
  const [overflowIndex, setOverflowIndex] = useState<number>(0);
  const [overflowButtonRef, setOverflowButtonRef] = useState<null | HTMLButtonElement>(null);

  return {
    overflowTabs,
    overflowIndex,
    overflowButtonRef,
    setOverflowTabs,
    setOverflowIndex,
    setOverflowButtonRef,
  };
}

export default useCollapsingTabList;
