import { useState } from "react";
import { useTypedTranslation, lang } from "../../../features/i18n";
import { ParseError, parsePhoneNumberWithError } from "libphonenumber-js";
import { invalidCharsRegex, composeValidators } from "..";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useContactDetails = (editMode: boolean) => {
  const [isValidPhoneFormat, setIsValidPhoneFormat] = useState<boolean>(false);
  const { t } = useTypedTranslation();
  const tRoot = lang.profile.contactDetails;

  const updateIsValidPhoneFormat = (isValid: boolean) => {
    if (isValid !== isValidPhoneFormat) {
      setIsValidPhoneFormat(isValid);
    }
  };

  const requiredValidation = (value: string | undefined) =>
    value ? undefined : t(lang.requiredField);

  const invalidChars = (value: string) =>
    value.match(invalidCharsRegex) === null ? undefined : t(lang.invalidChars);

  const emailValidation = (value: string) => {
    const required = requiredValidation(value);

    if (!required) {
      const emailRegex = new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
      );

      if (!emailRegex.test(value)) {
        return t(tRoot.invalidEmail);
      }
    }

    return required;
  };

  const validateEmail = composeValidators(emailValidation, invalidChars);

  const validatePhone = (value: string | undefined) => {
    let errorMessage = requiredValidation(value);

    if (value !== undefined && editMode) {
      try {
        const numberToParse = value.startsWith("+") ? value : `+${value}`;
        const phone = parsePhoneNumberWithError(numberToParse);
        const onlyNumbersAfterPlus = value?.match(/^[+]?[0-9]+$/);
        if (onlyNumbersAfterPlus && phone.isValid()) {
          updateIsValidPhoneFormat(true);
          return undefined;
        } else {
          errorMessage = t(tRoot.invalidPhone);
        }
      } catch (error) {
        if (error instanceof ParseError) {
          // Not a phone number, non-existent country, etc.
          errorMessage = t(tRoot.invalidPhone);
        } else {
          throw error;
        }
      }
    }
    if (errorMessage) {
      updateIsValidPhoneFormat(false);
    }
    return errorMessage;
  };

  return {
    t,
    isValidPhoneFormat,
    validateEmail,
    validatePhone,
  };
};

export default useContactDetails;
