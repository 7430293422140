import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthorizationState } from ".";
import { AppThunk, LoadingState } from "..";
import auth, { isPasswordResetError, IUser } from "../../features/authorization";
import invAuth from "../../features/invitation";

/* eslint-disable @typescript-eslint/no-unused-vars */

const initialState: AuthorizationState = {
  user: undefined,
  loading: "idle",
};

const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setUser: (state, result: PayloadAction<IUser | undefined>) => {
      state.user = result.payload;
    },
    resetAuth: (state) => {
      state = initialState;
    },
    startLoading: (state) => {
      state.loading = "pending";
    },
    successfulLogin: (state, result: PayloadAction<IUser>) => {
      state.user = result.payload;
      state.loading = "success";
    },
    failedLogin: (state) => {
      state.loading = "error";
    },
    setLoading: (state, result: PayloadAction<LoadingState>) => {
      state.loading = result.payload;
    },
  },
});

const { startLoading, successfulLogin, setLoading, resetAuth } = authorizationSlice.actions;

export const checkLogin = (): AppThunk => async (dispatch, getState) => {
  dispatch(startLoading());

  const authState = getState().authorization;
  const initialLoadingState = authState.loading;

  // check for existing user
  if (!getState().authorization.user) {
    auth.handleRedirect().then(
      (incomingUser) => {
        if (incomingUser) {
          dispatch(successfulLogin(incomingUser));
        } else {
          auth.login();
        }
      },
      (error) => {
        if (isPasswordResetError(error)) {
          auth.forgotPassword();
        } else {
          throw error;
        }
      }
    );
  } else {
    dispatch(setLoading(initialLoadingState));
  }
};

export const checkInvite = (token: string): AppThunk => async (dispatch, getState) => {
  dispatch(startLoading());

  const authState = getState().authorization;
  const initialLoadingState = authState.loading;

  // check for existing user
  if (!getState().authorization.user) {
    invAuth.handleRedirect().then(
      (incomingUser) => {
        if (incomingUser) {
          dispatch(successfulLogin(incomingUser));
        } else {
          invAuth.invite(token);
        }
      },
      (error) => {
        throw error;
      }
    );
  } else {
    dispatch(setLoading(initialLoadingState));
  }
};

export const logout = (): AppThunk => async (dispatch, getState) => {
  const user = getState().authorization.user;

  auth.logout(user);
  dispatch(resetAuth());
};

export default authorizationSlice;
