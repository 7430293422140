import React from "react";

import HcText from "./../hc-text";
import Row from "./../flex-row";
import Col from "./../flex-col";
import CardContainer from "./../card-container";
import Calendar from "./../calendar";
import { lang } from "./../../features/i18n";
import { CalendarType } from "./../calendar";
import useDateRange from "./hooks/use-date-range";
import { DateRangeType } from "./../calendar";
import { PartialDateRange } from ".";
import { ThemedDateRange } from "./../calendar";
import ArrowRight from "./../../images/arrow-right.svg";
import "./date-range.scss";
import InputFeedback from "./../input-feedback";
import { ReservationInfo } from "../holdings-calendar";

type DateRangeProps = {
  calendarType?: CalendarType;
  minDate?: Date;
  onRangeSelect?: (data: DateRangeType) => void;
  partialRangeData?: PartialDateRange;
  initialData?: ThemedDateRange[] | undefined;
  initUsingDate?: Date | undefined;
  handleToConfirm?: (range?: DateRangeType) => void;
  resetCurrentRange?: () => void;
  reservations?: ReservationInfo[];
};

const DateRange = ({
  initialData = undefined,
  calendarType = "ISO",
  onRangeSelect,
  initUsingDate = undefined,
  handleToConfirm,
  resetCurrentRange,
  reservations = [],
}: DateRangeProps): JSX.Element => {
  const {
    t,
    onInputFocus,
    onDateRangeChange,
    onCalendarClick,
    handleHover,
    ref,
    isCalendarVisible,
    rangeStart,
    rangeEnd,
    rangeData,
    onInputBlur,
    startIsValid,
    endIsValid,
    endInputRef,
    clickedDate,
    isOverlapping,
  } = useDateRange(initialData, onRangeSelect, reservations, handleToConfirm, resetCurrentRange);

  const tRoot = lang.rangePicker;

  /**
   * @returns {string} wrapper class
   */
  const getWrapperClass = () =>
    startIsValid && endIsValid && !isOverlapping
      ? "date-range-wrapper"
      : "date-range-wrapper--invalid";

  const feedback = isOverlapping
    ? t(tRoot.overlappingDate)
    : ((!endIsValid || !startIsValid) && t(tRoot.checkDate)) || undefined;

  return (
    <>
      <Row>
        <Col s={5} m={5} l={5}>
          <label htmlFor="date-range-start">
            <HcText colorVariant="weak" tag="span" size="s">
              {t(tRoot.reservationStarts)}
            </HcText>
          </label>
        </Col>
        <Col s={5} m={5} l={5} sOffset={2} mOffset={2} lOffset={2}>
          <label htmlFor="date-range-end">
            <HcText colorVariant="weak" tag="span" size="s">
              {t(tRoot.reservationEnds)}
            </HcText>
          </label>
        </Col>
      </Row>

      <div className={getWrapperClass()} ref={ref}>
        <Row>
          <Col s={5} m={5} l={5}>
            <input
              type="text"
              className={startIsValid ? "date-input" : "date-input--invalid"}
              placeholder="PP.KK.VVVV"
              id="date-range-start"
              maxLength={10}
              onChange={(e) => onDateRangeChange(e, true)}
              onFocus={onInputFocus}
              onBlur={(e) => onInputBlur(e, true)}
              autoComplete="off"
              value={rangeStart}
            />
          </Col>
          <Col s={2} m={2} l={2}>
            <div className="date-separator">
              <img src={ArrowRight} alt="Start and end date separator" className="date-separator" />
            </div>
          </Col>
          <Col s={5} m={5} l={5}>
            <input
              type="text"
              className={endIsValid ? "date-input" : "date-input--invalid"}
              placeholder="PP.KK.VVVV"
              id="date-range-end"
              maxLength={10}
              onChange={(e) => onDateRangeChange(e, false)}
              onFocus={onInputFocus}
              onBlur={(e) => onInputBlur(e, false)}
              autoComplete="off"
              value={rangeEnd}
              ref={endInputRef}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {isCalendarVisible && (
              <div className="card-container-wrapper">
                <CardContainer
                  style={{ paddingTop: 30, paddingRight: 13, paddingBottom: 24, paddingLeft: 24 }}
                >
                  <Calendar
                    calendarType={calendarType}
                    rangeData={rangeData && rangeData.length > 0 ? rangeData : undefined}
                    onDaySelect={onCalendarClick}
                    onDayHover={handleHover}
                    hoverModeEnabled={true}
                    initUsingDate={clickedDate || initUsingDate}
                    clickedDate={clickedDate}
                  />
                </CardContainer>
              </div>
            )}
          </Col>
        </Row>
      </div>
      {feedback && (
        <div className="feedback-wrapper">
          <InputFeedback text={feedback} isValid={false} />
        </div>
      )}
    </>
  );
};

export default DateRange;
