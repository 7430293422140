import { MyProfileData, MyProfileDtoKey } from "..";
import { MyProfileDto } from "../../../app/OwnerService-api";
import { parsePhoneNumberWithError, ParseError } from "libphonenumber-js";

/**
 * Contact details assume that all the fields are type of MyProfileDto
 * @param data Incoming data
 * @param selectedFields Selected fields out of all incoming data
 */
export const prepareData = (
  data: MyProfileDto,
  selectedFields: MyProfileDtoKey[]
): MyProfileData => {
  return selectedFields.reduce((result, fieldName) => {
    return {
      ...result,
      [fieldName]: data[fieldName],
    };
  }, {} as MyProfileData);
};

export const getReadOnlyPhoneNumber = (phoneNumber: string): string => {
  try {
    const parsedPhoneNumber = parsePhoneNumberWithError(`+${phoneNumber}`);
    return parsedPhoneNumber.countryCallingCode
      ? `+${parsedPhoneNumber.countryCallingCode} (0)${parsedPhoneNumber.nationalNumber}`
      : parsedPhoneNumber.number.toString();
  } catch (error) {
    if (error instanceof ParseError) {
      return "";
    }
  }
  return "";
};
