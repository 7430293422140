import React from "react";
import Select from "react-select";
import { DropdownDataType } from ".";
import "./hc-dropdown.scss";

type HcDropdownProps = {
  data: DropdownDataType[];
  name: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  className?: string;
  onSelectionChange: (val: number) => void;
};

const HcDropdown = ({
  data,
  name,
  className,
  isDisabled = false,
  isSearchable = false,
  isClearable = false,
  isLoading = false,
  onSelectionChange,
}: HcDropdownProps): JSX.Element => {
  const handleOptionChange = (val: DropdownDataType | null) => {
    if (val) {
      const selectedIndex = data.findIndex((item) => item.value === val.value);
      onSelectionChange(selectedIndex);
    }
  };

  return (
    <Select
      className={className || "hc-dropdown-css"}
      classNamePrefix="hc-dropdown-select"
      defaultValue={data[0]}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={false}
      isSearchable={isSearchable}
      name={name}
      options={data}
      onChange={(val) => handleOptionChange(val)}
    />
  );
};

export default HcDropdown;
