import React from "react";
import Select, { components } from "react-select";
import { lang, useTypedTranslation } from "../../features/i18n";
import HcButton from "../hc-button";
import HcText from "../hc-text";
import useAccountNumberSelect from "./use-account-number-select";
import ArrowRight from "./../../images/arrow-right.svg";
import { IBankAccountDto } from "../../app/OwnerService-api";

import "./account-number-select.scss";

type AccountNumberSelectProps = {
  /**
   * Array of bank accounts
   */
  account: IBankAccountDto;

  /**
   * When new account option is clicked
   */
  onNewAccountCreateClicked: () => void;

  id?: string;

  /**
   * Name attribute
   */
  nameValue?: string;

  /**
   * Event handlers
   */
  onFocus?: () => void;
  onBlur?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CreateNewAccountOption = (props: any) => {
  const { t } = useTypedTranslation();
  const { data, innerRef, innerProps, onCreateNewAccountClick } = props;
  const tRoot = lang.accountNumber.accountSelect;

  return data.custom ? (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ padding: "12px" }}
      //eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={() => {}}
      aria-hidden="true"
    >
      <div style={{ borderTop: "1px solid #ccc", paddingTop: "16px" }} />
      <HcText size="s">{t(tRoot.accountNumberChanged)}</HcText>
      <HcButton isSecondary className="new-account-button" onClick={onCreateNewAccountClick}>
        {t(tRoot.createNewAccount)} <img src={ArrowRight} alt="" />
      </HcButton>
    </div>
  ) : (
    <components.Option {...props} />
  );
};

const AccountNumberSelect = ({
  account,
  onNewAccountCreateClicked,
  id,
  nameValue,
  onBlur,
  onFocus,
}: AccountNumberSelectProps): JSX.Element => {
  const { accounts, selectedAccount, isLoading } = useAccountNumberSelect(account);

  const accountSelectContent: JSX.Element = isLoading ? (
    <div>Loading data...</div>
  ) : (
    <Select
      classNamePrefix="account-number-select"
      components={{
        Option: function CustomOptionList(optionProps) {
          return (
            <CreateNewAccountOption
              {...optionProps}
              onCreateNewAccountClick={onNewAccountCreateClicked}
            />
          );
        },
      }}
      options={accounts}
      defaultValue={selectedAccount}
      aria-label="Select account number"
      id={id}
      isSearchable={false}
      name={nameValue}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );

  return accountSelectContent;
};

export default AccountNumberSelect;
