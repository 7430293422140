import { useState } from "react";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useConfirmVillasFrac = (nextStep: () => void) => {
  const [isConfirmChecked, setIsConfirmChecked] = useState<boolean>(false);
  const { sendDataToGTM } = useGTM();

  const toggleConfirmChecked = () => setIsConfirmChecked((prev) => !prev);

  const handleNextStep = () => {
    // Google Tag Manager
    sendDataToGTM({
      event: "booking_confirmed",
      event_description: "Hyväksy ehdot ja lähetä varaus",
    });
    nextStep();
  };

  return { isConfirmChecked, toggleConfirmChecked, handleNextStep };
};

export default useConfirmVillasFrac;
