import React from "react";
import HcText from "../../hc-text";
import HcInput from "../../hc-input";
import HcButton, { ButtonTheme } from "../../hc-button";
import { lang, TypedTranslate } from "../../../features/i18n";
import InfoIcon from "../../../images/info-icon-16.svg";
import ArrowRight from "../../../images/arrow-right-white.svg";
import { PopupLink } from "../../../app/OwnerService-api";
import HcDropdown from "../../hc-dropdown";
import useBannerModalFooter from "./use-banner-modal-footer";

type BannerModalFooterProps = {
  translate: TypedTranslate;
  popupLinks: PopupLink[] | undefined;
  popupLink: string | undefined;
  buttonTheme: ButtonTheme;
  isHolidayChange: boolean;
};

const BannerModalFooter = ({
  translate: t,
  popupLinks,
  popupLink,
  buttonTheme = "default",
  isHolidayChange = false,
}: BannerModalFooterProps): JSX.Element => {
  const tRoot = lang.epi.horizontalList.modal;

  const {
    popupCode,
    handleFooterCTABtnClick,
    setShowBenefitSelector,
    showBenefitSelector,
    benefitSelectData,
    handleActiveBenefitChange,
  } = useBannerModalFooter(popupLink, popupLinks);

  let ownerBenefitContent: JSX.Element = (
    <>
      <label>
        <HcText weight="strong">{t(tRoot.ownerCustomerServiceNumberTitle)}</HcText>
        <HcInput value={t(lang.general.customerServicePhone)} readOnly />
      </label>
      <HcText size="xs" className="info-group">
        <img src={InfoIcon} alt="" />
        {t(lang.general.callPrice)}
      </HcText>
      <div className="spacer" />
    </>
  );

  if (popupCode !== undefined && !showBenefitSelector) {
    ownerBenefitContent = (
      <>
        <label>
          <HcText size="s" bottomSpace="xs">
            {t(tRoot.copyCode)}
          </HcText>
          <HcInput value={popupCode} readOnly className="banner-modal-footer-code-input" />
        </label>
        <HcButton theme={buttonTheme} onClick={() => handleFooterCTABtnClick(true)}>
          <div className="banner-modal-footer-btn-wrapper">
            <div className="banner-modal-footer-btn-content">{t(tRoot.redirectToEcommerce)}</div>
            <img src={ArrowRight} alt="" />
          </div>
        </HcButton>
        {popupLinks !== undefined && popupLinks.length > 1 && (
          <HcButton isSecondary onClick={() => setShowBenefitSelector(true)}>
            {t(tRoot.backFromDetails)}
          </HcButton>
        )}
      </>
    );
  }

  if (benefitSelectData.length > 0 && showBenefitSelector) {
    ownerBenefitContent = (
      <>
        <label>
          <HcText>{t(tRoot.selectBenefitDropdownLabel)}</HcText>
          <HcDropdown
            data={benefitSelectData}
            name="benefit-selection"
            onSelectionChange={handleActiveBenefitChange}
          />
        </label>
        <HcButton isSecondary onClick={() => setShowBenefitSelector(false)}>
          {t(tRoot.selectBenefit)}
        </HcButton>
      </>
    );
  }

  const holidayChangeContent: JSX.Element = (
    <HcButton theme="default" onClick={() => handleFooterCTABtnClick(false)}>
      {t(tRoot.rciButton)}
    </HcButton>
  );

  return (
    <div className="banner-modal-footer">
      {isHolidayChange ? holidayChangeContent : ownerBenefitContent}
    </div>
  );
};

export default BannerModalFooter;
