import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useLoginDetails = () => {
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const { t } = useTypedTranslation();

  /**
   * Update passwordsMatch state if necessary
   * @param {boolean} match - new state
   */
  const updatePasswordsMatch = (match: boolean) => {
    if (match !== passwordsMatch) {
      setPasswordsMatch(match);
    }
  };

  return {
    t,
    passwordsMatch,
    updatePasswordsMatch,
  };
};

export default useLoginDetails;
