import React from "react";
import { InputProps } from "../";
import cx from "classnames";
import "../hc-input.scss";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ inheritedClassName, id, feedback, className, ...inputProps }: InputProps, ref) => {
    const { readOnly } = inputProps;

    const selectText = (e: React.SyntheticEvent) => {
      const element = e.currentTarget as HTMLInputElement;
      if (!readOnly) {
        element.select();
      }
    };

    const cssClass = cx("hc-input__input", {
      "hc-input__input--valid": feedback?.isValid,
      "hc-input__input--invalid": feedback?.isValid === false,
      inheritedClassName,
      className,
    });

    return <input onClick={selectText} id={id} ref={ref} className={cssClass} {...inputProps} />;
  }
);

Input.displayName = "Input";
export default Input;
