import React from "react";
import { HoldingReserved } from "..";
import WizardContainer, { Wizard } from "../../wizard-container";
import Info from "./info";
import "./reservation-rci.scss";
import useReservationRci from "./use-reservation-rci";

type Props = {
  points: number;
  holding: HoldingReserved;
  translate: (key: string) => string;
} & Pick<Wizard, "cancelWizard" | "confirmWizard">;

const ReservationRci = ({
  confirmWizard,
  cancelWizard,
  points,
  holding,
  translate,
}: Props): JSX.Element => {
  const stepIndex = 0;
  const { selectedWeek, isRented } = useReservationRci(holding);

  return (
    <WizardContainer stepIndex={stepIndex}>
      <Info
        nextStep={confirmWizard}
        previousStep={cancelWizard}
        points={points}
        resortName={holding.resortName || ""}
        translate={translate}
        selectedWeek={selectedWeek}
        isRented={isRented}
      />
    </WizardContainer>
  );
};

export default ReservationRci;
