import * as React from "react";
import "./../../styles/_flexboxgrid.scss";

export type AlignType = "left" | "center" | "right";

export type FlexColProps = {
  /**
   * Breakpoints
   */
  s?: number;
  m?: number;
  l?: number;

  align?: AlignType;

  /**
   * Offset a column. Gives you way to align element (not full width) at specific breakpoint.
   */
  sOffset?: number;
  mOffset?: number;
  lOffset?: number;

  children: React.ReactNode;

  className?: string;
};

/**
 * Flex column component
 *
 * Returns element with classnames that shows how many columns it takes on different screen sizes. It follows the
 * idea of flexboxgrid (http://flexboxgrid.com/)
 *
 * Basic usage
 * <FlexCol
 *  s={number_of_columns if not 12}
 *  m={number_of_columns if not 12}
 *  l={number_of_columns if not 12}
 * >{content}</FlexCol>
 *
 * Use inside FlexRow component.
 *
 *
 * @param {number} s The number of columns (out of twelve) that this column should take up on extra small screens
 * @param {number} m The number of columns that this column should take up on extra small screens
 * @param {number} l The number of columns that this column should take up on extra small screens
 */
const FlexCol = ({
  children,
  s = 12,
  m = 12,
  l = 12,
  className: inheritedClass,
  ...props
}: FlexColProps): JSX.Element => {
  const colClasses = [];

  s && colClasses.push(`col-s-${s}`);
  m && colClasses.push(`col-m-${m}`);
  l && colClasses.push(`col-l-${l}`);

  props.sOffset && colClasses.push(`col-s-offset-${props.sOffset}`);
  props.mOffset && colClasses.push(`col-m-offset-${props.mOffset}`);
  props.lOffset && colClasses.push(`col-l-offset-${props.lOffset}`);

  props.align && props.align === "left" && colClasses.push("u-t-left");
  props.align && props.align === "center" && colClasses.push("u-t-center");
  props.align && props.align === "right" && colClasses.push("u-t-right");

  colClasses.push(inheritedClass ? inheritedClass : "");

  return <div className={colClasses.join(" ")}>{children}</div>;
};

export default FlexCol;
