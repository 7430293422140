import React from "react";
import Row from "../../flex-row";
import Col from "../../flex-col";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import HcCheckbox from "../../hc-checkbox";
import cx from "classnames";
import { lang } from "../../../features/i18n";
import useSelectFrac from "./use-select-frac";
import { ReservationSlotFrac } from "./types-villas-frac";
import { ReservationStatus } from "../../../app/OwnerService-api";
import { formatDateRangeStr } from "../utils";

type Props = {
  reservationSlots: ReservationSlotFrac[];
  parentClassName: string;
  setSelections: (newState: number[]) => void;
  initialSelections: number[];
  translate: (key: string) => string;
  isLastMinute: boolean;
  setIsLastMinute: (val: boolean) => void;
} & WizardStep;

const SelectVillasFrac = ({
  nextStep,
  previousStep,
  reservationSlots,
  parentClassName,
  setSelections,
  translate: t,
  initialSelections,
  isLastMinute,
  setIsLastMinute,
}: Props): JSX.Element => {
  const className = cx("no-p-lr", `${parentClassName}__step--select`);
  const selectionsClassName = cx(`${className}__selections`);
  const actionsClassName = cx(`${className}__actions`);

  const tRoot = lang.home;

  const { selected, isSelected, toggleSelected, handleNextStep } = useSelectFrac(
    initialSelections,
    nextStep,
    setSelections,
    reservationSlots,
    setIsLastMinute
  );

  const isNoneSelected = selected.size === 0;

  /**
   * Check if some of the selected reservations starts in less than 31 days
   * to show the right confirm button text
   */
  const confirmButtonText = isLastMinute
    ? t(tRoot.holdings.lastMinuteFrac.requestBooking)
    : t(tRoot.holdings.villas.confirmSelection);

  return (
    <Col className={className}>
      <Row className={selectionsClassName}>
        <HcText weight="strong">{t(tRoot.holdings.villas.infoGeneral)}</HcText>

        {reservationSlots.map((slot, index) => {
          const isDisabled =
            slot.reservationStatus === ReservationStatus.Pending ||
            slot.reservationStatus === ReservationStatus.Accepted;

          return (
            <HcCheckbox
              ariaLabel={slot.resortName}
              labelPosition="left"
              hasContainer
              checked={isSelected(slot.weekId) || isDisabled}
              onChange={() => toggleSelected(slot.weekId)}
              key={`selection-${index}`}
              disabled={isDisabled}
            >
              <HcText size="s" bottomSpace="0" colorVariant={isDisabled ? "weak" : "default"}>
                {slot.resortName}
              </HcText>
              <HcText size="s" bottomSpace="0" colorVariant="weak">
                {t(tRoot.holdings.room)} {slot.room}
              </HcText>

              <HcText size="s" bottomSpace="0" colorVariant="weak">
                {formatDateRangeStr(
                  slot.startDate,
                  slot.endDate,
                  t(tRoot.holdings.week),
                  slot.weekNumber
                )}
              </HcText>
            </HcCheckbox>
          );
        })}
      </Row>

      <Row className={actionsClassName}>
        <HcButton onClick={handleNextStep} disabled={isNoneSelected} theme="dark">
          {confirmButtonText}
        </HcButton>
        <HcButton onClick={previousStep} isSecondary>
          {t(tRoot.general.cancelTarget)}
        </HcButton>
      </Row>
    </Col>
  );
};

export default SelectVillasFrac;
