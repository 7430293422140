import React from "react";
import CloseIcon from "../../../images/close-white-32.svg";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
const CloseButton = ({ closeToast }: any): JSX.Element => {
  return (
    <button className="toast-custom-close" onClick={closeToast} type="button" aria-label="Close">
      <img src={CloseIcon} alt="" />
    </button>
  );
};

export default CloseButton;
