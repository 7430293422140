import { useState, useEffect, useRef } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import { ThemedDateRange } from "./../../calendar";
import useComponentVisible from "./use-calendar-visible";
import {
  strToDate,
  isRangeValid,
  fixIncorrectDate,
  addLeadingZero,
  swapDates,
  isValidDate,
  isInCorrectOrder,
  isPastDate,
} from "../utils";
import { DateRangeType } from "../../calendar";
import { format, isBefore } from "date-fns";
import { useSelector } from "react-redux";
import { selectClickedDate } from "../../../store/calendar";
import { ReservationInfo } from "../../holdings-calendar";
import { isSameDayOrBefore } from "../../holdings-calendar/utils";

type SelectHandlerType = (data: DateRangeType) => void;

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useDateRange = (
  initialData: ThemedDateRange[] | undefined,
  onRangeSelect: SelectHandlerType | undefined,
  reservations: ReservationInfo[],
  handleToConfirm?: (range?: DateRangeType) => void,
  resetCurrentRange?: () => void
) => {
  const [clickedDate, setClickedDate] = useState<Date | undefined>(undefined);
  const [clickCount, setClickCount] = useState(0);
  const [startIsValid, setStartIsValid] = useState<boolean>(true);
  const [endIsValid, setEndIsValid] = useState<boolean>(true);
  const [rangeStart, setRangeStart] = useState<string>("");
  const [rangeEnd, setRangeEnd] = useState<string>("");
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const [isOverlapping, setIsOverlapping] = useState<boolean>(false);
  const [rangeData, setRangeData] = useState<ThemedDateRange[] | undefined>(undefined);
  const { ref, isCalendarVisible, setIsCalendarVisible } = useComponentVisible(false);
  const { t } = useTypedTranslation();
  const endInputRef = useRef<HTMLInputElement>(null);
  const clickedDateFromStore = useSelector(selectClickedDate);

  /**
   * Update rangeStart or rangeEnd if necessary: fix incorrect date and add leading zero
   * @param {boolean} isStart - true if validating rangeStart
   */
  const formatDate = (isStart: boolean) => {
    let fixedDate = fixIncorrectDate(isStart ? rangeStart : rangeEnd);
    fixedDate = addLeadingZero(fixedDate);

    if (isStart && rangeStart !== fixedDate) setRangeStart(fixedDate);
    if (!isStart && rangeEnd !== fixedDate) setRangeEnd(fixedDate);
  };

  /**
   * Format both start and end date
   */
  const formatDates = () => {
    formatDate(true);
    formatDate(false);
  };

  /**
   * @param {Date} start - range start
   * @param {Date} end - range end
   * @returns {boolean} true if range is overlapping some reservation, not including start and end dates
   */
  const isRangeOverlappingReservation = (start: Date, end: Date): boolean => {
    return reservations.some(
      (r) =>
        (isBefore(r.startDate, start) && isBefore(start, r.endDate)) ||
        (isBefore(r.startDate, end) && isBefore(end, r.endDate)) ||
        (isSameDayOrBefore(start, r.startDate) && isSameDayOrBefore(r.endDate, end))
    );
  };

  /**
   * Update startIsValid and endIsValid if necessary
   * @returns {boolean} true if start, end and range are valid and reservations are not overlapping
   */
  const validateDates = (): boolean => {
    const start = isValidDate(rangeStart) ? strToDate(rangeStart) : undefined;
    const end = isValidDate(rangeEnd) ? strToDate(rangeEnd) : undefined;
    const rangeIsValid = isInCorrectOrder(start, end);
    const overlapping = start && end ? isRangeOverlappingReservation(start, end) : false;

    /**
     * Field is valid
     * - if it is empty
     * - if it is valid date and range is valid
     * - if it is valid date and the other field is empty
     */
    const updatedStartIsValid =
      rangeStart === "" || (start !== undefined && !isPastDate(start) && (rangeIsValid || !end));
    const updatedEndIsValid =
      rangeEnd === "" || (end !== undefined && !isPastDate(end) && (rangeIsValid || !start));

    // Update state if necessary
    if (startIsValid !== updatedStartIsValid) setStartIsValid(updatedStartIsValid);
    if (endIsValid !== updatedEndIsValid) setEndIsValid(updatedEndIsValid);

    if (overlapping !== isOverlapping) {
      setIsOverlapping(overlapping);
    }

    return updatedStartIsValid && updatedEndIsValid;
  };

  /**
   * Handle keyboard user selecting date range by pressing Enter
   * @param {KeyboardEvent} event
   */
  const handleEnterKey = (event: KeyboardEvent) => {
    if (event.key === "Enter" && isInputFocus && rangeStart && rangeEnd) {
      formatDates();

      if (validateDates() && !isOverlapping && handleToConfirm) {
        const selection = rangeData?.filter((item) => item.type === "selection");
        if (!selection || !selection[0]) return;
        const range = {
          start: selection![0].data.start, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          end: selection![0].data.end, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        };
        handleToConfirm(range);
      }
    }
  };

  /**
   * Enter key listener
   */
  useEffect(() => {
    document.addEventListener("keydown", handleEnterKey);

    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleEnterKey]);

  /**
   * If this is not the first reservation, clear rangeStart
   * Else set clickedDate, rangeStart and clickCount
   * Open date-picker calendar
   */
  useEffect(() => {
    if (initialData && initialData.length > 0) {
      setRangeStart("");
    } else {
      setClickedDate(clickedDateFromStore);
      if (clickedDateFromStore) {
        setRangeStart(format(clickedDateFromStore, "dd.MM.yyyy"));
      }
      endInputRef.current && endInputRef.current.focus();
    }

    setIsCalendarVisible(true);
  }, []);

  /**
   * Update rangeData when initialData changes
   */
  useEffect(() => {
    let data: ThemedDateRange[] = [];
    if (initialData !== undefined) {
      data = [...initialData];
    }

    if (clickedDate) {
      const d: ThemedDateRange = {
        data: {
          start: clickedDate,
          end: clickedDate,
        },
        type: "selection",
      };

      data = data.concat([d]);
      setClickCount(1);
    }

    setRangeData(data);
  }, [initialData]);

  /**
   * Update partially filled rangeData, set clickedDate and clickCount
   * @param {Date} partial
   */
  const updatePartialRangeData = (partial: Date) => {
    const newRangeData: ThemedDateRange[] = [];
    newRangeData.push({
      data: {
        start: partial,
        end: partial,
      },
      type: "selection",
    });
    updateRangeData(newRangeData);
    setClickedDate(partial);
    if (clickCount !== 1) {
      setClickCount(1);
    }
  };

  /**
   * If range start/end changes
   */
  useEffect(() => {
    const datesAreValid = validateDates();
    const startDate =
      rangeStart !== "" && isValidDate(rangeStart) ? strToDate(rangeStart) : undefined;
    const endDate = rangeEnd !== "" && isValidDate(rangeEnd) ? strToDate(rangeEnd) : undefined;
    const partial = startDate ? startDate : endDate;

    /**
     * If only start or end is valid update partial rangeData
     */
    if (partial && (!startDate || !endDate)) {
      updatePartialRangeData(partial);
    }

    /**
     * If start and end are invalid reset rangeData
     */
    if (!partial) {
      resetRangeData();
    }

    /**
     * If start or end is invalid reset currentRange in use-select-range-villas to disable submit
     */
    if (!datesAreValid && resetCurrentRange) {
      resetCurrentRange();
      return;
    }

    /**
     * Update range in date picker
     */
    const newRangeData: ThemedDateRange[] = [];
    newRangeData.push({
      data: {
        start: strToDate(rangeStart),
        end: strToDate(rangeEnd),
      },
      type: "selection",
    });

    if (rangeStart && rangeEnd && (clickCount === 0 || clickCount === 1)) {
      updateRangeData(newRangeData);
    }
  }, [rangeStart, rangeEnd]);

  /**
   * If reservations are overlapping (=not valid) reset current range
   */
  useEffect(() => {
    if (isOverlapping && resetCurrentRange) {
      resetCurrentRange();
    }
  }, [isOverlapping]);

  /**
   * Hide the calendar + reset user click count.
   */
  const hideCalendarAfterSelection = () => {
    setIsCalendarVisible(false);
    setClickCount(0);
  };

  /**
   * If range is valid --> return selected range
   */
  const selectRange = () => {
    if (isRangeValid(rangeStart, rangeEnd) && !isOverlapping && onRangeSelect !== undefined) {
      const selection = rangeData?.filter((item) => item.type === "selection");
      if (!selection || !selection[0]) {
        return;
      }
      const range = {
        start: selection![0].data.start, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        end: selection![0].data.end, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      };
      onRangeSelect(range);
    }
  };

  /**
   * If user has clicked twice select range.
   */
  useEffect(() => {
    if (clickCount === 2) {
      selectRange();
      hideCalendarAfterSelection();
    }
  }, [clickCount]);

  /**
   * If one of inputs is focused show calendar
   */
  const onInputFocus = () => {
    setIsCalendarVisible(true);
    setIsInputFocus(true);
  };

  /**
   * If one of inputs gets out of focus validate it
   * @param e - focus event
   * @param {boolean} isStart - true if start input
   */
  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>, isStart: boolean) => {
    formatDate(isStart);
    validateDates();
    setIsInputFocus(false);

    if (rangeStart && isValidDate(rangeStart)) {
      setClickedDate(strToDate(rangeStart));
    } else if (rangeEnd && isValidDate(rangeEnd)) {
      setClickedDate(strToDate(rangeEnd));
    }

    if (!isStart && rangeStart && rangeEnd && startIsValid && endIsValid) {
      selectRange();
      setClickCount(0);
      hideCalendarAfterSelection();
    }
  };

  /**
   * Handle date ranged change using keyboard
   * @param e - input change event
   * @param {boolean} isStart true if input for range start
   */
  const onDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>, isStart: boolean) => {
    if (isStart) {
      setRangeStart(e.target.value);
    } else {
      setRangeEnd(e.target.value);
    }
  };

  /**
   * Store user click on calendar
   * @param {Date} day - date clicked
   */
  const onCalendarClick = (day: Date) => {
    // let's store click so that we can use it for hover
    if (clickCount === 0) {
      setRangeStart(format(day, "dd.MM.yyyy"));
      setRangeEnd("");
    }
    setClickedDate(day);
    setClickCount((prev) => prev + 1);
  };

  /**
   * Handle user selecting date range with mouse when start/end is selected
   * @param {Date} day
   */
  const handleHover = (day: Date) => {
    if (clickedDate !== undefined && clickCount === 1) {
      const dateArray = swapDates(day, clickedDate);
      setRangeStart(dateArray[0]);
      setRangeEnd(dateArray[1]);
    }
  };

  /**
   * Reset local rangeData
   */
  const resetRangeData = () => {
    if (rangeData) {
      const filteredRangeData = rangeData.filter((item) => item.type !== "selection");
      setRangeData(filteredRangeData);
    }
    setClickCount(0);
    setClickedDate(undefined);
  };

  /**
   * Update local rangeData
   * @param {ThemedDateRange[]} data
   */
  const updateRangeData = (data: ThemedDateRange[]) => {
    if (rangeData !== undefined) {
      const filteredRangeData = rangeData.filter((item) => item.type !== "selection");
      const newData = [...filteredRangeData, ...data];
      setRangeData(newData);
    } else {
      setRangeData(data);
    }
  };

  return {
    t,
    onInputFocus,
    onDateRangeChange,
    onCalendarClick,
    handleHover,
    ref,
    isCalendarVisible,
    rangeStart,
    rangeEnd,
    rangeData,
    onInputBlur,
    startIsValid,
    endIsValid,
    endInputRef,
    clickedDate,
    isOverlapping,
  };
};

export default useDateRange;
