import { useTypedTranslation } from "../../features/i18n";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useFooter = () => {
  const { t } = useTypedTranslation();

  return {
    t,
  };
};

export default useFooter;
