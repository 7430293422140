import React from "react";
import { TabPanel, Tabs } from "react-tabs";

import { TabContainerProps } from ".";
import { useTabContainer, useCollapsingTabList } from "./hooks";
import "./tab-container.scss";
import CollapsingTabList from "./collapsing-tab-list";
import cx from "classnames";

const TabContainer = ({
  contentList,
  activeResortId = 0,
  onTabSelect,
}: TabContainerProps): JSX.Element => {
  const { tabIndex, setTabIndex, windowSize, containerRef, setContainerRef } = useTabContainer(
    activeResortId
  );
  const tabListHooks = useCollapsingTabList();
  const className = "tab-container";

  const tabContentClasses = cx("tab-container__content", {
    "single-tab": contentList.length === 1,
  });

  const onSelect = (index: number) => {
    onTabSelect && contentList[index] && onTabSelect(contentList[index].header);
    setTabIndex(index);
  };

  return (
    <div ref={setContainerRef}>
      <Tabs selectedIndex={tabIndex} onSelect={onSelect} className={className}>
        {
          // this component needs to be called this way to avoid exceptions from react-tabs
          CollapsingTabList({
            tabs: contentList,
            tabIndex: tabIndex,
            parentClass: className,
            parent: containerRef,
            windowSize: windowSize,
            setTabIndex,
            ...tabListHooks,
            onMenuItemSelect: onTabSelect,
          })
        }

        {contentList.map(({ Content }, index) => (
          <TabPanel key={`tab-container-panel-${index}`} className={tabContentClasses}>
            <Content />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default TabContainer;
