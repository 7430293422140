import { useTypedTranslation } from "../../../features/i18n";
import useGTM from "../../../features/use-gtm";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useInfo = (previousStep: () => void) => {
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();

  const handlePreviousStep = () => {
    // Google Tag Manager
    sendDataToGTM({
      event: "rent_week_start",
      event_description: "Vuokraa viikko eteenpäin",
    });
    previousStep();
  };

  return {
    t,
    handlePreviousStep,
  };
};

export default useInfo;
