import React from "react";
import Col from "../../flex-col";
import Row from "../../flex-row";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { lang } from "../../../features/i18n";
import { WizardStep } from "../../wizard-container";

import useConfirmRent from "./use-confirm-rent";
import HcCheckbox from "../../hc-checkbox";
import { Trans } from "react-i18next";
import "./confirm-rent.scss";

type ConfirmRentProps = {
  resortName: string;
  realEstateCompany: string;
  weekNumber: number | undefined;
  reservationRange: string;
} & WizardStep;

const ConfirmRent = ({
  resortName,
  realEstateCompany,
  weekNumber,
  reservationRange,
  nextStep,
  previousStep,
}: ConfirmRentProps): JSX.Element => {
  const { t, isConfirmed, toggleIsConfirmed, handleNextStep } = useConfirmRent(nextStep);
  const tRoot = lang.rentFormModal;

  const classNameRoot = "confirm-rent";
  const classNameContainer = `${classNameRoot}__container no-side-padding`;

  return (
    <Row className={classNameRoot}>
      <Col className={classNameContainer}>
        <Row className="confirm-step-body">
          <Col className="no-side-padding">
            <HcText weight="strong" bottomSpace="s">
              {t(tRoot.confirmRent.title)}
            </HcText>
            <div className="holding-info-box">
              <HcText size="s" bottomSpace="0">
                {resortName}
              </HcText>
              <HcText size="s" colorVariant="weak" bottomSpace="0">
                {realEstateCompany}
              </HcText>
              <HcText size="s" colorVariant="weak" bottomSpace="0">
                {t(tRoot.week)} {weekNumber}
                <span className="week-date-separator">|</span>
                {reservationRange}
              </HcText>
            </div>

            <HcCheckbox
              ariaLabel="i-have-not-targetted-holding-in-RCI"
              onChange={toggleIsConfirmed}
              checked={isConfirmed}
            >
              <HcText size="s" colorVariant="weak">
                <Trans i18nKey="home.rentFormModal.confirmRent.acceptTerms">
                  Hyväksyn{" "}
                  <a
                    href={t(tRoot.confirmRent.termsLinkTarget)}
                    rel="noopener noreferrer"
                    className="action-link"
                    target="_blank"
                  >
                    {{ termsLink: t(tRoot.confirmRent.termsLink) }}
                  </a>
                </Trans>
              </HcText>
            </HcCheckbox>
          </Col>
        </Row>

        <Row>
          <Col className="no-side-padding">
            <HcButton onClick={handleNextStep} disabled={!isConfirmed}>
              {t(tRoot.continue)}
            </HcButton>
            <HcButton onClick={previousStep} isSecondary>
              {t(tRoot.back)}
            </HcButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConfirmRent;
