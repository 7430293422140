import { RootState } from "..";
import modalSlice from "./modal-slice";

export type ModalState = {
  isOpen: boolean;
  isClosing: boolean;
  activeModalId: string;
};

const selectIsModalOpen = (state: RootState): boolean => state.modal.isOpen;
const selectActiveModalId = (state: RootState): string => state.modal.activeModalId;
const selectIsModalClosing = (state: RootState): boolean => state.modal.isClosing;

export { selectIsModalOpen, selectActiveModalId, selectIsModalClosing };

export const { openModal, closeModal, onRequestClose, cancelOnRequestClose } = modalSlice.actions;

export default modalSlice.reducer;
