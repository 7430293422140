import React from "react";
import Container from "./../flex-container";
import Row from "../flex-row";
import Col from "../flex-col";
import Logo from "./../../images/hc-logo-burgundy.svg";
import useNavMenu from "./use-nav-menu";
import cx from "classnames";
import HcText from "../hc-text";
import DropdownMenu, { MenuItem } from "../dropdown-menu";
import { lang } from "../../features/i18n";
import { Link } from "react-router-dom";
import FirstLoginBadge from "./first-login-badge";

import "./nav-menu.scss";

const NavMenu = (): JSX.Element => {
  const {
    initial,
    name,
    distance,
    buttonElement,
    setButtonElement,
    handleLogout,
    t,
    history,
    isModalOpen,
    scrollToTop,
    handleLinkClick,
    profileLoading,
    showFirstLoginBadge,
  } = useNavMenu();
  const tRoot = lang.navigation;

  const navCssClasses = cx("main-nav-container", {
    "nav-scroll-style": distance > 0,
  });

  const menuItems: MenuItem[] = [
    {
      name: t(tRoot.profile),
      action: () => {
        history.push("omat-tiedot");
        scrollToTop();
      },
      hasBottomDivider: true,
      hasBadge: showFirstLoginBadge,
    },
    {
      name: t(tRoot.customerService),
      action: () => handleLinkClick(t(tRoot.customerServiceUrl)),
    },
    {
      name: t(tRoot.feedback),
      action: () => handleLinkClick(t(tRoot.feedbackUrl)),
    },
    {
      name: t(tRoot.logout),
      action: () => handleLogout(),
    },
  ];

  const AvatarGroup = (): JSX.Element => (
    <div className="avatar-group">
      <div className="nav-avatar">{initial}</div>
      <HcText size="s" bottomSpace="0" className="avatar-full-name">
        {name}
      </HcText>
    </div>
  );

  const content = (
    <>
      <header className={navCssClasses}>
        <Container>
          <Row>
            <Col s={7} m={9} l={10} className="nav-logo-container">
              {isModalOpen ? (
                <img src={Logo} alt={t(tRoot.logoAltText)} className="hc-logo" />
              ) : (
                <>
                  <Link to="/" onClick={scrollToTop}>
                    <img src={Logo} alt={t(tRoot.logoAltText)} className="hc-logo" />
                  </Link>
                </>
              )}
            </Col>
            <Col s={5} m={3} l={2} className="nav-avatar-container">
              {profileLoading === "success" && showFirstLoginBadge && <FirstLoginBadge />}

              {isModalOpen ? (
                <AvatarGroup />
              ) : (
                <nav>
                  <button
                    ref={setButtonElement}
                    className="avatar-group-button"
                    aria-label={t(tRoot.navigationLabel)}
                    aria-haspopup="menu"
                    aria-expanded="false"
                  >
                    <AvatarGroup />
                  </button>
                  <DropdownMenu
                    referenceElement={buttonElement}
                    menuItems={menuItems}
                    altAxis={true}
                  />
                </nav>
              )}
            </Col>
          </Row>
        </Container>
      </header>
      <Container>
        <Row>
          <div className="nav-spacer"> </div>
        </Row>
      </Container>
    </>
  );

  return content;
};

export default NavMenu;
