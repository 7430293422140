import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { selectUser } from "../../../store/authorization";
import { selectHomepageState } from "../../../store/home";
import {
  reserveHolding as reserveHoldingAction,
  selectReservationsState,
} from "../../../store/reservations";
import { requestHomepageData as requestHomepageDataAction } from "../../../store/home";
import { IReserveHoldingDto } from "../../../app/OwnerService-api";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useHome = () => {
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const homepageState = useSelector(selectHomepageState);
  const reservationsState = useSelector(selectReservationsState);
  const benefitsRef = useRef<HTMLAnchorElement>(null);
  const holdingsRef = useRef<HTMLDivElement>(null);

  const reserveHolding = async (reservation: IReserveHoldingDto) => {
    dispatch(
      reserveHoldingAction({
        requestor: user,
        ...reservation,
      })
    );
  };

  const reserveHoldings = async (reservations: IReserveHoldingDto[]) => {
    await Promise.all(reservations.map(async (reservation) => await reserveHolding(reservation)));
  };

  const requestHomepageData = async () => {
    dispatch(requestHomepageDataAction(user));
  };

  const homepageData = homepageState.homepageData;
  const holdingsData = homepageState.homepageData.holdings;
  const epiContentData = homepageState.homepageData.content;
  const homepageLoading = homepageState.loading;
  const reservationsData = reservationsState.reservationsData.reservations;
  const restrictedUserInterfaceAccess = homepageState.restrictedUserInterfaceAccess;

  useEffect(() => {
    if (homepageLoading === "idle") {
      requestHomepageData();
    }
  }, [requestHomepageData, homepageLoading]);

  const scrollToBenefits = () => {
    if (benefitsRef && benefitsRef.current) {
      benefitsRef.current.focus();
      window.scrollTo(0, benefitsRef.current.offsetTop - 100);
    }
  };

  const scrollToHoldings = () => {
    if (holdingsRef && holdingsRef.current) {
      window.scrollTo(0, holdingsRef.current.offsetTop - 100);
    }
  };

  return {
    t,
    holdingsData,
    homepageData,
    epiContentData,
    reservationsData,
    homepageLoading,
    reserveHoldings,
    benefitsRef,
    scrollToBenefits,
    holdingsRef,
    scrollToHoldings,
    restrictedUserInterfaceAccess,
  };
};

export default useHome;
