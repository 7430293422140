import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { openReservationProcess } from "../../../store/reservations";
import useGTM from "../../../features/use-gtm";
import { ReservationSlotFrac } from "./types-villas-frac";
import { daysBeforeRangeStart } from "../../date-range/utils";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useSelectFrac = (
  initialSelections: number[],
  nextStep: () => void,
  setSelections: (newState: number[]) => void,
  reservationSlots: ReservationSlotFrac[],
  setIsLastMinute: (val: boolean) => void
) => {
  const [selected, setSelected] = useState(new Set<number>(initialSelections));
  const dispatch = useAppDispatch();
  const { sendDataToGTM } = useGTM();

  const isSelected = (holdingUnitId: number) => selected.has(holdingUnitId);
  const toggleSelected = (holdingUnitId: number) => {
    const newSelected = new Set(selected);

    if (selected.has(holdingUnitId)) {
      newSelected.delete(holdingUnitId);
    } else {
      newSelected.add(holdingUnitId);
    }

    setSelected(newSelected);
  };

  /**
   * Set reservation flow selections at use-selections,
   * send data to GTM and move to next step
   */
  const handleNextStep = () => {
    setSelections(Array.from(selected));

    // Google Tag Manager
    sendDataToGTM({
      event: "booking_choose_week",
      event_description: "Valitse viikko ja vahvista varaus",
    });
    nextStep();
  };

  useEffect(() => {
    // Set reservation process active --> need to confirm closing
    dispatch(openReservationProcess());
  }, [dispatch]);

  useEffect(() => {
    const lastMin = reservationSlots.some(
      (r) => selected.has(r.weekId) && daysBeforeRangeStart(new Date(), new Date(r.startDate)) < 31
    );

    // Update isLastMinute if necessary
    setIsLastMinute(lastMin);
  }, [selected, reservationSlots, setIsLastMinute]);

  return {
    selected,
    isSelected,
    toggleSelected,
    handleNextStep,
  };
};

export default useSelectFrac;
