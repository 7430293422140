import React from "react";
import { CancellationReservationSlot } from ".";
import { lang, TypedTranslate } from "../../../features/i18n";
import FlexCol from "../../flex-col";
import FlexRow from "../../flex-row";
import HcButton from "../../hc-button";
import HcCheckbox from "../../hc-checkbox";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import { formatDateRangeStr } from "../utils";
import useConfirmCancellation from "./use-confirm-cancellation";

type Props = WizardStep & {
  translate: TypedTranslate;
  selectedReservationIds: string[];
  reservations: CancellationReservationSlot[];
  parentClassName: string;
};

const Confirm = ({
  translate: t,
  nextStep,
  previousStep,
  selectedReservationIds,
  reservations,
  parentClassName,
}: Props): JSX.Element => {
  const tRoot = lang.home.holdings;
  const reservationsToCancel = reservations.filter((reservation) =>
    selectedReservationIds.includes(reservation.reservationId)
  );

  const baseClassName = `${parentClassName}__step--confirm`;
  const className = `${baseClassName} no-p-lr`;
  const classNameSelections = `${baseClassName}__selections`;

  const {
    isTermsAccepted,
    toggleIsTermsAccepted,
    handleConfirmCancellation,
  } = useConfirmCancellation(nextStep);

  return (
    <FlexCol className={className}>
      <FlexRow className={classNameSelections}>
        <HcText weight="strong">{t(tRoot.reserved.confirmTitle)}:</HcText>

        {reservationsToCancel.map((reservation, index) => (
          <HcText size="s" key={`reservation-cancellation-${index}`}>
            {formatDateRangeStr(
              reservation.startDate,
              reservation.endDate,
              t(tRoot.week),
              reservation.weekNumber
            )}
          </HcText>
        ))}
      </FlexRow>

      <FlexRow>
        <HcCheckbox
          ariaLabel={`${t(tRoot.reserved.confirmAcceptTerms)}`}
          checked={isTermsAccepted}
          onChange={toggleIsTermsAccepted}
        >
          <HcText size="s" bottomSpace="s">
            {t(tRoot.reserved.confirmAcceptTerms)}
          </HcText>
        </HcCheckbox>
      </FlexRow>

      <FlexRow>
        <HcButton onClick={handleConfirmCancellation} theme="dark" disabled={!isTermsAccepted}>
          {t(tRoot.reserved.confirmButtonPrimary)}
        </HcButton>
        <HcButton onClick={previousStep} isSecondary>
          {t(tRoot.back)}
        </HcButton>
      </FlexRow>
    </FlexCol>
  );
};

export default Confirm;
