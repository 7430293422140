import { isAuthDisabled } from "../authorization/auth-utils";
import { inviteModuleDummy } from "./invite-module-dummy";
import inviteModuleMsal from "./invite-module-msal";
import { IUser } from "./../authorization";

export interface IInviteModule {
  handleRedirect: () => Promise<IUser | void>;
  acquireToken: (user?: IUser) => Promise<string>;
  invite: (token: string) => void;
}

const inviteModule: IInviteModule = isAuthDisabled()
  ? new inviteModuleDummy()
  : new inviteModuleMsal();

export { isAuthDisabled };

export default inviteModule;
