import { useState, useEffect } from "react";
import { DateRangeType } from "../../calendar";
import { useAppDispatch } from "../../../store";
import { openReservationProcess } from "../../../store/reservations";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useRangeSelectVillas = (
  latestRange: DateRangeType | undefined,
  initUsingLatestRange: boolean
) => {
  const [selectedRange, setSelectedRange] = useState<DateRangeType | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedRange !== undefined) {
      // Set reservation process active --> need to confirm closing
      dispatch(openReservationProcess());
    }
  }, [selectedRange, dispatch]);

  useEffect(() => {
    if (initUsingLatestRange && latestRange !== undefined) {
      setSelectedRange(latestRange);
    }
  }, [initUsingLatestRange, latestRange]);

  /**
   * Set selected range
   * @param {DateRangeType} data - range data
   */
  const onRangeChange = (data: DateRangeType) => {
    setSelectedRange(data);
  };

  /**
   * Reset selected range
   */
  const resetSelectedRange = () => {
    setSelectedRange(undefined);
  };

  return {
    selectedRange,
    onRangeChange,
    resetSelectedRange,
  };
};

export default useRangeSelectVillas;
