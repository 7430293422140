import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function useRentFormModal() {
  const [stepIndex, setStepIndex] = useState(0);
  const { t } = useTypedTranslation();

  return {
    stepIndex,
    setStepIndex,
    t,
  };
}
