import React from "react";
import { LazyImage } from "react-lazy-images";
import PlaceholderImg from "../placeholder-img";
import { ResponsiveImageProps } from ".";

const ResponsiveImage = ({
  alt,
  src,
  srcSet = "",
  className,
  sizes = "(min-width: 1250px) 800px, (min-width: 570px) 1200px, 100vw",
  placeholderHeight = "80vh",
  placeholderLoadingState = true,
}: ResponsiveImageProps): JSX.Element => {
  const cssClasses = `responsive-img-base${className ? ` ${className}` : ""}`;

  return (
    <>
      <LazyImage
        src={src}
        alt={alt}
        placeholder={({ ref }) => (
          <PlaceholderImg
            ref={ref}
            roundedCorners={false}
            height={placeholderHeight}
            className={placeholderLoadingState ? "generic-laoding-state" : ""}
          />
        )}
        actual={({ imageProps }) => (
          <img
            alt={imageProps.alt}
            src={imageProps.src}
            srcSet={srcSet}
            sizes={sizes}
            className={cssClasses}
          />
        )}
      />
    </>
  );
};

export default ResponsiveImage;
