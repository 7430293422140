import { LoadingState, RootState } from "..";
import { ICalendarDto, IFrontPageDto, IHoldingReservationDto } from "../../app/OwnerService-api";
import { HoldingDto, IHoldingDto } from "../../app/OwnerService-api";
import homepageSlice, { requestHomepageData } from "./home-slice";

export type HomepageState = {
  homepageData: IFrontPageDto;
  showFirstLoginBadge: boolean;
  showFirstLoginTooltip: boolean;
  loading: LoadingState;
  restrictedUserInterfaceAccess: boolean;
  rentForm: {
    isOpen: boolean;
    holdingUnitId: IHoldingDto["unitId"];
    reservations: IHoldingReservationDto[];
    week: ICalendarDto | undefined;
    additionalInformation: string;
  };
};

const selectHomepageState = (state: RootState): HomepageState => ({
  ...state.homepageData,
  homepageData: {
    ...state.homepageData.homepageData,
    holdings: state.homepageData.homepageData.holdings
      ? state.homepageData.homepageData.holdings
      : [],
  },
});
const selectIsRentFormOpen = (state: RootState): boolean => state.homepageData.rentForm.isOpen;
const selectRentFormHoldingUnitId = (state: RootState): IHoldingDto["unitId"] =>
  state.homepageData.rentForm.holdingUnitId;
const selectHoldings = (state: RootState): HoldingDto[] =>
  state.homepageData.homepageData.holdings ? state.homepageData.homepageData.holdings : [];

const selectRentFormReservations = (state: RootState): IHoldingReservationDto[] =>
  state.homepageData.rentForm.reservations ? state.homepageData.rentForm.reservations : [];

const selectRentFormWeek = (state: RootState): ICalendarDto | undefined =>
  state.homepageData.rentForm.week;

const selectIsFirstLoginBadgeVisible = (state: RootState): boolean =>
  state.homepageData.homepageData.firstLogin && state.homepageData.showFirstLoginBadge;

const selectIsFirstLoginTooltipVisible = (state: RootState): boolean =>
  state.homepageData.homepageData.firstLogin &&
  state.homepageData.showFirstLoginBadge &&
  state.homepageData.showFirstLoginTooltip;

const selectIsFirstLogin = (state: RootState): boolean =>
  state.homepageData.homepageData.firstLogin;

const selectRentFormInformation = (state: RootState): string =>
  state.homepageData.rentForm.additionalInformation;

export {
  requestHomepageData,
  selectHomepageState,
  selectIsRentFormOpen,
  selectRentFormHoldingUnitId,
  selectHoldings,
  selectRentFormReservations,
  selectRentFormWeek,
  selectIsFirstLoginBadgeVisible,
  selectIsFirstLoginTooltipVisible,
  selectIsFirstLogin,
  selectRentFormInformation,
};
export const {
  openRentForm,
  closeRentForm,
  hideFirstLoginBadge,
  hideFirstLoginTooltip,
  updateAdditionalInformation,
} = homepageSlice.actions;

export default homepageSlice.reducer;
