import { IAuthModule, IUser } from ".";

/**
 * Class to skip authorization in local environment
 */
export class AuthModuleDummy implements IAuthModule {
  async handleRedirect(): Promise<IUser | undefined> {
    return Promise.resolve({
      access_token: "42",
      familyName: "osakas",
      givenName: "demo",
      accessToken: "42",
      environment: "any",
      homeAccountId: "42",
      tenantId: "42",
      username: "demo-osakas",
      localAccountId: "42",
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  forgotPassword(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  acquireToken(user?: IUser): Promise<string> {
    return Promise.resolve("42");
  }
}
