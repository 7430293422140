import React from "react";
import { HoldingReserved } from "..";
import { TypedTranslate } from "../../../features/i18n";
import WizardContainer, { Wizard } from "../../wizard-container";
import Info from "./info";
import { isPastDate } from "../../date-range/utils";
import { formatDate } from "../utils";

type Props = {
  holding: HoldingReserved;
  translate: TypedTranslate;
} & Pick<Wizard, "cancelWizard" | "confirmWizard">;

const OngoingReservation = ({
  holding,
  translate,
  cancelWizard,
  confirmWizard,
}: Props): JSX.Element => {
  const stepIndex = 0;
  const week = holding.weeks && holding.weeks.find((w) => isPastDate(new Date(w.weekStart)));
  const reservation = holding.reservations.find((r) => isPastDate(r.startDate));
  const endDateStr = week
    ? formatDate(new Date(week.weekEnd))
    : reservation && formatDate(reservation?.endDate);

  return (
    <WizardContainer stepIndex={stepIndex}>
      <Info
        nextStep={confirmWizard}
        previousStep={cancelWizard}
        resortName={holding.resortName ? holding.resortName : ""}
        endDate={endDateStr || ""}
        translate={translate}
      />
    </WizardContainer>
  );
};

export default OngoingReservation;
