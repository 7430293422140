import { useEffect, useState } from "react";
import { PopupLink } from "../../../app/OwnerService-api";
import useGTM from "../../../features/use-gtm";
import { DropdownDataType } from "../../hc-dropdown";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBannerModalFooter = (
  popupLinkData: string | undefined,
  popupData: PopupLink[] | undefined
) => {
  const [popupCode, setPopupCode] = useState<string | undefined>(undefined);
  const [popupLink, setPopupLink] = useState<string | undefined>("");
  const [showBenefitSelector, setShowBenefitSelector] = useState<boolean>(false);
  const [benefitSelectData, setBenefitSelectData] = useState<DropdownDataType[]>([]);
  const { sendDataToGTM } = useGTM();

  // helper function to check if code & link data is valid
  function hasCodeAndLink(code: string | undefined, link: string | undefined) {
    return code !== undefined && code.length > 0 && link !== undefined && link.length > 0;
  }

  useEffect(() => {
    if (popupLinkData !== undefined && popupLinkData.length > 0) {
      setPopupLink(popupLinkData);
    }

    // get valid data only
    const filteredPopupData =
      popupData !== undefined && popupData.length >= 1
        ? popupData.filter((item) => hasCodeAndLink(item.code, item.link))
        : undefined;

    if (filteredPopupData !== undefined && filteredPopupData.length === 1) {
      setPopupCode(filteredPopupData[0].code);
      setPopupLink(filteredPopupData[0].link);
    }

    if (filteredPopupData !== undefined && filteredPopupData.length > 1) {
      const benefitData: DropdownDataType[] = filteredPopupData.map((item) => {
        return {
          label: item.title || "",
          value: item.code || "",
        };
      });
      setBenefitSelectData(benefitData);
      setShowBenefitSelector(true);

      // the first item is selected by default
      setPopupCode(filteredPopupData[0].code);
      setPopupLink(filteredPopupData[0].link);
    }
  }, []);

  /**
   * Track click and open link in new tab if link is defined
   * @param {boolean} holidayChange true if called from benefits banner
   */
  const handleFooterCTABtnClick = (isBenefit: boolean) => {
    if (popupLink !== undefined && popupLink.length > 0) {
      const event = {
        event: isBenefit ? "benefit_modal_store_click" : "outbound_link_click",
        event_description: isBenefit ? "Siirtyminen verkkokauppaan" : popupLink,
      };

      // Google tag manager
      sendDataToGTM(event);

      window.open(popupLink, "_blank", "noopener noreferrer");
    }
  };

  const handleActiveBenefitChange = (index: number) => {
    if (popupData !== undefined && popupData.length > 1) {
      setPopupCode(popupData[index].code);
      setPopupLink(popupData[index].link);
    }
  };

  return {
    popupCode,
    showBenefitSelector,
    benefitSelectData,
    handleFooterCTABtnClick,
    setShowBenefitSelector,
    handleActiveBenefitChange,
  };
};

export default useBannerModalFooter;
