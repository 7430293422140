import { Route } from "react-router-dom";
import React from "react";
import { RouteWrapperProps } from "..";
import Landing from "../../components/landing";

/*
 * Landing route is not protected
 */
const LandingRoute = (props: RouteWrapperProps): JSX.Element => (
  <Route {...props} component={Landing} />
);

export default LandingRoute;
