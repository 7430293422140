import React from "react";
import cx from "classnames";
import { ColorStyle, WeightType, TagType, TextSizeType } from ".";
import { SpacingType } from "./../../features/types";

import "./hc-text.scss";

export type TextProps = {
  children: React.ReactNode;

  /**
   * font-weight: Normal or strong
   */
  weight?: WeightType;

  /**
   * font size. Value follows font-size token scale.
   * xxs|xs|s|m|l|xl|xxl
   */
  size?: TextSizeType;

  /**
   * Color style
   */
  colorVariant?: ColorStyle;

  id?: string;

  /**
   * Render text inside <p> or <span>
   */
  tag?: TagType;

  /**
   * Optional top & bottom spacing for text. Value follows size token scale.
   * xxxs|xxs|xs|s|m|l|xl|xxl|xxxl|xxxxl
   */
  topSpace?: SpacingType | undefined;
  bottomSpace?: SpacingType | undefined;

  className?: string;
};

/**
 * The HcText component renders <p> element with different styles.
 */
const HcText = ({
  weight = "normal",
  size = "m",
  colorVariant = "default",
  children,
  id,
  tag = "p",
  topSpace = "0",
  bottomSpace = "xs",
  className,
}: TextProps): JSX.Element => {
  const Tag = `${tag}` as TagType;

  const textClasses = cx({
    [`text-base`]: true,
    [`text-weight--${weight}`]: true,
    [`text-size--${size}`]: true,
    [`text-color--${colorVariant}`]: true,
    [`mt-${topSpace}`]: topSpace !== undefined,
    [`mb-${bottomSpace}`]: bottomSpace !== undefined,
  });

  return (
    <Tag
      className={className !== undefined ? textClasses.concat(" " + className) : textClasses}
      id={id}
    >
      {children}
    </Tag>
  );
};

export default HcText;
