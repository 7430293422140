import { useCallback } from "react";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

export type GTMData = {
  event: string;
  event_description: string;
  event_label?: string;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useGTM = () => {
  const send = useGTMDispatch();

  const sendDataToGTM = (data: GTMData) => {
    send(data);
  };

  const sendDataToGTMCallback = useCallback((data: GTMData) => {
    send(data);
  }, []);

  return { sendDataToGTM, sendDataToGTMCallback };
};

export default useGTM;
