import WizardContainer from "./wizard-container";

export type WizardStep = {
  nextStep: () => void;
  previousStep: () => void;
};

export type Wizard = {
  setStepIndex: (newStep: number) => void;
  cancelWizard: () => void;
  confirmWizard: () => void;
  stepIndex: number;
};

export default WizardContainer;
