import React from "react";
import HcText from "../../hc-text";
import HcButton from "../../hc-button";
import { lang, TypedTranslate } from "../../../features/i18n";
import parse from "html-react-parser";
import InfoIcon from "../../../images/info-icon-16.svg";
import BannerContentPoints from "../banner-modal-content-points";
import useBannerModalContent from "./use-banner-modal-content";
import { getConvertOptions } from "../../../features/utils";

type BannerModalContentProps = {
  translate: TypedTranslate;
  popupDescription: string | undefined;
  hasDetails: boolean;
  onShowDetails: () => void;
  isHolidayChange: boolean;
  totalPoints?: string;
  resortName: string;
  resetFilters?: () => void;
  id?: string;
};

const BannerModalContent = ({
  translate: t,
  popupDescription,
  hasDetails = false,
  onShowDetails,
  isHolidayChange = false,
  totalPoints,
  resortName,
  resetFilters,
  id = "",
}: BannerModalContentProps): JSX.Element => {
  const { handleShowAllBtnClick } = useBannerModalContent(id, resetFilters);

  const tRoot = lang.epi.horizontalList.modal;
  const hasDescription = popupDescription !== undefined && popupDescription.length > 0;

  const options = getConvertOptions("s", "m");

  let parsedDescription: string | JSX.Element | JSX.Element[] = <></>;
  if (hasDescription) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    parsedDescription = parse(popupDescription!, options);
  }

  const ownerBenefitContent: JSX.Element = hasDescription ? (
    <>
      <div>{parsedDescription}</div>
      {hasDetails && (
        <button className="banner-modal-content--read-more-btn" onClick={onShowDetails}>
          <img src={InfoIcon} alt="" /> {t(tRoot.showMore)}
        </button>
      )}
    </>
  ) : (
    <>
      <HcText size="s" bottomSpace="xl">
        {t(tRoot.basicInfo)}
      </HcText>
      <HcButton onClick={handleShowAllBtnClick}>{t(tRoot.showAllBenefitsBtn)}</HcButton>
    </>
  );

  const holidayChangeContent: JSX.Element = totalPoints ? (
    <BannerContentPoints points={totalPoints} resortName={resortName} />
  ) : (
    <HcText size="s" bottomSpace="m">
      {popupDescription}
    </HcText>
  );

  return (
    <div className="banner-modal-content">
      {isHolidayChange ? holidayChangeContent : ownerBenefitContent}
    </div>
  );
};

export default BannerModalContent;
