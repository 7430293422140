import React from "react";
import { AspectRatioType } from ".";
import "./aspect-ratio-wrapper.scss";

type AspectRatioWrapperProps = {
  children: React.ReactNode;
  aspectRatio?: AspectRatioType;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const AspectRatioWrapper = React.forwardRef<HTMLDivElement, AspectRatioWrapperProps>(
  ({ children, aspectRatio = "4:3" }: AspectRatioWrapperProps, ref) => {
    const aspectRationValue = "0 0 ".concat(aspectRatio?.split(":").join(" ") || "");

    const content = (
      <div className="aspect-ratio-wrapper aspect-ratio" ref={ref}>
        <svg viewBox={aspectRationValue} />
        <div className="aspect-ratio-wrapper-content">{children}</div>
      </div>
    );

    return content;
  }
);

AspectRatioWrapper.displayName = "AspectRatioWrapper";
export default AspectRatioWrapper;
