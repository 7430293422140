import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { BenefitInitState } from "..";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { selectUser } from "../../../store/authorization";
import { selectBenefitsPageState } from "../../../store/benefits";
import { requestBenefitsPageData as requestBenefitsPageDataAction } from "../../../store/benefits";
import { lang } from "../../../features/i18n";
import { useHistory } from "react-router-dom";
import useGTM from "../../../features/use-gtm";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useBenefits = () => {
  const [showSearch, setShowSearch] = useState(false);
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const benefitsPageState = useSelector(selectBenefitsPageState);
  const tRoot = lang.benefitsPage.general;
  const history = useHistory();
  const location = history.location;
  const { sendDataToGTM } = useGTM();

  /**
   * Get initial scrollPosition and activeSearchCategories[]
   * @returns {(BenefitInitState|undefined)}
   */
  const getInitState = (): BenefitInitState | undefined => {
    if (location.state !== undefined) {
      return location.state as BenefitInitState;
    }

    if (location.hash === t(tRoot.searchTabHash) || location.hash === t(tRoot.listTabHash)) {
      return { scrollPosition: "tabs" };
    }
    return undefined;
  };

  /**
   * Change active tab and send data to GTM
   * @param {boolean} toSearch true if changing to search tab
   */
  const handleTabChange = (toSearch: boolean) => {
    const hash = toSearch ? t(tRoot.searchTabHash) : t(tRoot.listTabHash);

    // Google Tag Manager
    sendDataToGTM({
      event: "benefit_tab_open",
      event_description: toSearch ? "Selaa osakasetujasi" : "Tutustu osakasetuihisi",
    });

    history.push(hash);
  };

  const initState = getInitState();

  const requestBenefitsPageData = async () => {
    dispatch(requestBenefitsPageDataAction(user));
  };

  const benefitsPageData = benefitsPageState.benefitsPageData;
  const benefitsPageLoading = benefitsPageState.loading;

  const tabsAnchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (benefitsPageLoading === "idle") {
      requestBenefitsPageData();
    }
  }, [requestBenefitsPageData, benefitsPageLoading]);

  useEffect(() => {
    setShowSearch(
      location.hash === t(tRoot.searchTabHash) || initState?.activeTab === t(tRoot.searchTabHash)
    );
  }, [location.hash]);

  useEffect(() => {
    if (
      initState !== undefined &&
      initState.scrollPosition === "tabs" &&
      benefitsPageLoading === "success"
    ) {
      let scrollPos = 0;
      if (tabsAnchorRef.current) {
        scrollPos =
          tabsAnchorRef.current?.offsetTop - tabsAnchorRef.current?.getBoundingClientRect().height;
      }

      window.scrollTo({
        top: scrollPos,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [benefitsPageLoading]);

  return {
    t,
    benefitsPageData,
    benefitsPageLoading,
    showSearch,
    tabsAnchorRef,
    initState,
    handleTabChange,
  };
};

export default useBenefits;
