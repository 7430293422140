import Signup from "./signup";
import InputAdapter from "../profile/input-adapter";
import {MyProfileDto} from "../../app/OwnerService-api";
import {MyProfileData, ProfileSectionsType} from "../profile";

export default Signup;

export class SignupDto {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export type SignupSectionsType = {
  signup: boolean;
  continue: boolean;
};

export type CustomSignupKey = "firstName" | "lastName" | "email";
export type SignupDtoKey = keyof SignupDto;
export type SignupKey = SignupDtoKey | CustomSignupKey;
export type SignupData = Partial<SignupDtoKey & Record<CustomSignupKey, string>>;

export type SignupField = {
  label: string;
  validate?: (value: string) => string | undefined;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  type?: string;
  AdapterComponent?: typeof InputAdapter;
  placeholder?: string;
  infoText?: string;
  successText?: string;
  showErrorsWhenActive?: boolean;
  inputId?: string;
  format?: (value: string) => string;
};

export type SignupDataFields = Partial<Record<SignupKey, SignupField>>;

export type SignupFormProps = {
  profileData: SignupDto;
  isEditable: boolean;
  editMode: boolean;
  onEditModeChange: () => void;
  commonOnSubmit: (
    newData: SignupData,
    editMode: boolean,
    formKey: keyof SignupSectionsType
  ) => void;
  isSubmitting: boolean;
  formKey: keyof SignupSectionsType;
};

export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);