import React from "react";
import HcInput from "../../hc-input";
import { Feedback } from "../../input-feedback";
import { FieldRenderProps } from "react-final-form";

const InputAdapter = ({
  input,
  meta,
  label,
  readOnly,
  disabled,
  infoText,
  successText,
  inputId,
  ...inputProps
}: FieldRenderProps<string, HTMLInputElement>): JSX.Element => {
  const getFeedback = (): Feedback | undefined => {
    if (successText) {
      return {
        isValid: true,
        text: successText,
      };
    }
    if (meta.error !== undefined && meta.error.length > 0) {
      return {
        isValid: false,
        text: meta.error,
      };
    }
    return infoText
      ? {
          text: infoText,
        }
      : undefined;
  };

  const isDisabled = disabled !== undefined ? disabled : false;
  const isReadOnly = readOnly !== undefined ? readOnly && !isDisabled : false;

  return (
    <HcInput
      {...input}
      {...inputProps}
      onChange={(value) => input.onChange(value)}
      onPaste={(value) => {
        const val = value.clipboardData.getData("text");
        input.onChange(val);
      }}
      label={label}
      feedback={getFeedback()}
      disabled={isDisabled}
      readOnly={isReadOnly}
      id={inputId}
    />
  );
};

export default InputAdapter;
