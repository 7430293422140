import React from "react";
import { Field, Form } from "react-final-form";
import FormGroupContainer from "../../form-group-container";
import Row from "../../flex-row";
import Col from "../../flex-col";
import {
  SignupData,
  SignupDataFields,
  SignupSectionsType,
} from "..";
import { Decorator, FormApi, ValidationErrors } from "final-form";
import { arrayEquals } from "../../../features/utils";
import useSignupForm from "./use-signup-form";
import FormGroupInput from "../../profile/form-group/form-group-input";
import createDecorator from "final-form-focus";
import FocusTrap from "focus-trap-react";
import { LoginDataType } from "../signup-details/signup-details";
import {saveButtonRowStyle} from "../../profile";
import HcButton from "../../hc-button";

type SignupFormProps = {
  isInEditMode: boolean;
  toggleIsInEditMode: () => void;
  onSubmit: (data: SignupData) => void;
  onCancel: () => void;
  dataRows: SignupData[];
  dataFieldRows: SignupDataFields[];
  groupTitle: string;
  groupDescription?: string | JSX.Element;
  fullWidthField?: boolean;
  isSubmitting: boolean;
  validateForm?: (
    values: LoginDataType,
    validationErrors: ValidationErrors
  ) => ValidationErrors | undefined;
  resetAfterSubmit?: boolean;
};

const focusOnError = createDecorator() as Decorator;

const SignupForm = ({
                     onSubmit,
                     onCancel, 
                     dataRows,
                     groupTitle,
                     dataFieldRows,
                     isInEditMode,
                     toggleIsInEditMode,
                     groupDescription,
                     fullWidthField = true,
                     isSubmitting,
                     validateForm,
                     resetAfterSubmit,
                   }: SignupFormProps): JSX.Element => {
  const getData = () => {
    return dataRows.reduce(
      (result, data) => ({
        ...result,
        ...data,
      }),
      {} as SignupData
    );
  };

  const isValidData = () => {
    if (dataRows.length !== dataFieldRows.length) {
      throw new Error(
        `Length of rows are invalid:\ndataRows length: ${dataRows.length}\ndataFieldRows length: ${dataFieldRows.length}`
      );
    }

    dataRows.forEach((data, index) => {
      const dataKeys = Object.keys(data);
      const dataFieldKeys = Object.keys(dataFieldRows[index]);

      if (!arrayEquals(dataKeys, dataFieldKeys)) {
        throw new Error(
          `Keys of data and dataFields do not match\nKeys of data row ${index}: ${dataKeys}\nKeys of dataField row ${index}: ${dataFieldKeys}`
        );
      }
    });
  };
  isValidData();

  const { validationErrors, setValidationErrors, t } = useSignupForm();

  const onValidate = (
    value: string,
    fieldName: string,
    validation?: (value: string) => string | undefined
  ): string | undefined => {
    if (validation) {
      const message = validation(value);
      const newErrors = validationErrors;

      newErrors[fieldName] = message;
      setValidationErrors(newErrors);

      return message;
    }
  };

  const handleEditButtonPress = (
    e: React.MouseEvent,
    formData: FormApi<SignupData, Partial<SignupData>>
  ) => {
    e.preventDefault();

    if (isInEditMode) {
      formData.reset(getData());
    }

    toggleIsInEditMode();
  };

  return (
    <FocusTrap active={isInEditMode}>
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={getData()}
          decorators={[focusOnError]}
          validate={validateForm ? (values) => validateForm(values, validationErrors) : undefined}
          render={({ handleSubmit, form, submitting, valid }) => (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (valid) {
                  handleSubmit(event);
                  if (resetAfterSubmit) {
                    form.reset();
                  }
                }
              }}
            >
              <FormGroupContainer isEditable={isInEditMode} isSubmitting={isSubmitting}>
                {dataFieldRows.map((dataFields, rowIndex) => (
                  <Row key={`form-row-${rowIndex}`}>
                    {Object.entries(dataFields)
                      .map(([name, fields]) =>
                        fields ? (
                          <Col
                            m={fullWidthField ? 12 : 6}
                            l={fullWidthField ? 12 : 6}
                            key={`form-input-${name}`}
                          >
                            <Field
                              name={name}
                              label={fields.label}
                              readOnly={!isInEditMode}
                              disabled={fields.isDisabled && isInEditMode}
                              type={fields.type}
                              validate={(value) => onValidate(value, name, fields.validate)}
                              format={fields.format}
                            >
                              {(fieldRendererProps) => (
                                <FormGroupInput
                                  fieldRendererProps={fieldRendererProps}
                                  fields={fields}
                                  infoText={fields.infoText}
                                  successText={fields.successText}
                                  inputId={fields.inputId || name}
                                  isSubmitting={isSubmitting}
                                  showErrorWhenActive={fields.showErrorsWhenActive}
                                />
                              )}
                            </Field>
                          </Col>
                        ) : undefined
                      )
                      .filter((val) => val !== undefined)}
                  </Row>
                ))}

                <Row style={saveButtonRowStyle}>
                  <Col m={fullWidthField ? 12 : 6} l={fullWidthField ? 12 : 6} align="center">
                    <HcButton type="submit">Jatka</HcButton>
                    <HcButton onClick={onCancel} isSecondary={true}>Takaisin</HcButton>
                  </Col>
                </Row>

              </FormGroupContainer>
            </form>
          )}
        />
      </div>
    </FocusTrap>
  );
};

export default SignupForm;
