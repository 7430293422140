import React from "react";
import { IContentEditorialBlockDto } from "../../../app/OwnerService-api";
import Col from "../../flex-col";
import HcHeading from "../../hc-heading";
import parse from "html-react-parser";
import { convertOptions } from "../../../features/utils";
import "./editorial-block.scss";

type EditorialBlockProps = {
  data: IContentEditorialBlockDto;
  className?: string;
};

const EditorialBlock = ({ data, className }: EditorialBlockProps): JSX.Element => {
  const { title, content } = data;

  let parsedContent: string | JSX.Element | JSX.Element[] = <></>;
  if (content !== undefined) {
    parsedContent = parse(content, convertOptions);
  }

  return (
    <Col
      s={12}
      m={12}
      l={6}
      className={`epi-editorial-block-content ${className !== undefined ? className : ""}`}
    >
      <div>
        <HcHeading semanticElement="h4">{title}</HcHeading>
      </div>
      <div>{parsedContent}</div>
    </Col>
  );
};

export default EditorialBlock;
