import React from "react";
import { useTypedTranslation, lang } from "../../features/i18n";
import HcCheckbox from "../hc-checkbox";

import BasicMember from "./../../images/hc-logo-burgundy.svg";
import GoldMember from "./../../images/gold-192x72.svg";
import VillasMember from "./../../images/villas-192x36.svg";
import { MembershipLevel } from "../../app/OwnerService-api";
import AspectRatioWrapper from "../aspect-ratio-wrapper";
import "./membership-card.scss";

export type CardProps = {
  membershipLevel?: MembershipLevel;
  memberNumber?: string;
  isStackSpace?: boolean;
  editMode?: boolean;
  editModeDisabled?: boolean;
  onMembershipCardSelect?: (e: React.ChangeEvent<HTMLInputElement>, cardId: string) => void;
};

const MembershipCard = ({
  memberNumber = "1234567890",
  membershipLevel = MembershipLevel.Basic,
  isStackSpace,
  editMode = false,
  editModeDisabled = false,
  onMembershipCardSelect,
}: CardProps): JSX.Element => {
  const { t } = useTypedTranslation();
  const tRoot = lang;

  let logoContent = BasicMember;
  switch (membershipLevel) {
    case MembershipLevel.Gold:
      logoContent = GoldMember;
      break;
    case MembershipLevel.Villas:
      logoContent = VillasMember;
      break;
  }

  const membershipLvlStr = MembershipLevel[membershipLevel].toLowerCase();
  const fontColorClass = membershipLevel === MembershipLevel.Gold ? "gold" : "";

  let content = (
    <div
      className={`membership-card-container membership-${membershipLvlStr} ${
        isStackSpace && "stacked"
      }`}
      data-testid={`membershipcard-${membershipLevel}`}
    >
      <AspectRatioWrapper aspectRatio="8:5">
        {editMode && onMembershipCardSelect !== undefined && (
          <HcCheckbox
            ariaLabel="Select membershipcard"
            className="membership-card-cb"
            defaultChecked={true}
            disabled={editModeDisabled}
            onChange={(e) => onMembershipCardSelect(e, memberNumber)}
          />
        )}
        <div className={`membership-card-content ${membershipLvlStr}`}>
          <div className={`membership-card-title ${fontColorClass}`}>
            {t(tRoot.membershipCard.cardTitle)}
          </div>
          <div className={`membership-card-logo-container ${membershipLvlStr}`}>
            <img src={logoContent} alt="Jäsenyyskortti" />
          </div>
          {membershipLevel === MembershipLevel.Gold && (
            <>
              <div className={`membership-number-title ${fontColorClass}`}>
                {t(tRoot.membershipCard.cardNumberTitle)}
              </div>
              <div className={`membership-number ${fontColorClass}`}>{memberNumber}</div>
            </>
          )}
        </div>
      </AspectRatioWrapper>
    </div>
  );

  if (editMode) {
    content = <label>{content}</label>;
  }

  return content;
};

export default MembershipCard;
