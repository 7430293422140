import { LoadingState } from "..";
import cardOrderSlice, { createCardOrder } from "./card-order-slice";

export type CardOrderState = {
  loading: LoadingState;
};

export { createCardOrder };

export default cardOrderSlice.reducer;
