import { useEffect, useState, useCallback } from "react";
import { HoldingReserved } from "..";
import { useAppDispatch } from "../../../store";
import { openRentForm } from "../../../store/home";
import { selectClickedDate } from "../../../store/calendar";
import { useSelector } from "react-redux";
import {
  CalendarDto,
  HoldingReservationDto,
  ICalendarDto,
  ReservationType,
} from "../../../app/OwnerService-api";
import { customToISOString } from "../../../features/utils";
import { isSameDayOrBefore } from "../utils";
import { isSameDay } from "date-fns";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useReservationWeeks = (holding: HoldingReserved) => {
  const dispatch = useAppDispatch();
  const [selectedWeek, setSelectedWeek] = useState<CalendarDto | undefined>(undefined);
  const [holdingReservations, setHoldingReservations] = useState<HoldingReservationDto[]>([]);
  const [isRented, setIsRented] = useState<boolean>(false);

  const clickedDate = useSelector(selectClickedDate);

  /**
   * Get selectedWeek by clickedDate
   */
  const getClickedWeek = useCallback(() => {
    return (
      clickedDate &&
      holding &&
      holding.weeks &&
      holding.weeks.find(
        (week) =>
          isSameDayOrBefore(new Date(week.weekStart), clickedDate) &&
          isSameDayOrBefore(clickedDate, new Date(week.weekEnd))
      )
    );
  }, [holding, clickedDate]);

  /**
   * Find selected week and open rent form modal
   */
  const openRentFormModal = () => {
    dispatch(
      openRentForm({
        holdingUnit: holding.unitId,
        reservations: holdingReservations,
        week: selectedWeek as ICalendarDto,
        additionalInformation: "",
      })
    );
  };

  // When clickedDate or holding changes
  useEffect(() => {
    // Update selectedWeek
    const selectedWeek = clickedDate ? getClickedWeek() : holding.weeks && holding.weeks[0];
    setSelectedWeek(selectedWeek);

    // Update holdingReservations
    const reservationData: HoldingReservationDto[] = holding.reservations.map((reservation) => {
      return {
        holdingId: holding.holdingId,
        holdingUnitId: holding.unitId,
        startDate: customToISOString(reservation.startDate),
        endDate: customToISOString(reservation.endDate),
        type: reservation.type,
        weekType: holding.weekType,
        status: reservation.reservationStatus,
        reservationId: reservation.reservationId || "",
        reservationDate: customToISOString(reservation.reservationDate),
      } as HoldingReservationDto;
    });
    setHoldingReservations(reservationData);
  }, [clickedDate, holding, getClickedWeek]);

  // When selectedWeek changes
  useEffect(() => {
    if (selectedWeek) {
      // Check if week is rented
      const rented = holding.reservations.some((r) => {
        return (
          isSameDay(r.startDate, new Date(selectedWeek.weekStart)) &&
          isSameDay(r.endDate, new Date(selectedWeek.weekEnd)) &&
          r.type === ReservationType.ForRental
        );
      });
      setIsRented(rented);
    }
  }, [selectedWeek, holding.reservations]);

  return {
    openRentFormModal,
    selectedWeek,
    isRented,
  };
};

export default useReservationWeeks;
