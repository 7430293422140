import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/authorization";
import { HoldingReservationDto } from "../../../app/OwnerService-api";

import { LoadingState, useAppDispatch } from "../../../store";
import {
  selectReservationsState,
  getReservations as getReservationsAction,
} from "../../../store/reservations";

const useReservations = (): {
  reservations: HoldingReservationDto[];
  reservationsLoading: LoadingState;
} => {
  const [reservations, setReservations] = useState<HoldingReservationDto[]>([]);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  /**
   * Get reservationsData from redux store
   */
  const reservationsState = useSelector(selectReservationsState);

  /**
   * If reservationsData loading state is idle, get reservations from db
   */
  useEffect(() => {
    const reservationsUpdated = () =>
      JSON.stringify(reservations) !==
      JSON.stringify(reservationsState.reservationsData.reservations);

    if (reservationsState.reservationsData.loading === "idle") {
      dispatch(getReservationsAction(user));
    }
    if (reservationsState.reservationsData.loading === "success" && reservationsUpdated()) {
      setReservations(reservationsState.reservationsData.reservations);
    }
  }, [
    reservationsState.reservationsData.loading,
    reservationsState.reservationsData.reservations,
    reservations,
    dispatch,
    user,
  ]);

  return {
    reservations,
    reservationsLoading: reservationsState.reservationsData.loading,
  };
};

export default useReservations;
