import React from "react";

type Props = {
  isValid: boolean;
};

const FeedbackIcon = ({ isValid }: Props): JSX.Element => {
  const validIconPath = "valid-input-icon.svg";
  const invalidIconPath = "invalid-input-icon.svg";

  return (
    <img src={`${process.env.PUBLIC_URL}/${isValid ? validIconPath : invalidIconPath}`} alt="" />
  );
};

export default FeedbackIcon;
