import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
  selectIsModalOpen,
  selectActiveModalId,
} from "../../store/modal";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useReduxModal = (id: string, onClose?: () => void) => {
  const dispatch = useAppDispatch();
  const isOpen = useSelector(selectIsModalOpen);
  const activeModalId = useSelector(selectActiveModalId);

  const openModal = (modalId: string) => {
    const currentId = id.length === 0 ? modalId : id;
    dispatch(openModalAction(currentId));
  };

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
    dispatch(closeModalAction());
  };

  return {
    isOpen,
    activeModalId,
    openModal,
    closeModal,
  };
};

export default useReduxModal;
