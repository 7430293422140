import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import {
  checkInvite as checkInviteAction,
  selectAuthState,
  selectUser,
} from "../../../store/authorization";
import { useHistory } from "react-router-dom";

const useInviteStatus = (): void => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthState);
  const user = useSelector(selectUser);

  const isAuthLoading = authState.loading;
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const token = searchParams.get("usertoken");

  useEffect(() => {
    if (!user) {
      if (isAuthLoading === "idle" && token) {
        dispatch(checkInviteAction(token));
      }
    } else {
      history.replace("/");
    }
  }, [user, isAuthLoading, history, token, dispatch]);
};

export default useInviteStatus;
