import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAppDispatch } from "../../../store";
import { closeModal } from "../../../store/modal";
import { closeRentForm } from "../../../store/home";
import { lang } from "../../../features/i18n";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useApp = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tRoot = lang.title;
  const { t } = useTypedTranslation();

  const closeModals = () => {
    dispatch(closeModal());
    dispatch(closeRentForm());
    document.documentElement.classList.remove("is-locked");
  };

  useEffect(() => {
    closeModals();

    switch (location.pathname) {
      case "/omat-tiedot": {
        document.title = t(tRoot.profile);
        return;
      }
      case "/osakasedut": {
        document.title = t(tRoot.benefits);
        return;
      }
      case "/invite": {
        document.title = t(tRoot.invite);
        return;
      }
      default:
        document.title = t(tRoot.home);
    }
  }, [location]);
};

export default useApp;
