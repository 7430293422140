import React from "react";
import cx from "classnames";
import "./overlay.scss";

type OverlayProps = {
  children: React.ReactNode;
  fadeIn?: boolean;
};

const Overlay = ({ fadeIn, children }: OverlayProps): JSX.Element => {
  const overlayClasses = cx("overlay-wrapper", {
    "fade-in": fadeIn,
  });

  return <div className={overlayClasses}>{children}</div>;
};

export default Overlay;
