import React from "react";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import DateRangePicker from "../../date-range";
import { DateRangeType, ThemedDateRange } from "../../calendar";
import useRangeSelectVillas from "./use-range-select-villas";
import { TypedTranslate } from "../../../features/i18n";
import { lang } from "../../../features/i18n";
import { ReservationInfo } from "..";

type Props = {
  resortName: string;
  initialRangeData?: ThemedDateRange[] | undefined;
  latestRange: DateRangeType | undefined;
  initUsingLatest: boolean;
  addCurrentRangeToSelections: (data: DateRangeType) => void;
  translate: TypedTranslate;
  clickedDate: Date | undefined;
  reservations: ReservationInfo[];
} & WizardStep;

const SelectRangeVillas = ({
  nextStep,
  previousStep,
  resortName,
  initialRangeData,
  latestRange,
  initUsingLatest,
  addCurrentRangeToSelections,
  translate: t,
  clickedDate,
  reservations,
}: Props): JSX.Element => {
  const { selectedRange, onRangeChange, resetSelectedRange } = useRangeSelectVillas(
    latestRange,
    initUsingLatest
  );
  const tRoot = lang.home.holdings.villas;

  /**
   * Date to initialise the calendar with, meaning which month is showing. Date is not selected in the date picker
   */
  const initDate = initUsingLatest && latestRange ? latestRange.start : clickedDate;

  /**
   * If called with range param, add range to selections,
   * else if selected range is not undefined, add it to selections.
   * @param range
   */
  const handleToConfirm = (range?: DateRangeType) => {
    if (range) {
      addCurrentRangeToSelections(range);
    } else if (selectedRange !== undefined) {
      addCurrentRangeToSelections(selectedRange);
    }
    nextStep();
  };

  return (
    <>
      <HcText weight="strong" bottomSpace="l" topSpace="l">
        {t(tRoot.infoReservationHolding, { resortName: resortName })}
      </HcText>
      <div>
        <DateRangePicker
          initUsingDate={initDate}
          onRangeSelect={onRangeChange}
          initialData={initialRangeData}
          handleToConfirm={handleToConfirm}
          resetCurrentRange={resetSelectedRange}
          reservations={reservations}
        />
      </div>
      <HcButton
        theme="dark"
        disabled={selectedRange === undefined ? true : false}
        onClick={() => handleToConfirm()}
      >
        {t(tRoot.toConfirmStep)}
      </HcButton>
      <HcButton onClick={() => previousStep()} isSecondary>
        {t(tRoot.back)}
      </HcButton>
    </>
  );
};

export default SelectRangeVillas;
