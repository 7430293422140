import React from "react";
import {
  FormGroupProps,
  MyProfileKey,
  MyProfileData,
  MyProfileDataFields,
  MyProfileDtoKey,
} from "..";
import FormGroup from "../form-group";
import { IMyProfileDto, MyProfileDto } from "../../../app/OwnerService-api";
import { lang } from "../../../features/i18n";
import useAddressDetails from "./use-address-details";
import { prepareData as commonPrepareData } from "../profile-utils";
import { invalidCharsRegex, composeValidators } from "..";

export type AddressDetails = Pick<
  Required<IMyProfileDto>,
  "address" | "postcode" | "city" | "country"
>;

type AddressDetailsProps = FormGroupProps;

const AddressDetails = ({
  editMode,
  onEditModeChange,
  isEditable,
  profileData,
  commonOnSubmit,
  isSubmitting,
  formKey,
}: AddressDetailsProps): JSX.Element => {
  const { t } = useAddressDetails();

  const required = (value: string) => (value ? undefined : t(lang.requiredField));
  const invalidChars = (value: string) =>
    value.match(invalidCharsRegex) === null ? undefined : t(lang.invalidChars);

  const selectedFields: MyProfileDtoKey[] = ["address", "city", "postcode", "country"];
  const tRoot = lang.profile.addressDetails;

  const prepareData = (data: MyProfileDto): MyProfileData[] => {
    const prepared: MyProfileData = commonPrepareData(data, selectedFields);

    return [prepared];
  };

  const prepareDataFields = (): MyProfileDataFields[] => {
    const getLabel = (fieldName: MyProfileKey): string => {
      type TField = keyof typeof tRoot;

      return t(tRoot[fieldName as TField]);
    };

    const prepared: MyProfileDataFields = selectedFields.reduce(
      (result, fieldName) => ({
        ...result,
        [fieldName]: {
          label: getLabel(fieldName),
          validate: composeValidators(required, invalidChars),
        },
      }),
      {} as MyProfileDataFields
    );

    return [prepared];
  };

  const onSubmit = (newData: MyProfileData) => {
    commonOnSubmit(newData, editMode, formKey);
  };

  return (
    <FormGroup
      isEditable={isEditable}
      toggleIsInEditMode={onEditModeChange}
      dataRows={prepareData(profileData)}
      dataFieldRows={prepareDataFields()}
      groupTitle={t(tRoot.title)}
      onSubmit={onSubmit}
      isInEditMode={editMode}
      isSubmitting={isSubmitting}
    />
  );
};

export default AddressDetails;
