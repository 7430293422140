import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useFormGroup = () => {
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );

  const { t } = useTypedTranslation();

  return {
    validationErrors,
    setValidationErrors,
    t,
  };
};

export default useFormGroup;
