import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsFirstLoginTooltipVisible, hideFirstLoginTooltip } from "../../../store/home";
import { useAppDispatch } from "../../../store";
import ReactTooltip from "react-tooltip";
import { useTypedTranslation } from "../../../features/i18n";
import { useHistory } from "react-router-dom";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useFirstLoginBadge = () => {
  const [refElement, setRefElement] = useState<HTMLSpanElement | null>(null);
  const showFirstLoginTooltip = useSelector(selectIsFirstLoginTooltipVisible);
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  function hideFirstLoginTooltipAction() {
    dispatch(hideFirstLoginTooltip());
  }

  // Show tooltip if
  // refElement is found and
  // the user has not clicked the tooltip to hide it
  useEffect(() => {
    if (refElement && showFirstLoginTooltip) {
      ReactTooltip.show(refElement);
    }
  }, [refElement]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleProfileLinkClick = () => {
    history.push("omat-tiedot");
    scrollToTop();
  };

  return {
    refElement,
    setRefElement,
    hideFirstLoginTooltipAction,
    t,
    handleProfileLinkClick,
  };
};

export default useFirstLoginBadge;
