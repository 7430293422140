import React from "react";
import useLoginStatus from "./hooks/use-login-status";
import useLanding from "./hooks/use-landing";
import HcButton from "../hc-button";
import HcHeading from "../hc-heading";
import ResponsiveImage from "../responsive-img";
import BackgroundImage from "../../images/sample-content/login-bg.jpg";
import CardContainer from "../card-container";
import HcText from "../hc-text";
import "./landing.scss";

const Landing = (): JSX.Element => {
  const { handleLogin, handleRegister } = useLanding();

  useLoginStatus();

  const content = (
    <div className="landing-content-container">
      <CardContainer className="landing-login-container">
        <HcHeading semanticElement="h1">Tervetuloa osakkaiden verkkopalveluun</HcHeading>
        <HcText size="l" className="landing-title-text" bottomSpace="l">
          Parasta aikaa oman lomaviikon omistajille.
        </HcText>
        <HcButton onClick={() => handleLogin()}>Kirjaudu</HcButton>
        <HcButton onClick={() => handleRegister()}>Luo tunnukset</HcButton>
      </CardContainer>
      <CardContainer className="landing-info-container">
        <HcHeading semanticElement="h2">Palvelussa voit:</HcHeading>
        <ul>
          <li>Tarkastella ja käyttää sinulle kuuluvia osakasetuja</li>
          <li>Nähdä omistukseesi liittyvät tiedot</li>
          <li>Asettaa oman viikkosi vuokralle tai varata viikon omaan käyttöön</li>
          <li>Tilata uuden mobiiliosakaskortin heti käyttöön</li>
          <li>Hallinnoida omia tietojasi</li>
        </ul>
      </CardContainer>
    </div>
  );

  return content;
};

export default Landing;
