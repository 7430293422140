import React from "react";

import { DropdownMenuProps } from ".";
import "./dropdown-menu.scss";
import useDropdownMenu from "./hooks";
import CardContainer from "../card-container";

const DropdownMenu = ({ referenceElement, menuItems, altAxis }: DropdownMenuProps): JSX.Element => {
  const dataShowAttr = "data-show";

  const {
    popperElement,
    popperStyles,
    popperAttributes,
    popperUpdate,
    windowSize,
    show,
    hide,
    setPopperElement,
    setArrowElement,
  } = useDropdownMenu(referenceElement, dataShowAttr, altAxis);

  React.useEffect(() => {
    if (popperUpdate) {
      popperUpdate();
    }
  }, [windowSize, popperUpdate]);

  React.useEffect(() => {
    function updatePopper() {
      if (popperUpdate) {
        popperUpdate();
      }
    }

    function toggleShow() {
      if (popperElement && popperElement.hasAttribute(dataShowAttr)) {
        hide();
      } else {
        show();
        updatePopper();
      }
    }

    function handleReferenceClick() {
      toggleShow();
    }

    referenceElement?.addEventListener("click", handleReferenceClick);

    return () => {
      referenceElement?.removeEventListener("click", handleReferenceClick);
    };
  }, [referenceElement, popperElement, popperUpdate, show, hide, dataShowAttr]);

  const rootClassName = "dropdown-menu";
  const arrowClassName = `${rootClassName}__arrow`;

  const handleButtonClick = (action: () => void) => {
    action();
    hide();
  };

  return (
    <CardContainer
      ref={setPopperElement}
      style={popperStyles.popper}
      {...popperAttributes.popper}
      className={rootClassName}
      role="tooltip"
      data-testid="dropdown-menu"
    >
      {menuItems.map(({ name, action, hasBottomDivider, hasBadge }, index) => {
        const buttonCssClass = hasBottomDivider === true ? "menu-divider" : "";
        return (
          <button
            onClick={() => handleButtonClick(action)}
            key={`dropdown-menu-item-${index}`}
            className={buttonCssClass}
          >
            {name}
            {hasBadge === true && <span className="menu-item-badge" />}
          </button>
        );
      })}

      <div
        ref={setArrowElement}
        style={popperStyles.arrow}
        className={arrowClassName}
        {...popperAttributes.arrow}
      />
    </CardContainer>
  );
};

export default DropdownMenu;
