import { useMemo } from "react";
import { HoldingReserved, Wizards } from "..";
import { ReservationStatus, WeekType } from "../../../app/OwnerService-api";
import { isPastDate } from "../../date-range/utils";
import { ContractLengthType, getContractLength, isReservation } from "../utils";

const useOverlayContent = (
  getWizards: (holding: HoldingReserved | undefined) => Wizards,
  activeHolding: HoldingReserved | undefined,
  clickedDate: Date | undefined,
  isFirstLogin: boolean
): (() => JSX.Element) => {
  const OverlayContent = useMemo(() => {
    /**
     * @param {HoldingReserved} holding - holding
     * @returns {boolean} true if holding has a week or reservation with start date in the past
     */
    const isFirstOngoing = (holding: HoldingReserved) => {
      const ongoingReservation = holding.reservations.some((r) => isPastDate(r.startDate));
      const ongoingWeek =
        holding.weeks && holding.weeks.some((w) => isPastDate(new Date(w.weekStart)));
      return ongoingReservation || ongoingWeek;
    };

    /**
     * @param {HoldingReserved} holding - holding
     * @param {Date} clickedDate - clickedDate
     * @returns {boolean} true if starting date of clicked range is in the past
     */
    const isOngoingClicked = (holding: HoldingReserved, clickedDate: Date) => {
      const ongoingReservation = holding.reservations.some(
        (r) => isPastDate(r.startDate) && isReservation(clickedDate, r.startDate, r.endDate)
      );
      const ongoingWeek =
        holding.weeks &&
        holding.weeks.some(
          (w) =>
            isPastDate(new Date(w.weekStart)) &&
            isReservation(clickedDate, new Date(w.weekStart), new Date(w.weekEnd))
        );
      return ongoingReservation || ongoingWeek;
    };

    /**
     * @param {HoldingReserved} holding
     * @returns {boolean} true if holding has some reservation
     */
    const isHoldingReserved = (holding: HoldingReserved): boolean => {
      return (
        (holding.weekType === WeekType.VillasFullOwnership ||
          holding.weekType === WeekType.VillasFractional) &&
        holding.reservations.some(
          (reservation) =>
            reservation.reservationStatus === ReservationStatus.Accepted ||
            reservation.reservationStatus === ReservationStatus.Pending
        )
      );
    };

    /**
     * @param {HoldingReserved} holding
     * @param {Date} clickedDate
     * @returns {boolean} true if holding has reservation at clicked date
     */
    const isReservationClicked = (holding: HoldingReserved, clickedDate: Date): boolean => {
      return holding.reservations.some(
        (reservation) =>
          isReservation(clickedDate, reservation.startDate, reservation.endDate) &&
          (reservation.reservationStatus === ReservationStatus.Accepted ||
            reservation.reservationStatus === ReservationStatus.Pending)
      );
    };

    /**
     * Get overlay component by holding week type
     * @param {Wizards} wizards - possible components
     * @param {HoldingReserved} holding - holding
     * @returns React component
     */
    const getOverlayComponent = (
      wizards: Wizards,
      holding: HoldingReserved
    ): (() => JSX.Element) => {
      if (holding.weekType) {
        const wizard = wizards.byWeekType[holding.weekType];

        return wizard ? wizard : wizards.default;
      }

      return wizards.fallback;
    };

    /**
     * Get overlay component for initial holding:
     * if holding has reservation(s) get cancellation overlay,
     * else get overlay by week type.
     * @param {Wizards} wizards - possible components
     * @param {HoldingReserved} holding - holding
     * @returns React component
     *
     */
    const getOverlayComponentFirstHolding = (
      wizards: Wizards,
      holding: HoldingReserved
    ): (() => JSX.Element) => {
      const contractLength = getContractLength(holding.contractDate);
      if (
        contractLength === ContractLengthType.OwnerNew ||
        contractLength === ContractLengthType.Owner2MonthsToYear ||
        isFirstLogin
      ) {
        return wizards.newCustomer;
      }
      if (isFirstOngoing(holding)) {
        return wizards.ongoing;
      }
      if (isHoldingReserved(holding)) {
        return wizards.cancellation;
      }

      return getOverlayComponent(wizards, holding);
    };

    /**
     * Get overlay for clicked holding:
     * If holding has reservation on clicked date, get cancellation overlay,
     * else get overlay by week type.
     * @param {Wizards} wizards - possible components
     * @param {HoldingReserved} holding - holding
     * @param {Date} clickedDate - clicked date
     * @returns React component
     */
    const getOverlayComponentClickedHolding = (
      wizards: Wizards,
      holding: HoldingReserved,
      clickedDate: Date
    ): (() => JSX.Element) => {
      if (isOngoingClicked(holding, clickedDate)) {
        return wizards.ongoing;
      }
      if (
        (holding.weekType === WeekType.VillasFractional ||
          holding.weekType === WeekType.VillasFullOwnership) &&
        isReservationClicked(holding, clickedDate)
      ) {
        return wizards.cancellation;
      }

      return getOverlayComponent(wizards, holding);
    };

    if (activeHolding && clickedDate) {
      // date on a holding time-slot was clicked
      return getOverlayComponentClickedHolding(
        getWizards(activeHolding),
        activeHolding,
        clickedDate
      );
    } else if (activeHolding) {
      // no click event have occured
      return getOverlayComponentFirstHolding(getWizards(activeHolding), activeHolding);
    } else {
      // something went wrong or there is no holdings
      return getWizards(undefined).fallback;
    }
  }, [activeHolding, clickedDate, isFirstLogin, getWizards]);

  return OverlayContent;
};

export default useOverlayContent;
