import { useState } from "react";
import { IMembershipCardDto, MembershipLevel } from "../../../app/OwnerService-api";
import { useTypedTranslation } from "../../../features/i18n";
import useCardOrder from "./use-card-order";
import useCardSelect from "./use-card-select";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useMembershipCards = (cards: IMembershipCardDto[]) => {
  const { t } = useTypedTranslation();
  const [editMode, setEditMode] = useState(false);

  const { selectedCards, onMembershipCardSelect, orderDisabled, setOrderDisabled } = useCardSelect(
    cards
  );
  const { activeOrder, changeActiveOrder, createCardOrders } = useCardOrder(
    selectedCards.filter((card) => card.selected)
  );

  // Hide Basic level card if you have Gold or Villas card
  const visibleCards = cards.filter((card) => {
    if (card.membershipLevel === MembershipLevel.Basic) {
      return (
        cards.findIndex(
          (c) =>
            c.membershipLevel === MembershipLevel.Gold ||
            c.membershipLevel === MembershipLevel.Villas
        ) < 0
      );
    }

    return true;
  });

  const isManyCards = visibleCards.length > 1;

  const cancelOrder = () => {
    setEditMode(false);
    changeActiveOrder("no-order");
    setOrderDisabled(false);
  };

  return {
    visibleCards,
    isManyCards,
    editMode,
    orderDisabled,
    setEditMode,
    t,
    onMembershipCardSelect,
    activeOrder,
    changeActiveOrder,
    selectedCards,
    createCardOrders,
    cancelOrder,
  };
};

export default useMembershipCards;
