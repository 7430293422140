import { ImageAsset } from "../../../app/OwnerService-api";
import { ImageDataType } from ".";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useHomeHeader = (imgData: ImageAsset | undefined) => {
  let parsedImgData: ImageDataType | undefined = undefined;
  let imgSrcSet = "";

  if (
    imgData !== undefined &&
    imgData.sources !== undefined &&
    "large" in imgData.sources &&
    imgData.sources.large.uri !== undefined
  ) {
    const defaultImgUri = imgData.sources.large.uri;

    const data: ImageDataType = {
      alt: imgData.description !== undefined ? imgData.description : "",
      large: defaultImgUri,
      medium: imgData.sources.medium.uri !== undefined ? imgData.sources.medium.uri : defaultImgUri,
      small: imgData.sources.small.uri !== undefined ? imgData.sources.small.uri : defaultImgUri,
    };
    parsedImgData = data;

    for (const key of Object.keys(imgData.sources).reverse()) {
      imgSrcSet += `${imgData.sources[key].uri} ${imgData.sources[key].width}w, `;
    }
    imgSrcSet = imgSrcSet.slice(0, -2);
  }

  return {
    parsedImgData,
    imgSrcSet,
  };
};

export default useHomeHeader;
