import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import useSelections from "./use-selections";
import useVillasCalendarData from "./use-villas-calendar-data";
import useVillasReservations from "./use-villas-reservations";
import { useAppDispatch } from "../../../store";
import { closeReservationProcess as closeReservationProcessAction } from "../../../store/reservations";
import { useSelector } from "react-redux";
import { selectClickedDate } from "../../../store/calendar";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useReservationVillas = (closeOverlay: () => void) => {
  const { t } = useTypedTranslation();
  const clickedDate = useSelector(selectClickedDate);

  const [stepIndex, setStepIndex] = useState(clickedDate ? 1 : 0);
  const [isLastMinute, setIsLastMinute] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { reservations, ...reservationHooks } = useVillasReservations();

  const { calendarInitialData } = useVillasCalendarData(reservations);

  const initialStepIndex = clickedDate ? 1 : 0;

  /**
   * Set reservation process state as closed in redux:
   * when state is open confirmation is required before closing overlay.
   * If initial step index is 0 go to first step, else close overlay
   */
  const closeReservationProcess = () => {
    dispatch(closeReservationProcessAction());

    if (initialStepIndex === 0) {
      setStepIndex(0);
    } else {
      closeOverlay();
    }
  };

  return {
    t,
    stepIndex,
    setStepIndex,
    ...reservationHooks,
    calendarInitialData,
    reservations,
    ...useSelections(),
    isLastMinute,
    setIsLastMinute,
    closeReservationProcess,
    clickedDate,
  };
};

export default useReservationVillas;
