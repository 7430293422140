import { useSelector } from "react-redux";
import { useTypedTranslation } from "../../../features/i18n";
import { HoldingDto } from "../../../app/OwnerService-api";
import useGTM from "../../../features/use-gtm";
import { selectSelectedHolding } from "../../../store/calendar-overlay";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useHoldingsDetails = (data: HoldingDto[]) => {
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();

  const selectedHolding = useSelector(selectSelectedHolding);

  // to set active holding tab in holdings details section
  let activeId;
  if (selectedHolding && selectedHolding !== "SHOW_ALL") {
    activeId = data.findIndex(
      (holding) =>
        holding.unitId === selectedHolding.unitId &&
        holding.weekNumber === selectedHolding.weekNumber
    );
  }

  /**
   * Send data to GTM
   * @param {string} tabHeader
   */
  const onTabSelect = (tabHeader: string) => {
    sendDataToGTM({ event: "property_view", event_description: tabHeader });
  };

  return {
    t,
    activeId,
    onTabSelect,
  };
};

export default useHoldingsDetails;
