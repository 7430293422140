import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { closeReservationProcess as closeReservationProcessAction } from "../../../store/reservations";

const useInfoLastMinuteCancellation = (): void => {
  const dispatch = useAppDispatch();

  /* Close reservation process in case where info is shown at the end of reservation flow */
  useEffect(() => {
    dispatch(closeReservationProcessAction());
  }, [dispatch]);
};

export default useInfoLastMinuteCancellation;
