import React from "react";
import cx from "classnames";
import { ReservationInfo } from "..";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import Col from "../../flex-col";
import Row from "../../flex-row";
import { TypedTranslate } from "../../../features/i18n";
import { lang } from "../../../features/i18n";
import HcCheckbox from "../../hc-checkbox";
import { formatDateRangeStr } from "../utils";
import useConfirmVillas from "./use-confirm-villas";

type Props = {
  selectedReservations: ReservationInfo[];
  translate: TypedTranslate;
  parentClassName: string;
  isLastMinute: boolean;
} & WizardStep;

const Confirm = ({
  nextStep,
  previousStep,
  selectedReservations,
  translate: t,
  parentClassName,
  isLastMinute,
}: Props): JSX.Element => {
  const tRoot = lang.home.holdings;
  const className = cx("no-p-lr", `${parentClassName}__step--confirm`);
  const selectionsClassName = `${className}__selections`;
  const selectionClassName = `${selectionsClassName}__selection`;

  const { handleNextStep, isConfirmChecked, setIsConfirmCheched } = useConfirmVillas(nextStep);

  /**
   * Check if some of the selected reservations starts in less than 31 days
   * to show the right confirm button text
   */
  const confirmButtonText = isLastMinute
    ? t(tRoot.lastMinuteFrac.requestBooking)
    : t(tRoot.villas.sendReservation);

  return (
    <Col className={className}>
      <Row className={selectionsClassName}>
        <HcText weight="strong">{t(tRoot.villas.confirmStep.sendReservationMsg)}</HcText>

        {selectedReservations.map((reservation, index) => (
          <div key={`reservation-confirmation-${index}`} className={selectionClassName}>
            <HcText size="s">
              {formatDateRangeStr(reservation.startDate, reservation.endDate)}
            </HcText>
          </div>
        ))}
      </Row>
      <Row>
        <HcCheckbox
          ariaLabel={t(tRoot.villas.confirmCheckboxLabel)}
          checked={isConfirmChecked}
          onChange={() => setIsConfirmCheched((prev) => !prev)}
        >
          <HcText size="s" bottomSpace="0" topSpace="m">
            {t(tRoot.reserved.confirmAcceptTerms)}
          </HcText>
        </HcCheckbox>
        <HcButton onClick={handleNextStep} disabled={!isConfirmChecked} theme="dark">
          {t(confirmButtonText)}
        </HcButton>
        <HcButton onClick={previousStep} isSecondary>
          {t(tRoot.villas.back)}
        </HcButton>
      </Row>
    </Col>
  );
};

export default Confirm;
