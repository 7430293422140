import { useState } from "react";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useConfirmCancellation = (nextStep: () => void) => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const { sendDataToGTM } = useGTM();

  const toggleIsTermsAccepted = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  /**
   * Send data to GTM and move to next step
   */
  const handleConfirmCancellation = () => {
    // Google Tag Manager
    sendDataToGTM({
      event: "cancel_booking_complete",
      event_description: "Poista varaus",
    });
    nextStep();
  };

  return {
    isTermsAccepted,
    toggleIsTermsAccepted,
    handleConfirmCancellation,
  };
};

export default useConfirmCancellation;
