import React from "react";
import { EpiGuideBlockProps } from ".";
import FlexContainer from "../flex-container";
import Row from "../flex-row";
import Col from "../flex-col";
import HcHeading from "../hc-heading";
import cx from "classnames";
import parse from "html-react-parser";
import VideoPlayer from "../video-player";
import { convertOptions } from "../../features/utils";
import "./epi-guide-block.scss";

const EpiGuideBlock = ({ data, className }: EpiGuideBlockProps): JSX.Element => {
  const { title, content, videoUrl } = data;
  const contentCssClasses = cx("content-base");

  let parsedContent: string | JSX.Element | JSX.Element[] = <></>;
  if (content !== undefined) {
    parsedContent = parse(content, convertOptions);
  }

  return (
    <div className="guide-block-wrapper epi-block">
      <FlexContainer
        className={`epi-guide-block-container orderable-block ${
          className !== undefined ? className : ""
        }`}
      >
        <Row>
          <Col>
            <HcHeading semanticElement="h4">{title}</HcHeading>
          </Col>
        </Row>
        <Row className="guide-block-content">
          <Col l={6} className="orderable-block-text-content">
            <div className={contentCssClasses}>{parsedContent}</div>
          </Col>
          <Col s={12} m={12} l={6} lOffset={0} className="orderable-block-other-content">
            <VideoPlayer aspectRatio="3:2" videoUrl={videoUrl} />
          </Col>
        </Row>
      </FlexContainer>
    </div>
  );
};

export default EpiGuideBlock;
