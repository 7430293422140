import { useState, useEffect } from "react";
import { getCalendarData } from "./../utils";
import { getLocalization, getMonthTitle } from "./../calendar-localization";
import { CalendarType, CalendarHook, ThemedDateRange, CalendarDayType } from "..";
import { VisibleDateRangeType } from "../../holdings-calendar/hooks/use-holdings-calendar";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useCalendar = (
  type: CalendarType,
  rangeData: ThemedDateRange[] | undefined,
  initUsingDate: Date | undefined,
  clickedDate?: Date | undefined,
  setVisibleDateRange?: (value: VisibleDateRangeType) => void
): CalendarHook => {
  const initYear =
    initUsingDate === undefined ? new Date().getFullYear() : initUsingDate.getFullYear();
  const initMonth = initUsingDate === undefined ? new Date().getMonth() : initUsingDate.getMonth();

  const [activeYear, setActiveYear] = useState(initYear);
  const [activeMonth, setActiveMonth] = useState(initMonth);

  const localizedData = getLocalization(type);

  const { prev, current, next } = getMonthTitle(initMonth, localizedData);
  const [activeMonthTitle, setActiveMonthTitle] = useState(current);
  const [prevMonthTitle, setPrevMonthTitle] = useState(prev);
  const [nextMonthTitle, setNextMonthTitle] = useState(next);

  const localizeDayNames = localizedData.dayNamesShort;

  const [calendarData, setCalendarData] = useState<CalendarDayType[] | undefined>(undefined);

  /**
   * Set calendar data when initially rendered
   * Warning is silenced since this hook runs only once, so we don't need to update dependencies
   */
  useEffect(() => {
    setCalendarData(getCalendarData(initYear, initMonth, type, rangeData));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // Update data when active month or range data changes
  useEffect(() => {
    const { prev, current, next } = getMonthTitle(activeMonth, localizedData);
    // set prev & next month nav title
    setPrevMonthTitle(prev);
    setNextMonthTitle(next);

    // set current month title
    setActiveMonthTitle(current);

    // set calendar data
    setCalendarData(getCalendarData(activeYear, activeMonth, type, rangeData));
  }, [activeMonth, activeYear, rangeData, type, localizedData]);

  // Update calendar view when range data changes
  useEffect(() => {
    if (
      rangeData !== undefined &&
      initUsingDate === undefined &&
      rangeData.length > 0 &&
      rangeData[0].data.start !== undefined
    ) {
      const first = rangeData.reduce((first, reservation) =>
        first.data.start !== undefined && first.data.start < reservation.data.start
          ? first
          : reservation
      );
      setActiveMonth(first.data.start.getMonth());
      setActiveYear(first.data.start.getFullYear());
    }
  }, [rangeData, initUsingDate]);

  // Update active month and year by initDate if defined
  useEffect(() => {
    if (initUsingDate !== undefined) {
      setActiveMonth(initUsingDate.getMonth());
      setActiveYear(initUsingDate.getFullYear());
    }
  }, [initUsingDate]);

  // Update active month and year by clickedDate if set
  useEffect(() => {
    if (clickedDate !== undefined) {
      setActiveMonth(clickedDate.getMonth());
      setActiveYear(clickedDate.getFullYear());
    }
  }, [clickedDate]);

  // If setFirstDate and setLastDate are defined, call them when calendarData changes
  useEffect(() => {
    if (setVisibleDateRange && calendarData) {
      const firstDate = calendarData[0].dayItem;
      const lastDate = calendarData[calendarData.length - 1].dayItem;
      setVisibleDateRange({ firstDate, lastDate });
    }
  }, [calendarData, setVisibleDateRange]);

  /**
   * Set previous month as the active month. Update year if necessary
   */
  const handlePrevMonth = () => {
    if (activeMonth > 0) {
      setActiveMonth((prevState) => prevState - 1);
    } else {
      setActiveMonth(11);
      setActiveYear((prevState) => prevState - 1);
    }
  };

  /**
   * Set next month as the active month. Update year if necessary
   */
  const handleNextMonth = () => {
    if (activeMonth < 11) {
      setActiveMonth((prevState) => prevState + 1);
    } else {
      setActiveMonth(0);
      setActiveYear((prevState) => prevState + 1);
    }
  };

  return {
    activeYear,
    activeMonth,
    activeMonthTitle,
    prevMonthTitle,
    nextMonthTitle,
    localizeDayNames,
    calendarData,
    handlePrevMonth,
    handleNextMonth,
  };
};

export default useCalendar;
