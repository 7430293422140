import useGTM from "../../../features/use-gtm";
import { useTypedTranslation, lang } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useRentedWeekOverlay = () => {
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();
  const tRoot = lang.rentFormModal;

  /**
   * Open customer service link in a new tab
   */
  const handleCustomerServiceClick = () => {
    // Google tag manager
    sendDataToGTM({
      event: "telephone_link_click",
      event_description: t(lang.general.customerServicePhone),
    });
    window.open(t(tRoot.rentedCustomerServiceLink), "_blank");
  };

  return { t, handleCustomerServiceClick };
};

export default useRentedWeekOverlay;
