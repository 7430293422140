import { LoadingState, RootState } from "..";
import { ContentDto, ContentOwnerBenefitDto } from "../../app/OwnerService-api";
import benefitsPageSlice, { requestBenefitsPageData } from "./benefits-slice";

export type BenefitsPageState = {
  benefitsPageData: {
    intro: ContentDto | undefined;
    content: ContentDto[] | undefined;
    benefits: ContentOwnerBenefitDto[] | undefined;
  };
  loading: LoadingState;
};

const selectBenefitsPageState = (state: RootState): BenefitsPageState => state.benefitsPageData;

export { requestBenefitsPageData, selectBenefitsPageState };
export default benefitsPageSlice.reducer;
