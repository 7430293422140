import { ContentOwnerBenefitDto } from "../../../app/OwnerService-api";
import useGTM from "../../../features/use-gtm";
import useReduxModal from "../../redux-modal/use-redux-modal";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBenefitCard = (modalId: string, data: ContentOwnerBenefitDto) => {
  const { openModal } = useReduxModal(modalId);
  const { sendDataToGTM } = useGTM();

  const handleCardClick = () => {
    sendDataToGTM({
      event: "benefit_modal_store_click",
      event_description: `${data.category}: ${data.title}`,
    });

    openModal("");
  };

  return {
    handleCardClick,
  };
};

export default useBenefitCard;
