import React from "react";
import { format, isToday } from "date-fns";
import HcText from "./../../hc-text";
import { ColorStyle } from "./../../hc-text";
import { HighlightColorType, HighlightType } from "..";
import cx from "classnames";

import "./calendar-day.scss";

type CalendarDayProps = {
  day: Date;
  dayTextStyle: ColorStyle;
  highlightColor: HighlightColorType;
  highlightType: HighlightType;

  /**
   * Show hover style for day item
   */
  showHover: boolean;

  /**
   * When hovering day item
   */
  onDayHover?: (day: Date, isActive: boolean) => void | undefined;

  /**
   * When clicking day item
   */
  onDaySelect?: (day: Date, isActive: boolean) => void | undefined;

  /**
   * Is previous day highlightType "none"
   */
  prevIsNone?: boolean;

  /**
   * Is next day highlightType "none"
   */
  nextIsNone?: boolean;
};

const CalendarDay = ({
  day,
  dayTextStyle,
  highlightColor = "none",
  highlightType = "none",
  showHover,
  onDaySelect,
  onDayHover,
  prevIsNone,
  nextIsNone,
}: CalendarDayProps): JSX.Element => {
  const dataDateTime = format(day, "yyyy-MM-dd");
  const dataDay = format(day, "d");

  /**
   * Handle day click
   */
  const handleClick = () => {
    if (onDaySelect !== undefined) {
      const isActive = highlightColor !== "none";

      onDaySelect(day, isActive);
    }
  };

  /**
   * Handle day hover
   */
  const handleHover = () => {
    if (onDayHover !== undefined) {
      const isActive = highlightColor !== "none";
      onDayHover(day, isActive);
    }
  };

  /**
   * Handle day focus
   * @param {Date} day - focused day item
   */
  const handleFocus = (day: Date) => {
    if (onDayHover !== undefined) {
      const isActive = highlightColor !== "none";
      onDayHover(day, isActive);
    }
  };

  /**
   * If reservations are overlapping and the day is the start or the end of the topmost range,
   * this class is used to show the color of the range below,
   * else this class is used to style the range
   */
  const dateBtnClasses = cx("day-item", {
    "hover-mode": showHover && highlightColor !== "white-selection",
    "br-l-50":
      highlightType === "range-start" ||
      (prevIsNone && highlightType.startsWith("overlapping-range-start")),
    "br-r-50":
      highlightType === "range-end" ||
      (nextIsNone && highlightType.startsWith("overlapping-range-end")),
    "br-50":
      highlightType === "range-start-end" ||
      (prevIsNone && nextIsNone && highlightType.startsWith("overlapping-range-one-day")),
    "theme-primary": highlightColor === "primary" && !highlightType.startsWith("overlapping-range-"),
    "theme-white": highlightColor === "white" && !highlightType.startsWith("overlapping-range-"),
    "theme-black": highlightColor === "black" && !highlightType.startsWith("overlapping-range-"),
    "bg-primary": highlightColor === "primary" && highlightType.startsWith("overlapping-range-"),
    "bg-white": highlightColor === "white" && highlightType.startsWith("overlapping-range-"),
    "bg-black": highlightColor === "black" && highlightType.startsWith("overlapping-range-"),
    selection: highlightColor === "white-selection",
    "overlapping-bg-end": highlightType.startsWith("overlapping-range-end"),
    "overlapping-bg-start": highlightType.startsWith("overlapping-range-start"),
    "overlapping-bg-one-day": highlightType.startsWith("overlapping-range-one-day"),
  });

  /**
   * If reservations are overlapping and the day is the start or the end of the topmost range,
   * this class is used to style the range
   */
  const overlappingRangeClasses = cx({
    "overlapping-end-day": highlightType.startsWith("overlapping-range-end"),
    "overlapping-start-day": highlightType.startsWith("overlapping-range-start"),
    "overlapping-one-day": highlightType.startsWith("overlapping-range-one-day"),
    "theme-primary":
      highlightType === "overlapping-range-start-primary" ||
      highlightType === "overlapping-range-end-primary" ||
      highlightType === "overlapping-range-one-day-primary",
    "theme-black":
      highlightType === "overlapping-range-start-black" ||
      highlightType === "overlapping-range-end-black" ||
      highlightType === "overlapping-range-one-day-black",
    "theme-white":
      highlightType === "overlapping-range-start-unconfirmed" ||
      highlightType === "overlapping-range-end-unconfirmed" ||
      highlightType === "overlapping-range-one-day-unconfirmed",
    "current-day br-50": isToday(day),
  });

  // Selection class
  const hlClass = cx("day-wrapper", {
    "selection-wrapper-hl":
      highlightColor === "white-selection" &&
      (highlightType === "range-start" ||
        highlightType === "range-start-end" ||
        highlightType === "range-end" ||
        highlightType.startsWith("overlapping-range-start") ||
        highlightType.startsWith("overlapping-range-one-day") ||
        highlightType.startsWith("overlapping-range-end")),
  });

  return (
    <button
      className={dateBtnClasses}
      onClick={handleClick}
      onMouseOver={handleHover}
      onFocus={() => handleFocus(day)}
      type="button"
      data-testid={highlightColor === "none" ? "normal" : "reserved"}
    >
      <div className={overlappingRangeClasses} data-date-time={dataDateTime}>
        <div className={hlClass}>
          <HcText tag="span" colorVariant={dayTextStyle} size="s">
            {dataDay}
          </HcText>
        </div>
      </div>
    </button>
  );
};

export default CalendarDay;
