import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReduxReservationsState, SentCancellationState } from ".";
import {
  IReserveHoldingDto,
  ReserveHoldingDto,
  HoldingReservationsClient,
} from "../../app/OwnerService-api";
import { FetchManager, IUser } from "../../features/authorization";
import { toast } from "react-toastify";
import { getToastContent } from "../toast-translations";

const initialState: ReduxReservationsState = {
  reservationsData: {
    loading: "idle",
    reservations: [],
  },
  sentReservations: {},
  sentCancellations: {},
  reservationProcessOpen: false,
};

export const getReservations = createAsyncThunk(
  "reservations/getReservations",
  async (requestor: IUser | undefined) => {
    const client = new HoldingReservationsClient(undefined, new FetchManager(requestor));

    const response = await client.get();

    return response;
  }
);

const extraGetReservationsReducers = (builder: ActionReducerMapBuilder<ReduxReservationsState>) => {
  builder.addCase(getReservations.pending, (state) => {
    state.reservationsData.loading = "pending";
  });

  builder.addCase(getReservations.fulfilled, (state, action) => {
    state.reservationsData.loading = "success";
    state.reservationsData.reservations = action.payload.map((reservation) => reservation.toJSON());
  });

  builder.addCase(getReservations.rejected, (state, action) => {
    state.reservationsData.loading = "error";

    console.error(JSON.stringify(action.error, null, 4));
  });
};

type ReservationRequest = {
  requestor: IUser | undefined;
} & IReserveHoldingDto;

export const reserveHolding = createAsyncThunk(
  "reservations/makeReservation",
  async ({
    requestor,
    holdingId,
    contractId,
    holdingUnitId,
    weekNumber,
    weekType,
    startDate,
    endDate,
    type,
    additionalInformation,
  }: ReservationRequest) => {
    const client = new HoldingReservationsClient(undefined, new FetchManager(requestor));

    const response = await client.makeReservation(
      new ReserveHoldingDto({
        holdingId,
        contractId,
        holdingUnitId,
        weekNumber,
        weekType,
        startDate,
        endDate,
        type,
        additionalInformation,
      })
    );

    return response;
  }
);

const extraReserveHoldingReducers = (builder: ActionReducerMapBuilder<ReduxReservationsState>) => {
  builder.addCase(reserveHolding.pending, (state, action) => {
    const request = action.meta.arg;

    const sentReservation = new ReserveHoldingDto({
      holdingId: request.holdingId,
      contractId: request.contractId,
      holdingUnitId: request.holdingUnitId,
      weekNumber: request.weekNumber,
      weekType: request.weekType,
      startDate: request.startDate,
      endDate: request.endDate,
      type: request.type,
    });

    state.sentReservations[action.meta.requestId] = {
      loading: "pending",
      reservation: sentReservation.toJSON(),
    };
  });

  builder.addCase(reserveHolding.fulfilled, (state, action) => {
    state.sentReservations[action.meta.requestId].loading = "success";
    state.reservationsData.loading = "idle";
    toast.success(getToastContent(true));
  });

  builder.addCase(reserveHolding.rejected, (state, action) => {
    state.sentReservations[action.meta.requestId].loading = "error";
    state.sentReservations[action.meta.requestId].error = JSON.stringify(action.error);

    console.error(JSON.stringify(action.error, null, 4));
    toast.error(getToastContent(false));
  });
};

type CancellationRequest = {
  requestor: IUser | undefined;
  reservationId: string;
};

export const cancelHoldingReservation = createAsyncThunk(
  "reservations/cancelReservation",
  async ({ requestor, reservationId }: CancellationRequest) => {
    const client = new HoldingReservationsClient(undefined, new FetchManager(requestor));

    const response = await client.cancelReservation(reservationId);

    return response;
  }
);

const extraCancelHoldingReservationReducers = (
  builder: ActionReducerMapBuilder<ReduxReservationsState>
) => {
  const isAnyPending = (cancellations: SentCancellationState[]) => {
    return cancellations.some((cancellation) => cancellation.loading === "pending");
  };

  builder.addCase(cancelHoldingReservation.pending, (state, action) => {
    state.sentCancellations[action.meta.arg.reservationId] = {
      loading: "pending",
    };
  });

  builder.addCase(cancelHoldingReservation.fulfilled, (state, action) => {
    state.sentCancellations[action.meta.arg.reservationId] = {
      loading: "success",
    };

    if (!isAnyPending(Object.values(state.sentCancellations))) {
      state.reservationsData.loading = "idle";
    }

    toast.success(getToastContent(true));
  });

  builder.addCase(cancelHoldingReservation.rejected, (state, action) => {
    state.sentCancellations[action.meta.arg.reservationId] = {
      loading: "error",
      error: JSON.stringify(action.error),
    };

    console.error(JSON.stringify(action.error, null, 4));

    if (!isAnyPending(Object.values(state.sentCancellations))) {
      state.reservationsData.loading = "idle";
    }

    toast.error(getToastContent(false));
  });
};

const reservationsSlice = createSlice({
  initialState,
  name: "reservations",
  reducers: {
    openReservationProcess: (state) => {
      state.reservationProcessOpen = true;
    },
    closeReservationProcess: (state) => {
      state.reservationProcessOpen = false;
    },
  },
  extraReducers: (builder) => {
    extraGetReservationsReducers(builder);
    extraReserveHoldingReducers(builder);
    extraCancelHoldingReservationReducers(builder);
  },
});

export default reservationsSlice;
