import {
  isAfter,
  isBefore,
  isSameDay,
  getDaysInMonth,
  format,
  differenceInDays,
  isPast,
} from "date-fns";

/**
 * Checks if given date is kind of valid. So it has three parts and all parts
 * contains only numbers. Year must be 4 characters long.
 *
 * @param {Date} date - date to validate
 * @returns {boolean} true if date has the correct amount of characters that are numbers
 */
const isValidFormat = (date: string): boolean => {
  const dateArray = date.split(".");

  const isValidLength = dateArray.length === 3 ? true : false;
  const isValidInput =
    isValidLength &&
    Number.isInteger(parseInt(dateArray[0])) &&
    Number.isInteger(parseInt(dateArray[1])) &&
    Number.isInteger(parseInt(dateArray[2])) &&
    dateArray[2].length === 4
      ? true
      : false;

  return isValidInput;
};

/**
 * Check if date is in the past
 *
 * @param {Date} date - in Date format
 * @returns {boolean} true if date is in the past
 */
export const isPastDate = (date: Date): boolean => {
  return isPast(date.setHours(23, 59, 59, 999));
};

/**
 * Check if date is valid. It checks some of cases (not all).
 * It uses isValidFormat.
 *
 * @param {Date} date - in string format
 * @returns {boolean} true if date is valid
 */
export const isValidDate = (dateStr: string): boolean => {
  const dateArray = dateStr.split(".");

  const isInValidFormat = isValidFormat(dateStr);
  const isValidInput =
    isInValidFormat &&
    parseInt(dateArray[0]) >= 1 &&
    parseInt(dateArray[1]) >= 1 &&
    parseInt(dateArray[2]) >= new Date().getFullYear();
  return isValidInput;
};

/**
 * Check if given dates are in correct order
 *
 * @param {Date} start in date format
 * @param {Date} end in date isValidFormat
 * @returns {boolean} true if start is before or the same day as end
 */
export const isInCorrectOrder = (start?: Date, end?: Date): boolean => {
  return start !== undefined && end !== undefined && (isSameDay(start, end) || isAfter(end, start))
    ? true
    : false;
};

/**
 * Check if given date range is valid
 *
 * @param {string} start in string format
 * @param {string} end in string format
 * @returns {boolean} true if start and end are valid dates and start is before or same day as end
 */
export const isRangeValid = (start: string, end: string): boolean => {
  const isValidStart = isValidDate(start);
  const isValidEnd = isValidDate(end);

  const startD = isValidStart ? new Date(strToDate(start)) : undefined;
  const endD = isValidEnd ? new Date(strToDate(end)) : undefined;

  return isInCorrectOrder(startD, endD);
};

/**
 * Swap dates if start is after end
 *
 * @param {Date} end
 * @param {Date} start
 * @returns {string[]} array of date strings
 */
export const swapDates = (start: Date, end: Date): string[] => {
  const result: string[] = [];

  if (isSameDay(start, end) || isBefore(start, end)) {
    result[0] = format(start, "dd.MM.yyyy");
    result[1] = format(end, "dd.MM.yyyy");
  }
  if (isAfter(start, end)) {
    result[0] = format(end, "dd.MM.yyyy");
    result[1] = format(start, "dd.MM.yyyy");
  }

  return result;
};

/**
 * Converts given date string (12.12.2020) to correct Date format
 *
 * @param {string} dateStr
 * @returns {Date} date
 */
export const strToDate = (dateStr: string): Date => {
  const dateArray = dateStr.split(".").reverse();
  const year = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1]) - 1;
  const day = parseInt(dateArray[2]);

  return new Date(year, month, day);
};

/**
 * Adds leading zero to given date. If user types 1.1.2020 we return
 * 01.01.2020
 *
 * @param {string} dateStr
 * @returns {string} date string with leading zeros
 */
export const addLeadingZero = (dateStr: string): string => {
  if (isValidDate(dateStr)) {
    const dateArray = dateStr.split(".");

    if (parseInt(dateArray[0]) < 10 && dateArray[0].length === 1) {
      dateArray[0] = "0" + dateArray[0];
    }

    if (parseInt(dateArray[1]) < 10 && dateArray[1].length === 1) {
      dateArray[1] = "0" + dateArray[1];
    }

    return dateArray.join(".");
  } else {
    return dateStr;
  }
};

/**
 * Fix incorrect dates like 32.1.2020 to max values of that month etc.
 *
 * @param {string} dateStr
 * @returns {string} fixed date string
 */
export const fixIncorrectDate = (dateStr: string): string => {
  if (isValidDate(dateStr)) {
    const dateArray = dateStr.split(".");
    const year = parseInt(dateArray[2]);
    const month = parseInt(dateArray[1]) - 1;
    const day = parseInt(dateArray[0]);
    const daysInMonth = getDaysInMonth(new Date(year, month));

    if (day > daysInMonth) {
      dateArray[0] = daysInMonth.toString();
    }
    if (parseInt(dateArray[1]) > 12) {
      dateArray[1] = "12";
    }

    return dateArray.join(".");
  } else {
    return dateStr;
  }
};

/**
 * @param {Date} day
 * @param {Date} start
 * @returns {number} amount of days from given day to start
 */
export const daysBeforeRangeStart = (day: Date, start: Date): number => {
  return differenceInDays(start, day) + 1;
};
