import React from "react";
import Col from "../../flex-col";
import Row from "../../flex-row";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { lang } from "../../../features/i18n";
import { WizardStep } from "../../wizard-container";

import useSelectBankAccount from "./use-select-bank-account";
import AccountNumber from "../../account-number";

import "./select-bank-account.scss";

type Props = {
  contractId: number;
} & WizardStep;

const SelectBankAccount = ({ contractId, nextStep, previousStep }: Props): JSX.Element => {
  const {
    t,
    isConfirmed,
    dataLoading,
    bankAccount,
    handleBankAccountChange,
    handleNextStep,
  } = useSelectBankAccount(nextStep, contractId);
  const tRoot = lang.rentFormModal;

  const classNameRoot = "select-bank-account";
  const classNameContainer = `${classNameRoot}__container no-side-padding`;

  return (
    <Row className={classNameRoot}>
      <Col className={classNameContainer}>
        <Row className="confirm-step-body">
          <Col className="no-side-padding">
            <HcText weight="strong" bottomSpace="s">
              {t(tRoot.selectBankAccount.title)}
            </HcText>
            {dataLoading === "success" ? (
              <AccountNumber
                account={bankAccount}
                label={t(tRoot.selectBankAccount.bankAccountLabel)}
                name="bankAccount"
                onChange={handleBankAccountChange}
              />
            ) : (
              <>{t(tRoot.selectBankAccount.loadingBankAccounts)}</>
            )}
          </Col>
        </Row>

        <Row className="step-action-row">
          <Col className="no-side-padding">
            <HcButton onClick={handleNextStep} disabled={!isConfirmed}>
              {t(tRoot.selectBankAccount.continue)}
            </HcButton>
            <HcButton onClick={previousStep} isSecondary>
              {t(tRoot.back)}
            </HcButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectBankAccount;
