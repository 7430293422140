import * as React from "react";

import { SignupFormProps, SignupData, SignupDto, SignupKey, SignupDataFields, SignupSectionsType } from ".."
import useSignupDetails from "./use-signup-details";
import { lang } from "../../../features/i18n";
import SignupForm from "../signup-form";
import { ValidationErrors } from "final-form";

type SignupDetailsProps = {
  submitRegistration: (values: any) => void;
  cancelRegistration: () => void;
} & Omit<SignupFormProps, "commonOnSubmit">;

export type LoginDataType = Pick<SignupData, "firstName" | "lastName" | "email">;

const SignupDetails = ({
                        editMode,
                        onEditModeChange,
                        isEditable,
                        profileData,
                        submitRegistration,
                        cancelRegistration,
                        isSubmitting,
                        formKey,
                      }: SignupDetailsProps): JSX.Element => {

  const { t } = useSignupDetails();
  const tRoot = lang.profile.loginDetails;

  const required = (value: string) => (value ? undefined : t(lang.requiredField));

  const getLabel = (fieldName: SignupKey): string => {
    type TField = keyof typeof tRoot;
    return t(tRoot[fieldName as TField]);
  };

  const placeholderText = t(tRoot.placeholderText);

  const prepareData = (): SignupData[] => {
    const firstRow: SignupData = {
      firstName: ""
    } as SignupData;
    const secondRow: SignupData = {
      lastName: ""
    } as SignupData;
    const thirdRow: SignupData = {
      email: ""
    } as SignupData;
    return [firstRow, secondRow, thirdRow];
  };

  const prepareDataFields = (): SignupDataFields[] => {
    const firstRow: SignupDataFields = {
      firstName: {
        label: getLabel("firstName"),
        type: "text",
        validate: required,
        placeholder: placeholderText,
      },
    };
    const secondRow: SignupDataFields = {
      lastName: {
        label: getLabel("lastName"),
        type: "text",
        validate: required,
        placeholder: placeholderText,
      },
    };
    const thirdRow: SignupDataFields = {
      email: {
        label: getLabel("email"),
        type: "text",
        validate: required,
        inputId: "login-email",
      },
    }
    
    return [firstRow, secondRow, thirdRow];
  };

  const onSubmit = (newData: SignupData) => {
    if (newData.firstName && newData.lastName && newData.email) {
      submitRegistration(newData);
    }
  };
  
  const validateForm = (values: LoginDataType, validationErrors: ValidationErrors) => {
    const errors: SignupData = {};
    if (!values.firstName ||
      values.firstName.length > 25 ||
      validationErrors.firstName
    ) {
      errors.firstName = t(tRoot.firstName);
    }

    if (!values.lastName ||
      values.lastName.length > 25 ||
      validationErrors.lastName
    ) {
      errors.lastName = t(tRoot.lastName);
    }

    if (!values.email ||
      values.email.length > 50 ||
      validationErrors.email
    ) {
      errors.email = t(tRoot.email);
    }
    
    return errors;
  };

  return (
    <SignupForm
      toggleIsInEditMode={onEditModeChange}
      dataRows={prepareData()}
      dataFieldRows={prepareDataFields()}
      onSubmit={onSubmit}
      onCancel={cancelRegistration}
      isInEditMode={editMode}
      isSubmitting={isSubmitting}
      groupTitle={t(tRoot.title)}
      validateForm={validateForm}
      resetAfterSubmit={false}
    />
  );
};

export default SignupDetails;
