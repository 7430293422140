import { Route } from "react-router-dom";
import Error404 from "../../components/error-404";
import React from "react";
import { RouteWrapperProps } from "..";

/*
 * Not found route is not protected
 */
const NotFoundRoute = (props: RouteWrapperProps): JSX.Element => (
  <Route {...props} component={Error404} />
);

export default NotFoundRoute;
