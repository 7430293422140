import React from "react";
import Row from "../../flex-row";
import Col from "../../flex-col";
import { Trans } from "react-i18next";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcText from "../../hc-text";
import HcButton from "../../hc-button";
import { saveButtonRowStyle, passwordSaveButtonRowStyle } from "..";

type FormGroupSubmitButtonProps = {
  isLoginFormGroup: boolean;
  fullWidthField: boolean;
  translate: TypedTranslate;
};

const FormGroupSubmitButton = ({
  isLoginFormGroup,
  fullWidthField,
  translate: t,
}: FormGroupSubmitButtonProps): JSX.Element => {
  const tRoot = lang.profile.loginDetails;
  return isLoginFormGroup ? (
    <>
      <Row>
        <Col>
          <HcText topSpace="m">{t(tRoot.description)}</HcText>
        </Col>
      </Row>
      <Row style={passwordSaveButtonRowStyle}>
        <Col m={fullWidthField ? 12 : 6} l={fullWidthField ? 12 : 6}>
          <div>
            <Trans i18nKey="profile.loginDetails.passwordRequirements">
              <HcText bottomSpace="xs" size="m">
                - Vähintään 8, enintään 64 merkkiä pitkä
              </HcText>
              <HcText bottomSpace="xs" size="m">
                - Vähintään 1 iso kirjain
              </HcText>
              <HcText bottomSpace="xs" size="m">
                - Vähintään 1 pieni kirjain
              </HcText>
              <HcText bottomSpace="xs" size="m">
                - Vähintään 1 numero tai symbooli
              </HcText>
            </Trans>
          </div>
        </Col>
        <Col
          m={fullWidthField ? 12 : 6}
          l={fullWidthField ? 12 : 4}
          className="login-details-form-button"
        >
          <HcButton type="submit">{t(tRoot.setPassword)}</HcButton>
        </Col>
      </Row>
    </>
  ) : (
    <Row style={saveButtonRowStyle}>
      <Col m={fullWidthField ? 12 : 6} l={fullWidthField ? 12 : 4} lOffset={fullWidthField ? 0 : 8}>
        <HcButton type="submit">{t(lang.saveChangesButton)}</HcButton>
      </Col>
    </Row>
  );
};

export default FormGroupSubmitButton;
