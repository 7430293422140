import React from "react";
import { HcInputProps } from ".";
import Input from "./input";

import "./hc-input.scss";
import InputWrapper from "./input-wrapper";

const HcInput = React.forwardRef<HTMLInputElement, HcInputProps>(
  (
    { id, label, className, inputClassName, feedback, ...inputProps }: HcInputProps,
    ref
  ): JSX.Element => {
    return (
      <InputWrapper inheritedClassName={className} feedback={feedback} label={label} inputId={id}>
        <Input
          id={id}
          inheritedClassName={inputClassName}
          feedback={feedback}
          ref={ref}
          {...inputProps}
        />
      </InputWrapper>
    );
  }
);

HcInput.displayName = "HcInput";
export default HcInput;
