import React from "react";
import HcHeading from "../hc-heading/hc-heading";
import HcText from "../hc-text/hc-text";
import HcButton from "../hc-button";
import ResponsiveImage from "../responsive-img/responsive-img";
import BackgroundImage from "../../images/sample-content/hero-1.png";
import CardContainer from "../card-container/card-container";
import useError500 from "./use-error-500";
import { lang } from "../../features/i18n";
import "./error-500.scss";

const Error500 = (): JSX.Element => {
  const { reloadPage, t } = useError500();
  const tRoot = lang.error500;
  return (
    <div className="error-bg-container">
      <ResponsiveImage alt={t(tRoot.altText)} src={BackgroundImage} className="error-background" />
      <div className="error-content-container">
        <CardContainer className="error-container">
          <HcHeading semanticElement="h1" className="error-header">
            {t(tRoot.title)}
          </HcHeading>
          <HcText size="l" className="error-text" bottomSpace="l">
            {t(tRoot.infoText)}
          </HcText>
          <HcButton onClick={reloadPage}> {t(tRoot.reloadPage)}</HcButton>
        </CardContainer>
      </div>
    </div>
  );
};

export default Error500;
