import React from "react";
import { lang } from "../../../features/i18n";
import useFallbackComponent from "./use-fallback-components";

const FallbackComponent = (): JSX.Element => {
  const tRoot = lang.protected;
  const t = useFallbackComponent();

  return <div>{t(tRoot.checkAuth)}</div>;
};

export default FallbackComponent;
