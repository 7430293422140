import React from "react";
import Layout from "../layout";

import "./app.scss";
import HomeRoute from "../../routes/home";
import ProfileRoute from "../../routes/profile";
import { Switch } from "react-router-dom";
import InviteRoute from "../../routes/invite";
import BenefitsRoute from "../../routes/benefits";
import i18n from "i18next";

import useApp from "./hooks";
import NotFoundRoute from "../../routes/notfound";
import LandingRoute from "../../routes/landing";
import SignupRoute from "../../routes/signup";

const App = (): JSX.Element => {
  useApp();
  document.documentElement.lang = i18n.language;

  return (
    <Layout>
      <Switch>
        <LandingRoute path="/" exact />
        <SignupRoute path="/rekisteroidy" exact />
        <HomeRoute path="/koti" />
        <ProfileRoute path="/omat-tiedot" />
        <BenefitsRoute path="/osakasedut" />
        <InviteRoute path="/invite" />
        <NotFoundRoute />
      </Switch>
    </Layout>
  );
};

export default App;
