import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { selectAuthState, selectUser } from "../../../store/authorization";
import { useHistory } from "react-router-dom";

const useLoginStatus = (): void => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthState);
  const user = useSelector(selectUser);
  const isAuthLoading = authState.loading;
  const history = useHistory();

  useEffect(() => {
    // redirect logged in user to home page
    // if (user && isAuthLoading === "idle") {
    //   history.replace("/koti");
    // }
    if (user) {
      history.replace("/koti");
    }
  }, [user, isAuthLoading, history, dispatch]);
};

export default useLoginStatus;
