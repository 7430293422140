import React from "react";
import { Trans } from "react-i18next";
import { lang } from "../../../features/i18n";
import HcInput from "../../hc-input";
import InfoIcon from "../../../images/info-icon-16.svg";
import useInfoRented from "./use-info-rented";
import Col from "../../flex-col";
import HcText from "../../hc-text";
import HcButton from "../../hc-button";

type InfoRentedProps = {
  resortName: string;
};

const InfoRented = ({ resortName }: InfoRentedProps): JSX.Element => {
  const { t, handleCustomerServiceClick } = useInfoRented();
  const tRoot = lang.rentFormModal;
  return (
    <>
      <Col className="no-side-padding">
        <HcText size="s" bottomSpace="m">
          <Trans i18nKey="home.rentFormModal.rentedMsg">
            Olet jättänyt kohteen{" "}
            <HcText size="s" weight="strong" tag="span">
              {resortName}
            </HcText>{" "}
            vuokralle. Voit tiedustella vuokrauksesi tilaa asiakaspalvelun numerosta:
          </Trans>
        </HcText>
      </Col>
      <Col className="no-side-padding">
        <HcInput
          value={t(lang.general.customerServicePhone)}
          readOnly={true}
          className="mb-xs"
          label=""
          aria-label={t(lang.general.customerServicePhone)}
        ></HcInput>
      </Col>
      <Col className="no-side-padding">
        <HcText size="xs" bottomSpace="xl" className="info-group">
          <img src={InfoIcon} alt="" />
          {t(lang.general.callPrice)}
        </HcText>
      </Col>
      <Col className="no-side-padding">
        <HcButton onClick={handleCustomerServiceClick}>
          {t(tRoot.rentedCustomerServiceBtn)}
        </HcButton>
      </Col>
    </>
  );
};

export default InfoRented;
