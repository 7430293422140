import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { openReservationProcess } from "../../../store/reservations";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useSelect = (
  initialSelectedReservations: string[],
  setWizardReservationSlots: (selected: string[]) => void,
  nextStep: () => void
) => {
  const [selectedReservationIds, setSelectedReservations] = useState<Set<string>>(
    new Set(initialSelectedReservations)
  );

  const dispatch = useAppDispatch();
  const { sendDataToGTM } = useGTM();

  const handleNextStep = (): void => {
    setWizardReservationSlots(Array.from(selectedReservationIds));
    // Google Tag Manager
    sendDataToGTM({
      event: "cancel_booking_confirm",
      event_description: "Vahvista valinta",
    });
    nextStep();
  };

  const isSelected = (reservationId: string): boolean => selectedReservationIds.has(reservationId);
  const toggleSelection = (reservationId: string): void => {
    const newState = new Set(selectedReservationIds);

    if (isSelected(reservationId)) {
      newState.delete(reservationId);
    } else {
      newState.add(reservationId);
    }

    setSelectedReservations(newState);
  };

  useEffect(() => {
    // Set reservation process active --> need to confirm closing
    dispatch(openReservationProcess());
  }, [dispatch]);

  return {
    selectedReservationIds,
    isSelected,
    toggleSelection,
    handleNextStep,
  };
};

export default useSelect;
