import React from "react";
import HcHeading from "../hc-heading";
import Row from "../flex-row";
import Col from "../flex-col";
import Banner from "../banner";
import { IContentListItemDto } from "../../app/OwnerService-api";
import "./banner-scroller.scss";

type BannerScrollerProps = {
  /**
   * Title for banner scroller. For example "Osakasedut"
   */
  title: string;

  /**
   * Show more link
   */
  showMoreLink?: React.ReactNode;

  /**
   * Array of banner data. Banner = nostokortti
   */
  bannerData: IContentListItemDto[];
  id: string;
};

const BannerScroller = ({
  title,
  showMoreLink,
  bannerData,
  id,
}: BannerScrollerProps): JSX.Element => {
  // Max number of items is 4
  const data = bannerData.length > 4 ? bannerData.slice(0, 4) : bannerData;

  return (
    <div className="banner-scroller" id={id}>
      <Row className="banner-scroller-title-wrapper">
        <Col s={6} m={6} l={8}>
          <HcHeading semanticElement="h2">
            {title}
          </HcHeading>
        </Col>
        {showMoreLink}
      </Row>
      <Row className="banner-row-wrapper">
        {data &&
          data.length > 0 &&
          data.map((item, i) => (
            <Col
              s={12}
              m={6}
              l={3}
              key={i + "col"}
              className={`${data.length > 1 ? "banner-col-custom-padding" : ""}`}
            >
              <Banner
                data={item}
                itemType={"changeType" in item ? "HolidayChange" : "OwnerBenefit"}
                modalId={title + "-" + i}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default BannerScroller;
