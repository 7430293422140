import React from "react";
import WizardContainer from "../../wizard-container";
import ConfirmMobileCardOrder from "./confirm-mobile-card-order";
import SendMobileCardOrder from "./send-mobile-card-order";
import { ActiveOrder, SelectedCards } from "../hooks";
import useMobileCard from "./use-mobile-card";
import { getReadOnlyPhoneNumber } from "../../profile/profile-utils";

type MobileCardOrderProps = {
  setEditMode: (value: boolean) => void;
  changeActiveOrder: (value: ActiveOrder) => void;
  phone: string;
  selectedCards: SelectedCards[];
};

const MobileCard = ({
  setEditMode,
  changeActiveOrder,
  phone,
  selectedCards,
}: MobileCardOrderProps): JSX.Element => {
  const { t, stepIndex, setStepIndex, sendDataToGTM } = useMobileCard();

  const sendCardOrder = () => {
    sendDataToGTM({
      event: "owner_card_order",
      event_description: "Mobiiliosakaskortin tilaus",
    });
    setEditMode(false);
    changeActiveOrder("mobile-card-order-done");
  };

  return (
    <WizardContainer stepIndex={stepIndex}>
      <ConfirmMobileCardOrder
        translate={t}
        phone={getReadOnlyPhoneNumber(phone)}
        nextStep={() => setStepIndex(1)}
        previousStep={() => changeActiveOrder("no-order")}
      />

      <SendMobileCardOrder
        translate={t}
        phone={getReadOnlyPhoneNumber(phone)}
        nextStep={sendCardOrder}
        previousStep={() => setStepIndex(0)}
        selectedCards={selectedCards}
      />
    </WizardContainer>
  );
};

export default MobileCard;
