import React from "react";
import { TypedTranslate, lang } from "../../../features/i18n";
import { WizardStep } from "../../wizard-container";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { ContractLengthType } from "../utils";
import { HoldingReserved } from "..";
import { WeekType } from "../../../app/OwnerService-api";
import { selectProfileState } from "../../../store/customer-profile";
import { useSelector } from "react-redux";
import { selectIsFirstLogin } from "../../../store/home";

type Props = {
  resortName: string;
  contractLength: ContractLengthType;
  translate: TypedTranslate;
  holding: HoldingReserved;
} & WizardStep;

const Info = ({
  nextStep,
  previousStep,
  resortName,
  contractLength,
  translate: t,
  holding,
}: Props): JSX.Element => {
  const tRoot = lang.home;
  const tCustomer = lang.home.holdings.newCustomer;

  const profileState = useSelector(selectProfileState);
  const isFirstLogin = useSelector(selectIsFirstLogin);
  const membershipNumber =
    profileState.profileData[profileState.profileData.length - 1].goldMembershipNumber;
  const isGoldMember = membershipNumber !== undefined && membershipNumber !== "";

  const isVillas =
    holding.weekType === WeekType.VillasFractional ||
    holding.weekType === WeekType.VillasFullOwnership;

  const ownerNewWeekType = isVillas ? t(tCustomer.ownerNew.villas) : t(tCustomer.ownerNew.week);
  const owner2MonthsToYearWeekType = isVillas
    ? t(tCustomer.owner2MonthsToYear.villas)
    : t(tCustomer.owner2MonthsToYear.week);

  const textContent =
    contractLength === ContractLengthType.OwnerNew && !isFirstLogin ? (
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        {t(tCustomer.ownerNew.part1, { holdingType: ownerNewWeekType })}
        <HcText tag="span" weight="strong" size="s">
          {resortName}
        </HcText>
        {isGoldMember && isVillas ? t(tCustomer.ownerNew.part2gold) : t(tCustomer.ownerNew.part2)}
      </HcText>
    ) : (
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        {t(tCustomer.owner2MonthsToYear.part1, { holdingType: owner2MonthsToYearWeekType })}
      </HcText>
    );

  return (
    <>
      {profileState.loading === "pending" ? "" : textContent}
      <HcButton onClick={nextStep} theme={isVillas ? "dark" : "default"}>
        {t(tRoot.general.inspectBenefits)}
      </HcButton>
      <HcButton onClick={previousStep} isSecondary={true}>
        {t(tRoot.general.inspectHolding)}
      </HcButton>
    </>
  );
};

export default Info;
