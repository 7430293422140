import { useMemo } from "react";
import { HoldingReserved } from "..";
import {
  CalendarDto,
  HoldingReservationDto,
  ReservationStatus,
  WeekType,
} from "../../../app/OwnerService-api";
import { isBefore } from "date-fns";
import { isSameDayOrBefore } from "../utils";
import { VisibleDateRangeType } from "./use-holdings-calendar";

type FirstHoldingType = {
  firstHolding: HoldingReserved;
  firstStart?: Date;
  firstEnd?: Date;
};

/**
 * Assumes that holdings' weeks are already sorted.
 * @param {HoldingReserved[]} holdings - user holdings
 * @param {HoldingReservationDto[]} reservations - user reservations
 * @param {LoadingState} reservationsLoading - the state of loading reservations
 * @param {Date} [firstDate] - first date visible in calendar
 * @param {Date} [lastDate] - last date visible in calendar
 * @returns {(HoldingReserved|undefined)} holding that has the closest reservation to current calendar month
 */
const useFirstHolding = (
  holdings: HoldingReserved[],
  reservations: HoldingReservationDto[],
  visibleDateRange?: VisibleDateRangeType
): HoldingReserved | undefined => {
  const firstHolding: HoldingReserved | undefined = useMemo(() => {
    // If reservations are still loading return undefined
    if (!visibleDateRange) {
      return undefined;
    }

    const { firstDate, lastDate } = visibleDateRange;
    /**
     * If current day is visible in the calendar, use it as the active day
     * Else use the first visible date
     */
    const todayIsVisible =
      firstDate &&
      lastDate &&
      isSameDayOrBefore(firstDate, new Date()) &&
      isSameDayOrBefore(new Date(), lastDate);
    const firstActiveVisibleDate = firstDate && !todayIsVisible ? firstDate : new Date();

    /**
     * Use date-fns functions to compare dates.
     * @param {Date} [date]
     * @param {Date} [compareTo]
     * @returns {boolean} true if date given as the first parameter is closer to
     * firstActiveVisibleDate than the date to compare to
     */
    const isCloserDate = (date?: Date, compareTo?: Date): boolean => {
      if (date && compareTo) {
        if (isBefore(date, firstActiveVisibleDate) && isBefore(compareTo, firstActiveVisibleDate)) {
          return isBefore(date, compareTo) ? false : true;
        }
        if (isBefore(date, firstActiveVisibleDate)) {
          return false;
        }
        if (isBefore(compareTo, firstActiveVisibleDate)) {
          return true;
        }
        return isBefore(date, compareTo) ? true : false;
      }
      if (!date && compareTo) {
        return false;
      }
      return true;
    };

    /**
     * Find the closest holding from Week holdings
     */
    const firstWeeksHolding = holdings.reduce<HoldingReserved | undefined>((first, current) => {
      if (first === undefined) {
        return current;
      }

      /**
       * Find the closest week to firstActiveVisibleDate
       * @param {CalendarDto[]} [weeks] - array of holding weeks
       * @returns {(Date|undefined)} date of the closest weekEnd
       */
      const getClosestWeek = (weeks: CalendarDto[] | undefined): Date | undefined => {
        if (!weeks || !weeks.length) return undefined;

        const next = weeks.find((week) =>
          isSameDayOrBefore(firstActiveVisibleDate, new Date(week.weekEnd))
        );

        return next ? new Date(next.weekEnd) : new Date(weeks[weeks.length - 1].weekEnd);
      };

      return isCloserDate(getClosestWeek(first.weeks), getClosestWeek(current.weeks))
        ? first
        : current;
    }, undefined);

    /**
     * @param {HoldingReservationDto} reservation
     * @returns {boolean} true if reservation status is accepted or pending
     */
    const isActiveReservation = (reservation: HoldingReservationDto): boolean => {
      return (
        reservation.status === ReservationStatus.Accepted ||
        reservation.status === ReservationStatus.Pending
      );
    };

    /**
     * Find the first accepted or pending reservation from Villas reservations
     */
    const firstReservation = reservations
      .filter((reservation) => isActiveReservation(reservation))
      .reduce<HoldingReservationDto | undefined>((first, current) => {
        if (first === undefined) {
          return current;
        }

        return isCloserDate(new Date(first.endDate), new Date(current.endDate)) ? first : current;
      }, undefined);

    /**
     * Convert Weeks holding to FirstHoldingType
     * @param {HoldingReserved} [holding] - holding to convert
     * @returns {(FirstHoldingType|undefined)}
     */
    const holdingReservedToFirstHolding = (
      holding: HoldingReserved | undefined
    ): FirstHoldingType | undefined => {
      if (holding) {
        let firstHolding: FirstHoldingType = {
          firstHolding: holding,
        };

        if (holding.weeks && holding.weeks.length > 0) {
          const filteredWeeks = holding.weeks.filter((w) =>
            isBefore(firstActiveVisibleDate, new Date(w.weekEnd))
          );

          if (filteredWeeks[0]) {
            firstHolding = {
              ...firstHolding,
              firstStart: new Date(filteredWeeks[0].weekStart),
              firstEnd: new Date(filteredWeeks[0].weekEnd),
            };
          }
        }

        return firstHolding;
      } else {
        return undefined;
      }
    };

    /**
     * Convert Villas holding to FirstHoldingType
     * @param {HoldingReserved} [holding] - holding to convert
     * @param {HoldingReservationDto} [reservation] - reservation to use
     * @returns {(FirstHoldingType|undefined)}
     */
    const holdingWithReservationToFirstHolding = (
      holding: HoldingReserved | undefined,
      reservation: HoldingReservationDto | undefined
    ): FirstHoldingType | undefined => {
      if (holding && reservation) {
        return {
          firstHolding: holding,
          firstEnd: new Date(reservation.endDate),
          firstStart: new Date(reservation.startDate),
        };
      }
    };

    /**
     * @param {number} unitId
     * @returns {(HoldingReserved|undefined)}
     */
    const getHoldingByUnitId = (unitId: number) =>
      holdings.find((holding) => holding.unitId === unitId);

    const holdingWithWeeks = holdingReservedToFirstHolding(firstWeeksHolding);
    const reservedHolding = firstReservation
      ? holdingWithReservationToFirstHolding(
          getHoldingByUnitId(firstReservation.holdingUnitId),
          firstReservation
        )
      : undefined;

    const isVillasFullOwner = holdings.some((h) => h.weekType === WeekType.VillasFullOwnership);

    /**
     * @param {Date} start - range start date
     * @param {Date} end - range end date
     * @returns {boolean} true if any part of the range is visible
     */
    const rangeIsVisibleInCalendar = (start: Date, end: Date): boolean => {
      if (!firstDate || !lastDate) return false;

      return (
        (isSameDayOrBefore(firstDate, start) && isSameDayOrBefore(start, lastDate)) ||
        (isSameDayOrBefore(firstDate, end) && isSameDayOrBefore(end, lastDate)) ||
        (isBefore(start, firstDate) && isBefore(lastDate, end))
      );
    };

    /**
     * @returns {boolean} true if any of the reservations is visible
     */
    const someReservationIsVisible = (): boolean => {
      return (
        reservations.some((r) => {
          const isVisible = rangeIsVisibleInCalendar(new Date(r.startDate), new Date(r.endDate));
          return isVisible;
        }) ||
        holdings.some(
          (h) =>
            h.weeks &&
            h.weeks.some((w) => {
              const isVisible = rangeIsVisibleInCalendar(
                new Date(w.weekStart),
                new Date(w.weekEnd)
              );
              return isVisible;
            })
        )
      );
    };

    /**
     * If there are no reservations visible in the current calendar month
     * and the user is Villas Full owner, return Villas Full holding
     */
    if (firstDate && isVillasFullOwner && !someReservationIsVisible()) {
      return holdings.find((h) => h.weekType === WeekType.VillasFullOwnership);
    }

    /**
     * If there is a Villas reservation and a Weeks holding, return the closest one
     * Else return the existing one or undefined if there is none.
     */
    if (reservedHolding !== undefined && reservedHolding.firstStart !== undefined) {
      return holdingWithWeeks === undefined ||
        holdingWithWeeks.firstEnd === undefined ||
        isCloserDate(reservedHolding.firstEnd, holdingWithWeeks.firstEnd)
        ? reservedHolding.firstHolding
        : holdingWithWeeks.firstHolding;
    }

    return holdingWithWeeks?.firstHolding;
  }, [holdings, reservations, visibleDateRange]);

  return firstHolding;
};

export default useFirstHolding;
