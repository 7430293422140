import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import fi from "./translations/fi";

i18next.use(initReactI18next).init({
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: "fi", // "en" | "fi"
  debug: false,
  resources: {
    en: {
      translation: en,
    },
    fi: {
      translation: fi,
    },
  },
});

export default i18next;
