import React from "react";
import { Trans } from "react-i18next";
import { TypedTranslate, lang } from "../../../features/i18n";
import { WizardStep } from "../../wizard-container";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";

type Props = {
  resortName: string;
  endDate: string;
  translate: TypedTranslate;
} & WizardStep;

const Info = ({ nextStep, resortName, endDate, translate: t }: Props): JSX.Element => {
  const tRoot = lang.home.holdings.ongoing;
  return (
    <>
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        <Trans i18nKey="lang.home.holdings.ongoing.info">
          Lomasi on käynnissä kohteessa{" "}
          <HcText size="s" weight="strong" tag="span">
            {{ resortName }} {{ endDate }}
          </HcText>{" "}
          saakka.
        </Trans>
      </HcText>

      <HcButton onClick={nextStep}>{t(tRoot.showCalendar)}</HcButton>
    </>
  );
};

export default Info;
