import * as React from "react";

import {
  FormGroupProps,
  MyProfileData,
  MyProfileDataFields,
  MyProfileDtoKey,
  MyProfileKey,
} from "..";
import { lang } from "../../../features/i18n";
import useContactDetails from "./use-contact-details";
import { IMyProfileDto, MyProfileDto } from "../../../app/OwnerService-api";
import { prepareData as commonPrepareData } from "../profile-utils";
import FormGroup from "../form-group";
import { getReadOnlyPhoneNumber } from "../profile-utils";

export type ContactDetails = Pick<IMyProfileDto, "phone" | "email">;

type ContactDetailsProps = FormGroupProps;

const ContactDetails = ({
  editMode,
  onEditModeChange,
  isEditable,
  profileData,
  commonOnSubmit,
  isSubmitting,
  formKey,
}: ContactDetailsProps): JSX.Element => {
  const { t, isValidPhoneFormat, validatePhone, validateEmail } = useContactDetails(editMode);
  const tRoot = lang.profile.contactDetails;

  const onSubmit = (newData: MyProfileData) => {
    const phoneToSubmit = newData.phone?.startsWith("+") ? newData.phone.slice(1) : newData.phone;

    commonOnSubmit({ ...newData, phone: phoneToSubmit }, editMode, formKey);
  };

  const selectedFields: MyProfileDtoKey[] = ["email", "phone"];

  const prepareDataFields = (): MyProfileDataFields[] => {
    const getLabel = (fieldName: MyProfileKey): string => {
      type TField = keyof typeof tRoot;

      return t(tRoot[fieldName as TField]);
    };

    const prefixPhoneNumber = (value: string): string => {
      if (!value || value.length === 0) {
        return "+";
      }
      return value.startsWith("+") ? value : `+${value}`;
    };

    const formatPhoneNumber = (value: string): string => {
      return editMode ? prefixPhoneNumber(value) : getReadOnlyPhoneNumber(value);
    };

    const prepared: MyProfileDataFields = {
      email: {
        label: getLabel("email"),
        validate: validateEmail,
        isDisabled: true, // disabled until the verification can be done
      },
      phone: {
        label: getLabel("phone"),
        validate: validatePhone,
        format: formatPhoneNumber,
        showErrorsWhenActive: true,
        infoText: editMode && !isValidPhoneFormat ? t(tRoot.phoneInfo) : "",
        successText: editMode && isValidPhoneFormat ? t(tRoot.phoneValidFormat) : undefined,
      },
    };

    return [prepared];
  };

  const prepareData = (data: MyProfileDto): MyProfileData[] => {
    const prepared: MyProfileData = commonPrepareData(data, selectedFields);

    return [prepared];
  };

  return (
    <FormGroup
      dataRows={prepareData(profileData)}
      dataFieldRows={prepareDataFields()}
      groupTitle={t(tRoot.title)}
      isEditable={isEditable}
      isInEditMode={editMode}
      onSubmit={onSubmit}
      toggleIsInEditMode={onEditModeChange}
      groupDescription={t(tRoot.description)}
      isSubmitting={isSubmitting}
    />
  );
};

export default ContactDetails;
