import { useCallback, useState } from "react";
import { usePopper } from "react-popper";

import { pixelStrToNum } from "../../../features/token-helper/token-helper";
import useOutsideClick from "../../../features/use-outside-click";
import useWindowSize from "../../../features/use-window-size";
import * as tokens from "../../../styles/tokens-data/tokens.json";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useDropdownMenu = (
  referenceElement: HTMLElement | null,
  dataShowAttr: string,
  altAxis: boolean
) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const cardRadius = pixelStrToNum(tokens.radiusS.value);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      { name: "arrow", options: { element: arrowElement, padding: cardRadius } },
      { name: "offset", options: { offset: [0, 4] } },
      { name: "preventOverflow", options: { altAxis: altAxis, padding: 8 } },
    ],
  });

  const windowSize = useWindowSize();

  const show = useCallback(() => {
    if (popperElement && !popperElement.hasAttribute(dataShowAttr)) {
      popperElement.setAttribute(dataShowAttr, "");
    }
    if (referenceElement) {
      referenceElement.setAttribute("aria-expanded", "true");
    }
  }, [popperElement, dataShowAttr]);

  const hide = useCallback(() => {
    if (popperElement && popperElement.hasAttribute(dataShowAttr)) {
      popperElement.removeAttribute(dataShowAttr);
    }
    if (referenceElement) {
      referenceElement.setAttribute("aria-expanded", "false");
    }
  }, [popperElement, dataShowAttr]);

  useOutsideClick(popperElement, hide);

  return {
    popperElement,
    arrowElement,
    popperStyles: styles,
    popperAttributes: attributes,
    popperUpdate: update,
    windowSize,
    show,
    hide,
    setPopperElement,
    setArrowElement,
  };
};

export default useDropdownMenu;
