export function isAuthDisabled(): boolean {
  const isAuthDisabledStr: undefined | string = process.env.REACT_APP_IS_AUTH_DISABLED;

  return (
    (isAuthDisabledStr !== undefined &&
      isAuthDisabledStr === "true" &&
      process.env.NODE_ENV === "development") ||
    process.env.NODE_ENV === "test"
  );
}

export function isPasswordResetError(error: Error): boolean {
  // Error code is from https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview#linking-user-flows
  const forgotPasswordCheck = '"errorMessage":"AADB2C90118:';
  const errorStr = JSON.stringify(error).trim();
  return errorStr.includes(forgotPasswordCheck);
}
