import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalState } from ".";

const initialState: ModalState = {
  isOpen: false,
  isClosing: false,
  activeModalId: "",
};

const modalSlice = createSlice({
  initialState,
  name: "cardOrder",
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.isClosing = false;
      state.activeModalId = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.isClosing = false;
      state.activeModalId = "";
    },
    onRequestClose: (state) => {
      state.isClosing = true;
    },
    cancelOnRequestClose: (state) => {
      state.isClosing = false;
    },
  },
});

export default modalSlice;
