import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useConfirmRent = (nextStep: () => void) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();

  /**
   * Send data to GTM and move to next step
   */
  const handleNextStep = () => {
    // Google Tag Manager
    sendDataToGTM({
      event: "rent_week_agree_terms",
      event_description: "Hyväksy ehdot",
    });
    nextStep();
  };

  return {
    isConfirmed,
    toggleIsConfirmed: () => setIsConfirmed((prev) => !prev),
    t,
    handleNextStep,
  };
};

export default useConfirmRent;
