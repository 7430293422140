import { useState, useEffect } from "react";
import { BannerItemType } from "..";
import { IContentHolidayChangeDto, IContentOwnerBenefitDto } from "../../../app/OwnerService-api";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBannerContent = (
  data: IContentOwnerBenefitDto | IContentHolidayChangeDto,
  itemType: BannerItemType
) => {
  const [primaryHeadline, setPrimaryHeadline] = useState<string>("");
  const [secondaryHeadline, setSecondaryHeadline] = useState<string>("");
  const [subHeadline, setSubHeadline] = useState<string>("");
  const [badgeAdditionalUnit, setBadgeAdditionalUnit] = useState<string>("");
  const [imgSrc, setImgSrc] = useState<string>("");
  const [imgAlt, setImgAlt] = useState<string>("");

  useEffect(() => {
    const headline = data.title || "";
    const secondaryHead = data.category || "";
    const imageUrl = data.imageUrl || "";
    const imageAlt = data.imageAlt || "";
    let subHead = "";

    if (itemType === "OwnerBenefit") {
      const dataArray = data as IContentOwnerBenefitDto;
      subHead = dataArray.subtitle || "";
    } else {
      const dataArray = data as IContentHolidayChangeDto;
      subHead = dataArray.when || "";
    }

    setPrimaryHeadline(headline);
    setSecondaryHeadline(secondaryHead);
    setSubHeadline(subHead);
    setImgSrc(imageUrl);
    setImgAlt(imageAlt);
    setBadgeAdditionalUnit("");
  }, []);

  return {
    primaryHeadline,
    secondaryHeadline,
    subHeadline,
    badgeAdditionalUnit,
    imgSrc,
    imgAlt,
  };
};

export default useBannerContent;
