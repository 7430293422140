import { useState } from "react";
import { useSelector } from "react-redux";
import { LoginStatusHooks } from "..";
import { useTypedTranslation } from "../../../features/i18n";
import { selectUser } from "../../../store/authorization";

function useLoginStatus(): LoginStatusHooks {
  const user = useSelector(selectUser);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { t } = useTypedTranslation();

  return {
    user,
    isLoggingOut,
    setIsLoggingOut,
    t,
  };
}

export default useLoginStatus;
