import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { selectProfileState } from "../../../store/customer-profile";
import {
  requestProfile as requestCustomersAction,
  updateProfile as updateProfileAction,
} from "../../../store/customer-profile";
import { hideFirstLoginBadge as hideFirstLoginBadgeAction } from "../../../store/home";
import { selectUser } from "../../../store/authorization";
import {
  IMyProfileDto,
  MyProfileDto,
  UpdateMyProfileDto,
  UpdateMyPasswordDto,
  CustomerPasswordClient,
} from "../../../app/OwnerService-api";
import useGTM from "../../../features/use-gtm";
import { ProfileSectionsType, MyProfileData } from "..";
import { FetchManager } from "../../../features/authorization";
import { toast } from "react-toastify";
import { getToastContent } from "../../../store/toast-translations";
import useReduxModal from "../../redux-modal/use-redux-modal";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useProfile = (alertModalId: string) => {
  const allFalse: ProfileSectionsType = {
    login: false,
    personal: false,
    contact: false,
    address: false,
  };

  // Is form area in edit mode
  const [editMode, setEditMode] = useState<ProfileSectionsType>(allFalse);
  const [submitMode, setSubmitMode] = useState<ProfileSectionsType>(allFalse);

  const dispatch = useAppDispatch();
  const { sendDataToGTM } = useGTM();
  const { t } = useTypedTranslation();
  const { openModal: openAlertModal, closeModal: closeAlertModal } = useReduxModal(alertModalId);

  const user = useSelector(selectUser);
  const profileState = useSelector(selectProfileState);
  const profileData = profileState.profileData[profileState.profileData.length - 1];
  const profileLoading = profileState.loading;

  /**
   * Get customers from store
   */
  const requestCustomers = async () => {
    dispatch(requestCustomersAction(user));
  };

  useEffect(() => {
    if (profileLoading === "idle") {
      requestCustomers();
    }

    if (profileLoading === "success") {
      dispatch(hideFirstLoginBadgeAction());
    }
  }, [requestCustomers, profileLoading]);

  /**
   * Dispatch update profile action, clear submit mode when dispatch is done
   * @param {MyProfileDto} newProfile - new profile data
   */
  const updateProfile = async (newProfile: MyProfileDto) => {
    await dispatch(
      updateProfileAction({
        requestor: user,
        updatedProfile: new UpdateMyProfileDto(newProfile),
      })
    );
    setSubmitMode(allFalse);
  };

  /**
   * @returns {string} users initials
   */
  const getInitials = () => {
    const givenNameFirstLetter =
      user?.givenName !== undefined ? user?.givenName.charAt(0).toUpperCase() : "";
    const familyNameFirstLetter =
      user?.familyName !== undefined ? user?.familyName.charAt(0).toUpperCase() : "";

    return givenNameFirstLetter !== "" && familyNameFirstLetter !== ""
      ? givenNameFirstLetter + familyNameFirstLetter
      : "-";
  };

  /**
   * Handle submit: if form is on edit mode, update profile data,
   * send data to GTM, close edit mode
   * @param {MyProfileData} newData - new profile data
   * @param {boolean} editMode - current editMode
   * @param formKey - key of active form
   */
  const commonOnSubmit = (
    newData: MyProfileData,
    editMode: boolean,
    formKey: keyof ProfileSectionsType
  ): void => {
    handleIsSubmitting(formKey);

    if (editMode) {
      const mergedData: IMyProfileDto = {
        ...profileData,
        ...newData,
        membershipCards: profileData.membershipCards,
      };

      const newProfile = new MyProfileDto(mergedData);

      updateProfile(newProfile);

      // Google Tag Manager
      sendDataToGTM({
        event: "update_user_info",
        event_description: "Omien tietojen päivitys",
      });
      toggleIsInEditMode(formKey);
    }
  };

  /**
   * Submit new password, send data to GTM, show toast
   * @param {string} newPassword - new password string
   */
  const changePassword = async (newPassword: string) => {
    const passwordDto = new UpdateMyPasswordDto({
      newPassword: newPassword,
    });

    handleIsSubmitting("login");
    const client = new CustomerPasswordClient(undefined, new FetchManager(user));

    try {
      const response = await client.update(passwordDto); // await promise

      if (response.status === 200) {
        // Google Tag Manager
        sendDataToGTM({
          event: "update_user_info",
          event_description: "Omien tietojen päivitys",
        });

        toast.success(getToastContent(true));

        toggleIsInEditMode("login");
      } else {
        toast.error(getToastContent(false));
      }
    } catch (e) {
      toast.error(getToastContent(false));
    }

    setSubmitMode(allFalse);
  };

  /**
   * Toggle edit mode of the section. If profile is not modifiable, show alert
   * @param section - key of edited section
   */
  const toggleIsInEditMode = (section: keyof ProfileSectionsType) => {
    const sectionState: boolean = editMode[section];

    if (sectionState) {
      setEditMode({
        ...editMode,
        [section]: false,
      });
    } else if (profileData.modifiable) {
      setEditMode({
        ...allFalse,
        [section]: true,
      });
    } else {
      openAlertModal(alertModalId);
    }
  };

  const handleIsSubmitting = (section: keyof ProfileSectionsType) => {
    setSubmitMode({ ...submitMode, [section]: true });
  };

  /**
   * @param section - key of edited section
   * @returns {boolean} true if section is active or none of the sections are active
   */
  const isEditable = useCallback(
    (section: keyof ProfileSectionsType) => {
      const isNoneActive = Object.values(editMode).every((editMode) => editMode === false);
      const isSectionActive = editMode[section];

      return isNoneActive || isSectionActive;
    },
    [editMode]
  );

  return {
    t,
    profileData,
    profileLoading,
    getInitials,
    commonOnSubmit,
    editMode,
    changePassword,
    toggleIsInEditMode,
    isEditable,
    submitMode,
    closeAlertModal,
  };
};

export default useProfile;
