import React, { RefObject } from "react";
import HcHeading from "../../hc-heading";
import { lang } from "../../../features/i18n";
import useHoldingsDetails from "./use-holdings-details";
import TabContainer, { TabContent } from "../../tab-container";
import { HoldingDto, HoldingReservationDto, WeekType } from "../../../app/OwnerService-api";
import HoldingsTab from "./holdings-tab";
import VillasReservationModal from "../../villas-reservation-modal";
import "./holdings-details.scss";

type HoldingsDetailsProps = {
  holdingsData: HoldingDto[];
  reservationsData: HoldingReservationDto[];
  holdingsRef: RefObject<HTMLDivElement>;
};

const HoldingsDetails = ({
  holdingsData,
  reservationsData,
  holdingsRef,
}: HoldingsDetailsProps): JSX.Element => {
  const { t, activeId, onTabSelect } = useHoldingsDetails(holdingsData);
  const tRoot = lang.home.holdingsDetails;

  /**
   * Generate a list of tabs containing each of users holdings
   * @returns {TabContent[]}
   */
  const generateTabs = (): TabContent[] => {
    const tabs: TabContent[] = holdingsData.map((holding) => ({
      header: holding.resortName !== undefined ? holding.resortName : "-",
      // eslint-disable-next-line react/display-name
      Content: () => (
        <HoldingsTab holding={holding} reservationsData={reservationsData} translate={t} />
      ),
    }));

    return tabs;
  };

  return (
    <>
      <div className="ownership-title-section" id="omistukset" ref={holdingsRef}>
        <HcHeading semanticElement="h2" bottomSpace="xl">
          {t(tRoot.title)}
        </HcHeading>
      </div>
      <TabContainer
        contentList={generateTabs()}
        activeResortId={activeId}
        onTabSelect={onTabSelect}
      />
      {holdingsData.map((h) => {
        const villasModalId = "villas-reservation-modal-" + h.unitId;

        if (
          h.weekType === WeekType.VillasFullOwnership ||
          h.weekType === WeekType.VillasFractional
        ) {
          return <VillasReservationModal key={villasModalId} id={villasModalId} holding={h} />;
        }
      })}
    </>
  );
};

export default HoldingsDetails;
