import React from "react";
import Carousel from "nuka-carousel";
import ArrowLeft from "./../../images/arrow-left.svg";
import ArrowRight from "./../../images/arrow-right.svg";
import PlaceholderImg from "../placeholder-img";
import AspectRatioWrapper, { AspectRatioType } from "../aspect-ratio-wrapper";
import VideoPlayer from "../video-player";
import { IContentVideoBlockDto } from "../../app/OwnerService-api";
import "./video-carousel.scss";

export type VideoCarouselProps = {
  data: IContentVideoBlockDto[] | undefined;
  videoAspectRatio?: AspectRatioType;
  slideIndex?: number;
  setActiveVideo: (i: number) => void;
  isPlaying: boolean;
  setIsPlaying: (val: boolean) => void;
};

const getPlaceholderFallback = (aspectRatio: AspectRatioType) => {
  return (
    <AspectRatioWrapper aspectRatio={aspectRatio}>
      <PlaceholderImg roundedCorners={false} />
    </AspectRatioWrapper>
  );
};

/**
 * We are using nuka-carousel with some custom styles.
 *
 * Nuka: https://github.com/FormidableLabs/nuka-carousel
 *
 * @param data Array of image objects
 */
const VideoCarousel = ({
  data,
  videoAspectRatio = "16:9",
  slideIndex,
  setActiveVideo,
  isPlaying,
  setIsPlaying,
}: VideoCarouselProps): JSX.Element => {
  return (
    <>
      {data !== undefined && data.length > 0 ? (
        <Carousel
          afterSlide={() => {
            setIsPlaying(false);
          }}
          wrapAround={true}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide} type="button" className="nav-button prev-arrow">
              <img src={ArrowLeft} className="arrow" alt="Navigate to previous slide" />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} type="button" className="nav-button next-arrow">
              <img src={ArrowRight} className="arrow" alt="Navigate to next slide" />
            </button>
          )}
          slideIndex={slideIndex}
        >
          {data.map((videoItem, index) => {
            return (
              <VideoPlayer
                aspectRatio={videoAspectRatio}
                videoUrl={videoItem.url}
                onPlay={() => {
                  setActiveVideo(index);
                  setIsPlaying(true);
                }}
                playing={isPlaying && slideIndex === index}
                key={videoItem.url}
              />
            );
          })}
        </Carousel>
      ) : (
        getPlaceholderFallback(videoAspectRatio)
      )}
    </>
  );
};

export default VideoCarousel;
