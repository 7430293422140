import React from "react";
import { ToastContainer } from "react-toastify";
import CloseButton from "./close-button";

import "react-toastify/dist/ReactToastify.css";
import "./generic-toast.scss";

const GenericToast = (): JSX.Element => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeButton={<CloseButton />}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default GenericToast;
