import React from "react";
import { WeekType, HoldingReservationDto, HoldingDto } from "../../../app/OwnerService-api";
import Row from "../../flex-row";
import Col from "../../flex-col";
import HcImageCarousel from "../../hc-image-carousel";
import { TypedTranslate } from "../../../features/i18n";
import HoldingsTabHeading from "./holdings-tab-heading";
import HoldingsTabLinks from "./holdings-tab-links";
import HoldingsTabDetails from "./holdings-tab-details";

export type NextWeekDataType = {
  calendarId?: number;
  weekNumber?: number;
  weekStart: string;
  weekEnd: string;
};

const HoldingsTabContent = ({
  holding,
  reservationsData,
  translate: t,
}: {
  holding: HoldingDto;
  reservationsData: HoldingReservationDto[];
  translate: TypedTranslate;
}): JSX.Element => {
  const isVillasType =
    holding.weekType === WeekType.VillasFullOwnership ||
    holding.weekType === WeekType.VillasFractional;

  /**
   * Get week data to pass to elements
   * @returns {NextWeekDataType|undefined}
   */
  const getNextWeekData = (): NextWeekDataType | undefined => {
    if (isVillasType) {
      const next = reservationsData.find(
        (reservation) => reservation.holdingUnitId === holding.unitId
      );

      return next
        ? {
            weekStart: next.startDate,
            weekEnd: next.endDate,
          }
        : undefined;
    }

    const next = holding.weeks && holding.weeks.length > 0 ? holding.weeks[0] : undefined;

    return next
      ? {
          calendarId: next.calendarId,
          weekNumber: next.weekNumber,
          weekStart: next.weekStart,
          weekEnd: next.weekEnd,
        }
      : undefined;
  };

  const nextWeekData = getNextWeekData();

  return (
    <div className="tab-content-container">
      <Row>
        <Col l={6}>
          <HcImageCarousel data={holding.images} cropToAspectRatio />
        </Col>
        <Col l={5} lOffset={1}>
          <div className="ownership-description-container">
            <HoldingsTabHeading holding={holding} nextWeekData={nextWeekData} translate={t} />
            <hr />

            <Row>
              <HoldingsTabDetails holding={holding} translate={t} />
            </Row>
            <hr />

            <Row>
              <HoldingsTabLinks
                holding={holding}
                reservationsData={reservationsData}
                nextWeekData={nextWeekData}
                translate={t}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HoldingsTabContent;
