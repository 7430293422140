import React from "react";
import HcText from "../../hc-text";
import { HTMLReactParserOptions, domToReact } from "html-react-parser";
import { Element, DataNode } from "domhandler/lib/node";

const convertToComponent = (tag: string, content: string): JSX.Element => {
  // remove empty p elements (<p>&nbsp</p>) by replacing elements with React.fragment
  if (content === "\u00a0") {
    return <></>;
  }
  switch (tag) {
    case "p":
      return (
        <HcText tag={tag} bottomSpace="l">
          {content}
        </HcText>
      );
    case "strong":
      return (
        <HcText tag="span" weight="strong">
          {content}
        </HcText>
      );
    case "strong-p":
      return (
        <HcText tag="p" weight="strong">
          {content}
        </HcText>
      );

    default:
      return React.createElement(tag, {}, content);
  }
};

const convertNodeWithChildrenToComponent = (node: Element) => {
  return <HcText bottomSpace="l">{domToReact(node.children)}</HcText>;
};

export const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      if (domNode.children.length === 1 && domNode.children[0].type === "text") {
        const nodeContent = domNode.children[0] as DataNode;
        return convertToComponent(domNode.name, nodeContent.data);
      } else if (domNode.children.length === 1 && domNode.children[0] instanceof Element) {
        const nodeContent = domNode.children[0] as Element;
        const childData = nodeContent.children[0] as DataNode;

        if (domNode.name === "p" && nodeContent.tagName === "strong") {
          return convertToComponent("strong-p", childData.data);
        }
      } else if (
        domNode.children.length > 1 &&
        domNode.name === "p" &&
        domNode.children[0].type === "text"
      ) {
        return convertNodeWithChildrenToComponent(domNode);
      } else {
        return domNode;
      }
    }
  },
};
