import { useState } from "react";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useEpiVideoCarouselBlock = () => {
  const [activeVideo, setActiveVideo] = useState<number | undefined>(undefined);
  const [isPlaying, setIsPlaying] = useState(false);

  return {
    activeVideo,
    setActiveVideo,
    isPlaying,
    setIsPlaying,
  };
};

export default useEpiVideoCarouselBlock;
