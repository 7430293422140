import React from "react";

import "./card-container.scss";

export type IProps = React.HtmlHTMLAttributes<HTMLDivElement>;

const CardContainer = React.forwardRef<HTMLDivElement, IProps>(
  ({ children, className, ...props }: IProps, ref): JSX.Element => {
    return (
      <div ref={ref} className={`card-container ${className ? className : ""}`} {...props}>
        {children}
      </div>
    );
  }
);

CardContainer.displayName = "CardContainer";
export default CardContainer;
