import React from "react";
import { ICalendarDto, HoldingDto, DataSource } from "../../app/OwnerService-api";
import { lang } from "../../features/i18n";
import Col from "../flex-col";
import FlexRow from "../flex-row";
import HcHeading from "../hc-heading";
import HcModal from "../hc-modal";
import HcText from "../hc-text";
import useRentFormModal from "./hooks";
import InfoRented from "./info-rented";
import RentWizard from "./rent-wizard";
import InfoRentNotPossible from "./info-rent-not-possible";
import "./rent-form-modal.scss";

type Props = {
  isOpen: boolean;
  closeRentFormModal: () => void;
  holding?: HoldingDto;
  rented: boolean;
  weekToRent: ICalendarDto | undefined;
  additionalInformation: string;
};

const RentFormModal = ({
  isOpen,
  closeRentFormModal,
  holding,
  rented,
  weekToRent,
  additionalInformation,
}: Props): JSX.Element => {
  const { stepIndex, setStepIndex, t } = useRentFormModal();
  const tRoot = lang.rentFormModal;

  const handleClose = () => {
    closeRentFormModal();
    setStepIndex(0);
  };

  const classNameRoot = "rent-form";
  const classNameContainer = `${classNameRoot}__container`;
  const classNameContent = `${classNameContainer}__content`;

  const header =
    holding?.source === DataSource.SPIPms ? (
      <div>
        <HcText colorVariant="weak">
          {t(tRoot.week)} {holding?.weekNumber}
        </HcText>

        <HcHeading semanticElement="h5" bottomSpace="s">
          {holding?.resortName}
        </HcHeading>
        <HcText size="s">
          <a href="#omistukset">{t(tRoot.showResort)}</a>
        </HcText>
      </div>
    ) : (
      <HcHeading semanticElement="h5" bottomSpace="s">
        {t(tRoot.infoRentNotPossible.title)}
      </HcHeading>
    );

  const getContent = () => {
    if (holding?.source !== DataSource.SPIPms) {
      return <InfoRentNotPossible closeModal={closeRentFormModal} translate={t} />;
    }
    return rented ? (
      <InfoRented resortName={holding?.resortName || ""} />
    ) : (
      <RentWizard
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        holding={holding}
        weekToRent={weekToRent}
        handleClose={handleClose}
        additionalInformation={additionalInformation}
      />
    );
  };

  return (
    <HcModal
      ariaLabel="holding renting form"
      isOpen={isOpen}
      onClose={handleClose}
      shouldConfirmBeforeClose={!rented}
      header={header}
      id="rent-form-modal"
    >
      <FlexRow className={classNameRoot}>
        <Col className={classNameContainer}>
          <FlexRow className={classNameContent}>{getContent()}</FlexRow>
        </Col>
      </FlexRow>
    </HcModal>
  );
};

export default RentFormModal;
