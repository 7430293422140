import React, { useEffect } from "react";
import { IBankAccountDto } from "../../app/OwnerService-api";
import useAccountNumber from "./use-account-number";
import HcButton from "../hc-button";
import { lang, TypedTranslate } from "../../features/i18n";
import ArrowRight from "./../../images/arrow-right.svg";
import AccountNumberSelect from "../account-number-select";
import NewAccount from "./new-account";
import HcInput from "../hc-input";
import { maskIBAN } from "./account-utils";

import "./account-number.scss";

type AccountNumberProps = {
  /**
   * Bank account if any
   */
  account: IBankAccountDto | undefined;

  /**
   * Is component in readOnly mode
   */
  readOnly?: boolean;

  /**
   * Is component disabled
   */
  isDisabled?: boolean;

  /**
   * Label field
   */
  label: string;

  id?: string;

  /**
   * Reset component state
   */
  resetState?: boolean;

  /**
   * name attribute for bank account select
   */
  name: string;

  /**
   * Event handlers
   */
  onChange?: (data: IBankAccountDto | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

type NewAccountButtonProps = {
  translation: TypedTranslate;
  onNewAccountBtnClick: () => void;
  isDisabled: boolean;
};

const NewAccountButton = ({
  translation: t,
  onNewAccountBtnClick,
  isDisabled,
}: NewAccountButtonProps) => {
  const tRoot = lang.accountNumber;

  return (
    <HcButton
      isSecondary
      className="new-account-button--solo"
      onClick={onNewAccountBtnClick}
      disabled={isDisabled}
    >
      {t(tRoot.createNewAccount)} <img src={ArrowRight} alt="" />
    </HcButton>
  );
};

const AccountNumber = ({
  account,
  readOnly = false,
  isDisabled = false,
  label,
  id,
  name,
  resetState,
  onChange,
  onBlur,
  onFocus,
}: AccountNumberProps): JSX.Element => {
  const {
    t,
    handleCreateNewAccountClick,
    showNewAccountForm,
    closeNewBankAccountForm,
    saveNewAccount,
    bankAccount,
    newAccountFeedback,
    resetAccountData,
    resetNewAccountFeedback,
    validateNewBankAccountWithoutSave,
  } = useAccountNumber(account, onChange);

  useEffect(() => {
    if (resetState) {
      resetAccountData();
    }
  }, [resetState, resetAccountData]);

  const readOnlyValue = bankAccount ? bankAccount.accountNumberMasked : "";

  const ReadOnlyField = (): JSX.Element => {
    return (
      <HcInput
        value={readOnlyValue !== undefined ? maskIBAN(readOnlyValue) : readOnlyValue}
        readOnly={true}
        name={name}
      />
    );
  };

  const getNewBankAccountButton = (disabled: boolean): JSX.Element => {
    return (
      <NewAccountButton
        translation={t}
        onNewAccountBtnClick={handleCreateNewAccountClick}
        isDisabled={disabled}
      />
    );
  };

  const defaultAccountContent: JSX.Element = bankAccount ? (
    <div className="account-number__input-group">
      <label htmlFor={id} className="hc-input__label">
        {label}
      </label>
      <AccountNumberSelect
        id={id}
        account={bankAccount}
        onNewAccountCreateClicked={handleCreateNewAccountClick}
        nameValue={name}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  ) : (
    getNewBankAccountButton(isDisabled)
  );

  const getContent = () => {
    let content: JSX.Element = <></>;

    if (bankAccount) {
      content = <ReadOnlyField />;
    } else {
      content = getNewBankAccountButton(readOnly);
    }

    return content;
  };

  return showNewAccountForm && !readOnly ? (
    <div className="account-number__new-account-container">
      <NewAccount
        translate={t}
        onFormClose={closeNewBankAccountForm}
        onNewAccountSave={saveNewAccount}
        newAccountFeedback={newAccountFeedback}
        resetNewAccountFeedback={resetNewAccountFeedback}
        validateBankAccount={validateNewBankAccountWithoutSave}
      />
    </div>
  ) : readOnly ? (
    getContent()
  ) : (
    defaultAccountContent
  );
};

export default AccountNumber;
