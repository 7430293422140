import { useState, useEffect } from "react";
import { HoldingReserved } from "..";
import { useTypedTranslation } from "../../../features/i18n";
import { daysBeforeRangeStart } from "../../date-range/utils";
import { isReservation } from "../utils";
import { useAppDispatch } from "../../../store";
import { closeReservationProcess as closeReservationProcessAction } from "../../../store/reservations";
import useGTM from "../../../features/use-gtm";
import { useSelector } from "react-redux";
import { selectClickedDate } from "../../../store/calendar";
import { CalendarDto } from "../../../app/OwnerService-api";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useReservationVillasFrac = (holding: HoldingReserved, closeOverlay: () => void) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [selectedWeekIds, setSelectedWeekIds] = useState<number[]>([]);
  const [isLastMinute, setIsLastMinute] = useState<boolean>(false);
  const [skipInfo, setSkipInfo] = useState<boolean>(false);
  const [clickedWeek, setClickedWeek] = useState<CalendarDto | undefined>(undefined);
  const [daysBefore, setDaysBefore] = useState<number | undefined>(undefined);

  const clickedDate = useSelector(selectClickedDate);
  const { t } = useTypedTranslation();
  const dispatch = useAppDispatch();
  const { sendDataToGTMCallback } = useGTM();

  useEffect(() => {
    // Google tag manager
    sendDataToGTMCallback({
      event: "booking_start",
      event_description: "Pyydä varausta",
    });
  }, [sendDataToGTMCallback]);

  useEffect(() => {
    // Find clicked week by clickedDate
    const week =
      clickedDate && holding.weeks && holding.weeks.length
        ? holding.weeks.find((w) =>
            isReservation(clickedDate, new Date(w.weekStart), new Date(w.weekEnd))
          )
        : undefined;

    setClickedWeek(week);

    /**
     * Selected week is clicked week if it is defined,
     * else it is the first week
     */
    const selectedWeek = week || (holding.weeks && holding.weeks[0]);

    const daysBeforeWeek = selectedWeek
      ? daysBeforeRangeStart(new Date(), new Date(selectedWeek.weekStart))
      : undefined;

    // If week starts in less than 31 days, set isLastMinute true
    if (daysBeforeWeek !== undefined && daysBeforeWeek < 31) {
      setIsLastMinute(true);
    }

    setDaysBefore(daysBeforeWeek);
  }, [clickedDate, holding.weeks]);

  useEffect(() => {
    const initialSelectedWeekIds: number[] = clickedWeek ? [clickedWeek.calendarId] : [];

    // Skip info if calendar date was clicked
    const skip = initialSelectedWeekIds && initialSelectedWeekIds.length > 0;

    setSkipInfo(skip);

    if (skip) {
      setStepIndex(1);
      setSelectedWeekIds(initialSelectedWeekIds);
    }
  }, [clickedWeek]);

  /**
   * Close reservation process in redux
   * If info was originally shown, go back to first step,
   * else close overlay
   */
  const closeReservationProcess = () => {
    dispatch(closeReservationProcessAction());

    if (skipInfo) {
      closeOverlay();
    } else {
      setStepIndex(0);
    }
  };

  return {
    t,
    stepIndex,
    setStepIndex,
    selectedWeekIds,
    setSelectedWeekIds,
    isLastMinute,
    setIsLastMinute,
    daysBefore,
    closeReservationProcess,
  };
};

export default useReservationVillasFrac;
