import * as React from "react";
import cx from "classnames";

import "./form-group-container.scss";

export type FormGroupContainerProps = {
  children: React.ReactNode;
  isEditable?: boolean;
  isSubmitting?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

const FormGroupContainer = ({
  children,
  isEditable = false,
  isSubmitting = false,
  className,
}: FormGroupContainerProps): JSX.Element => {
  const cssClasses = cx("form-group-container", {
    editable: isEditable,
    "generic-loading-state": isSubmitting,
  });

  return (
    <div className={className !== undefined ? cssClasses.concat(" " + className) : cssClasses}>
      {children}
    </div>
  );
};

export default FormGroupContainer;
