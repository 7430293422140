import React, { RefObject } from "react";
import Col from "../flex-col";
import ArrowRight from "./../../images/arrow-right.svg";
import { IContentListItemDto } from "../../app/OwnerService-api";
import useEpiHorizontalList from "./use-epi-horizontal-list";
import { lang } from "../../features/i18n";
import { Link } from "react-router-dom";
import { BannerItemType } from "../banner";
import BannerScroller from "../banner-scroller";

type EpiHorizontalListBlockProps = {
  /**
   * Title for banner scroller. For example "Osakasedut"
   */
  title: string;

  /**
   * Banner scroller items
   */
  items: IContentListItemDto[];

  showMoreLink: boolean;
  benefitsRef?: RefObject<HTMLAnchorElement>;
};

const EpiHorizontalListBlock = ({
  title,
  items,
  showMoreLink,
  benefitsRef,
}: EpiHorizontalListBlockProps): JSX.Element => {
  const { t } = useEpiHorizontalList();
  const tRoot = lang;
  // Max number of items is 4

  const contentType: BannerItemType =
    items !== undefined &&
    items.length > 0 &&
    Object.prototype.hasOwnProperty.call(items[0], "changeType")
      ? "HolidayChange"
      : "OwnerBenefit";

  const showMoreLinkContent: JSX.Element = showMoreLink ? (
    <Col s={6} m={6} l={4} className="banner-text-end">
      <Link
        to={{
          pathname: t(tRoot.benefitsPage.general.pathname),
          hash:
            contentType === "HolidayChange"
              ? t(tRoot.benefitsPage.general.listTabHash)
              : t(tRoot.benefitsPage.general.searchTabHash),
          state: {
            scrollPosition: "tabs",
          },
        }}
        innerRef={contentType === "OwnerBenefit" ? benefitsRef : undefined}
      >
        {t(tRoot.epi.horizontalList.showMoreLinkText)} <img src={ArrowRight} alt="" />
      </Link>
    </Col>
  ) : (
    <></>
  );

  return (
    <BannerScroller
      title={title}
      showMoreLink={showMoreLinkContent}
      bannerData={items}
      id={contentType === "OwnerBenefit" ? "osakasetusi" : "lomanvaihto"}
    />
  );
};

export default EpiHorizontalListBlock;
