import { IInviteModule } from ".";
import { IUser } from "./../authorization";

/**
 * Class to skip authorization in local environment
 */
export class inviteModuleDummy implements IInviteModule {
  async handleRedirect(): Promise<IUser | undefined> {
    return Promise.resolve({
      access_token: "42",
      familyName: "meikeläinen",
      givenName: "matti",
      accessToken: "42",
      environment: "any",
      homeAccountId: "42",
      tenantId: "42",
      username: "matti-meikelainen",
      localAccountId: "42",
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  invite(token: string): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  acquireToken(user?: IUser): Promise<string> {
    return Promise.resolve("42");
  }
}
