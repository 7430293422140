import { useTypedTranslation } from "../../features/i18n";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useError500 = () => {
  const { t } = useTypedTranslation();

  const reloadPage = () => {
    window.location.reload();
  };

  return { reloadPage, t };
};

export default useError500;
