import React from "react";
import cx from "classnames";
import { ButtonTheme } from ".";

import "./hc-button.scss";

export type Props = {
  isSecondary?: boolean;
  isSimple?: boolean;
  theme?: ButtonTheme;
  isLinkButton?: boolean;

  /**
   * Button without styles.
   */
  noStyles?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const HcButton = ({
  isSecondary,
  isSimple,
  isLinkButton,
  noStyles,
  theme = "default",
  className,
  children,
  ...props
}: Props): JSX.Element => {
  let buttonClasses = cx("hc-button", {
    "hc-button--secondary": isSecondary,
    "hc-button--simple": isSimple,
    "hc-button--dark": theme === "dark",
    "hc-button--gold": theme === "gold",
    "hc-button--link-button": isLinkButton,
  });

  // Button without styles
  if (noStyles) {
    buttonClasses = "hc-button--no-styles";
  }

  return (
    <button {...props} className={`${buttonClasses} ${className !== undefined ? className : ""}`}>
      {children}
    </button>
  );
};

export default HcButton;
