import React from "react";
import { Tab, TabList } from "react-tabs";
import { CollapsingTabListProps } from ".";
import { TabContent } from "..";
import DropdownMenu, { MenuItem } from "../../dropdown-menu";
import OverflowButton from "./overflow-button";

const CollapsingTabList = ({
  tabs,
  tabIndex,
  parentClass,
  parent,
  windowSize,
  setTabIndex,
  overflowTabs,
  setOverflowTabs,
  overflowIndex,
  setOverflowIndex,
  overflowButtonRef,
  setOverflowButtonRef,
  onMenuItemSelect,
}: CollapsingTabListProps): JSX.Element => {
  const tabsClassName = `${parentClass}__tabs`;
  const tabClassName = `${tabsClassName}__tab`;
  const tabTextClassName = `${tabClassName}__text`;

  React.useEffect(() => {
    /**
     * Retrieves all tabs. Since default tabs do not accept ref-value, they're queried in from `document`.
     */
    const getTabElements = (): Element[] =>
      Array.from(document.getElementsByClassName(tabClassName));

    /**
     * Retrieves the index of first tab which doesn't fit into tab-list. A recursive function.
     * @param index index of current iteration's tab
     * @param widthUsed width used so far
     * @param tabElements all tab elements
     * @param containerWidth tab-list maximum width
     */
    function getOverflowingTabIndex(
      index: number,
      widthUsed: number,
      tabElements: Element[],
      containerWidth: number
    ): number {
      if (index >= tabElements.length) {
        return index;
      }

      const currentElement = tabElements[index];
      const newWidth = widthUsed + Math.floor(currentElement.getBoundingClientRect().width);

      if (newWidth > Math.floor(containerWidth)) {
        return index - 1;
      }

      return getOverflowingTabIndex(index + 1, newWidth, tabElements, containerWidth);
    }

    function tabContentToMenuItems(tabs: TabContent[], startingIndex: number): MenuItem[] {
      const menuItems = tabs.map((tab, index) => ({
        name: tab.header,
        action: () => {
          onMenuItemSelect && onMenuItemSelect(tab.header);
          setTabIndex(index + startingIndex);
        },
      }));

      return menuItems.slice(1);
    }

    const tabElements = getTabElements();

    const parentWidth = parent ? parent.getBoundingClientRect().width : 0;

    const overflowIndex = getOverflowingTabIndex(0, 0, tabElements, parentWidth);

    const overflowTabs = tabs.slice(overflowIndex, tabs.length);

    setOverflowTabs(tabContentToMenuItems(overflowTabs, overflowIndex));
    setOverflowIndex(overflowIndex);
  }, [windowSize, parent, setOverflowIndex, setOverflowTabs, setTabIndex, tabClassName, tabs]);

  return (
    <TabList className={tabsClassName}>
      {tabs.map(({ header }, index) => {
        const isOverflow = index > overflowIndex;

        const selectedAfter: string =
          index === tabIndex - 1 && !isOverflow && tabIndex < overflowIndex
            ? "--selected-after"
            : "";

        const overflowClass: string = isOverflow ? "--overflow" : "";

        const overflowSelected = tabIndex > overflowIndex;
        return (
          <Tab
            key={`tab-container-header-${index}`}
            className={`${tabClassName} ${overflowClass} ${
              overflowSelected ? "overflow-tab-selected" : ""
            }`}
            data-testid={`tab-${index}`}
            tabIndex={isOverflow ? "-1" : "0"}
          >
            <p className={`${tabTextClassName}${selectedAfter}`}>{header}</p>
          </Tab>
        );
      })}

      <OverflowButton
        ref={setOverflowButtonRef}
        isHidden={overflowTabs.length === 0}
        data-testid="overflow-button"
      />

      <DropdownMenu referenceElement={overflowButtonRef} menuItems={overflowTabs} altAxis={false} />
    </TabList>
  );
};

export default CollapsingTabList;
