import React from "react";
import { Trans } from "react-i18next";
import HcText from "../../hc-text";

type BannerModalContentPointsProps = {
  points: string;
  resortName: string;
};

const BannerContentPoints = ({
  points,
  resortName,
}: BannerModalContentPointsProps): JSX.Element => {
  return (
    <>
      <HcText bottomSpace="m" size="s">
        <Trans i18nKey="epi.horizontalList.modal.rciPointsInfo">
          Voit varata omistuksistasi kertyneillä
          <HcText size="s" weight="strong" tag="span">
            {{ points: points }} pisteellä
          </HcText>
          seuraavan lomasi kohteessa {{ resortName }} tai jossain toisessa RCI lomanvaihtoverkon
          kohteessa.
        </Trans>
      </HcText>
    </>
  );
};

export default BannerContentPoints;
