import { differenceInDays } from "date-fns";
import { useState, useEffect, useCallback } from "react";
import { ReservationInfo } from "..";
import { ReservationStatus } from "../../../app/OwnerService-api";
import { DateRangeTheme, ThemedDateRange } from "../../calendar";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useVillasCalendarData = (reservations: ReservationInfo[]) => {
  const [calendarInitialData, setCalendarInitialData] = useState<ThemedDateRange[]>([]);

  /**
   * Sort and set calendar initial data
   * @param {ThemedDateRange[]} data - array of ThemedDateRange
   */
  const updateInitialData = useCallback((data: ThemedDateRange[]) => {
    /**
     * @param {Date} date - date to convert
     * @returns {number} time value in milliseconds
     */
    const getTime = (date: Date): number => {
      return date !== null ? date.getTime() : 0;
    };

    /**
     * @param {ThemedDateRange[]} calendarData
     * @returns {ThemedDateRange[]} calendarData sorted by startDate, ascending
     */
    const sortCalendarData = (calendarData: ThemedDateRange[]): ThemedDateRange[] => {
      return calendarData.slice().sort((a, b) => getTime(a.data.start) - getTime(b.data.start));
    };

    const sorted = sortCalendarData(data);
    setCalendarInitialData(sorted);
  }, []);

  /**
   * @param {Date} start - reservation start
   * @param {Date} end - reservation end
   * @param {Date} created - reservation created
   * @param {(ReservationStatus|undefined)} status - reservation status
   * @returns {ThemedDateRange} reservation as ThemedDateRange
   */
  const getThemedDateRange = (
    start: Date,
    end: Date,
    created: Date,
    status: ReservationStatus | undefined = undefined
  ): ThemedDateRange => {
    let rangeType: DateRangeTheme = "confirmed-black";
    if (status !== undefined && status === ReservationStatus.Pending) {
      rangeType = "unconfirmed";
    } else if (status === undefined) {
      const diff = differenceInDays(start, created);
      rangeType = diff < 30 ? "unconfirmed" : "confirmed-black";
    }

    return {
      data: {
        start: start,
        end: end,
      },
      type: rangeType,
    };
  };

  /**
   * Update initial data when reservations change
   */
  useEffect(() => {
    if (reservations !== undefined) {
      const data: ThemedDateRange[] = reservations.map((item) => {
        return getThemedDateRange(item.startDate, item.endDate, item.reservationDate);
      });

      updateInitialData(data);
    }
  }, [reservations, updateInitialData]);

  return {
    calendarInitialData,
  };
};

export default useVillasCalendarData;
