import { Route } from "react-router-dom";
import Invite from "../../components/invite-status";
import React from "react";
import { RouteWrapperProps } from "..";

/*
 * Invite route is not protected
 */
const InviteRoute = (props: RouteWrapperProps): JSX.Element => (
  <Route {...props} component={Invite} />
);

export default InviteRoute;
