import React from "react";
import { HoldingReserved } from "..";
import { TypedTranslate } from "../../../features/i18n";
import WizardContainer, { Wizard } from "../../wizard-container";
import Info from "./info";
import { getContractLength } from "../utils";

type Props = {
  holding: HoldingReserved;
  translate: TypedTranslate;
} & Pick<Wizard, "cancelWizard" | "confirmWizard">;

const NewCustomerWelcomeOverlay = ({
  holding,
  translate,
  cancelWizard,
  confirmWizard,
}: Props): JSX.Element => {
  const stepIndex = 0;
  const contractLength = getContractLength(holding.contractDate);

  return (
    <WizardContainer stepIndex={stepIndex}>
      <Info
        nextStep={confirmWizard}
        previousStep={cancelWizard}
        resortName={holding.resortName ? holding.resortName : ""}
        contractLength={contractLength}
        translate={translate}
        holding={holding}
      />
    </WizardContainer>
  );
};

export default NewCustomerWelcomeOverlay;
