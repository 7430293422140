import React from "react";
import HcText from "../../hc-text";
import HcHeading from "../../hc-heading";
import Badge from "../../badge";

type BannerModalHeaderProps = {
  primaryHeadline: string;
  secondaryHeadline: string;
  subtitle: string;
  hasCategoryBadge: boolean;
};

const BannerModalHeader = ({
  primaryHeadline,
  secondaryHeadline,
  subtitle,
  hasCategoryBadge = false,
}: BannerModalHeaderProps): JSX.Element => {
  return (
    <div className="banner-modal-header">
      {hasCategoryBadge ? (
        <div className="banner-modal-header-badge-wrapper">
          <Badge theme="green">{secondaryHeadline}</Badge>
        </div>
      ) : (
        <HcText colorVariant="weak" size="xs">{secondaryHeadline}</HcText>
      )}

      <HcHeading semanticElement="h5" variant="h3" bottomSpace="s" topSpace="s">
        {primaryHeadline}
      </HcHeading>
      <HcText colorVariant="weak" size="s" bottomSpace="0">
        {subtitle}
      </HcText>
    </div>
  );
};

export default BannerModalHeader;
