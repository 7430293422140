import React from "react";
import HcHeading from "../hc-heading";
import HcText from "../hc-text";
import ReduxModal from "../redux-modal";
import { IHoldingDto, WeekType } from "../../app/OwnerService-api";
import useVillasReservationModal from "./hooks/use-villas-reservation-modal";
import HcButton from "../hc-button";
import { lang } from "../../features/i18n";
import "./villas-reservation-modal.scss";

type VillasReservationModalProps = {
  id: string;
  holding: IHoldingDto;
};

const VillasReservationModal = ({ id, holding }: VillasReservationModalProps): JSX.Element => {
  const { handleReservationButtonClick, t } = useVillasReservationModal(holding);
  const tRoot = lang.villasReservationModal;

  return (
    <ReduxModal
      id={id}
      ariaLabel="my-storyboook-modal"
      shouldConfirmBeforeClose={false}
      defaultPadding={false}
    >
      <div className="villas-reservation-modal-container">
        <div className="villas-reservation-modal-header">
          <HcText colorVariant="weak">{holding.villasShare}</HcText>
          <HcHeading semanticElement="h5">{holding.resortName}</HcHeading>
        </div>
        <div className="villas-reservation-modal-content-separator-wrapper">
          <div className="villas-reservation-modal-content-separator" />
        </div>
        <div className="villas-reservation-modal-content">
          <HcText size="m" bottomSpace="m" weight="strong">
            {t(tRoot.reservationInfoTitle)}
          </HcText>
          <HcText size="s" bottomSpace="xl">
            {holding.weekType === WeekType.VillasFullOwnership
              ? t(tRoot.reservationInfoVillasFull)
              : t(tRoot.reservationInfoOther)}
          </HcText>
        </div>
        <div className="villas-reservation-modal-footer">
          <HcButton onClick={handleReservationButtonClick} theme="dark">
            {t(tRoot.reservationAction)}
          </HcButton>
        </div>
      </div>
    </ReduxModal>
  );
};

export default VillasReservationModal;
