import * as React from "react";
import { useTypedTranslation, lang } from "../../features/i18n";

import "./form-edit-button.scss";

export type FormEditButtonProps = {
  inEditMode?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const FormEditButton = ({ inEditMode = false, ...props }: FormEditButtonProps): JSX.Element => {
  const { t } = useTypedTranslation();
  const tRoot = lang;

  let content;

  if (inEditMode) {
    content = (
      <button className="form-area-edit-button edit-mode" {...props}>
        {t(tRoot.editLink.cancel)}
      </button>
    );
  } else {
    content = (
      <button className="form-area-edit-button" {...props}>
        {t(tRoot.editLink.default)}
      </button>
    );
  }

  return <>{content}</>;
};

export default FormEditButton;
