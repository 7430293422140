import React from "react";
import { HoldingReserved } from "..";
import WizardContainer, { Wizard } from "../../wizard-container";
import Info from "./info";
import useReservationWeeks from "./use-reservation-weeks";

type Props = {
  holding: HoldingReserved;
} & Pick<Wizard, "cancelWizard" | "confirmWizard">;

const ReservationWeeks = ({ confirmWizard, cancelWizard, holding }: Props): JSX.Element => {
  const stepIndex = 0;
  const { openRentFormModal, selectedWeek, isRented } = useReservationWeeks(holding);

  /**
   * Open rent form modal and close calendar overlay
   */
  const handlePreviousStep = () => {
    openRentFormModal();
    cancelWizard();
  };

  return (
    <WizardContainer stepIndex={stepIndex}>
      <Info
        nextStep={confirmWizard}
        previousStep={handlePreviousStep}
        selectedWeek={selectedWeek}
        resortName={holding.resortName || ""}
        key="reservation-weeks--info"
        isRented={isRented}
      />
    </WizardContainer>
  );
};

export default ReservationWeeks;
