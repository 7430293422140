import * as React from "react";
import {
  FormGroupProps,
  MyProfileKey,
  MyProfileData,
  MyProfileDataFields,
  MyProfileDtoKey,
} from "..";
import usePersonalDataDetails from "./use-personal-data-details";
import { lang } from "../../../features/i18n";
import { Trans } from "react-i18next";
import { MyProfileDto } from "../../../app/OwnerService-api";
import { prepareData as commonPrepareData } from "../profile-utils";
import FormGroup from "../form-group";
import { invalidCharsRegex, composeValidators } from "..";

type PersonalDataProps = FormGroupProps;

const PersonalDataDetails = ({
  editMode,
  onEditModeChange,
  isEditable,
  profileData,
  commonOnSubmit,
  isSubmitting,
  formKey,
}: PersonalDataProps): JSX.Element => {
  const { t } = usePersonalDataDetails();
  const tRoot = lang.profile.personalDataDetails;

  const required = (value: string) => (value ? undefined : t(lang.requiredField));
  const invalidChars = (value: string) =>
    value.match(invalidCharsRegex) === null ? undefined : t(lang.invalidChars);

  const firstRow: MyProfileDtoKey[] = ["nickname"];
  const secondRow: MyProfileDtoKey[] = ["firstName", "lastName"];

  const prepareData = (data: MyProfileDto): MyProfileData[] => {
    const preparedFirstRow = commonPrepareData(data, firstRow);
    const preparedSecondRow = commonPrepareData(data, secondRow);

    return [preparedFirstRow, preparedSecondRow];
  };

  const prepareDataFields = (): MyProfileDataFields[] => {
    const getLabel = (fieldName: MyProfileKey): string => {
      type TField = keyof typeof tRoot;

      return t(tRoot[fieldName as TField]);
    };

    const preparedFirstRow: MyProfileDataFields = {
      nickname: {
        label: getLabel("nickname"),
        validate: composeValidators(required, invalidChars),
      },
    } as MyProfileDataFields;

    const preparedSecondRow = secondRow.reduce(
      (result, fieldName) => ({
        ...result,
        [fieldName]: {
          label: getLabel(fieldName),
          isDisabled: true,
        },
      }),
      {} as MyProfileDataFields
    );

    return [preparedFirstRow, preparedSecondRow];
  };

  const onSubmit = (newData: MyProfileData) => {
    commonOnSubmit(newData, editMode, formKey);
  };

  const description = (
    <Trans i18nKey={t(tRoot.description)}>
      Voit muokata sinulle luotua kutsumanimeä, jolla puhuttelemme sinua jatkossa Osakaspalvelussa.
      Muuttaaksesi muita sinusta osakassopimukseen tallennettuja henkilötietoja, pyydämme sinua
      toimittamaan kopion virkatodistuksesta, passista tai ajokortista rekisterin ylläpitäjälle
      sähköpostitse: <a href="mailto:isannointi@holidayclub.fi">isannointi@holidayclub.fi</a>
    </Trans>
  );

  return (
    <FormGroup
      isEditable={isEditable}
      toggleIsInEditMode={onEditModeChange}
      dataRows={prepareData(profileData)}
      dataFieldRows={prepareDataFields()}
      groupTitle={t(tRoot.title)}
      onSubmit={onSubmit}
      isInEditMode={editMode}
      groupDescription={description}
      isSubmitting={isSubmitting}
    />
  );
};

export default PersonalDataDetails;
