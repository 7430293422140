import React from "react";
import { lang, TypedTranslate } from "../../../features/i18n";
import Col from "../../flex-col";
import HcText from "../../hc-text";
import HcButton from "../../hc-button";

type InfoRentNotPossibleProps = {
  translate: TypedTranslate;
  closeModal: () => void;
};

const InfoRentNotPossible = ({
  translate: t,
  closeModal,
}: InfoRentNotPossibleProps): JSX.Element => {
  const tRoot = lang.rentFormModal.infoRentNotPossible;
  return (
    <>
      <Col className="no-side-padding">
        <HcText size="s" bottomSpace="xxxxl">
          {t(tRoot.description)}
        </HcText>
      </Col>
      <Col className="no-side-padding">
        <HcButton onClick={closeModal}>{t(tRoot.closeButtonText)}</HcButton>
      </Col>
    </>
  );
};

export default InfoRentNotPossible;
