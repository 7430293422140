import { useState } from "react";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useSelections = () => {
  /**
   * These are reservation flow selections that are updated at select-villas
   * and used to create selectedReservations array for confirm-villas
   */
  const [selections, setSelections] = useState<Set<number>>(new Set());

  const clearSelections = () => {
    setSelections(new Set());
  };

  return {
    selections,
    clearSelections,
    setSelections,
  };
};

export default useSelections;
