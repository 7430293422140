import React from "react";
import HcHeading from "../../hc-heading";
import parse from "html-react-parser";
import { ContentOwnerBenefitDto, BenefitType } from "../../../app/OwnerService-api";
import { options } from "./benefit-card-content-utils";

import { BadgeThemeType } from "../../badge";
import { TypedTranslate } from "../../../features/i18n";
import { lang } from "../../../features/i18n";
import Badge from "../../badge";
import HcText from "../../hc-text";
import BannerModal from "../../banner/banner-modal";
import useBenefitCard from "./use-benefit-card";

import "./benefit-card.scss";

type BenefitCardProps = {
  data: ContentOwnerBenefitDto;
  translate: TypedTranslate;
  id: string;
  resetFilters: () => void;
};

const BenefitCard = ({ data, translate: t, id, resetFilters }: BenefitCardProps): JSX.Element => {
  const { handleCardClick } = useBenefitCard(id, data);
  const tRoot = lang.benefitsPage.benefitCard;

  const getBadgeTheme = (item: BenefitType): BadgeThemeType => {
    let result: BadgeThemeType = "grey";

    switch (item) {
      case BenefitType.NewShareOwner:
        result = "green";
        break;
      case BenefitType.Villas:
        result = "villas";
        break;
      case BenefitType.Gold:
        result = "black";
        break;
      case BenefitType.ShareOwner:
      case BenefitType.Destination:
        result = "beige";
        break;
      case BenefitType.RCIPoints:
        result = "green";
        break;
    }

    return result;
  };

  const getBadgeText = (item: BenefitType): string => {
    let result = "";

    switch (item) {
      case BenefitType.NewShareOwner:
        result = t(tRoot.newShareOwnerBadge);
        break;
      case BenefitType.Villas:
        result = t(tRoot.villasBadge);
        break;
      case BenefitType.Gold:
        result = t(tRoot.goldBadge);
        break;
      case BenefitType.ShareOwner:
        result = t(tRoot.shareOwnerBadge);
        break;
      case BenefitType.Destination:
        result = t(tRoot.destinationBadge);
    }

    return result;
  };

  return (
    <div className="benefit-search-card-container">
      <button className="benefit-card-button" onClick={handleCardClick}>
        <div className="benefit-card-button-content-wrapper">
          <div className="benefit-card-badge-row">
            <HcText colorVariant="weakgold" size="s" bottomSpace="0">
              {data.category}
            </HcText>
            {data.benefitType !== undefined && (
              <div className="benefit-card-badge-wrapper">
                <Badge theme={getBadgeTheme(data.benefitType)}>
                  {getBadgeText(data.benefitType)}
                </Badge>
              </div>
            )}
          </div>
          <HcHeading semanticElement="h5" variant="h6" topSpace="s">{data.title}</HcHeading>
          {data.subtitle && (
            <HcText colorVariant="weak" size="s" bottomSpace="l">
              {data.subtitle}
            </HcText>
          )}
          <div className="benefit-card-separator" />
          <div className="benefit-card-parsed-content">
            {data.content !== undefined && parse(data.content, options)}
          </div>
        </div>
      </button>
      <BannerModal data={data} itemType="OwnerBenefit" id={id} resetFilters={resetFilters} />
    </div>
  );
};

export default BenefitCard;
