import Col from "../../flex-col";
import Row from "../../flex-row";
import HcButton from "../../hc-button";
import { WizardStep } from "../../wizard-container";
import useInfoRent from "./use-info-rent";
import React from "react";
import { lang } from "../../../features/i18n";
import HcText from "../../hc-text";
import HcCheckbox from "../../hc-checkbox";
import "./info-rent.scss";

type Props = WizardStep;

const InfoRent = ({ nextStep, previousStep }: Props): JSX.Element => {
  const { t, isConfirmed, toggleIsConfirmed, handleNextStep } = useInfoRent(nextStep);
  const tRoot = lang.rentFormModal;
  const classNameRoot = "info-rent";
  const classNameContainer = `${classNameRoot}__container no-side-padding`;

  return (
    <Row className={classNameRoot}>
      <Col className={classNameContainer}>
        <Row>
          <Col className="no-side-padding">
            <HcText size="s" bottomSpace="l">
              {t(tRoot.infoRent.text)}
            </HcText>

            <HcCheckbox
              ariaLabel="i-have-not-targetted-holding-in-RCI"
              onChange={toggleIsConfirmed}
            >
              <HcText size="s" colorVariant="weak">
                {t(tRoot.infoRent.acceptTerms)}
              </HcText>
            </HcCheckbox>
          </Col>
        </Row>

        <Row>
          <Col className="no-side-padding">
            <HcButton onClick={handleNextStep} disabled={!isConfirmed}>
              {t(tRoot.continue)}
            </HcButton>
            <HcButton onClick={previousStep} isSecondary>
              {t(tRoot.back)}
            </HcButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InfoRent;
