import React, { useState, useEffect } from "react";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useNewAccount = (
  resetNewAccountFeedback: () => void,
  onNewAccountSave: (e: React.MouseEvent<HTMLButtonElement>, value: string) => void
) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [isNewIBANinputEmpty, setIsNewIBANinputEmpty] = useState<boolean>(true);

  // Simple iban validation to enable save button. We do validate IBAN properly when user clicks save
  const isValidIBANInput = (value: string) => {
    const regex = /^[A-Z]{2}[0-9A-Z]*$/i;
    return value.match(regex) !== null && value.length >= 15;
  };

  const handleIBANInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.toUpperCase().replace(/\s+/g, "");
    const isValid = isValidIBANInput(newValue);

    const isEmpty = newValue.length === 0;
    setIsNewIBANinputEmpty(isEmpty);

    setIsSaveDisabled(!isValid);
    setAccountNumber(newValue);
  };

  const { sendDataToGTM } = useGTM();

  useEffect(() => {
    if (isNewIBANinputEmpty) {
      resetNewAccountFeedback();
    }
  }, [isNewIBANinputEmpty]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Google Tag Manager
    sendDataToGTM({
      event: "rent_week_input_account",
      event_description: "Anna tilinumero",
    });
    onNewAccountSave(e, accountNumber);
  };

  return {
    isSaveDisabled,
    handleIBANInputChange,
    accountNumber,
    handleClick,
  };
};

export default useNewAccount;
