import { useState, useEffect } from "react";
import { BenefitType, ContentOwnerBenefitDto } from "../../../app/OwnerService-api";

type BenefitFilter = {
  filterType: BenefitType;
  isActive: boolean;
};

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useBenefitSearch = (
  benefits: ContentOwnerBenefitDto[],
  initSearchCategories?: BenefitType[] | undefined
) => {
  const [allSelected, setIsAllSelected] = useState<boolean>(false);
  const [filters, setFilters] = useState<BenefitFilter[]>([]);
  const [visibleFilters, setVisibleFilters] = useState<BenefitFilter[]>([]);
  const [visibleBenefits, setVisibleBenefits] = useState<ContentOwnerBenefitDto[]>(benefits);

  // Create filters and visible filter buttons (checkbox) from benefits data
  useEffect(() => {
    let filterData: BenefitFilter[] = [];
    benefits.forEach((item) => {
      if (item.benefitType !== undefined) {
        const isAdded = filterData.find((element) => element.filterType === item.benefitType);
        if (isAdded === undefined) {
          const newItem: BenefitFilter = {
            filterType: item.benefitType,
            isActive: true,
          };
          filterData.push(newItem);
        }
      }
    });

    // Check if initial search filters added
    if (initSearchCategories !== undefined) {
      filterData = filterData.map((item) => {
        const result: BenefitFilter = {
          filterType: item.filterType,
          isActive: false,
        };
        if (initSearchCategories.includes(item.filterType)) {
          result.isActive = true;
        }

        return result;
      });
    }

    const visibleFilters = filterData.filter(
      (item) => item.filterType !== BenefitType.NewShareOwner
    );

    setFilters(filterData);
    setVisibleFilters(visibleFilters);
  }, []);

  /**
   * Toggle filter checkbox when pressed
   * @param filterType
   */
  const toggleFilter = (filterType: BenefitType) => {
    const updatedFilters: BenefitFilter[] = filters.map((item) => {
      const data: BenefitFilter = item;
      if (item.filterType === filterType && filterType !== BenefitType.ShareOwner) {
        data.isActive = !item.isActive;
      }
      if (
        filterType === BenefitType.ShareOwner &&
        (item.filterType === filterType || item.filterType === BenefitType.NewShareOwner)
      ) {
        data.isActive = !item.isActive;
      }
      return data;
    });

    const parsedFilterData = updatedFilters.filter(
      (item) => item.filterType !== BenefitType.NewShareOwner
    );

    setFilters(updatedFilters);
    setVisibleFilters(parsedFilterData);
  };

  const updateIsAllSelected = () => {
    const isAllActive = filters.every((item) => item.isActive === true);

    setIsAllSelected(isAllActive);
  };

  const selectAllFilters = () => {
    const allActive = filters.map((item) => {
      return {
        filterType: item.filterType,
        isActive: true,
      };
    });
    const allVisible = visibleFilters.map((item) => {
      return {
        filterType: item.filterType,
        isActive: true,
      };
    });

    setFilters(allActive);
    setVisibleFilters(allVisible);
  };

  useEffect(() => {
    updateIsAllSelected();
    updateVisibleBenefits();
  }, [filters]);

  const updateVisibleBenefits = () => {
    const activeFilters = filters.filter((item) => item.isActive).map((item) => item.filterType);
    const newVisibleBenefits = benefits.filter(
      (item) => item.benefitType !== undefined && activeFilters.includes(item.benefitType)
    );
    setVisibleBenefits(newVisibleBenefits);
  };

  return {
    selectAllFilters,
    toggleFilter,
    allSelected,
    filters,
    visibleBenefits,
    visibleFilters,
  };
};

export default useBenefitSearch;
