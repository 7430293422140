import React from "react";
import { EpiTestimonialBlockProps } from ".";
import HcHeading from "../hc-heading";
import VideoPlayer from "../video-player";
import PlaceholderImg from "../placeholder-img";
import ResponsiveImage from "../responsive-img";
import CardContainer from "../card-container";
import HcText from "../hc-text";
import HcButton from "../hc-button";
import ReduxModal from "../redux-modal";
import parse from "html-react-parser";
import { getConvertOptions } from "../../features/utils";
import { lang } from "../../features/i18n";
import useEpiTestimonialBlock from "./use-epi-testimonial-block";

import "./epi-testimonial-block.scss";

const EpiTestimonialBlock = ({ data }: EpiTestimonialBlockProps): JSX.Element => {
  const videoModalId = "testimonial-video";
  const { t, openModal } = useEpiTestimonialBlock(videoModalId);
  const { imageUrl, videoUrl, title, subtitle, description } = data;
  const tRoot = lang.epi.testimonialBlock;
  const options = getConvertOptions("s", "xxl");

  let parsedDescription: string | JSX.Element | JSX.Element[] = <></>;
  if (description !== undefined) {
    parsedDescription = parse(description, options);
  }

  return (
    <div className="testimonial-block-container epi-block">
      {imageUrl !== undefined ? (
        <ResponsiveImage
          alt="sample desc"
          src={imageUrl}
          className="testimonial-block-background"
        />
      ) : (
        <PlaceholderImg roundedCorners={false} height="80vh" />
      )}
      <div className="testimonial-block-content-container">
        <CardContainer className="testimonial-block-card-container">
          <HcText colorVariant="weak">Osakkaat kertovat</HcText>
          <HcHeading semanticElement="h5" bottomSpace="s">
            {title}
          </HcHeading>
          <HcText colorVariant="weak" size="s">
            {subtitle}
          </HcText>
          <div className="testimonial-divider" />
          <div className="t-l">{parsedDescription}</div>
          <HcButton onClick={() => openModal("testimonial-modal-" + title)}>
            {t(tRoot.showVideoButton)}
          </HcButton>
        </CardContainer>
      </div>

      <ReduxModal ariaLabel="Testimonial modal window" id={videoModalId} hasWidth={false}>
        <div className="video-wrapper">
          <VideoPlayer
            aspectRatio="16:9"
            videoUrl={videoUrl}
            className="testimonial-modal-video"
            title={title}
          />
        </div>
      </ReduxModal>
    </div>
  );
};

export default EpiTestimonialBlock;
