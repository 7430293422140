import React from "react";
import { useRentFormModalRedux } from "./hooks";
import RentFormModal from "./rent-form-modal";

const RentFormModalRedux = (): JSX.Element => {
  const {
    isOpen,
    closeRentFormModal,
    holding,
    rented,
    rentFormWeek,
    additionalInformation,
  } = useRentFormModalRedux();

  return (
    <RentFormModal
      closeRentFormModal={closeRentFormModal}
      holding={holding}
      isOpen={isOpen}
      rented={rented}
      weekToRent={rentFormWeek}
      additionalInformation={additionalInformation}
    />
  );
};

export default RentFormModalRedux;
