import { useEffect } from "react";

const useOutsideClick = (ref: Element | null, callback: () => void): void => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (event.target) {
        const target = event.target as Node;

        if (ref && !ref.contains(target)) {
          callback();
        }
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useOutsideClick;
