import React from "react";
import HcHeading from "../../components/hc-heading";
import HcText, { TextSizeType } from "../../components/hc-text";
import { SpacingType } from "../types";
import { HTMLReactParserOptions, domToReact } from "html-react-parser";
import { Element, DataNode } from "domhandler/lib/node";

const convertToComponent = (tag: string, nodeClass: string, content: string): JSX.Element => {
  switch (tag) {
    case "h1":
    case "h2":
    case "h3":
    case "h5":
    case "h6":
      return <HcHeading semanticElement={tag}>{content}</HcHeading>;

    case "h4":
      return (
        <HcHeading semanticElement="h2" variant="h4" className="welcome-block-top-space">
          {content}
        </HcHeading>
      );

    case "p":
      switch (nodeClass) {
        case "ingress":
          return (
            <HcText tag={tag} bottomSpace="l" size="m">
              {content}
            </HcText>
          );
        case "footnote":
          return (
            <HcText tag={tag} bottomSpace="l" size="s" weight="strong">
              {content}
            </HcText>
          );
        default:
          return (
            <HcText tag={tag} bottomSpace="l" size="s">
              {content}
            </HcText>
          );
      }
    case "span":
      return <HcText tag={tag}>{content}</HcText>;
    case "strong":
      return (
        <HcText tag="span" weight="strong" size="s">
          {content}
        </HcText>
      );
    case "strong-p":
      return (
        <HcText tag="p" weight="strong" size="s">
          {content}
        </HcText>
      );

    default:
      return React.createElement(tag, {}, content);
  }
};

/**
 * Prevent styling errors if the text content in a p element contains a br tag.
 */
const convertNodeWithChildrenToComponent = (node: Element): JSX.Element => {
  return <HcText bottomSpace="l">{domToReact(node.children)}</HcText>;
};

const convertOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.children) {
      const nodeClass = domNode.attribs.class;
      if (domNode.children.length === 1 && domNode.children[0].type === "text") {
        const nodeContent = domNode.children[0] as DataNode;
        return convertToComponent(domNode.name, nodeClass, nodeContent.data);
      } else if (domNode.children.length === 1 && domNode.children[0] instanceof Element) {
        const nodeContent = domNode.children[0] as Element;
        const childData = nodeContent.children[0] as DataNode;

        if (domNode.name === "p" && nodeContent.tagName === "strong") {
          return convertToComponent("strong-p", nodeClass, childData.data);
        }
      } else if (
        domNode.children.length > 1 &&
        domNode.name === "p" &&
        domNode.children[0].type === "text"
      ) {
        return convertNodeWithChildrenToComponent(domNode);
      } else {
        return domNode;
      }
    }
  },
};

/**
 * Parse html string to HcText component(s). Html strings from epi for this function should
 * only contain p element(s) but nested br tags will not break the styling.
 * Also removes any empty elements from content.
 */
export const getConvertOptions = (
  size: TextSizeType,
  bottomSpace: SpacingType
): HTMLReactParserOptions => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.children) {
        if (domNode.children[0] !== undefined && domNode.children[0].type === "text") {
          const content = domNode.children[0] as DataNode;
          if (content.data === "\u00a0") {
            return <></>;
          }
        }
        return (
          <HcText size={size} bottomSpace={bottomSpace}>
            {domToReact(domNode.children)}
          </HcText>
        );
      }
    },
  };
  return options;
};

export default convertOptions;
