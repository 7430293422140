import React from "react";
import { TypedTranslate } from "../../../features/i18n";
import { lang } from "../../../features/i18n";
import HcText from "../../hc-text";
import Col from "../../flex-col";
import HcButton from "../../hc-button";
import { WizardStep } from "../../wizard-container";
import { SelectedCards } from "../hooks";
import { MembershipLevel } from "../../../app/OwnerService-api";

type SendMobileCardOrderProps = {
  translate: TypedTranslate;
  phone: string;
  selectedCards: SelectedCards[];
} & WizardStep;

const SendMobileCardOrder = ({
  translate: t,
  phone,
  nextStep,
  previousStep,
  selectedCards,
}: SendMobileCardOrderProps): JSX.Element => {
  const tRoot = lang.membershipCard.orderMobileCard;

  const basicCardSelected = selectedCards
    .filter((card) => card.card.membershipLevel === MembershipLevel.Basic)
    .some((card) => card.selected === true);
  const goldCardSelected = selectedCards
    .filter((card) => card.card.membershipLevel === MembershipLevel.Gold)
    .some((card) => card.selected === true);
  const villasCardSelected = selectedCards
    .filter((card) => card.card.membershipLevel === MembershipLevel.Villas)
    .some((card) => card.selected === true);

  return (
    <Col>
      <HcText weight="strong" bottomSpace="m">
        {t(tRoot.confirmOrder)}
      </HcText>
      {basicCardSelected && (
        <>
          <HcText weight="strong" size="s">
            {t(tRoot.basicNumberTitle)}
          </HcText>
          <HcText size="s" bottomSpace="l">
            {phone}
          </HcText>
        </>
      )}
      {villasCardSelected && (
        <>
          <HcText weight="strong" size="s">
            {t(tRoot.villasNumberTitle)}
          </HcText>
          <HcText size="s" bottomSpace="s">
            {phone}
          </HcText>
        </>
      )}
      {goldCardSelected && (
        <>
          <HcText weight="strong" size="s">
            {t(tRoot.goldNumberTitle)}
          </HcText>
          <HcText size="s" bottomSpace="s">
            {phone}
          </HcText>
        </>
      )}
      <div className="mb-l" />
      <HcButton onClick={nextStep}>{t(tRoot.orderMobileCard)}</HcButton>
      <HcButton onClick={previousStep} isSecondary>
        {t(tRoot.previousStep)}
      </HcButton>
    </Col>
  );
};

export default SendMobileCardOrder;
