import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import FallbackComponent from "./fallback-component";
import useProtectedRoute from "./hooks";

/* eslint-disable @typescript-eslint/no-explicit-any */
type Props = Pick<RouteProps, "exact" | "location" | "path" | "render" | "sensitive" | "strict"> & {
  render: (props: RouteComponentProps<any>) => React.ReactNode;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const ProtectedRoute = ({ render: childRender, ...props }: Props): JSX.Element => {
  const { protectedRender } = useProtectedRoute(childRender, () => <FallbackComponent />);

  return <Route render={(props) => protectedRender(props)} {...props} />;
};

export default ProtectedRoute;
