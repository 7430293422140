import React from "react";

type Props = {
  children: (JSX.Element | null)[] | JSX.Element;
  stepIndex: number;
} & React.HTMLProps<HTMLDivElement>;

/**
 * Wizard or switch container, which only shows one of the child components at any given time.
 * Returns child-components inside a div-component.
 *
 * It should be noted that the component using the WizardContainer should not inherit the stepIndex
 * from a parent component to avoid any unwanted re-rendering of WizardContainer's content.
 *
 * If inheriting data from parent is mandatory, the updates done to the inherited data should be kept
 * at minimum.
 */
const WizardContainer = ({ children, stepIndex, ...divProps }: Props): JSX.Element => {
  const childArray = Array.isArray(children) ? children.filter((v) => v != null) : [children];
  const content = stepIndex < childArray.length && stepIndex >= 0 ? childArray[stepIndex] : <div />;

  return <div {...divProps}>{content}</div>;
};

export default WizardContainer;
