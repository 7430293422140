import React from "react";
import { IContentImageBlockDto } from "../../../app/OwnerService-api";
import AspectRatioWrapper from "../../aspect-ratio-wrapper";
import PlaceholderImg from "../../placeholder-img";
import Col from "../../flex-col";
import { LazyImage } from "react-lazy-images";

type ImageBlockProps = {
  data: IContentImageBlockDto;
  className?: string;
};

const ImageBlock = ({ data, className }: ImageBlockProps): JSX.Element => {
  const { title, url, cssClass } = data;

  let content;
  if (url !== undefined) {
    content = (
      <Col l={6} className={`${className !== undefined ? className : ""}`}>
        <LazyImage
          src={url}
          alt={title}
          placeholder={({ ref }) => (
            <AspectRatioWrapper ref={ref}>
              <PlaceholderImg roundedCorners={false} className="generic-loading-state" />
            </AspectRatioWrapper>
          )}
          actual={({ imageProps }) =>
            cssClass !== "map" ? (
              <AspectRatioWrapper>
                <div
                  className="image-block-portrait-placeholder"
                  style={{ backgroundImage: `url(${url})` }}
                  role="img"
                  aria-label={imageProps.alt}
                >
                  {" "}
                </div>
              </AspectRatioWrapper>
            ) : (
              <img src={imageProps.src} alt={imageProps.alt} className="image-map" />
            )
          }
        />
      </Col>
    );
  } else {
    content = (
      <Col l={6} className={`${className !== undefined ? className : ""}`}>
        <AspectRatioWrapper aspectRatio="3:2">
          <PlaceholderImg roundedCorners={false} />
        </AspectRatioWrapper>
      </Col>
    );
  }

  return content;
};

export default ImageBlock;
