import { useState } from "react";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { selectUser } from "../../../store/authorization";
import {
  cancelHoldingReservation,
  closeReservationProcess as closeReservationProcessAction,
  selectIsReservationProcessOpen,
} from "../../../store/reservations";
import { selectClickedDate } from "../../../store/calendar";
import { HoldingReserved } from "..";
import { CancellationReservationSlot } from ".";
import { ReservationStatus, WeekType } from "../../../app/OwnerService-api";
import { isSameDay } from "date-fns";
import { isReservation } from "../utils";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useCancellationVillas = (holding: HoldingReserved) => {
  const { t } = useTypedTranslation();
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [reservationsToCancel, setReservationsToCancel] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const isReservationProcessOpen = useSelector(selectIsReservationProcessOpen);
  const clickedDate = useSelector(selectClickedDate);

  /**
   * Reduce active reservations to CancellationReservationSlot type
   */
  const reservations = holding.reservations.reduce<CancellationReservationSlot[]>(
    (slots, reservation) => {
      if (
        reservation.reservationId &&
        (reservation.reservationStatus === ReservationStatus.Accepted ||
          reservation.reservationStatus === ReservationStatus.Pending)
      ) {
        const weekNumber =
          holding.weekType === WeekType.VillasFractional && holding.weeks
            ? holding.weeks.find((w) => isSameDay(new Date(w.weekStart), reservation.startDate))
                ?.weekNumber
            : undefined;

        const newSlot: CancellationReservationSlot = {
          endDate: reservation.endDate,
          resortName: holding.resortName || "",
          room: holding.roomNumber || "",
          startDate: reservation.startDate,
          weekNumber,
          reservationId: reservation.reservationId,
          reservationStatus: reservation.reservationStatus,
        };
        return [...slots, newSlot];
      } else {
        return slots;
      }
    },
    []
  );

  /**
   * Find the reservation that was clicked
   */
  const clickedReservation = clickedDate
    ? reservations.find((reservation) =>
        isReservation(clickedDate, reservation.startDate, reservation.endDate)
      )
    : undefined;

  /**
   * When cancellation is opened, initial reservation is automatically selected.
   * Initial reservation is clickedReservation if defined, else first reservation.
   */
  const selectedReservation = clickedReservation || reservations[0];

  /**
   * Cancel a reservation by reservationId
   * @param {string} reservationId
   * @returns {Promise} Promise response, fulfilled when cancellation succesful
   */
  const cancelReservation = async (reservationId: string) => {
    const promise = dispatch(
      cancelHoldingReservation({ reservationId: reservationId, requestor: user })
    );
    return promise;
  };

  /**
   * Cancel multiple reservations
   */
  const cancelReservations = async () => {
    await Promise.all(
      reservationsToCancel.map(async (reservationId) => await cancelReservation(reservationId))
    );
  };

  /**
   * Set reservation process state as closed at redux store
   */
  const closeReservationProcess = () => {
    if (isReservationProcessOpen) {
      dispatch(closeReservationProcessAction());
    }
  };

  return {
    t,
    stepIndex,
    setStepIndex,
    reservationsToCancel,
    setReservationsToCancel,
    cancelReservations,
    closeReservationProcess,
    reservations,
    selectedReservation,
  };
};

export default useCancellationVillas;
