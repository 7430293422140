import React, { useState } from "react";
import cx from "classnames";

import "./hc-checkbox.scss";

type LabelPositionType = "left" | "right";

export type Props = {
  /**
   * Single/multiline label element like <div><p>Title</p><p>Subtitle</p></div>
   */
  children?: React.ReactNode;
  ariaLabel: string;

  /**
   * Left or right aligned label
   */
  labelPosition?: LabelPositionType;

  /**
   * Is checkbox and label wrapped inside bordered box
   */
  hasContainer?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const HcCheckbox = ({
  ariaLabel,
  children,
  labelPosition = "right",
  hasContainer = false,
  className,
  ...inputProps
}: Props): JSX.Element => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const checkboxClasses = cx("hc-checkbox", {
    "hc-checkbox__container": hasContainer,
    "hc-checkbox__label--right": labelPosition === "right",
    "hc-checkbox__label--left": labelPosition === "left",
    [`${className}`]: className !== undefined,
  });

  const checkmarkClasses = cx("hc-checkbox__checkmark", {
    "hc-checkbox__checkmark--left": labelPosition === "right",
    "hc-checkbox__checkmark--right": labelPosition === "left",
    "hc-checkbox__checkmark--focused": hasFocus,
  });

  return (
    <label className={checkboxClasses}>
      <input
        {...inputProps}
        tabIndex={0}
        type="checkbox"
        aria-label={ariaLabel}
        className="hc-checkbox__checkbox"
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
      {children}
      <span className={checkmarkClasses}></span>
    </label>
  );
};

export default HcCheckbox;
