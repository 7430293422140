import { useState, useEffect } from "react";
import {
  ChangeType,
  IContentHolidayChangeDto,
  IContentOwnerBenefitDto,
  PopupLink,
} from "../../../app/OwnerService-api";
import { useTypedTranslation } from "../../../features/i18n";
import { BannerItemType } from "..";
import useBannerContent from "../hooks/use-banner-content";
import useBannerTheme from "../hooks/use-banner-theme";
import { lang } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBannerModal = (
  data: IContentOwnerBenefitDto | IContentHolidayChangeDto,
  itemType: BannerItemType
) => {
  const [popupDescription, setPopupDescription] = useState<string | undefined>("");
  const [popupDetails, setPopupDetails] = useState<string | undefined>("");
  const [popupLink, setPopupLink] = useState<string | undefined>("");
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [hasCategoryBadge, setHasCategoryBadge] = useState<boolean>(false);
  const [totalPoints, setTotalPoints] = useState<string | undefined>("");
  const [resortName, setResortName] = useState<string | undefined>("");
  const [popupLinks, setPopupLinks] = useState<PopupLink[] | undefined>(undefined);

  const { primaryHeadline, secondaryHeadline, subHeadline } = useBannerContent(data, itemType);
  const { buttonTheme: modalButtonTheme } = useBannerTheme(data, itemType);
  const { t } = useTypedTranslation();
  const tRoot = lang.epi.horizontalList.modal;

  useEffect(() => {
    if (itemType === "OwnerBenefit") {
      const item = data as IContentOwnerBenefitDto;
      setPopupLinks(item.popupLinks);
      setPopupDescription(item.popupDescription);
      setPopupDetails(item.popupDetails);
    }
    if (itemType === "HolidayChange") {
      const item = data as IContentHolidayChangeDto;

      const hasBadge = item.changeType === ChangeType.RCIPoints;
      const resortName = item.category || "";
      const description = item.changeType === ChangeType.RCIWeeks ? t(tRoot.rciWeeksInfo) : "";
      const hLink =
        item.link !== undefined && item.link.length > 0 ? item.link : t(tRoot.rciLinkDefaultTarget);

      const pointsString = item.totalPoints
        ? new Intl.NumberFormat("fi-FI").format(item.totalPoints)
        : "";

      setPopupLink(hLink);
      setHasCategoryBadge(hasBadge);
      setPopupDescription(description);
      setTotalPoints(pointsString);
      setResortName(resortName);
    }
  }, []);

  return {
    t,
    primaryHeadline,
    secondaryHeadline,
    modalButtonTheme,
    popupLinks,
    popupLink,
    popupDescription,
    popupDetails,
    subHeadline,
    showDetails,
    setShowDetails,
    hasCategoryBadge,
    resortName,
    totalPoints,
  };
};

export default useBannerModal;
