import { BannerItemType } from "..";
import { IContentHolidayChangeDto, IContentOwnerBenefitDto } from "../../../app/OwnerService-api";
import useReduxModal from "../../redux-modal/use-redux-modal";
import useBannerContent from "./use-banner-content";
import useBannerTheme from "./use-banner-theme";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useBanner = (
  data: IContentOwnerBenefitDto | IContentHolidayChangeDto,
  itemType: BannerItemType,
  modalId: string
) => {
  const {
    primaryHeadline,
    secondaryHeadline,
    subHeadline,
    badgeAdditionalUnit,
    imgSrc,
    imgAlt,
  } = useBannerContent(data, itemType);
  const { badge: badgeTheme, badgeText } = useBannerTheme(data, itemType);
  const { openModal } = useReduxModal(modalId);
  const { t } = useTypedTranslation();

  const handleCardClick = () => {
    openModal("");
  };

  return {
    handleCardClick,
    primaryHeadline,
    secondaryHeadline,
    subHeadline,
    badgeAdditionalUnit,
    imgSrc,
    imgAlt,
    badgeTheme,
    badgeText,
    t,
  };
};

export default useBanner;
