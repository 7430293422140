import * as React from "react";
import NavMenu from "../nav-menu";
import Footer from "../footer";
import GenericToast from "../generic-toast";

import "./layout.scss";

const Layout = (props: { children?: React.ReactNode }): JSX.Element => (
  <>
    <NavMenu />
    <GenericToast />
    <main className="main-content-container">{props.children}</main>
    <Footer />
  </>
);

export default Layout;
