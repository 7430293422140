import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CardOrderState } from ".";
import {
  IMembershipCardOrderDto,
  MembershipCardClient,
  MembershipCardOrderDto,
} from "../../app/OwnerService-api";
import { FetchManager, IUser } from "../../features/authorization";
import { toast } from "react-toastify";
import { getToastContent } from "../toast-translations";

const initialState: CardOrderState = {
  loading: "idle",
};

type CardOrderRequest = {
  requestor: IUser | undefined;
} & IMembershipCardOrderDto;

export const createCardOrder = createAsyncThunk(
  "cardOrder/newCardOrder",
  async ({ cardType, cardFormat, requestor }: CardOrderRequest) => {
    const client = new MembershipCardClient(undefined, new FetchManager(requestor));

    const response = await client.orderCard(
      new MembershipCardOrderDto({
        cardType,
        cardFormat,
      })
    );

    return response;
  }
);

const extraNewCardOrderReducers = (builder: ActionReducerMapBuilder<CardOrderState>) => {
  builder.addCase(createCardOrder.pending, (state) => {
    state.loading = "pending";
  });

  builder.addCase(createCardOrder.fulfilled, (state) => {
    state.loading = "success";
    toast.success(getToastContent(true));
  });

  builder.addCase(createCardOrder.rejected, (state, action) => {
    state.loading = "error";
    console.error(JSON.stringify(action.error, null, 4));
    toast.error(getToastContent(false));
  });
};

const cardOrderSlice = createSlice({
  initialState,
  name: "cardOrder",
  reducers: {},
  extraReducers: (builder) => {
    extraNewCardOrderReducers(builder);
  },
});

export default cardOrderSlice;
