import { routerMiddleware } from "connected-react-router";
import rootReducer from "./root-reducer";
import { configureStore } from "@reduxjs/toolkit";
import { default as browserHistory } from "../features/history";
import { History } from "history";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function configureAppStore(history?: History, initialState?: any) {
  const middleware = [routerMiddleware(history ? history : browserHistory)];
  const reducer = rootReducer(history ? history : browserHistory);

  return configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).prepend(...middleware),
    preloadedState: initialState,
  });
}
