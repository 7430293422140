import React from "react";
import Row from "../flex-row";
import Col from "../flex-col";
import HcButton from "../hc-button";
import { TypedTranslate } from "../../features/i18n";
import { lang } from "../../features/i18n";
import { ActiveOrder } from "./hooks";

type OrderCardActionBlockProps = {
  isOrderingDisabled: boolean;
  translate: TypedTranslate;
  setEditMode: (value: boolean) => void;
  changeActiveOrder: (value: ActiveOrder) => void;
};

const OrderCardActionBlock = ({
  isOrderingDisabled,
  translate: t,
  changeActiveOrder,
}: OrderCardActionBlockProps): JSX.Element => {
  const tRoot = lang.membershipCard;

  return (
    <Row>
      <Col>
        <HcButton
          disabled={isOrderingDisabled}
          onClick={() => changeActiveOrder("mobile-card-order-wip")}
        >
          {t(tRoot.orderNewMobileCard)}
        </HcButton>
      </Col>
    </Row>
  );
};

export default OrderCardActionBlock;
