import { MyProfileDto } from "../../app/OwnerService-api";
import InputAdapter from "./input-adapter";
import Profile from "./profile";

export const formTitleStyle = {
  marginBottom: 8,
} as React.CSSProperties;

export const formGroupStyle = {
  marginBottom: 0,
} as React.CSSProperties;

export const saveButtonRowStyle = {
  marginBottom: 0,
  marginTop: 40,
} as React.CSSProperties;

export const passwordSaveButtonRowStyle = {
  marginBottom: 0,
  marginTop: 20,
  justifyContent: "space-between",
} as React.CSSProperties;

export type ProfileSectionsType = {
  login: boolean;
  personal: boolean;
  contact: boolean;
  address: boolean;
};

export type FormGroupProps = {
  profileData: MyProfileDto;
  isEditable: boolean;
  editMode: boolean;
  onEditModeChange: () => void;
  commonOnSubmit: (
    newData: MyProfileData,
    editMode: boolean,
    formKey: keyof ProfileSectionsType
  ) => void;
  isSubmitting: boolean;
  formKey: keyof ProfileSectionsType;
};

// regex for invalid chars
/* eslint-disable-next-line  no-useless-escape */
export const invalidCharsRegex = /(\<|\>)|(\&|\”|\’|\/|\"|\')$/g;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export type CustomProfileKey = "password" | "oldPassword" | "newPassword" | "repeatPassword";
export type MyProfileDtoKey = keyof MyProfileDto;
export type MyProfileKey = MyProfileDtoKey | CustomProfileKey;
export type MyProfileData = Partial<MyProfileDto & Record<CustomProfileKey, string>>;

/**
 * `AdapterComponent` replaces the default `InputAdapter`
 */
export type MyProfileField = {
  label: string;
  validate?: (value: string) => string | undefined;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  type?: string;
  AdapterComponent?: typeof InputAdapter;
  placeholder?: string;
  infoText?: string;
  successText?: string;
  showErrorsWhenActive?: boolean;
  inputId?: string;
  format?: (value: string) => string;
};
export type MyProfileDataFields = Partial<Record<MyProfileKey, MyProfileField>>;

export default Profile;
