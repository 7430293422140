import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { IBankAccountDto } from "../../app/OwnerService-api";
import { useTypedTranslation, lang } from "../../features/i18n";
import { useAppDispatch } from "../../store";
import { validateBankAccount as validateBankAccountAction } from "../../store/customer-profile";
import { Feedback } from "../input-feedback";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useAccountNumber = (
  account: IBankAccountDto | undefined,
  onChange: ((data: IBankAccountDto | undefined) => void) | undefined
) => {
  const [showNewAccountForm, setShowNewAccountForm] = useState<boolean>(false);
  const [newAccountFeedback, setNewAccountFeedback] = useState<Feedback | undefined>(undefined);
  const [bankAccount, setBankAccount] = useState<IBankAccountDto | undefined>(undefined);

  const { t } = useTypedTranslation();
  const tRoot = lang.accountNumber;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      setBankAccount(account);
    }
  }, [account]);

  useEffect(() => {
    if (onChange) {
      onChange(bankAccount);
    }
  }, [bankAccount]);

  const resetAccountData = () => {
    setShowNewAccountForm(false);
    if (account) {
      setBankAccount(account);
    }
  };

  const handleCreateNewAccountClick = () => {
    openNewAccountForm();
  };

  const openNewAccountForm = () => {
    setNewAccountFeedback(undefined);
    setShowNewAccountForm(true);
  };

  const closeNewBankAccountForm = () => {
    setShowNewAccountForm(false);
  };

  const closeAndSaveNewBankAccount = () => {
    setNewAccountFeedback({
      isValid: true,
      text: t(tRoot.validation.validIBAN),
    });
    setTimeout(() => {
      setShowNewAccountForm(false);
    }, 500);
  };

  const validateBankAccount = async (newAccount: string) => {
    return dispatch(validateBankAccountAction({ bankAccount: newAccount }));
  };

  const saveNewAccount = (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
    e.preventDefault();
    const isValidResponse = validateBankAccount(value);

    isValidResponse
      .then(unwrapResult)
      .then(() => {
        const newBankAccount: IBankAccountDto = {
          id: bankAccount ? bankAccount.id : undefined,
          accountNumberMasked: value,
          active: true,
        };

        setBankAccount(newBankAccount);
        closeAndSaveNewBankAccount();
      })
      .catch(() => {
        setNewAccountFeedback({
          isValid: false,
          text: t(tRoot.validation.invalidIBAN),
        });
      });
  };

  const resetNewAccountFeedback = () => {
    setNewAccountFeedback(undefined);
  };

  const validateNewBankAccountWithoutSave = (newBankAccount: string) => {
    const isValidBankAccount = validateBankAccount(newBankAccount);

    isValidBankAccount
      .then(unwrapResult)
      .then(() => {
        setNewAccountFeedback({
          isValid: true,
          text: t(tRoot.validation.validIBAN),
        });
      })
      .catch(() => {
        setNewAccountFeedback({
          isValid: false,
          text: t(tRoot.validation.invalidIBAN),
        });
      });
  };

  return {
    t,
    showNewAccountForm,
    handleCreateNewAccountClick,
    openNewAccountForm,
    closeNewBankAccountForm,
    saveNewAccount,
    bankAccount,
    newAccountFeedback,
    resetAccountData,
    resetNewAccountFeedback,
    validateNewBankAccountWithoutSave,
  };
};

export default useAccountNumber;
