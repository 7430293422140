import React from "react";
import WizardContainer from "../../wizard-container";
import InfoRent from "../info-rent";
import StartRent from "../start-rent";
import RentHobby from "../rent-hobby";
import SelectBankAccount from "../select-bank-account";
import FinishRentProcess from "../finish-rent-process";
import ConfirmRent from "../confirm-rent";
import { HoldingDto, ICalendarDto } from "../../../app/OwnerService-api";
import { format } from "date-fns";

type RentWizardProps = {
  stepIndex: number;
  setStepIndex: (value: number) => void;
  holding: HoldingDto;
  weekToRent: ICalendarDto | undefined;
  handleClose: () => void;
  additionalInformation: string;
};

const RentWizard = ({
  stepIndex,
  setStepIndex,
  holding,
  weekToRent,
  handleClose,
  additionalInformation,
}: RentWizardProps): JSX.Element => {
  const formatReservationRange = (weekStart: string, weekEnd: string): string => {
    const start = format(new Date(weekStart), "d.M.yyyy");
    const end = format(new Date(weekEnd), "d.M.yyyy");

    return start + " - " + end;
  };

  const reservationRange =
    holding.weeks !== undefined
      ? formatReservationRange(holding.weeks[0].weekStart, holding.weeks[0].weekEnd)
      : "";

  const handleNextStep = () => {
    setStepIndex(stepIndex + 1);
  };

  const handlePrevStep = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  return (
    <WizardContainer stepIndex={stepIndex}>
      <StartRent nextStep={handleNextStep} previousStep={handleClose} />

      <InfoRent nextStep={handleNextStep} previousStep={handlePrevStep} />

      <ConfirmRent
        resortName={holding.resortName || ""}
        realEstateCompany={holding.realEstateCompany || ""}
        weekNumber={holding.weekNumber}
        reservationRange={reservationRange}
        nextStep={handleNextStep}
        previousStep={handlePrevStep}
      />

      {holding.resortName === "Holiday Club Katinkulta" ? (
        <RentHobby
          resortName={holding.resortName || ""}
          nextStep={handleNextStep}
          previousStep={handlePrevStep}
        />
      ) : null}

      <SelectBankAccount
        contractId={holding.contractId}
        nextStep={handleNextStep}
        previousStep={handlePrevStep}
      />

      <FinishRentProcess
        holdingId={holding.holdingId}
        contractId={holding.contractId}
        holdingUnitId={holding.unitId}
        weekStart={weekToRent?.weekStart}
        weekEnd={weekToRent?.weekEnd}
        resortName={holding.resortName || ""}
        realEstateCompany={holding.realEstateCompany || ""}
        weekNumber={holding.weekNumber}
        weekType={holding.weekType}
        reservationRange={reservationRange}
        nextStep={handleClose}
        previousStep={handlePrevStep}
      />
    </WizardContainer>
  );
};

export default RentWizard;
