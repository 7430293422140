import React from "react";
import { ImageAsset } from "../../../app/OwnerService-api";
import PlaceholderImg from "../../placeholder-img";

import ResponsiveImage from "../../responsive-img";
import useHomeHeader from "./use-home-header";

import "./home-header.scss";

type HomeHeaderProps = {
  children: React.ReactNode;
  bgImageData: ImageAsset | undefined;
};

const HomeHeader = ({ children, bgImageData }: HomeHeaderProps): JSX.Element => {
  const { parsedImgData, imgSrcSet } = useHomeHeader(bgImageData);

  const backgroundImgCss = "home-header-bg-image fade-in";

  return (
    <div className="header-container">
      {parsedImgData !== undefined ? (
        <ResponsiveImage
          alt={parsedImgData.alt}
          src={parsedImgData.large}
          srcSet={imgSrcSet}
          className={backgroundImgCss}
          placeholderLoadingState={false}
        />
      ) : (
        <PlaceholderImg roundedCorners={false} height="80vh" />
      )}

      {children}
    </div>
  );
};

export default HomeHeader;
