export default {
  key: "hei maailma",
  general: {
    customerServicePhone: "+358 (0) 300 870 922",
    callPrice: "Puhelun hinta pvm/mpm",
  },
  title: {
    home: "Osakkaiden verkkopalvelu | Holiday Club",
    profile: "Osakkaiden verkkopalvelu | Omat tiedot | Holiday Club",
    benefits: "Osakkaiden verkkopalvelu | Omat edut | Holiday Club",
    invite: "Tervetuloa Osakkaiden verkkopalveluun | Holiday Club",
  },
  loginStatus: {
    logOut: "kirjaudu ulos",
  },
  protected: {
    checkAuth: "tarkistetaan kirjautumista",
  },
  loadingStatus: {
    contentLoading: "Ladataan sisältöä...",
    error: "Jokin meni pieleen",
  },
  error500: {
    altText: "Järvimaisema",
    title: "Palvelussa on häiriö",
    infoText: "Odota hetki ja yritä sitten uudestaan",
    reloadPage: "Lataa sivu uudestaan",
    previousPage: "Palaa edelliselle sivulle",
  },
  error404: {
    altText: "Järvimaisema",
    title: "Sivua ei löytynyt",
    infoText: "Tarkista osoite tai palaa etusivulle",
    returnRootPage: "Palaa etusivulle",
  },
  editLink: {
    default: "Muokkaa",
    cancel: "Peruuta",
  },
  toast: {
    success: "Toiminto onnistui",
    error: "Toiminto epäonnistui",
  },
  membershipCard: {
    cardTitle: "Osakaskortti",
    cardNumberTitle: "Osakasnumero",
    showMembershipBenefitsButton: "Tarkastele osakasetuja",
    orderNewMembershipCard: "Tilaa uusi kortti",
    cancel: "Peruuta",
    orderNewMobileCard: "Tilaa uusi mobiilikortti",
    orderToNumberTitle: "Lähetä mobiilikortti numeroon:",
    confirmPhoneNumber: "Vahvista numero",
    editPhoneNumber: "Muokkaa numeroa",
    sendOrder: "Lähetä tilaus",
    orderInfo: "Mobiiliosakaskortti lähetetään puhelimeesi tekstiviestillä.",
    orderMobileCard: {
      sendToNumberTitle: "Lähetä mobiilikortti numeroon:",
      confirmPhone: "Vahvista numero",
      previousStep: "Takaisin",
      confirmOrder: "Vahvista tilaus",
      villasNumberTitle: "Villas mobiilikortti puhelinnumeroon:",
      goldNumberTitle: "Gold mobiilikortti puhelinnumeroon:",
      basicNumberTitle: "Mobiilikortti puhelinnumeroon:",
      orderMobileCard: "Lähetä tilaus",
    },
  },
  requiredField: "Lisää pakollinen tieto",
  invalidChars: "Tarkista kielletty merkki: & > < ” ’ /",
  saveChangesButton: "Tallenna muutokset",
  calendar: {
    monthNames: [
      "Tammikuu",
      "Helmikuu",
      "Maaliskuu",
      "Huhtikuu",
      "Toukokuu",
      "Kesäkuu",
      "Heinäkuu",
      "Elokuu",
      "Syyskuu",
      "Lokakuu",
      "Marraskuu",
      "Joulukuu",
    ],
    monthNamesShort: [
      "Tammikuu",
      "Helmikuu",
      "Maaliskuu",
      "Huhtikuu",
      "Toukokuu",
      "Kesäkuu",
      "Heinäkuu",
      "Elokuu",
      "Syyskuu",
      "Lokakuu",
      "Marraskuu",
      "Joulukuu",
    ],
    dayNames: [
      "Maanantai",
      "Tiistai",
      "Keskiviikko",
      "Torstai",
      "Perjantai",
      "Lauantai",
      "Sunnuntai",
    ],
    dayNamesShort: ["Ma", "Ti", "Ke", "To", "Pe", "La", "Su"],
  },
  profile: {
    closeIconAltText: "Etusivulle",
    addressDetails: {
      address: "Katuosoite",
      city: "Kaupunki",
      postcode: "Postinumero",
      country: "Maa",
      title: "Osoitetiedot",
    },
    contactDetails: {
      invalidPhone: "Anna maakoodi ja numero muodossa +358401234567",
      phone: "Puhelin",
      invalidEmail: "Virheellinen sähköposti",
      email: "Sähköposti",
      description: "Voit muokata puhelinnumeroasi alta.",
      title: "Yhteystiedot",
      phoneInfo: "Anna maakoodi ja numero muodossa +358401234567",
      phoneValidFormat: "Anna maakoodi ja numero muodossa +358401234567",
    },
    personalDataDetails: {
      nickname: "Kutsumanimi",
      firstName: "Etunimi",
      lastName: "Sukunimi",
      description:
        "Voit muokata sinulle luotua kutsumanimeä, jolla puhuttelemme sinua jatkossa Osakkaiden verkkopalvelussa. Muuttaaksesi muita sinusta osakassopimukseen tallennettuja henkilötietoja, pyydämme sinua toimittamaan kopion virkatodistuksesta, passista tai ajokortista rekisterin ylläpitäjälle sähköpostitse: <1>isannointi@holidayclub.fi</1>",
      title: "Henkilötiedot",
    },
    loginDetails: {
      firstName: "Etunimi",
      lastName: "Sukunimi",
      email: "Sähköpostiosoite",
      password: "Salasana",
      placeholderText: "Syötä salasana",
      oldPassword: "Vanha salasana",
      newPassword: "Uusi salasana",
      repeatPassword: "Uusi salasana uudestaan",
      invalidPassword: "Virheellinen salasana",
      setPassword: "Aseta uusi salasana",
      passwordMatch: "Salasana oikein",
      passwordNoMatch: "Salasanat eivät täsmää",
      title: "Kirjautumisasetukset",
      description:
        "Voit muuttaa kirjautumiseen käytettävää salasanaa syöttämällä haluamasi uuden salasanan kahdesti. Salasanassa täytyy olla:",
      passwordRequirements:
        "<0>- 8-64 merkkiä</0><1>- Vähintään 1 iso kirjain</1><2>- Vähintään 1 pieni kirjain</2><3>- Vähintään 1 numero tai symbooli</3>",
    },
    accountingDetails: {
      title: "Tilitystiedot",
      description:
        "Valitse tilinumero jolle mahdolliset vuokratuotot tilitetään kunkin tilityskuden päätteeksi.",
      bankAccounts: "Tilinumero",
    },
    alertModal: {
      title: "Omien tietojen muokkaaminen ei ole vielä mahdollista",
      description:
        "Pääset muokkaamaan tietoja sitten kun omistuksesi on rekisteröity osakasrekisteriin.",
      closeButtonText: "Sulje ilmoitus",
    },
  },
  home: {
    general: {
      inNDays: "{{ days }} päivän päästä",
      makeAnotherBooking: "Tee toinen varaus",
      cancelTarget: "Peruuta {{ target }}",
      inspectBenefits: "Tarkastele etujasi",
      inspectHolding: "Katsele omistustasi",
      firstLoginNotificationMessage: "Tarkistathan vielä, että omat asiakastietosi ovat oikein!",
      firstLoginNotificationLink: "Omat tiedot",
    },
    holdings: {
      week: "Vko {{week}}",
      weekLong: "Viikko {{week}}",
      room: "Huoneisto {{room}}",
      villasShare: "{{share}} osuus",
      showHolding: "Näytä kohde",
      back: "Takaisin",
      confirmTarget: "Vahvista {{ target }}",
      selection: "valinta",
      closeOverlayAlt: "Sulje kalenterin overlay",
      allHoldingsSelected: "Kaikki omistuksesi",
      rciReservation: {
        infoTextDefault:
          "Varaa omistuksistasi kertyneillä <1>{{ points }} pisteellä</1> seuraava lomasi. Käytössäsi on RCI lomanvaihto-organisaation yli 4000 lomakeskusta yli 100 maassa. Holiday Club Points -järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.",
        infoText10to11:
          "Sinulla on etuoikeus tehdä omistuksistasi kertyneillä <1>{{ points }} pisteellä</1> varauksia muista Holiday Club -kohteista ennen muita vaihtojärjestelmän jäseniä, kun varaat viimeistään 10 kk ennen haluamaasi loma-ajankohtaa. Holiday Club Points -järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.",
        infoText11to12:
          "Hyödynnä etuoikeutesi varata omistuksistasi kertyneillä <1>{{ points }} pisteellä</1> loma <2>{{ resortName }}</2> viimeistään 11 kk ennen haluamaasi ajankohtaa. Holiday Club Points -järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.",
        infoText12to13:
          "Varaa omistuksistasi kertyneillä <1>{{ points }} pisteellä</1> seuraava lomasi kohteessasi <2>{{ resortName }}</2>, muistathan tehdä varauksen viimeistään 12kk ennen omistamasi lomaviikon alkua. Holiday Club Points -järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.",
        seeAtRci: "Katso RCI.com",
      },
      reserved: {
        infoText:
          "Oma lomaviikkosi kohteessa {{ resortName }} alkaa <3>{{ days }}</3>. Suunnittele lomaviikkosi etukäteen tarkastelemalla kohdepalveluja ja -etuja. Jos lomallesi tulee este, muistathan peruuttaa varauksesi.",
        reservation: "varaus",
        selectTitle: "Poista Villas varaus",
        confirmTitle: "Lähetä pyyntö varauksen poistamisesta",
        confirmButtonPrimary: "Poista varaus",
        confirmAcceptTerms:
          "Hyväksyn allekirjoittamassani operaattorisopimuksessa ilmoitetut käyttöpäivien rajoitukset, jotka saattavat rajoittaa varaustani",
        lastMinuteInfo:
          "Käsittelemme varaustasi kohteeseen {{resortName}}. Voit tiedustella pyytämäsi varauksen tilaa asiakaspalvelun numerosta:",
      },
      villas: {
        makeReservation: "Tee oma varaus",
        infoStep: {
          part1: "Seuraava lomasi kohteessa {{resortName}} voi alkaa jo ",
          part2: "31 päivän päästä",
          part3:
            ". Varaa kohde omaan käyttöön ja aloita lomaviikkosi suunnittelu etukäteen tarkastelemalla kohdepalveluja.",
        },
        back: "Takaisin",
        confirmStep: {
          sendReservationMsg: "Lähetä varaus:",
          reservationMsg: "Varaa {{resortName}} {{room}} omaan käyttöön:",
        },
        infoReservationHolding: "Varaa {{ resortName }} omaan käyttöön:",
        infoGeneral: "Varaa Villas omaan käyttöön:",
        toConfirmStep: "Siirry vahvistamaan",
        addMore: "Lisää toinen varaus",
        confirmSelection: "Vahvista varaus",
        sendReservation: "Lähetä varaus",
        confirmCheckboxLabel:
          "Hyväksyn allekirjoittamassani operaattorisopimuksessa ilmoitetut käyttöpäivien rajoitukset, jotka saattavat rajoittaa varaustani",
      },
      lastMinuteFrac: {
        infoStep: {
          part1: "Omistamasi viikon alkuun kohteessa {{resortName}} on enää ",
          part2: "{{days}} päivää. ",
          part3:
            "Jos haluat varata viikon omaan käyttöön, tee varauspyyntö ja vahvistamme varauksen sähköpostitse, mikäli kohde on vielä varattavissa.",
        },
        requestBooking: "Pyydä varausta",
      },
      weeks: {
        infoStep: {
          part1: "Oma lomaviikkosi alkaa",
          part2:
            "kohteessa {{resortName}}. Voit tarkastella osakasetuja ja palveluita kohteessasi tai voit vuokrata viikkosi eteenpäin. Mikäli olet RCI-jäsen, käytössäsi on lisäksi",
          link: "lomanvaihtojärjestelmämme",
          linkUrl:
            "https://www.holidayclubresorts.com/fi/viikko-osake/mika-on-viikko-osake/lomanvaihto/",
        },
        rent: "Jätä vuokralle",
      },
      ongoing: {
        info: "Oma lomaviikkosi on käynnissä kohteessa <1>{{resortName}} {{endDate}}</1> saakka.",
        showCalendar: "Näytä kalenteri",
      },
      newCustomer: {
        ownerNew: {
          part1: "Onneksi olkoon uudesta {{holdingType}} kohteessa ",
          part2: "! Hyödynnä osakkaan edut ja varaa viikko osakashintaan vaikka heti!",
          part2gold: "! Hyödynnä osakkaan edut ja varaa lisäviikko Gold-etuhintaan vaikka heti!",
          week: "viikko-osakkeesta",
          villas: "Villas omistuksesta",
        },
        owner2MonthsToYear: {
          part1:
            "Tervetuloa Holiday Club -osakkaiden verkkopalveluun! Palvelusta löydät kaikki {{holdingType}} liittyvät omistustiedot, osakkaan tarjoukset ja lomavinkit - kätevästi yhdestä osoitteesta.",
          week: "viikko-osakkuuteesi",
          villas: "villas-osakkuuteesi",
        },
      },
    },
    holdingsDetails: {
      title: "Omistuksesi",
      weekNumber: "Viikko {{week}}",
      villasShare: "{{share}} osuus",
      floorPlan: "Pohjakuva",
      articlesOfAssociation: "Yhtiöjärjestys",
      customerService: "Holiday Club Asiakaspalvelu",
      customerServiceContactInfo: "Asiakaspalvelun yhteystiedot",
      customerServiceUrl:
        "https://www.holidayclubresorts.com/fi/footermenu/ota-yhteytta/asiakaspalvelu/",
      management: "Kohteen hallinnointi",
      lendForward: "Vuokraa viikko eteenpäin",
      destinationTips: "Vinkkejä matkalle",
      destinationBenefits: "Katso edut kohteessa",
      villasManageReservationLink: "Varaa omaan käyttöön",
    },
  },
  rangePicker: {
    reservationStarts: "Varaus alkaa",
    reservationEnds: "Varaus loppuu",
    checkDate: "Tarkista päivämäärä",
    overlappingDate: "Päivämäärällä on jo varaus",
  },
  modal: {
    confirmCloseTitle: "Sulkemalla ikkunan menetät tehdyt muutokset",
    confirmCloseContent: "Sulje ikkuna tai jatka muutosten tekemistä",
    confirmCloseContinue: "Jatka muokkaamista",
    confirmCloseExit: "Sulje ikkuna",
  },
  accountNumber: {
    createNewAccount: "Syötä uusi tilinumero",
    IBANFieldLabel: "Syötä IBAN-tilinumero",
    saveNewAccountNumber: "Lisää tilinumero",
    accountSelect: {
      accountNumberChanged: "Onko tilinumero vaihtunut?",
      createNewAccount: "Syötä uusi tilinumero",
    },
    validation: {
      validIBAN: "Tilinumero oikein",
      invalidIBAN: "Virheellinen tilinumero",
      duplicate: "Tilinumero on jo lisätty",
    },
  },
  rentFormModal: {
    continue: "Jatka",
    back: "Takaisin",
    week: "Viikko",
    showResort: "Näytä kohde",
    startRent: {
      text:
        "Huom! Asiakaspalvelun kautta tehdyt vuokraustoimeksiannot eivät vielä näy palvelussa. Jos olet jo jättänyt viikkosi vuokrattavaksi, älä tee sitä uudelleen verkkopalvelussa.",
    },
    infoRent: {
      text:
        "Huomioithan ettet ole tallettanut vuokralle jättämääsi osaketta mihinkään vaihtojärjestelmään tai antanut sitä muualle vuokravälitykseen. Holiday Club Points -jäsenenä et voi myöskään jättää viikkoa vuokralle, ellei ko. viikkoa ole varattu omaan käyttöön.",
      acceptTerms: "Vahvistan yllä olevan",
    },
    confirmRent: {
      title: "Aseta viikko vuokralle:",
      acceptTerms: "Hyväksyn {{termsLink}}",
      termsLink: "vuokranvälitysehdot",
      termsLinkTarget:
        "https://www.holidayclubresorts.com/fi/viikko-osake/osakkaille/vuokravalitys/",
      continue: "Vahvista valinnat",
    },
    selectBankAccount: {
      title: "Mille tilille vuokratuotot tilitetään?",
      continue: "Vahvista tilinumero",
      bankAccountLabel: "Tilinumero",
      loadingBankAccounts: "Haetaan tilinumeroita...",
    },
    finishProcess: {
      title: "Aseta viikko vuokralle:",
      continue: "Aseta vuokralle",
    },
    infoRentNotPossible: {
      title: "Vuokrallejättö ei ole vielä mahdollista",
      description:
        "Voit jättää kohteen vuokralle sitten kun omistuksesi on rekisteröity osakasrekisteriin.",
      closeButtonText: "Sulje ilmoitus",
    },
    rentedMsg:
      "Olet jättänyt kohteen <1>{{resortName}}</1> vuokralle. Voit tiedustella vuokrauksesi tilaa asiakaspalvelun numerosta:",
    rentedCustomerServiceBtn: "Tiedustele varaustilannetta",
    rentedCustomerServiceLink:
      "https://www.holidayclubresorts.com/fi/footermenu/ota-yhteytta/asiakaspalvelu/",
  },
  epi: {
    welcomeBlock: {
      phoneTitle: "Osakkaan oma palvelunumero:",
    },
    testimonialBlock: {
      showVideoButton: "Katso video",
    },
    horizontalList: {
      benefitBannerAriaLabel: "Yläkuva osakasedulle: ",
      holidayChangeBannerAriaLabel: "Yläkuva lomanvaihdolle: ",
      showMoreLinkText: "Näytä lisää",
      showMoreLink: "/osakasedut",
      shareOwnerBadge: "Osakasetu",
      villasBadge: "Villas-osakkaille",
      goldBadge: "Gold-osakkaille",
      newShareOwnerBadge: "Uusille osakkaille",
      modal: {
        labelText: "Etumodaali",
        basicInfo:
          "Voit tiedustella kaikkia osakkaille kuuluvia etuja asiakaspalvelun numerosta tai tutustua niihin suoraan Osakkaiden verkkopalvelussa.",
        showAllBenefitsBtn: "Näytä kaikki edut",
        ownerCustomerServiceNumberTitle: "Osakkaan palvelunumero:",
        copyCode: "Kopioi koodi tästä talteen",
        redirectToEcommerce: "Siirry verkkokauppaan",
        showMore: "Lue lisää",
        backFromDetails: "Takaisin",
        selectBenefit: "Valitse kohde",
        selectBenefitDropdownLabel: "Valitse kohde, josta olet kiinnostunut",
        rciButton: "Katso RCI.com",
        rciLinkDefaultTarget: "https://www.rci.com",
        rciWeeksInfo:
          "Lomanvaihtojärjestelmämme avulla voit käyttää vuosittaisen lomaoikeutesi haluamassasi kohteessa ja sinulle sopivana ajankohtana. Järjestelmän toiminnasta vastaa maailman johtava lomanvaihto-organisaatio RCI.",
        rciPointsInfo:
          "Voit varata omistuksistasi kertyneillä <1>{{ points }} pisteellä</1> seuraavan lomasi kohteessa {{ resortName }} tai jossain toisessa RCI lomanvaihtoverkon kohteessa.",
        readMoreNewCustomerInfo:
          "Tässä sinulle kaupassa myönnetyt huippuedut! Muistathan hyödyntää ne ennen mahdollista voimassaolon päättymistä.",
      },
    },
  },
  navigation: {
    profile: "Omat tiedot",
    customerService: "Asiakaspalvelu",
    feedback: "Anna palautetta",
    logout: "Kirjaudu ulos",
    customerServiceUrl:
      "https://www.holidayclubresorts.com/fi/footermenu/ota-yhteytta/asiakaspalvelu/",
    feedbackUrl:
      "https://response.questback.com/isa/qbv.dll/bylink?p=TaO3blbo5Vuagm7Dnb-SX_DL7N45Zqw4jcZB9MQ0PHo8Ty3Fk0k4gZor_K580K0Wn29XPSWUqnSLEZ4_-H-XAQ2",
    logoAltText: "Holiday club -logo",
    navigationLabel: "Navigaatio-valikko",
  },
  benefitsPage: {
    general: {
      closeIconAltText: "Etusivulle",
      pathname: "osakasedut",
      listTabHash: "#tutustu",
      searchTabHash: "#selaa",
      listTab: "Tutustu osakasetuihisi",
      searchTab: "Selaa osakasetujasi",
      noBenefits: "Ei etuja",
      noContent: "Ei sisältöä",
    },
    benefitCard: {
      shareOwnerBadge: "Osakasetu",
      villasBadge: "Villas-osakkaille",
      goldBadge: "Gold-osakkaille",
      newShareOwnerBadge: "Uusille osakkaille",
      destinationBadge: "Kohteessa",
    },
    search: {
      selectVisibleBenefits: "Näytettävät edut",
      showAll: "Näytä kaikki",
      generalBenefits: "Yleiset osakasedut",
      destinationBenefits: "Kohdekohtaiset edut",
      goldBenefits: "Gold jäsenedut",
      villasBenefits: "Villas jäsenedut",
    },
  },
  footer: {
    aboutLinks: {
      categoryName: "Hyvä tietää",
      faq: {
        link: "https://www.holidayclubresorts.com/fi/lomaosakkeet/faq/",
        text: "Usein kysytyt kysymykset",
      },
      owner: {
        link: "https://www.holidayclubresorts.com/fi/viikko-osake/osakkaille/osakaspalvelu/",
        text: "Omistajan palvelut",
      },
      resale: {
        link: "https://www.holidayclubresorts.com/fi/viikko-osake/jalleenmyynti/",
        text: "Jälleenmyynti",
      },
      gdpr: {
        link: "https://www.holidayclubresorts.com/fi/footermenu/verkkopalvelustamme/tietosuoja/",
        text: "Tietosuoja",
      },
      termsOfUse: {
        link:
          "https://www.holidayclubresorts.com/fi/footermenu/verkkopalvelustamme/osakkaiden-verkkopalvelu-ehdot/",
        text: "Käyttöehdot",
      },
    },
    contactLinks: {
      categoryName: "Yhteystiedot",
      customerService: {
        link: "https://www.holidayclubresorts.com/fi/footermenu/ota-yhteytta/asiakaspalvelu/",
        text: "Asiakaspalvelu",
      },
      propertyManager: {
        link:
          "https://www.holidayclubresorts.com/fi/viikko-osake/osakkaille/kiinteistoyhtiot-ja-isannointi/",
        text: "Isännöintipalvelut",
      },
      newsletters: {
        link: "https://www.holidayclubresorts.com/fi/lomavinkit/uutiskirje/",
        text: "Uutiskirjeet",
      },
      feedback: {
        link:
          "https://response.questback.com/isa/qbv.dll/bylink?p=TaO3blbo5Vuagm7Dnb-SX_DL7N45Zqw4jcZB9MQ0PHo8Ty3Fk0k4gZor_K580K0Wn29XPSWUqnSLEZ4_-H-XAQ2",
        text: "Anna palautetta",
      },
    },
    shopLinks: {
      categoryName: "Verkkokauppa",
      reserve: {
        link: "https://www.holidayclubresorts.com/fi/",
        text: "Varaa loma",
      },
      gift: {
        link: "https://www.holidayclubresorts.com/fi/store/tilaa-lahjakortti/",
        text: "Lahjakortit",
      },
    },
    followLinks: {
      categoryName: "Seuraa meitä",
      facebook: {
        link: "https://www.facebook.com/HolidayClubSuomi",
        text: "Facebook",
      },
      instagram: {
        link: "https://www.instagram.com/holidayclub/",
        text: "Instagram",
      },
      twitter: {
        link: "https://twitter.com/HolidayClubFi",
        text: "Twitter",
      },
      pinterest: {
        link: "https://www.pinterest.com/holidayclubfi/",
        text: "Pinterest",
      },
      youtube: {
        link: "https://www.youtube.com/user/HolidayClubfi",
        text: "YouTube",
      },
      linkedin: {
        link: "https://www.linkedin.com/company/holidayclubresorts",
        text: "LinkedIn",
      },
    },
  },
  calendarOverlay: {
    confirmCloseTitle: "Sulkemalla varausikkunan menetät tehdyt muutokset",
    confirmCloseContent: "Sulje varausikkuna tai jatka muutosten tekemistä",
    cancelConfirmCloseButton: "Jatka muokkaamista",
    confirmCloseButton: "Sulje varausikkuna",
  },
  villasReservationModal: {
    reservationInfoTitle: "Kohteen varaaminen",
    reservationInfoVillasFull:
      "Varauksien tekeminen tapahtuu sivun yläosassa sijaitsevasta kalenterista. Klikkaa kalenterista haluamaasi päivää ja valitse varattava jakso.",
    reservationInfoOther:
      "Varauksien tekeminen tapahtuu sivun yläosassa sijaitsevasta kalenterista. Klikkaa kalenterista varattavaa viikkoa ja etene vahvistamaan varaus.",
    reservationAction: "Siirry tekemään varaus",
  },
  signup: {
    firstName: "Etunimi",
    lastName: "Sukunimi",
    email: "Sähköpostiosoite",
  },
};
