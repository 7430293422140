import { MonthIndexType, LocalizedText, CalendarType } from ".";

const localization: { fi: LocalizedText; en: LocalizedText } = {
  fi: {
    monthNames: [
      "Tammikuu",
      "Helmikuu",
      "Maaliskuu",
      "Huhtikuu",
      "Toukokuu",
      "Kesäkuu",
      "Heinäkuu",
      "Elokuu",
      "Syyskuu",
      "Lokakuu",
      "Marraskuu",
      "Joulukuu",
    ],
    monthNamesShort: [
      "Tammikuu",
      "Helmikuu",
      "Maaliskuu",
      "Huhtikuu",
      "Toukokuu",
      "Kesäkuu",
      "Heinäkuu",
      "Elokuu",
      "Syyskuu",
      "Lokakuu",
      "Marraskuu",
      "Joulukuu",
    ],
    dayNames: [
      "Maanantai",
      "Tiistai",
      "Keskiviikko",
      "Torstai",
      "Perjantai",
      "Lauantai",
      "Sunnuntai",
    ],
    dayNamesShort: ["Ma", "Ti", "Ke", "To", "Pe", "La", "Su"],
  },
  en: {
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  },
};

/**
 * @param {month} month - current month
 * @returns {MonthIndexType} prev, current and next month index 0...11
 */
const getMonthIndexes = (month: number): MonthIndexType => {
  const currentMonth = month;
  const prevMonth = month === 0 ? 11 : month - 1;
  const nextMonth = month === 11 ? 0 : month + 1;

  return {
    prev: prevMonth,
    current: currentMonth,
    next: nextMonth,
  };
};

/**
 * Get localized title for prev, current and next month
 * @param {number} currentMonth
 * @param {LocalizedText} localizedData
 * @returns object with prev, current and next month names
 */
export const getMonthTitle = (
  currentMonth: number,
  localizedData: LocalizedText
): {
  prev: string;
  current: string;
  next: string;
} => {
  const monthIndexes = getMonthIndexes(currentMonth);
  const prev = localizedData.monthNames[monthIndexes.prev];
  const current = localizedData.monthNames[monthIndexes.current];
  const next = localizedData.monthNames[monthIndexes.next];

  return {
    prev,
    current,
    next,
  };
};

/**
 * Get day and month name translation
 * @param {CalendarType} calendarType
 * @returns {LocalizedText} translations
 */
export const getLocalization = (calendarType: CalendarType): LocalizedText => {
  return calendarType === "ISO" ? localization.fi : localization.en;
};

export default { getMonthTitle, getLocalization };
