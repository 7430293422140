import React from "react";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcText from "../../hc-text";

type Props = {
  resortName: string;
  days: string;
  translate: TypedTranslate;
};

const LastMinuteInfoText = ({ resortName, days, translate: t }: Props): JSX.Element => {
  const tRoot = lang.home;

  return (
    <>
      <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
        {t(tRoot.holdings.lastMinuteFrac.infoStep.part1, { resortName, days })}
        <HcText weight="strong" tag="span" size="s">
          {t(tRoot.holdings.lastMinuteFrac.infoStep.part2, { days })}
        </HcText>
        {t(tRoot.holdings.lastMinuteFrac.infoStep.part3)}
      </HcText>
    </>
  );
};

export default LastMinuteInfoText;
