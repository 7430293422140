import * as React from "react";

import { FormGroupProps, MyProfileData, MyProfileDataFields, MyProfileKey } from "..";
import useLoginDetails from "./use-login-details";
import { lang } from "../../../features/i18n";
import { MyProfileDto } from "../../../app/OwnerService-api";
import FormGroup from "../form-group";
import { ValidationErrors } from "final-form";

type LoginDetailsProps = {
  changePassword: (newPassword: string) => void;
} & Omit<FormGroupProps, "commonOnSubmit">;

export type LoginDataType = Pick<MyProfileData, "email" | "newPassword" | "repeatPassword">;

const LoginDetails = ({
  editMode,
  onEditModeChange,
  isEditable,
  profileData,
  changePassword,
  isSubmitting,
  formKey,
}: LoginDetailsProps): JSX.Element => {
  const { t, passwordsMatch, updatePasswordsMatch } = useLoginDetails();
  const tRoot = lang.profile.loginDetails;

  const required = (value: string) => (value ? undefined : t(lang.requiredField));

  const passwordValidation = (value: string) => {
    const isEmpty = required(value);

    const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,64}$/;

    if (!isEmpty) {
      if (!passwordRegex.test(value)) {
        return t(tRoot.invalidPassword);
      }
    }

    return isEmpty;
  };

  const getLabel = (fieldName: MyProfileKey): string => {
    type TField = keyof typeof tRoot;
    return t(tRoot[fieldName as TField]);
  };

  const placeholderText = t(tRoot.placeholderText);

  const prepareData = (data: MyProfileDto): MyProfileData[] => {
    const firstRow: MyProfileData = {
      email: data.email,
    } as MyProfileData;
    const secondRow: MyProfileData = {
      newPassword: "",
      repeatPassword: "",
    } as MyProfileData;

    return [firstRow, secondRow];
  };

  const prepareDataFields = (): MyProfileDataFields[] => {
    const firstRow: MyProfileDataFields = {
      email: {
        label: getLabel("email"),
        validate: required,
        isDisabled: true,
        inputId: "login-email",
      },
    };
    const secondRow: MyProfileDataFields = {
      newPassword: {
        label: getLabel("newPassword"),
        validate: passwordValidation,
        type: "password",
        placeholder: placeholderText,
      },
      repeatPassword: {
        label: getLabel("repeatPassword"),
        type: "password",
        placeholder: placeholderText,
        successText:
          editMode && passwordsMatch ? t(lang.profile.loginDetails.passwordMatch) : undefined,
        showErrorsWhenActive: true,
      },
    };

    return [firstRow, secondRow];
  };

  const onSubmit = (newData: MyProfileData) => {
    if (newData.newPassword) {
      changePassword(newData.newPassword);
    }
  };

  const validateForm = (values: LoginDataType, validationErrors: ValidationErrors) => {
    const errors: MyProfileData = {};
    if (
      values.newPassword &&
      values.newPassword === values.repeatPassword &&
      !validationErrors.newPassword
    ) {
      updatePasswordsMatch(true);
    } else {
      if (values.newPassword && values.newPassword !== values.repeatPassword) {
        errors.repeatPassword = t(tRoot.passwordNoMatch);
      }
      updatePasswordsMatch(false);
    }
    return errors;
  };

  return (
    <FormGroup
      isEditable={isEditable}
      toggleIsInEditMode={onEditModeChange}
      dataRows={prepareData(profileData)}
      dataFieldRows={prepareDataFields()}
      onSubmit={onSubmit}
      isInEditMode={editMode}
      isSubmitting={isSubmitting}
      groupTitle={t(tRoot.title)}
      validateForm={validateForm}
      formKey={formKey}
      resetAfterSubmit={true}
    />
  );
};

export default LoginDetails;
