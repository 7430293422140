import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import authModule from "../../features/authorization";
import { resetAuth } from "../../store/authorization";
import { LoginStatusHooks } from "../login-status";
import useLoginStatus from "../login-status/hooks/use-login-status";
import { selectIsFirstLoginBadgeVisible, selectIsRentFormOpen } from "../../store/home";
import { selectIsModalOpen } from "../../store/modal";
import { selectProfileState } from "../../store/customer-profile";
import { requestProfile as requestCustomersAction } from "../../store/customer-profile";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { hideFirstLoginBadge as hideFirstLoginBadgeAction } from "../../store/home";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useNavMenu = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user, isLoggingOut, setIsLoggingOut, t }: LoginStatusHooks = useLoginStatus();
  const [buttonElement, setButtonElement] = useState<null | HTMLElement>(null);
  const [distance, setDistance] = useState<number>(0);
  const [nickName, setNickName] = useState<string | undefined>(undefined);

  const auth = authModule;

  const profileState = useSelector(selectProfileState);
  const profileData = profileState.profileData[profileState.profileData.length - 1];
  const profileLoading = profileState.loading;
  const showFirstLoginBadge = useSelector(selectIsFirstLoginBadgeVisible);

  useEffect(() => {
    const requestCustomers = async () => {
      dispatch(requestCustomersAction(user));
    };
    if (profileLoading === "idle" && user) {
      requestCustomers();
    }
    if (profileLoading === "success") {
      setNickName(profileData.nickname);

      if (profileData.modifiable === false) {
        dispatch(hideFirstLoginBadgeAction());
      }
    }
  }, [profileLoading, user]);

  const addScrollClass = () => {
    const el = document.scrollingElement || document.documentElement;
    setDistance(el.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", addScrollClass);

    return () => {
      window.removeEventListener("scroll", addScrollClass);
    };
  }, []);

  useEffect(() => {
    if (isLoggingOut) {
      setIsLoggingOut(false);
      auth.logout();
      resetAuth();
    }
  }, [isLoggingOut, setIsLoggingOut, auth]);

  const givenNameFirstLetter =
    user?.givenName !== undefined ? user?.givenName.charAt(0).toUpperCase() : "";
  const familyNameFirstLetter =
    user?.familyName !== undefined ? user?.familyName.charAt(0).toUpperCase() : "";
  const initial =
    givenNameFirstLetter !== "" && familyNameFirstLetter !== ""
      ? givenNameFirstLetter + familyNameFirstLetter
      : "-";
  const givenName = user?.givenName;

  const name = nickName ? nickName : givenName;

  const isRentFormOpen = useSelector(selectIsRentFormOpen);
  const isModalWindowOpen = useSelector(selectIsModalOpen);
  const isModalOpen = isRentFormOpen || isModalWindowOpen;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /**
   * Open link in new tab
   * @param {string} href url
   */
  const handleLinkClick = (href: string) => {
    window.open(href, "_blank");
  };

  /**
   * Logout and remove first login items from local storage
   *
   */
  const handleLogout = () => {
    setIsLoggingOut(true);

    if (localStorage.getItem("firstLoginBadge") !== null) {
      localStorage.removeItem("firstLoginBadge");
    }
    if (localStorage.getItem("firstLoginTooltip") !== null) {
      localStorage.removeItem("firstLoginTooltip");
    }
  };

  return {
    initial,
    name,
    distance,
    buttonElement,
    setButtonElement,
    t,
    history,
    isModalOpen,
    scrollToTop,
    handleLinkClick,
    showFirstLoginBadge,
    profileLoading,
    handleLogout,
  };
};

export default useNavMenu;
