import React from "react";
import useSignup from "./hooks/use-signup";
import HcHeading from "../hc-heading";
import CardContainer from "../card-container";
import HcText from "../hc-text";
import HcButton from "../hc-button";
import "./signup.scss";
import { ValidationErrors} from "final-form";
import {
  SignupData,
  SignupDataFields,
  SignupSectionsType,
} from ".";
import {SignupDto} from "./index";
import SignupDetails from "./signup-details";

type SignupProps = {
  onSubmit: (data: SignupDto) => void;
  dataRows: SignupData[];
  dataFieldRows: SignupDataFields[];
  fullWidthField?: boolean;
  isSubmitting: boolean;
  validateForm?: (
    values: SignupData,
    validationErrors: ValidationErrors
  ) => ValidationErrors | undefined;
  resetAfterSubmit?: boolean;
  formKey?: keyof SignupSectionsType;
};

const Signup = ({
                  onSubmit,
                  dataRows,
                  dataFieldRows,
                  fullWidthField = false,
                  isSubmitting,
                  validateForm,
                  resetAfterSubmit,
                  formKey,
                }: SignupProps): JSX.Element => {

  const profileData: SignupDto = new SignupDto();
  const { handleContinue, handleCancel, handleReset, isSubmitted, isSubmitReady, hasSubmitError } = useSignup();
  
  const content = (
        <div className="signup-content-container">
          <CardContainer className="signup-form-container">
            <HcHeading semanticElement="h1">Aloita tilin luonti täältä</HcHeading>
            <HcText size="l" className="signup-title-text" bottomSpace="l">
              Luomalla tilin varmistat parhaan lomakokemuksen ja hinnan
            </HcText>

            {!isSubmitted && (
              <SignupDetails
                isEditable={true}
                onEditModeChange={() => {}}
                editMode={true}
                profileData={profileData}
                submitRegistration={handleContinue}
                cancelRegistration={handleCancel}
                isSubmitting={isSubmitting}
                formKey={"signup"}
              />              
              )}
            {isSubmitted && !isSubmitReady && !hasSubmitError && (
              <HcText>
                Tietoja lähetetään, odota hetki...
              </HcText>
            )}
            {isSubmitted && isSubmitReady && !hasSubmitError && (
              <div>
                <HcText>
                  Varmista sähköpostiosoitteesi klikkaamalla sähköpostiisi lähetettyä varmistus linkkiä.
                </HcText>
                <HcButton onClick={handleCancel}>Palaa</HcButton>
              </div>
            )}
            {isSubmitted && hasSubmitError && (
              <div>
                <HcText>
                  Tapahtui virhe, yritä uudelleen.
                </HcText>
                <HcButton onClick={handleReset}>Takaisin</HcButton>               
              </div>
            )}
          </CardContainer>
        </div>
  );
  
  return content;
};

export default Signup;
