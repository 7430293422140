import React from "react";
import Row from "../flex-row";
import Col from "../flex-col";
import HcButton from "../hc-button";
import { TypedTranslate } from "../../features/i18n";
import { lang } from "../../features/i18n";
import HcText from "../hc-text";

type OrderInfoProps = {
  translate: TypedTranslate;
  cancelOrder: () => void;
};

const OrderInfo = ({ translate: t, cancelOrder }: OrderInfoProps): JSX.Element => {
  const tRoot = lang.membershipCard;

  return (
    <Row className="mt-l">
      <Col s={8} m={8} l={8}>
        <HcText weight="strong" tag="span">
          {t(tRoot.orderNewMembershipCard)}
        </HcText>
      </Col>
      <Col s={4} m={4} l={4} className="cancel-button-block">
        <HcButton isLinkButton onClick={cancelOrder}>
          {t(tRoot.cancel)}
        </HcButton>
      </Col>
      <Col className="mb-l mt-l">
        <HcText>{t(tRoot.orderInfo)}</HcText>
      </Col>
    </Row>
  );
};

export default OrderInfo;
