import { useEffect, useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";
import { useSelector } from "react-redux";
import { selectRentFormInformation, updateAdditionalInformation } from "../../../store/home";
import { useAppDispatch } from "../../../store";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
//const useHobbyRent = (nextStep: () => void, prevStep: () => void) => {
const useHobbyRent = () => {
  const dispatch = useAppDispatch();
  const info = useSelector(selectRentFormInformation);
  const [additionalInformation, setAdditionalInformation] = useState<string>(info);
  const { t } = useTypedTranslation();

  const handleInformationChange = (newValue: string) => {
    setAdditionalInformation(newValue);
  };

  useEffect(() => {
    if (additionalInformation !== undefined) {
      dispatch(updateAdditionalInformation(additionalInformation));
    }
  }, [additionalInformation, dispatch]);

  return {
    additionalInformation,
    handleInformationChange,
    t,
  };
};

export default useHobbyRent;
