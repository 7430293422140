import React from "react";
import useExternalLink from "./use-external-link";

type TagType = "div" | "span";

type ExternalLinkProps = {
  wrapper?: TagType;
  wrapperClassName?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternalLink = ({
  href,
  children,
  target = "_blank",
  wrapperClassName,
  className,
  wrapper = "div",
  ...props
}: ExternalLinkProps): JSX.Element => {
  const Tag = `${wrapper}` as TagType;

  const { handleClick } = useExternalLink(href);

  return (
    <Tag className={wrapperClassName !== undefined ? wrapperClassName : ""}>
      <a
        href={href}
        className={className !== undefined ? className : ""}
        target={target}
        onClick={handleClick}
        {...props}
      >
        {children}
      </a>
    </Tag>
  );
};

export default ExternalLink;
