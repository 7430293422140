import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { selectUser } from "../../../store/authorization";
import { CardFormat, IMembershipCardOrderDto } from "../../../app/OwnerService-api";
import { ActiveOrder } from ".";
import { createCardOrder as createCardOrderAction } from "../../../store/card-order";
import { SelectedCards } from ".";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useCardOrder = (cards: SelectedCards[]) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [activeOrder, setActiveOrder] = useState<ActiveOrder>("no-order");

  const createCardOrder = async (cardOrder: IMembershipCardOrderDto) => {
    dispatch(
      createCardOrderAction({
        requestor: user,
        ...cardOrder,
      })
    );
  };

  const changeActiveOrder = (value: ActiveOrder) => {
    setActiveOrder(value);
  };

  useEffect(() => {
    if (activeOrder === "mobile-card-order-done") {
      const cardOrderData: IMembershipCardOrderDto[] = cards.map((card) => {
        return {
          cardType: card.card.membershipLevel,
          cardFormat: CardFormat.Digital,
        };
      });

      createCardOrders(cardOrderData);
      setActiveOrder("no-order");
    }
  }, [activeOrder]);

  const createCardOrders = async (cardOrders: IMembershipCardOrderDto[]) => {
    await Promise.all(cardOrders.map(async (cardOrder) => await createCardOrder(cardOrder)));
  };

  return {
    activeOrder,
    changeActiveOrder,
    createCardOrders,
  };
};

export default useCardOrder;
