import React from "react";
import WizardContainer from "../../wizard-container";
import Info from "./info-villas";
import SelectRangeVillas from "./select-range-villas";
import SelectVillas from "./select-villas";
import Confirm from "./confirm-villas";
import cx from "classnames";
import useReservationVillas from "./use-reservation-villas";
import { HoldingReserved, VillasReservationWizardProps } from "..";
import "./reservation-villas.scss";
import { arrayMultisplice, customToISOString } from "../../../features/utils";
import { IReserveHoldingDto, ReservationType } from "../../../app/OwnerService-api";

type Props = {
  holding: HoldingReserved;
} & VillasReservationWizardProps;

const ReservationVillas = ({
  cancelWizard,
  confirmWizard,
  holding,
  reserveHoldings,
  closeOverlay,
}: Props): JSX.Element => {
  const {
    t,
    stepIndex,
    setStepIndex,
    addCurrentSelectionToReservations,
    reservations,
    calendarInitialData,
    latestDateRange,
    initUsingLatest,
    selections,
    setSelections,
    clearSelections,
    removeLatestFromReservations: removeLatest,
    setInitUsingLatest: updateInitUsingLatest,
    isLastMinute,
    setIsLastMinute,
    closeReservationProcess,
    clickedDate,
  } = useReservationVillas(closeOverlay);
  const className = cx("reservation-villas");

  /**
   * Reserve selected holdings
   */
  const handleConfirm = () => {
    const selectionIndices = Array.from(selections);

    if (selectionIndices.length) {
      const newReservations = arrayMultisplice(reservations, ...selectionIndices);

      const reservationDtos: IReserveHoldingDto[] = newReservations.map((reservationInfo) => ({
        holdingId: holding.holdingId,
        contractId: holding.contractId,
        holdingUnitId: holding.unitId,
        weekNumber: holding.weekNumber,
        weekType: holding.weekType,
        type: ReservationType.OwnUse,
        startDate: customToISOString(reservationInfo.startDate),
        endDate: customToISOString(reservationInfo.endDate),
      }));

      reserveHoldings(reservationDtos);

      /**
       * Check if overlay should be closed
       * if reservation is done at last minute, don't close overlay, cancellation info is shown to user
       */
      if (!isLastMinute) {
        confirmWizard();
      }
    }
  };

  /**
   * If there are already selected ranges, latest date range is the last most recent one,
   * else undefined
   */
  const latestRange =
    latestDateRange.length > 0 ? latestDateRange[latestDateRange.length - 1] : undefined;

  return (
    <WizardContainer stepIndex={stepIndex} className={className}>
      <Info
        nextStep={() => setStepIndex(1)}
        previousStep={() => cancelWizard()}
        resortName={holding.resortName || ""}
        key="reservation-villas--info"
        translate={t}
      />
      <SelectRangeVillas
        nextStep={() => {
          setStepIndex(2);
        }}
        previousStep={() => {
          /**
           * When back button is clicked,
           * if there are already selected ranges, go to select villas step
           * else close the reservation flow
           */
          if (latestRange) {
            setStepIndex(2);
          } else {
            closeReservationProcess();
          }
        }}
        resortName={holding.resortName || ""}
        key="reservation-villas--select-range"
        latestRange={latestRange}
        initUsingLatest={initUsingLatest}
        initialRangeData={calendarInitialData}
        addCurrentRangeToSelections={addCurrentSelectionToReservations}
        translate={t}
        clickedDate={clickedDate}
        reservations={holding.reservations}
      />
      <SelectVillas
        nextStep={() => setStepIndex(3)}
        previousStep={() => {
          setStepIndex(1);
          clearSelections();
        }}
        resortName={holding.resortName || ""}
        room={holding.roomNumber || ""}
        reservationSlots={reservations}
        parentClassName={className}
        translate={t}
        removeLatest={removeLatest}
        updateInitUsingLatest={updateInitUsingLatest}
        setSelections={setSelections}
        isLastMinute={isLastMinute}
        setIsLastMinute={setIsLastMinute}
      />
      <Confirm
        nextStep={handleConfirm}
        previousStep={() => setStepIndex(2)}
        selectedReservations={arrayMultisplice(reservations, ...Array.from(selections))}
        translate={t}
        parentClassName={className}
        isLastMinute={isLastMinute}
      />
    </WizardContainer>
  );
};

export default ReservationVillas;
