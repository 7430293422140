const useLanding = () => {
  return {
    handleLogin: () => {
      window.location.href = "/koti";
    },
    handleRegister: () => {
      window.location.href = "/rekisteroidy";
    },
  };
};

export default useLanding;
