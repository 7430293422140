import React from "react";
import { ReservationInfo } from "../";
import Row from "../../flex-row";
import Col from "../../flex-col";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import HcCheckbox from "../../hc-checkbox";
import cx from "classnames";
import { lang } from "../../../features/i18n";
import useSelectVillas from "./use-select-villas";
import { formatDateRangeStr } from "../utils";

type Props = {
  resortName: string;
  room: string;
  reservationSlots: ReservationInfo[];
  parentClassName: string;
  setSelections: (newState: Set<number>) => void;
  translate: (key: string) => string;
  removeLatest: () => void;
  updateInitUsingLatest: (value: boolean) => void;
  isLastMinute: boolean;
  setIsLastMinute: (val: boolean) => void;
} & WizardStep;

const SelectVillas = ({
  resortName,
  room,
  nextStep,
  previousStep,
  reservationSlots,
  parentClassName,
  setSelections,
  removeLatest,
  translate: t,
  updateInitUsingLatest,
  isLastMinute,
  setIsLastMinute,
}: Props): JSX.Element => {
  const className = cx("no-p-lr", `${parentClassName}__step--select`);
  const selectionsClassName = cx(`${className}__selections`);
  const actionsClassName = cx(`${className}__actions`);

  const tRoot = lang.home.holdings;

  const { selected, isSelected, toggleSelected, handleNextStep } = useSelectVillas(
    reservationSlots,
    nextStep,
    setSelections,
    setIsLastMinute
  );

  const isNoneSelected = selected.size === 0;

  const confirmButtonText = isLastMinute
    ? t(tRoot.lastMinuteFrac.requestBooking)
    : t(tRoot.villas.confirmSelection);

  return (
    <Col className={className}>
      <Row className={selectionsClassName}>
        <HcText weight="strong">{t(tRoot.villas.infoGeneral)}</HcText>

        {reservationSlots.map((slot, index) => (
          <HcCheckbox
            ariaLabel={resortName}
            labelPosition="left"
            hasContainer
            checked={isSelected(index)}
            onChange={() => toggleSelected(index)}
            key={`selection-${index}`}
          >
            <HcText size="s" bottomSpace="0">
              {resortName}
            </HcText>
            <HcText size="s" bottomSpace="0" colorVariant="weak">
              {room}
            </HcText>

            <HcText size="s" bottomSpace="0" colorVariant="weak">
              {formatDateRangeStr(slot.startDate, slot.endDate)}
            </HcText>
          </HcCheckbox>
        ))}
      </Row>

      <Row className={actionsClassName}>
        <HcButton
          isSimple
          isSecondary
          onClick={() => {
            updateInitUsingLatest(false);
            previousStep();
          }}
        >
          {t(tRoot.villas.addMore)}
        </HcButton>
        <HcButton onClick={handleNextStep} disabled={isNoneSelected} theme="dark">
          {confirmButtonText}
        </HcButton>
        <HcButton
          onClick={() => {
            updateInitUsingLatest(true);
            removeLatest();
            previousStep();
          }}
          isSecondary
        >
          {t(tRoot.villas.back)}
        </HcButton>
      </Row>
    </Col>
  );
};

export default SelectVillas;
