import { useState } from "react";
import { useTypedTranslation } from "../../../features/i18n";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useStartRent = (nextStep: () => void) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { t } = useTypedTranslation();

  const handleNextStep = () => {
    nextStep();
  };

  return {
    isConfirmed,
    toggleIsConfirmed: () => setIsConfirmed((prev) => !prev),
    t,
    handleNextStep,
  };
};

export default useStartRent;
