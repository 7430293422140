import { LoadingState, RootState } from "..";
import { IUser } from "../../features/authorization";
import authorizationSlice from "./authorization-slice";

export const { setUser, resetAuth } = authorizationSlice.actions;
export { checkLogin, checkInvite } from "./authorization-slice";

export interface AuthorizationState {
  user: IUser | undefined;
  loading: LoadingState;
}

export const selectAuthState = (state: RootState): AuthorizationState => state.authorization;
export const selectUser = (state: RootState): IUser | undefined => state.authorization.user;

export default authorizationSlice.reducer;
