import React from "react";
import { lang, TypedTranslate } from "../../../features/i18n";
import HcButton from "../../hc-button";
import HcText from "../../hc-text";
import { WizardStep } from "../../wizard-container";
import LastMinuteInfoText from "./last-minute-info-text";

type Props = {
  resortName: string;
  days: string;
  translate: TypedTranslate;
  isLastMinute: boolean;
} & WizardStep;

const InfoVillasFrac = ({
  nextStep,
  previousStep,
  resortName,
  days,
  translate: t,
  isLastMinute,
}: Props): JSX.Element => {
  const tRoot = lang.home;

  /**
   * Check if some of the selected reservations starts in less than 31 days
   * to show the right confirm button text
   */
  const confirmButtonText = isLastMinute
    ? t(tRoot.holdings.lastMinuteFrac.requestBooking)
    : t(tRoot.holdings.villas.makeReservation);

  return (
    <>
      {isLastMinute ? (
        <LastMinuteInfoText resortName={resortName} days={days} translate={t} />
      ) : (
        <>
          <HcText bottomSpace="xl" className="overlay-description-content" size="s" topSpace="l">
            {t(tRoot.holdings.villas.infoStep.part1, { resortName })}
            <HcText weight="strong" tag="span" size="s">
              {t(tRoot.general.inNDays, { days })}
            </HcText>
            {t(tRoot.holdings.villas.infoStep.part3)}
          </HcText>
        </>
      )}
      <HcButton onClick={() => nextStep()} theme="dark">
        {confirmButtonText}
      </HcButton>
      <HcButton onClick={() => previousStep()} isSecondary>
        {t(tRoot.general.inspectBenefits)}
      </HcButton>
    </>
  );
};

export default InfoVillasFrac;
