import { useState, useEffect } from "react";
import { BankAccountDto, IBankAccountDto, IMyProfileDto } from "../../../app/OwnerService-api";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { selectProfileState } from "../../../store/customer-profile";
import {
  requestProfile as requestCustomersAction,
  updateProfile as updateProfileAction,
} from "../../../store/customer-profile";
import { selectUser } from "../../../store/authorization";
import { MyProfileDto, UpdateMyProfileDto } from "../../../app/OwnerService-api";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useSelectBankAccount = (nextStep: () => void, contractId: number) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [bankAccount, setBankAccount] = useState<IBankAccountDto>();
  const [updatedProfileData, setUpdatedProfileData] = useState<IMyProfileDto | undefined>(
    undefined
  );
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();

  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const profileState = useSelector(selectProfileState);
  const requestCustomers = async () => {
    dispatch(requestCustomersAction(user));
  };

  const updateProfile = async (newProfile: MyProfileDto) => {
    dispatch(
      updateProfileAction({
        requestor: user,
        updatedProfile: new UpdateMyProfileDto(newProfile),
        contractId: contractId,
      })
    );
  };

  const handleBankAccountChange = (account: IBankAccountDto | undefined) => {
    if (account) {
      setBankAccount(account);
    }
  };

  const handleConfirmBankAccount = () => {
    const mergedData: IMyProfileDto = {
      ...updatedProfileData,
      bankAccount: bankAccount as BankAccountDto,
    };
    const newProfileData = new MyProfileDto(mergedData);
    updateProfile(newProfileData);
  };

  const profileData = profileState.profileData[profileState.profileData.length - 1];
  const dataLoading = profileState.loading;

  const handleNextStep = () => {
    handleConfirmBankAccount();
    sendDataToGTM({
      event: "rent_week_account_confirmed",
      event_description: "Vahvista tilinumero",
    });
    nextStep();
  };

  useEffect(() => {
    if (dataLoading === "idle") {
      requestCustomers();
    }
    if (dataLoading === "success") {
      setUpdatedProfileData(profileData);
    }

    if (dataLoading === "success" && profileData.bankAccount && bankAccount === undefined) {
      setBankAccount(profileData.bankAccount);
    }
  }, [requestCustomers, dataLoading]);

  useEffect(() => {
    if (bankAccount) {
      setIsConfirmed(true);
    }
  }, [bankAccount]);

  return {
    isConfirmed,
    t,
    bankAccount,
    dataLoading,
    handleBankAccountChange,
    handleNextStep,
  };
};

export default useSelectBankAccount;
