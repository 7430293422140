import { AccountClient, SignupDto } from "../../../app/OwnerService-api";
import {SignupData} from "../index";
import {useState} from "react";

const useSignup = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const handleContinue = async (values: SignupData) => {
    setIsSubmitted(true)
      const client = new AccountClient();
      const signupDto= {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName
      } as SignupDto;
      
      console.log(signupDto);
      try {
        const response = await client.post(signupDto);
        setIsSubmitReady(true)
        if (response.status === 400) {
          setHasSubmitError(true);
        }
        
      }
      catch (error) {
        setIsSubmitReady(true)
        setHasSubmitError(true);
      }
      
    };
  
  const handleCancel = () => {
      window.location.href = "/";
    };
  
  const handleReset = () => {
      setIsSubmitted(false);
      setIsSubmitReady(false);
      setHasSubmitError(false);
    };
  

  return {
    handleContinue,
    handleCancel,
    handleReset,
    isSubmitted,
    isSubmitReady,
    hasSubmitError
  };
  
};

export default useSignup;
