import React from "react";
import FlexContainer from "../../flex-container";
import Row from "../../flex-row";
import Col from "../../flex-col";
import HcHeading from "../../hc-heading";
import HcButton from "../../hc-button";
import BenefitCard from "../benefit-card";
import { BenefitType, ContentOwnerBenefitDto } from "../../../app/OwnerService-api";
import { TypedTranslate } from "../../../features/i18n";
import BenefitFilterBadge from "../benefit-filter-badge";
import cx from "classnames";
import useBenefitSearch from "./use-benefit-search";
import { lang } from "../../../features/i18n";

import "./benefit-search.scss";

type BenefitSearchProps = {
  benefits: ContentOwnerBenefitDto[];
  translate: TypedTranslate;
  initSearchCategories: BenefitType[] | undefined;
};

const BenefitSearch = ({
  benefits,
  translate: t,
  initSearchCategories,
}: BenefitSearchProps): JSX.Element => {
  const {
    selectAllFilters,
    toggleFilter,
    allSelected,
    visibleBenefits,
    visibleFilters,
  } = useBenefitSearch(benefits, initSearchCategories);
  const tRoot = lang.benefitsPage;

  const selectAllCss = cx("select-all-filters", {
    "select-all-filters--is-active": allSelected,
    "select-all-filters--not-active": !allSelected,
  });

  const headingId = "select-visible-benefits-header";

  return (
    <>
      <div className="benefits-tab--panel benefits-search-wrapper">
        <FlexContainer className="benefits-search-panel">
          <Row className="benefits-search-container">
            <Col className="benefits-search-panel--content" m={6}>
              <HcHeading id={headingId} semanticElement="h5" variant="h3">
                {t(tRoot.search.selectVisibleBenefits)}
              </HcHeading>{" "}
            </Col>
            {visibleFilters.length > 0 &&
              visibleFilters.map((item, i) => {
                return (
                  <Col
                    s={12}
                    m={12}
                    l={2}
                    className="benefits-search-filter-row--item"
                    key={i + item.filterType}
                  >
                    <BenefitFilterBadge
                      filterType={item.filterType}
                      isActive={item.isActive}
                      toggle={() => toggleFilter(item.filterType)}
                      translate={t}
                      describedBy={headingId}
                    />
                  </Col>
                );
              })}
            <Col s={12} m={6} l={3} className="benefits-search-filter-row--button">
              <HcButton onClick={selectAllFilters} className={selectAllCss} isSecondary>
                {t(tRoot.search.showAll)}
              </HcButton>
            </Col>
          </Row>
        </FlexContainer>
      </div>
      <FlexContainer className="benefits-search-card-list-container">
        <Row>
          {visibleBenefits.map((benefit, i) => {
            return (
              <Col
                s={12}
                m={6}
                l={3}
                key={benefit.title + "-" + benefit.category}
                className="benefit-card-col"
              >
                <BenefitCard
                  data={benefit}
                  translate={t}
                  id={i + "-benefit-card"}
                  resetFilters={selectAllFilters}
                />
              </Col>
            );
          })}
        </Row>
      </FlexContainer>
    </>
  );
};
export default BenefitSearch;
