import en from "./translations/en";

/* eslint-disable @typescript-eslint/no-explicit-any */

// Definition is the type of language object
type Definitions = typeof en;

// The wrapper class recording the keys that have been accessed
export class Lang {
  constructor(public paths: PropertyKey[]) {}
}

// The Symbol to access the keys
export const GET_VALUE: any = Symbol("__get");

// factory function creating proxified Lang object
function factory(langObj: Lang) {
  // The proxy intercepts the object access
  const obj = new Proxy(langObj, {
    get: (t, k) => {
      // if the key is GET_VALUE symbol, return the keys joined with "."
      if (k === GET_VALUE) {
        return langObj.paths.join(".");
      }
      // if not, record the current key and generate another proxified Lang object
      // making the Lang object immutable
      // more on that later
      return factory(new Lang([...t.paths, k]));
    },
  }) as any;
  return obj;
}

// The root proxified Lang object
const lang = factory(new Lang([]));

// Lie to TS compiler
// that lang object is of type Definitions (the type of language object)
// and export the object
export default lang as Definitions;
