import { IHoldingDto, WeekType } from "../../../app/OwnerService-api";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { setInitDate } from "../../../store/calendar";
import {
  closeCalendarOverlay as closeCalendarOverlayAction,
  SelectedHolding,
  setSelectedHolding as setSelectedHoldingAction,
} from "../../../store/calendar-overlay";
import { closeModal as closeModalAction } from "../../../store/modal";

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const useVillasReservationModal = (holding: IHoldingDto) => {
  const dispatch = useAppDispatch();
  const { t } = useTypedTranslation();

  const handleReservationButtonClick = () => {
    if (holding.weekType === WeekType.VillasFullOwnership) {
      const currentDay = new Date();
      dispatch(setInitDate(currentDay));
    } else {
      dispatch(closeCalendarOverlayAction());
    }

    /* Unlock the background to scroll */
    document.documentElement.classList.remove("is-locked");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    closeModal();
    const selectedHoldingData: SelectedHolding = holding;
    dispatch(setSelectedHoldingAction(selectedHoldingData));
  };

  const closeModal = () => {
    dispatch(closeModalAction());
  };

  return {
    handleReservationButtonClick,
    t,
  };
};
export default useVillasReservationModal;
