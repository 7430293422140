import { createBrowserHistory, createMemoryHistory, History } from "history";

let history: History;

// Create browser history to use in the Redux store
if (process.env.NODE_ENV !== "test") {
  const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;
  history = createBrowserHistory({ basename: baseUrl });
} else {
  history = createMemoryHistory();
}

export default history;
