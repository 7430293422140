import { RootState } from "..";
import calendarSlice from "./calendar-slice";

export type CalendarState = {
  clickedDate: Date | undefined;
  initDate: Date | undefined;
};

const selectClickedDate = (state: RootState): Date | undefined => state.calendar.clickedDate;
const selectInitDate = (state: RootState): Date | undefined => state.calendar.initDate;

export { selectClickedDate, selectInitDate };

export const {
  setClickedDate,
  clearClickedDate,
  setInitDate,
  clearInitDate,
} = calendarSlice.actions;

export default calendarSlice.reducer;
