import useGTM from "../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useExternalLink = (href?: string) => {
  const { sendDataToGTM } = useGTM();

  const handleClick = () => {
    if (href) {
      // Google Tag Manager
      sendDataToGTM({
        event: "outbound_link_click",
        event_description: href,
      });
    }
  };

  return { handleClick };
};

export default useExternalLink;
