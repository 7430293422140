import { useSelector } from "react-redux";
import { IReserveHoldingDto, ReservationType, WeekType } from "../../../app/OwnerService-api";
import { useTypedTranslation } from "../../../features/i18n";
import { useAppDispatch } from "../../../store";
import { selectUser } from "../../../store/authorization";
import { selectRentFormInformation } from "../../../store/home";
import { reserveHolding as reserveHoldingAction } from "../../../store/reservations";
import { customToISOString } from "../../../features/utils";
import useGTM from "../../../features/use-gtm";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useFinishRentProcess = (
  holdingId: string,
  contractId: number,
  holdingUnitId: number,
  weekNumber: number,
  weekType: WeekType,
  startDate: string | undefined,
  endDate: string | undefined,
  nextStep: () => void
) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const info = useSelector(selectRentFormInformation);
  const { t } = useTypedTranslation();
  const { sendDataToGTM } = useGTM();

  const reserveHolding = async (reservation: IReserveHoldingDto) => {
    dispatch(
      reserveHoldingAction({
        requestor: user,
        ...reservation,
      })
    );
  };

  const confirmForRental = () => {
    if (
      startDate !== undefined &&
      endDate !== undefined &&
      startDate.length > 0 &&
      endDate.length > 0
    ) {
      const newReservation: IReserveHoldingDto = {
        holdingId: holdingId,
        contractId: contractId,
        holdingUnitId: holdingUnitId,
        weekNumber: weekNumber,
        weekType: weekType,
        type: ReservationType.ForRental,
        startDate: customToISOString(new Date(startDate)),
        endDate: customToISOString(new Date(endDate)),
        additionalInformation: info,
      };
      reserveHolding(newReservation);
    }
  };

  /**
   * Confirm rental, send data to GTM and move to next step
   */
  const handleFinishProcess = () => {
    confirmForRental();

    // Google Tag Manager
    sendDataToGTM({
      event: "rent_week_complete",
      event_description: "Aseta vuokralle",
    });

    nextStep();
  };

  return {
    t,
    handleFinishProcess,
  };
};

export default useFinishRentProcess;
