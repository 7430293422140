import { useMemo } from "react";
import { HoldingReserved, ReservationInfo } from "..";
import {
  ReservationStatus,
  WeekType,
  CalendarDto,
  ReservationType,
} from "../../../app/OwnerService-api";
import { DateRangeTheme, ThemedDateRange } from "../../calendar";
import { isPastDate } from "../../date-range/utils";
import { isSameDay } from "date-fns";

/**
 * Get themed calendar ranges
 * @param {HoldingReserved[]} holdings - user holdings
 * @returns {ThemedDateRange[]}
 */
const useCalendarData = (holdings: HoldingReserved[]): ThemedDateRange[] => {
  const calendarData = useMemo(() => {
    /**
     * Get villas theme by reservationStatus
     * @param {ReservationInfo} reservation
     * @returns {DateRangeTheme}
     */
    const getVillasTheme = (reservation: ReservationInfo): DateRangeTheme => {
      switch (reservation.reservationStatus) {
        case ReservationStatus.Pending:
          return "unconfirmed";
        case ReservationStatus.Accepted:
          return "confirmed-black";
        default:
          return "confirmed-primary";
      }
    };

    /**
     * Get week theme: check if user has rented the week
     * @param {ReservationInfo} reservation - reservation info
     * @returns {DateRangeTheme}
     */
    const getWeeksTheme = (reservation: ReservationInfo): DateRangeTheme => {
      return reservation.type === ReservationType.ForRental ? "unconfirmed" : "confirmed-primary";
    };

    /**
     * Get reservation or week theme by weekType
     * @param {HoldingReserved} holding - user holding
     * @param {ReservationInfo} [reservation] - reservation info if available
     * @returns {DateRangeTheme}
     */
    const getDateTheme = (
      holding: HoldingReserved,
      rangeStart: Date,
      reservation?: ReservationInfo
    ): DateRangeTheme => {
      if (isPastDate(rangeStart)) {
        return "unconfirmed";
      }
      if (reservation) {
        switch (holding.weekType) {
          case WeekType.VillasFullOwnership:
            return getVillasTheme(reservation);
          case WeekType.VillasFractional:
            return getVillasTheme(reservation);
          case WeekType.PointsFixed:
          case WeekType.Fixed:
            return getWeeksTheme(reservation);
          default:
            return "confirmed-primary";
        }
      } else {
        switch (holding.weekType) {
          case WeekType.VillasFullOwnership:
            return "unconfirmed";
          case WeekType.VillasFractional:
            return "unconfirmed";
          default:
            return "confirmed-primary";
        }
      }
    };

    const themedCalendarData: ThemedDateRange[] = holdings.reduce<ThemedDateRange[]>(
      (allDateRanges, holding) => {
        const reservedRanges: ThemedDateRange[] = holding.reservations.reduce<ThemedDateRange[]>(
          (reservationDateRanges, reservation) => {
            if (
              reservation.reservationStatus !== ReservationStatus.Accepted &&
              reservation.reservationStatus !== ReservationStatus.Pending
            ) {
              return reservationDateRanges;
            } else {
              const newThemedDateRange: ThemedDateRange = {
                data: {
                  start: new Date(reservation.startDate),
                  end: new Date(reservation.endDate),
                },
                type: getDateTheme(holding, reservation.startDate, reservation),
              };

              return [...reservationDateRanges, newThemedDateRange];
            }
          },
          []
        );

        /**
         * Convert weeks to ThemedDateRanges
         * @param {CalendarDto[]} weeksToTransform
         * @returns {ThemedDateRange[]}
         */
        const weeksToRanges = (weeksToTransform: CalendarDto[]): ThemedDateRange[] =>
          weeksToTransform.reduce<ThemedDateRange[]>((ranges, week) => {
            const isRangeReserved = Boolean(
              reservedRanges.find(
                (range) =>
                  isSameDay(range.data.start, new Date(week.weekStart)) &&
                  isSameDay(range.data.end, new Date(week.weekEnd))
              )
            );

            // If range is already in reservations, don't add it
            if (isRangeReserved) {
              return ranges;
            } else {
              return [
                ...ranges,
                {
                  data: {
                    start: new Date(week.weekStart),
                    end: new Date(week.weekEnd),
                  },
                  type: getDateTheme(holding, new Date(week.weekStart)),
                },
              ];
            }
          }, []);

        const unreservedRanges: ThemedDateRange[] = holding.weeks
          ? weeksToRanges(holding.weeks)
          : [];

        return [...allDateRanges, ...reservedRanges, ...unreservedRanges];
      },
      []
    );

    return themedCalendarData;
  }, [holdings]);

  return calendarData;
};

export default useCalendarData;
