import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarOverlayState, SelectedHolding } from ".";
import { HoldingReserved } from "../../components/holdings-calendar";

const initialState: CalendarOverlayState = {
  isOpen: true,
  activeHolding: undefined,
  isClosing: false,
  selectedHolding: "SHOW_ALL",
};

const calendarOverlaySlice = createSlice({
  initialState,
  name: "calendarOverlay",
  reducers: {
    openCalendarOverlay: (state) => {
      state.isOpen = true;
      state.isClosing = false;
    },
    closeCalendarOverlay: (state) => {
      state.isOpen = false;
      state.isClosing = false;
    },
    setActiveHolding: (state, action: PayloadAction<HoldingReserved>) => {
      state.activeHolding = action.payload;
    },
    clearActiveHolding: (state) => {
      state.activeHolding = undefined;
    },
    onRequestCloseOverlay: (state) => {
      state.isClosing = true;
    },
    cancelOnRequestCloseOverlay: (state) => {
      state.isClosing = false;
    },
    setSelectedHolding: (state, action: PayloadAction<SelectedHolding>) => {
      state.selectedHolding = action.payload;
    },
  },
});

export default calendarOverlaySlice;
