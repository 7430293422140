import React from "react";
import Col from "../../flex-col";
import Row from "../../flex-row";
import HcText from "../../hc-text";
import FooterLink from "../footer-link";
import { lang, TypedTranslate } from "../../../features/i18n";

import FacebookIcon from "../../../images/social-media-icons/facebook.svg";
import InstgramIcon from "../../../images/social-media-icons/instagram.svg";
import TwitterIcon from "../../../images/social-media-icons/twitter.svg";
import PinterestIcon from "../../../images/social-media-icons/pinterest.svg";
import YoutubeIcon from "../../../images/social-media-icons/youtube.svg";
import LinkedinIcon from "../../../images/social-media-icons/linkedin.svg";

import "./social-media-section.scss";

type SocialMediaSectionProps = {
  translate: TypedTranslate;
};

const SocialMediaSection = ({ translate: t }: SocialMediaSectionProps): JSX.Element => {
  const tRoot = lang.footer.followLinks;

  const socialMediaData = [
    {
      link: t(tRoot.facebook.link),
      text: t(tRoot.facebook.text),
      icon: FacebookIcon,
      id: "face_id",
    },
    {
      link: t(tRoot.instagram.link),
      text: t(tRoot.instagram.text),
      icon: InstgramIcon,
      id: "insta_id",
    },
    {
      link: t(tRoot.twitter.link),
      text: t(tRoot.twitter.text),
      icon: TwitterIcon,
      id: "twitter_id",
    },
    {
      link: t(tRoot.pinterest.link),
      text: t(tRoot.pinterest.text),
      icon: PinterestIcon,
      id: "pinterest_id",
    },
    {
      link: t(tRoot.youtube.link),
      text: t(tRoot.youtube.text),
      icon: YoutubeIcon,
      id: "youtube_id",
    },
    {
      link: t(tRoot.linkedin.link),
      text: t(tRoot.linkedin.text),
      icon: LinkedinIcon,
      id: "linkedin_id",
    },
  ];

  return (
    <Col>
      <HcText weight="strong" size="l" colorVariant="white" bottomSpace="l" className="footer-category-text">
        {t(tRoot.categoryName)}
      </HcText>

      <Row>
        {socialMediaData.map((item) => (
          <Col s={4} m={2} l={4} key={item.id}>
            <FooterLink href={item.link} className="social-media-link" external={true}>
              <div className="social-media-link-group">
                <div className="social-media-link-group-icon">
                  <img src={item.icon} alt="" />
                </div>
                <div className="social-media-link-group-text">{item.text}</div>
              </div>
            </FooterLink>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default SocialMediaSection;
