import React from "react";

import { Feedback } from "./";
import FeedbackIcon from "./feedback-icon";
import cx from "classnames";
import "./input-feedback.scss";

const InputFeedback = ({ text, isValid }: Feedback): JSX.Element => {
  const cssClasses = cx("feedback", {
    "feedback--valid": isValid,
    "feedback--invalid": isValid === false,
  });
  return (
    <div className={cssClasses}>
      {isValid !== undefined && <FeedbackIcon isValid={isValid} />}
      {text}
    </div>
  );
};

export default InputFeedback;
